import ApiConnector from "./ApiConnector";
import axios from "axios";

let connector = new ApiConnector(process.env.VUE_APP_API_HOST);

export const getAll = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.MensileApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getAllMensiles(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getById = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.MensileApi();
    apiInstance.getMensileById(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getCount = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.MensileApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.countMensiles(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getPage = (token, page = 1, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.MensileApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getMensilePage(page, opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const add = (token, mensile) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.MensileApi();
    apiInstance.addMensile(mensile, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getByIdWithInclude = (token, id, include) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.MensileApi();

    let opts = {
      selectOptions: {
        options: {
          where: {
            id: id,
          },
          include: include,
        },
      },
    };
    apiInstance.getAllMensiles(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body[0]);
      }
    });
  });
};

export const addConfigurazioneMensileLocale = (
  token,
  locale,
  configurazione
) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ConfigurazioneMensileLocaleApi();
    apiInstance.addConfigurazioneMensileLocale(
      { ...configurazione, locale: locale },
      (error, data, response) => {
        if (error) {
          return reject(error);
        } else {
          resolve(response.body);
        }
      }
    );
  });
};

export const updateConfigurazioneMensileLocale = (
  token,
  locale,
  configurazione
) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ConfigurazioneMensileLocaleApi();
    apiInstance.updateConfigurazioneMensileLocale(
      locale,
      configurazione,
      (error, data, response) => {
        if (error) {
          return reject(error);
        } else {
          resolve(response.body);
        }
      }
    );
  });
};

export const update = (token, id, mensile) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.MensileApi();
    apiInstance.updateMensile(id, mensile, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const updateArticoloMensile = (token, id, data) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloMensileApi();
    apiInstance.updateArticoloMensile(id, data, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const addArticoloMensile = (token, data) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloMensileApi();
    apiInstance.addArticoloMensile(data, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const deleteArticoloMensile = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloMensileApi();
    apiInstance.deleteArticoloMensileById(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const disdettaArticoloMensile = (token, id, motivazione, note) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloMensileApi();
    const data = {
      motivazione: motivazione,
      note: note ? note : null,
    };
    apiInstance.disdiciArticoloMensile(id, data, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const generaFatture = (accessToken) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  //Send request
  return axios.put(
    `${process.env.VUE_APP_API_HOST}/mensile/generaFatture`,
    {},
    config
  );
};

export const effettuaControlli = (accessToken, date = new Date()) => {
  console.log("qui");
  console.log(accessToken);

  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      date,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/mensile/controllaFatturazione`,
    config
  );
};

export const totaleProfili = (accessToken, mese, anno) => {
  console.log(mese);
  console.log(anno);

  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/mensile/totaleProfili/${mese}/${anno}`,
    config
  );
};

export const cambioPiano = (accessToken, id, data) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  //Send request
  return axios.put(
    `${process.env.VUE_APP_API_HOST}/articoloMensile/${id}/cambioPiano`,
    data,
    config
  );
};

export const sposta = (accessToken, id, nuovaScadenza) => {
  const dataScadenza = new Date(nuovaScadenza);
  const dataFormattata = `${dataScadenza.getFullYear()}-${
    dataScadenza.getMonth() + 1
  }-${dataScadenza.getDate()}`;
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  //Send request
  return axios.put(
    `${process.env.VUE_APP_API_HOST}/mensile/${id}/sposta`,
    { scadenza: dataFormattata },
    config
  );
};
