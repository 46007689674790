<template>
  <div>
    <div>
      <b-modal
        centered
        id="modale-disdette"
        :title="
          'Motivazioni Disdette ' +
          modaleMotivazioniDisdette.mese +
          '/' +
          modaleMotivazioniDisdette.anno
        "
        size="lg"
        hide-footer
      >
        <b-table
          striped
          hover
          stacked="lg"
          :items="modaleMotivazioniDisdette.grouped"
          :fields="modaleMotivazioniDisdette.groupedFields"
          @row-clicked="setupModaleDettaglioDisdette"
          class="pointer"
        >
          <template #cell(totale)="data">
            {{ formatPrice(data.item.totale) }}
          </template>
          <template #cell(locali)="data">
            {{ data.item.locali.length }}
          </template>
          <template #cell(clienti)="data">
            {{ data.item.clienti.length }}
          </template>
        </b-table>
      </b-modal>
      <b-modal
        centered
        id="modale-dettaglio-disdette"
        :title="modaleMotivazioniDisdette.dettaglio.titolo"
        size="xl"
        hide-footer
      >
        <b-table
          striped
          hover
          stacked="lg"
          :items="modaleMotivazioniDisdette.dettaglio.items"
          :fields="modaleMotivazioniDisdette.dettaglio.fields"
          :per-page="modaleMotivazioniDisdette.dettaglio.perPage"
          :current-page="modaleMotivazioniDisdette.dettaglio.pagina"
        >
          <template #cell(prezzo)="data">
            {{ formatPrice(data.item.prezzo) }}
          </template>

          <template #cell(nota)="data">
            <font-awesome-icon
              icon="fa-solid fa-circle-info"
              size="xl"
              :id="'popover-' + data.index"
              :style="{
                color: data.item.nota ? 'red' : 'black',
              }"
            />
            <b-popover
              :target="'popover-' + data.index"
              title="Nota"
              triggers="hover focus"
              :content="data.item.nota"
            ></b-popover>
          </template>
        </b-table>
        <b-pagination
          v-model="modaleMotivazioniDisdette.dettaglio.pagina"
          :total-rows="modaleMotivazioniDisdette.dettaglio.items.length"
          :per-page="modaleMotivazioniDisdette.dettaglio.perPage"
        ></b-pagination>
      </b-modal>
      <b-modal
        id="modale-storico-sellout-cic"
        centered
        hide-footer
        title="Storico Sell-Out CIC"
        size="sm"
      >
        <b-table
          :items="modaleStoricoSelloutCic.items"
          :fields="modaleStoricoSelloutCic.fields"
        >
          <template #cell(sellout)="data">
            {{ formatPrice(data.value) }}
          </template>
        </b-table>
      </b-modal>
      <div class="row m-1 mt-4 mb-3">
        <div class="col-lg-3 col-md-6 mb-2 mb-lg-0">
          <b-card class="background-secondary">
            <div class="row">
              <div class="col-9">
                <div class="row">
                  <div class="col">
                    <h3>Scaduti</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h4>{{ formatPrice(totScaduto) }}</h4>
                  </div>
                </div>
              </div>
              <div class="col-3 align-self-center">
                <b-button
                  size="lg"
                  class="background text-color"
                  pill
                  v-b-tooltip.hover
                  title="Visualizza scaduti"
                  @click="routeToScaduti"
                >
                  <font-awesome-icon icon="fa-solid fa-calendar-xmark" />
                </b-button>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-lg-3 col-md-6 mb-2 mb-lg-0">
          <b-card class="background-secondary">
            <div class="row">
              <div class="col-9">
                <div class="row">
                  <div class="col">
                    <h3>Insoluti</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h4>{{ formatPrice(totInsoluti) }}</h4>
                  </div>
                </div>
              </div>
              <div class="col-3 align-self-center">
                <b-button
                  size="lg"
                  class="background text-color"
                  pill
                  v-b-tooltip.hover
                  title="Visualizza insoluti"
                  @click="routeToInsoluti"
                >
                  <!-- <font-awesome-icon icon="fa-brands fa-creative-commons-nc-eu" /> -->
                  <font-awesome-icon icon="fa-solit fa-file-invoice-dollar" />
                </b-button>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-lg-3 col-md-6 mb-2 mb-lg-0">
          <b-card class="background-secondary">
            <div class="row">
              <div class="col-9">
                <div class="row">
                  <div class="col">
                    <h3>Totale Scaduto</h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <h4>{{ formatPrice(totScadutoInsoluto) }}</h4>
                  </div>
                </div>
              </div>
              <div class="col-3 align-self-center">
                <!-- <b-button size="lg">S</b-button> -->
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-lg-3 col-md-6 mb-2 mb-lg-0">
          <b-card class="background-secondary">
            <div class="row">
              <div class="col-9">
                <div class="row">
                  <div class="col">
                    <h3>
                      SellOut&nbsp;CIC&nbsp;{{ new Date().getFullYear() }}
                    </h3>
                  </div>
                </div>
                <div class="row">
                  <div
                    class="col text-decoration-underline"
                    v-b-modal.modale-storico-sellout-cic
                  >
                    <h4>{{ formatPrice(totCic) }}</h4>
                  </div>
                </div>
              </div>
              <div class="col-3 align-self-center">
                <!-- <b-button size="lg">S</b-button> -->
              </div>
            </div>
          </b-card>
        </div>
      </div>
      <div class="row m-1 mb-3">
        <div class="col-lg-6 mb-2 mb-lg-0">
          <!-- FATTURATO CONTABILE -->
          <div class="card p-3 background-secondary">
            <div class="d-flex justify-content-between">
              <h3>Fatturato contabile</h3>
              <b-button
                @click="downloadCSVFatturatoContabile"
                v-b-tooltip.hover
                title="Esporta CSV"
                class="pointer background text-color"
              >
                <font-awesome-icon icon="fa-solid fa-download" />
              </b-button>
            </div>
            <div class="d-flex flex-row mb-2">
              <div class="me-2">
                <label for="anno-base">Anno base:</label>
                <br />
                <b-form-select
                  id="anno-base"
                  v-model="fatturatoContabile.anno_base"
                  class="form-control background-secondary text-color"
                  :options="anni"
                ></b-form-select>
              </div>
              <div>
                <label for="anno-base">Anno confronto:</label>
                <br />
                <b-form-select
                  id="anno-base"
                  v-model="fatturatoContabile.anno_confronto"
                  class="form-control background-secondary text-color"
                  :options="anni"
                ></b-form-select>
              </div>
            </div>

            <div>
              <b-table
                :items="tabellaFatturatoContabile"
                :fields="fieldsTabellaFatturatoContabile"
                bordered
                stacked="sm"
                class="text-color text-end"
                foot-clone
                no-footer-sorting
              >
                <template #cell(base)="data">
                  {{ formatPrice(data.value) }}
                </template>
                <template #cell(confronto)="data">
                  {{ formatPrice(data.value) }}
                </template>
                <template #cell(differenza)="data">
                  {{ formatPrice(data.value) }}
                </template>
                <template #cell(differenza_percentuale)="data">
                  {{ formatPerc(data.value) }}
                </template>
                <template #foot(mese)>
                  {{ totaleTabellaFatturatoContabile.mese }}
                </template>
                <template #foot(base)>
                  {{ formatPrice(totaleTabellaFatturatoContabile.base) }}
                </template>
                <template #foot(confronto)>
                  {{ formatPrice(totaleTabellaFatturatoContabile.confronto) }}
                </template>
                <template #foot(differenza)>
                  {{ formatPrice(totaleTabellaFatturatoContabile.differenza) }}
                </template>
                <template #foot(differenza_percentuale)>
                  {{
                    formatPerc(
                      totaleTabellaFatturatoContabile.differenza_percentuale
                    )
                  }}
                </template>
              </b-table>
            </div>
          </div>
          <!-- FINE FATTURATO CONTABILE -->
        </div>
        <div class="col-lg-6">
          <!-- DISDETTE MESE -->
          <div class="card p-3 background-secondary">
            <div class="d-flex justify-content-between">
              <h3>Disdette</h3>
              <b-button
                @click="downloadCSVDisdette"
                v-b-tooltip.hover
                title="Esporta CSV"
                class="pointer background text-color"
              >
                <font-awesome-icon icon="fa-solid fa-download" />
              </b-button>
            </div>
            <div class="d-flex flex-row mb-2">
              <div class="me-2">
                <label for="anno-base">Anno base:</label>
                <br />
                <b-form-select
                  id="anno-base"
                  v-model="disdetteMese.anno_base"
                  class="form-control background-secondary text-color"
                  :options="anni"
                ></b-form-select>
              </div>
              <div>
                <label for="anno-base">Anno confronto:</label>
                <br />
                <b-form-select
                  id="anno-base"
                  v-model="disdetteMese.anno_confronto"
                  class="form-control background-secondary text-color"
                  :options="anni"
                ></b-form-select>
              </div>
            </div>
            <div>
              <b-table
                :items="tabellaDisdetteMese"
                :fields="fieldsTabellaDisdetteMese"
                bordered
                stacked="sm"
                class="text-color text-end"
                foot-clone
                no-footer-sorting
              >
                <template #cell(base)="data">
                  <span
                    v-b-modal.modale-disdette
                    class="text-decoration-underline"
                    @click="
                      () =>
                        setupModaleMotivazioniDisdette(
                          disdetteMese.anno_base,
                          data.item.id_mese
                        )
                    "
                  >
                    {{ formatPrice(data.value) }}
                  </span>
                </template>
                <template #cell(confronto)="data">
                  <span
                    v-b-modal.modale-disdette
                    class="text-decoration-underline"
                    @click="
                      () =>
                        setupModaleMotivazioniDisdette(
                          disdetteMese.anno_confronto,
                          data.item.id_mese
                        )
                    "
                  >
                    {{ formatPrice(data.value) }}
                  </span>
                </template>
                <template #cell(differenza)="data">
                  {{ formatPrice(data.value) }}
                </template>
                <template #cell(differenza_percentuale)="data">
                  {{ formatPerc(data.value) }}
                </template>
                <template #foot(mese)>
                  {{ totaleTabellaDisdette.mese }}
                </template>
                <template #foot(base)>
                  {{ formatPrice(totaleTabellaDisdette.base) }}
                </template>
                <template #foot(confronto)>
                  {{ formatPrice(totaleTabellaDisdette.confronto) }}
                </template>
                <template #foot(differenza)>
                  {{ formatPrice(totaleTabellaDisdette.differenza) }}
                </template>
                <template #foot(differenza_percentuale)>
                  {{ formatPerc(totaleTabellaDisdette.differenza_percentuale) }}
                </template>
              </b-table>
            </div>
          </div>
          <!-- FINE DISDETTE MESE -->
        </div>
      </div>
      <div class="row m-1 mb-3">
        <!-- GRAFICO FATTURATO -->
        <div class="col-lg-6">
          <div class="card p-3 background-secondary">
            <h3>Fatturato contabile</h3>

            <apexchart
              :options="opzioniGraficoFatturato"
              :series="serieGraficoFatturato"
            ></apexchart>
          </div>
        </div>
        <!-- FINE GRAFICO FATTURATO -->
        <!-- GRAFICO DISDETTE -->
        <div class="col-lg-6">
          <div class="card p-3 background-secondary">
            <h3>Disdette</h3>

            <apexchart
              :options="opzioniGraficoDisdette"
              :series="serieGraficoDisdette"
            ></apexchart>
          </div>
        </div>
        <!-- FINE GRAFICO DISDETTE -->
      </div>
      <div class="row m-1 mb-3">
        <div class="col-lg-8">
          <!-- FATTURATO TOTALE -->
          <div class="card p-3 background-secondary h-100">
            <h3>Fatturato totale ordini</h3>
            <div class="d-flex flex-row mb-2">
              <div class="me-2">
                <label for="anno-base">Anno base:</label>
                <br />
                <b-form-select
                  id="anno-base"
                  v-model="fatturatoTot.anno_base"
                  class="form-control background-secondary text-color"
                  :options="anni"
                ></b-form-select>
              </div>
              <div class="me-2">
                <label for="anno-base">Anno confronto:</label>
                <br />
                <b-form-select
                  id="anno-base"
                  v-model="fatturatoTot.anno_confronto"
                  class="form-control background-secondary text-color"
                  :options="anni"
                ></b-form-select>
              </div>
              <div>
                <label for="mese">Mese:</label>
                <br />
                <b-form-select
                  id="mesi"
                  v-model="fatturatoTot.mese"
                  class="form-control background-secondary text-color"
                  :options="mesi"
                ></b-form-select>
              </div>
            </div>
            <b-table
              :items="tabellaFatturatoTot"
              :fields="fieldsTabellaFatturatoTot"
              bordered
              stacked="sm"
              class="text-color text-end"
              foot-clone
              no-footer-sorting
            >
              <template #cell(base)="data">
                {{ formatPrice(data.value) }}
              </template>
              <template #cell(confronto)="data">
                {{ formatPrice(data.value) }}
              </template>
              <template #cell(differenza)="data">
                {{ formatPrice(data.value) }}
              </template>
              <template #cell(differenza_percentuale)="data">
                {{ formatPerc(data.value) }}
              </template>
              <template #foot(tipo)>
                {{ totaleTabellaFatturatoTot.tipo }}
              </template>
              <template #foot(base)>
                {{ formatPrice(totaleTabellaFatturatoTot.base) }}
              </template>
              <template #foot(confronto)>
                {{ formatPrice(totaleTabellaFatturatoTot.confronto) }}
              </template>
              <template #foot(differenza)>
                {{ formatPrice(totaleTabellaFatturatoTot.differenza) }}
              </template>
              <template #foot(differenza_percentuale)>
                {{
                  formatPerc(totaleTabellaFatturatoTot.differenza_percentuale)
                }}
              </template>
            </b-table>
          </div>
          <!-- FINE FATTURATO TOTALE -->
        </div>
        <div class="col-lg-4">
          <!-- LICENZE CIC -->
          <div
            class="card p-3 h-100 background-secondary"
            style="max-height: 90vh; overflow-y: scroll"
          >
            <h3>Licenze CIC</h3>

            <b-table
              :items="tabellaLicenzeCic"
              :fields="fieldsTabellaLicenzeCic"
              bordered
              stacked="sm"
              class="text-color"
              foot-clone
              no-footer-sorting
            >
              <template #foot(licenza)>
                {{ totaleLicenzeCic.licenza }}
              </template>
              <template #foot(totale)>
                {{ totaleLicenzeCic.totale }}
              </template>
            </b-table>
          </div>
          <!-- FINE LICENZE CIC -->
        </div>
      </div>

      <div class="row m-1 mb-3">
        <div class="col-lg-6">
          <!-- STORICO CLIENTI ABBONATI -->
          <div class="card p-3 background-secondary h-100">
            <div class="d-flex justify-content-between">
              <h3>Storico clienti abbonati</h3>
              <!-- <b-button
              @click="downloadCSVFatturatoContabile"
              v-b-tooltip.hover
              title="Esporta CSV"
              class="pointer background text-color"
            >
              <font-awesome-icon icon="fa-solid fa-download" />
            </b-button> -->
            </div>
            <div class="d-flex flex-row mb-2">
              <div class="me-2">
                <label for="anno-base">Anno base:</label>
                <br />
                <b-form-select
                  id="anno-base"
                  v-model="storicoClientiAbbonati.anno_base"
                  class="form-control background-secondary text-color"
                  :options="anni"
                ></b-form-select>
              </div>
              <div>
                <label for="anno-base">Anno confronto:</label>
                <br />
                <b-form-select
                  id="anno-base"
                  v-model="storicoClientiAbbonati.anno_confronto"
                  class="form-control background-secondary text-color"
                  :options="anni"
                ></b-form-select>
              </div>
            </div>

            <div style="max-height: 90vh; overflow-y: scroll">
              <b-table
                :items="tabellaClientiAbbonati"
                :fields="fieldsTabellaClientiAbbonati"
                bordered
                stacked="sm"
                class="text-color text-end"
              >
                <template #cell(differenza_percentuale)="data">
                  {{ formatPerc(data.value) }}
                </template>
              </b-table>
            </div>
          </div>
          <!-- FINE STORICO CLIENTI ABBONATI -->
        </div>
        <div class="col-lg-6">
          <!-- STORICO CLIENTI ABBONATI -->
          <div class="card p-3 background-secondary">
            <div class="d-flex justify-content-between">
              <h3>Storico clienti abbonati per profilo</h3>
              <!-- <b-button
              @click="downloadCSVFatturatoContabile"
              v-b-tooltip.hover
              title="Esporta CSV"
              class="pointer background text-color"
            >
              <font-awesome-icon icon="fa-solid fa-download" />
            </b-button> -->
            </div>
            <div class="d-flex flex-row mb-2">
              <div class="me-2">
                <label for="anno-base">Anno base:</label>
                <br />
                <b-form-select
                  id="anno-base"
                  v-model="storicoClientiAbbonatiPerProfilo.anno_base"
                  class="form-control background-secondary text-color"
                  :options="anni"
                ></b-form-select>
              </div>
              <div class="me-2">
                <label for="anno-base">Anno confronto:</label>
                <br />
                <b-form-select
                  id="anno-base"
                  v-model="storicoClientiAbbonatiPerProfilo.anno_confronto"
                  class="form-control background-secondary text-color"
                  :options="anni"
                ></b-form-select>
              </div>
              <div>
                <label for="anno-base">Mese:</label>
                <br />
                <b-form-select
                  id="anno-base"
                  v-model="storicoClientiAbbonatiPerProfilo.mese"
                  class="form-control background-secondary text-color"
                  :options="mesi"
                ></b-form-select>
              </div>
            </div>

            <div style="max-height: 100vh; overflow-y: scroll">
              <b-table
                :items="tabellaClientiAbbonatiPerProfilo"
                :fields="fieldsTabellaClientiAbbonatiPerProfilo"
                bordered
                stacked="sm"
                class="text-color text-end"
                foot-clone
                no-footer-sorting
              >
                <template #cell(differenza_percentuale)="data">
                  {{ formatPerc(data.value) }}
                </template>
                <template #foot(profilo)>
                  {{ totaleTabellaClientiAbbonatiPerProfilo.profilo }}
                </template>
                <template #foot(base)>
                  {{ totaleTabellaClientiAbbonatiPerProfilo.base }}
                </template>
                <template #foot(confronto)>
                  {{ totaleTabellaClientiAbbonatiPerProfilo.confronto }}
                </template>
                <template #foot(differenza)>
                  {{ totaleTabellaClientiAbbonatiPerProfilo.differenza }}
                </template>
                <template #foot(differenza_percentuale)>
                  {{
                    formatPerc(
                      totaleTabellaClientiAbbonatiPerProfilo.differenza_percentuale
                    )
                  }}
                </template>
              </b-table>
            </div>
          </div>
          <!-- FINE STORICO CLIENTI ABBONATI -->
        </div>
      </div>
      <div class="row m-1 mb-3">
        <div class="col-lg-6">
          <!-- GRAFICO STORICO CLIENTI ABBONATI -->
          <div class="card p-3 background-secondary h-100">
            <h3>Storico clienti abbonati</h3>
            <apexchart
              :options="opzioniGraficoClientiAbbonati"
              :series="serieGraficoClientiAbbonati"
            ></apexchart>
          </div>
          <!-- FINE GRAFICO STORICO CLIENTI ABBONATI -->
        </div>
        <div class="col-lg-6">
          <!-- GRAFICO STORICO CLIENTI ABBONATI PROFILO -->
          <div class="card p-3 background-secondary h-100">
            <h3>Storico clienti abbonati per profilo</h3>
            <apexchart
              :options="opzioniGraficoClientiAbbonatiPerProfilo"
              :series="serieGraficoClientiAbbonatiPerProfilo"
            ></apexchart>
          </div>
          <!-- FINE GRAFICO STORICO CLIENTI ABBONATI PROFILO -->
        </div>
      </div>
      <div class="row m-1 mb-3">
        <div class="col-lg-6">
          <!-- PREVISIONE RINNOVI -->
          <div class="card p-3 background-secondary h-100">
            <div class="d-flex justify-content-between">
              <h3>Previsione rinnovi</h3>
              <!-- <b-button
              @click="downloadCSVFatturatoContabile"
              v-b-tooltip.hover
              title="Esporta CSV"
              class="pointer background text-color"
            >
              <font-awesome-icon icon="fa-solid fa-download" />
            </b-button> -->
            </div>
            <div class="d-flex flex-row mb-2">
              <div class="me-2">
                <label for="anno-base">Anno:</label>
                <br />
                <b-form-select
                  id="anno"
                  v-model="previsioneRinnovi.anno"
                  class="form-control background-secondary text-color"
                  :options="anniRinnoviAnnuali"
                ></b-form-select>
              </div>
              <div class="me-2">
                <label for="anno-base">Mese:</label>
                <br />
                <b-form-select
                  id="mesi"
                  v-model="previsioneRinnovi.mese"
                  class="form-control background-secondary text-color"
                  :options="mesi"
                ></b-form-select>
              </div>
            </div>
            <b-table
              :items="tabellaPrevisioneRinnoviAnnuali"
              :fields="fieldsTabellaPrevisioneRinnoviAnnuali"
              bordered
              stacked="sm"
              class="text-color text-end"
              foot-clone
              no-footer-sorting
            >
              <template #cell(totale)="data">
                {{ formatPrice(data.value) }}
              </template>
              <template #foot(profilo)>
                {{ totaleTabellaPrevisioneRinnoviAnnuali.profilo }}
              </template>
              <template #foot(rinnovi)>
                {{ totaleTabellaPrevisioneRinnoviAnnuali.rinnovi }}
              </template>
              <template #foot(totale)>
                {{ formatPrice(totaleTabellaPrevisioneRinnoviAnnuali.totale) }}
              </template>
            </b-table>
          </div>
          <!-- FINE PREVISIONE RINNOVI -->
        </div>
        <div class="col-lg-6">
          <!-- PREVISIONE MENSILI -->
          <div class="card p-3 background-secondary h-100">
            <div class="d-flex justify-content-between">
              <h3>Previsione mensili</h3>
              <!-- <b-button
              @click="downloadCSVFatturatoContabile"
              v-b-tooltip.hover
              title="Esporta CSV"
              class="pointer background text-color"
            >
              <font-awesome-icon icon="fa-solid fa-download" />
            </b-button> -->
            </div>
            <div class="d-flex flex-row mb-2">
              <div class="me-2">
                <label for="anno-base">Anno:</label>
                <br />
                <b-form-select
                  id="anno"
                  v-model="previsioneMensili.anno"
                  class="form-control background-secondary text-color"
                  :options="anniRinnoviMensili"
                ></b-form-select>
              </div>
              <div class="me-2">
                <label for="anno-base">Mese:</label>
                <br />
                <b-form-select
                  id="mesi"
                  v-model="previsioneMensili.mese"
                  class="form-control background-secondary text-color"
                  :options="mesi"
                ></b-form-select>
              </div>
            </div>
            <b-table
              :items="tabellaPrevisioneRinnoviMensili"
              :fields="fieldsTabellaPrevisioneRinnoviMensili"
              bordered
              stacked="sm"
              class="text-color text-end"
              foot-clone
              no-footer-sorting
            >
              <template #cell(totale)="data">
                {{ formatPrice(data.value) }}
              </template>
              <template #foot(profilo)>
                {{ totaleTabellaPrevisioneRinnoviMensili.profilo }}
              </template>
              <template #foot(rinnovi)>
                {{ totaleTabellaPrevisioneRinnoviMensili.rinnovi }}
              </template>
              <template #foot(totale)>
                {{ formatPrice(totaleTabellaPrevisioneRinnoviMensili.totale) }}
              </template>
            </b-table>
          </div>
          <!-- FINE PREVISIONE MENSILI -->
        </div>
      </div>
      <div class="row m-1 mb-3">
        <div class="col-lg-5">
          <!-- FATTURATO CONTABILE PER TIPO -->
          <div class="card p-3 background-secondary h-100">
            <div class="d-flex justify-content-between">
              <h3>Fatturato contabile per tipo ordine</h3>
              <!-- <b-button
              @click="downloadCSVFatturatoContabile"
              v-b-tooltip.hover
              title="Esporta CSV"
              class="pointer background text-color"
            >
              <font-awesome-icon icon="fa-solid fa-download" />
            </b-button> -->
            </div>
            <div class="d-flex flex-row mb-2">
              <div class="me-2">
                <label for="anno-base">Anno:</label>
                <br />
                <b-form-select
                  id="anno"
                  v-model="fatturatoContabilePerTipo.anno"
                  class="form-control background-secondary text-color"
                  :options="anniFatturatoContabilePerTipo"
                ></b-form-select>
              </div>
              <div class="me-2">
                <label for="anno-base">Mese:</label>
                <br />
                <b-form-select
                  id="mesi"
                  v-model="fatturatoContabilePerTipo.mese"
                  class="form-control background-secondary text-color"
                  :options="mesi"
                ></b-form-select>
              </div>
            </div>
            <b-table
              :items="tabellaFatturatoContabilePerTipo"
              :fields="fieldsTabellaFatturatoContabilePerTipo"
              bordered
              stacked="sm"
              class="text-color text-end"
              foot-clone
              no-footer-sorting
            >
              <template #cell(tipo)="data">
                {{ data.value }}
              </template>
              <template #cell(totale)="data">
                {{ formatPrice(data.value) }}
              </template>
              <template #foot(tipo)>
                {{ totaleTabellaFatturatoContabilePerTipo.tipo }}
              </template>
              <template #foot(totale)>
                {{ formatPrice(totaleTabellaFatturatoContabilePerTipo.totale) }}
              </template>
            </b-table>
          </div>
          <!-- FINE FATTURATO CONTABILE PER TIPO -->
        </div>
        <div class="col-lg-7">
          <!-- GRAFICO FATTURATO CONTABILE PER TIPO -->
          <div class="card p-3 background-secondary h-100">
            <div class="d-flex justify-content-between">
              <h3>Fatturato contabile per tipo ordine</h3>
              <!-- <b-button
              @click="downloadCSVFatturatoContabile"
              v-b-tooltip.hover
              title="Esporta CSV"
              class="pointer background text-color"
            >
              <font-awesome-icon icon="fa-solid fa-download" />
            </b-button> -->
            </div>
            <div class="d-flex flex-row mb-2">
              <div class="me-2">
                <label for="tipo-ordine-xyz">Tipo:</label>
                <br />
                <b-form-select
                  id="tipo-ordine-xyz"
                  class="form-control background-secondary text-color"
                  v-model="graficoTipoOrdine.tipo"
                  :options="
                    [
                      { text: 'Tutti', value: -1 },
                      { text: 'SENZA ORDINE (CUBE)', value: null },
                    ].concat(
                      tipiOrdine.map((tipo) => {
                        return { text: tipo.descrizione, value: tipo.id };
                      })
                    )
                  "
                ></b-form-select>
              </div>
            </div>
            <apexchart
              :options="opzioniGraficoFatturatoContabilePerTipoOrdine"
              :series="serieGraficoFatturatoContabilePerTipoOrdine"
            ></apexchart>
          </div>
          <!-- FINE PREVISIONE MENSILI -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as statisticheService from "../services/Statistiche";

export default {
  name: "Statistiche",
  components: {},
  data: function () {
    return {
      modaleMotivazioniDisdette: {
        anno: null,
        mese: null,
        dettaglio: {
          pagina: 1,
          perPage: 10,
          titolo: "",
          items: [],
          fields: [
            { key: "cod_cli" },
            { key: "cliente" },
            { key: "locale" },
            { key: "articolo" },
            { key: "prezzo", tdClass: "text-end" },
            { key: "utente" },
            { key: "nota" },
          ],
        },
        grouped: [],
        groupedFields: [
          { key: "motivo" },
          { key: "clienti", thClass: "text-center", tdClass: "text-center" },
          { key: "locali", thClass: "text-center", tdClass: "text-center" },
          { key: "articoli", thClass: "text-center", tdClass: "text-center" },
          { key: "totale", thClass: "text-end", tdClass: "text-end" },
        ],
        items: [],
        fields: [
          { key: "cliente" },
          { key: "locale" },
          { key: "articolo" },
          { key: "prezzo" },
          { key: "motivo" },
        ],
      },
      modaleStoricoSelloutCic: {
        items: [],
        fields: [
          { key: "anno" },
          {
            key: "sellout",
            label: "Sell-Out",
            thClass: "text-end",
            tdClass: "text-end",
          },
        ],
      },
      totScaduto: 0,
      totInsoluti: 0,
      totScadutoInsoluto: 0,
      totCic: 0,
      fatturatoContabile: {
        items: [],
        anno_base: new Date().getFullYear() - 1,
        anno_confronto: new Date().getFullYear(),
      },
      fatturatoContabilePerTipo: {
        items: [],
        anno: new Date().getFullYear(),
        mese: new Date().getMonth() + 1,
      },
      disdetteMese: {
        items: [],
        anno_base: new Date().getFullYear() - 1,
        anno_confronto: new Date().getFullYear(),
      },
      fatturatoTot: {
        items: [],
        anno_base: new Date().getFullYear() - 1,
        anno_confronto: new Date().getFullYear(),
        mese: new Date().getMonth() + 1,
      },
      previsioneRinnovi: {
        items: [],
        anno: new Date().getFullYear(),
        mese: new Date().getMonth() + 1,
      },
      previsioneMensili: {
        items: [],
        anno: new Date().getFullYear(),
        mese: new Date().getMonth() + 1,
      },
      storicoClientiAbbonati: {
        items: [],
        anno_base: new Date().getFullYear() - 1,
        anno_confronto: new Date().getFullYear(),
      },
      storicoClientiAbbonatiPerProfilo: {
        items: [],
        anno_base: new Date().getFullYear() - 1,
        anno_confronto: new Date().getFullYear(),
        mese: 1,
      },
      licenzeCic: [],
      mesi: [
        { value: 1, text: "Gennaio" },
        { value: 2, text: "Febbraio" },
        { value: 3, text: "Marzo" },
        { value: 4, text: "Aprile" },
        { value: 5, text: "Maggio" },
        { value: 6, text: "Giugno" },
        { value: 7, text: "Luglio" },
        { value: 8, text: "Agosto" },
        { value: 9, text: "Settembre" },
        { value: 10, text: "Ottobre" },
        { value: 11, text: "Novembre" },
        { value: 12, text: "Dicembre" },
      ],
      graficoTipoOrdine: {
        tipo: -1,
      },
    };
  },
  async created() {
    await this.downloadAll();
    const accessToken = await this.$auth.getTokenSilently();
    await this.$store.dispatch("caricaProfiliLocale", accessToken);
    await this.$store.dispatch("caricaTipiOrdine", accessToken);
  },
  methods: {
    setupModaleDettaglioDisdette(arg) {
      this.modaleMotivazioniDisdette.dettaglio.titolo =
        "Disdette per " + arg.motivo;
      this.modaleMotivazioniDisdette.dettaglio.items = arg.items;
      this.modaleMotivazioniDisdette.dettaglio.pagina = 1;
      this.$bvModal.show("modale-dettaglio-disdette");
    },
    async setupModaleMotivazioniDisdette(anno, mese) {
      this.modaleMotivazioniDisdette.items = [];
      this.modaleMotivazioniDisdette.grouped = [];
      this.modaleMotivazioniDisdette.mese = mese;
      this.modaleMotivazioniDisdette.anno = anno;
      await this.getDisdetteMotivoMese(mese, anno);
    },
    routeToScaduti() {
      this.$router.push({
        name: "listaScaduti",
      });
    },
    routeToInsoluti() {
      this.$router.push({
        name: "listaInsoluti",
      });
    },
    async downloadAll() {
      const promises = [
        this.getTotScaduto(),
        this.getTotInsoluti(),
        this.getTotScadutoInsoluto(),
        this.getStoricoCic(),
        this.getFatturatoContabile(),
        this.getFatturatoContabilePerTipo(),
        this.getDisdetteMese(),
        this.getFatturatoTot(),
        this.getPrevisioneRinnovi(),
        this.getPrevisioneMensili(),
        this.getStoricoClientiAbbonati(),
        this.getLicenzeCic(),
      ];
      this.$loading(true);
      const response = Promise.all(promises).finally(() => {
        this.$loading(false);
      });
    },
    async getTotScaduto(options = {}) {
      console.log("scarico totale scaduto");
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.totScaduto(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione tot scaduto senza dati");
          return;
        }
        this.totScaduto = response.data[0].tot_scaduto;
      } catch (e) {
        console.error("Errore estrazione tot scaduto");
      }
    },
    async getTotInsoluti(options = {}) {
      console.log("scarico totale insoluto");

      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.totInsoluti(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione tot insoluti senza dati");
          return;
        }
        this.totInsoluti = response.data[0].insoluti;
      } catch (e) {
        console.error("Errore estrazione tot insoluti");
      }
    },
    async getTotScadutoInsoluto(options = {}) {
      console.log("scarico totale scaduto insoluto");

      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.totScadutoInsoluto(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione tot scaduto insoluto senza dati");
          return;
        }
        this.totScadutoInsoluto = response.data[0].totale_scaduto_insoluto;
      } catch (e) {
        console.error("Errore estrazione tot scaduto insoluto");
      }
    },
    async getStoricoCic(options = {}) {
      console.log("scarico storico sellout cic");

      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.storicoSelloutCic(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione storico sellout cic senza dati");
          return;
        }
        this.totCic = 0;

        //Imposto lo storico ordinato per anno
        this.modaleStoricoSelloutCic.items = response.data.sort(
          (a, b) => a.anno - b.anno
        );

        //Cerco il valore per l'anno attuale
        const actual = response.data.find(
          (value) => value.anno == new Date().getFullYear()
        );
        if (actual) this.totCic = actual.sellout;
      } catch (e) {
        console.log(e);
        console.error("Errore estrazione storico sellout cic");
      }
    },
    async getFatturatoContabile(options = {}) {
      console.log("scarico fatturato contabile");

      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.fatturatoContabile(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione fatturato contabile senza dati");
          return;
        }
        this.fatturatoContabile.items = response.data;
      } catch (e) {
        console.error("Errore estrazione fatturato contabile");
      }
    },
    async getFatturatoContabilePerTipo(options = {}) {
      console.log("scarico fatturato contabile per tipo");

      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.fatturatoContabilePerTipo(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione fatturato contabile per tipo senza dati");
          return;
        }
        this.fatturatoContabilePerTipo.items = response.data;
      } catch (e) {
        console.error("Errore estrazione fatturato contabile per tipo");
      }
    },
    async getDisdetteMese(options = {}) {
      console.log("scarico disdette mese");

      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.disdetteMese(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione disdette mese senza dati");
          return;
        }
        this.disdetteMese.items = response.data;
      } catch (e) {
        console.error("Errore estrazione disdette mese");
      }
    },
    async getDisdetteMotivoMese(mese, anno) {
      console.log("scarico disdette mese");

      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.disdetteMotivoMese(
          accessToken,
          mese,
          anno
        );
        if (!response.data) {
          console.error("Estrazione disdette motivo mese senza dati");
          return;
        }
        // this.modaleMotivazioniDisdette.items = response.data;
        //Raggruppo disdette per motivo
        const grouped = [];
        let tot = 0;
        for (let disdetta of response.data) {
          const index = grouped.findIndex(
            (search) => search.motivo === disdetta.motivo
          );
          tot += disdetta.prezzo;
          console.log(disdetta);
          if (index == -1) {
            grouped.push({
              motivo: disdetta.motivo,
              articoli: 1,
              totale: disdetta.prezzo,
              items: [disdetta],
              locali: [disdetta.locale],
              clienti: [disdetta.cod_cli],
            });
          } else {
            grouped[index].articoli++;
            grouped[index].totale += disdetta.prezzo;
            grouped[index].items.push(disdetta);
            //Conto locali
            if (
              grouped[index].locali.findIndex(
                (value) => value == disdetta.locale
              ) == -1
            ) {
              grouped[index].locali.push(disdetta.locale);
            }
            //Conto clienti
            if (
              grouped[index].clienti.findIndex(
                (value) => value == disdetta.cod_cli
              ) == -1
            ) {
              grouped[index].clienti.push(disdetta.cod_cli);
            }
          }
        }
        console.log(grouped);
        this.modaleMotivazioniDisdette.grouped = grouped;
        console.log(tot);
      } catch (e) {
        console.error("Errore estrazione disdette motivo mese");
        console.log(e);
      }
    },
    async getFatturatoTot(options = {}) {
      console.log("scarico fatturato totale");

      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.fatturatoTot(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione fatturato tot senza dati");
          return;
        }
        this.fatturatoTot.items = response.data;
      } catch (e) {
        console.error("Errore estrazione fatturato tot");
      }
    },
    async getPrevisioneRinnovi(options = {}) {
      console.log("scarico previsione rinnovi");

      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.previsioneRinnovi(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione previsione rinnovi senza dati");
          return;
        }
        this.previsioneRinnovi.items = response.data;
      } catch (e) {
        console.error("Errore estrazione previsione rinnovi");
        console.log(e);
      }
    },
    async getPrevisioneMensili(options = {}) {
      console.log("scarico previsione mensili");

      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.previsioneMensili(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione previsione mensili senza dati");
          return;
        }
        this.previsioneMensili.items = response.data;
      } catch (e) {
        console.error("Errore estrazione previsione mensili");
        console.log(e);
      }
    },
    async getPrevisioneMensili(options = {}) {
      console.log("scarico previsione mensili");

      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.previsioneMensili(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione previsione mensili senza dati");
          return;
        }
        this.previsioneMensili.items = response.data;
      } catch (e) {
        console.error("Errore estrazione previsione rinnovi");
        console.log(e);
      }
    },

    async getStoricoClientiAbbonati(options = {}) {
      console.log("scarico storico clienti abbonati");
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.storicoClientiAbbonati(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione storico clienti abbonati senza dati");
          return;
        }
        this.storicoClientiAbbonati.items = response.data;
        this.storicoClientiAbbonatiPerProfilo.items = JSON.parse(
          JSON.stringify(response.data)
        );
      } catch (e) {
        console.error("Errore estrazione storico clienti abbonati");
      }
    },

    async getLicenzeCic(options = {}) {
      console.log("scarico licenze cic");
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await statisticheService.licenzeCic(
          accessToken,
          options
        );
        if (!response.data) {
          console.error("Estrazione licenze cic senza dati");
          return;
        }
        this.licenzeCic = response.data;
      } catch (e) {
        console.error("Errore estrazione licenze cic");
      }
    },
    formatPrice(number) {
      if (!number) number = 0;
      return (
        Number(number).toLocaleString("it-IT", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }) + "€"
      );
    },
    formatPerc(number) {
      if (!number) number = 0;
      return parseFloat(number).toFixed(2) + "%";
    },
    downloadCSVFatturatoContabile() {
      try {
        document
          .getElementById("apexchartsgraficoxfatturato")
          .getElementsByClassName("exportCSV")[0]
          .click();
      } catch (e) {
        this.$toast.error("Errore generazione CSV");
      }
    },
    downloadCSVDisdette() {
      try {
        document
          .getElementById("apexchartsgraficoxdisdette")
          .getElementsByClassName("exportCSV")[0]
          .click();
      } catch (e) {
        this.$toast.error("Errore generazione CSV");
      }
    },
  },
  computed: {
    isAdmin() {
      if (this.$auth.user && this.$auth.user["https://rca-api/roles"]) {
        return (
          this.$auth.user["https://rca-api/roles"].roles.indexOf(
            "Administrator"
          ) != -1
        );
      }
      return false;
    },
    tabellaFatturatoContabile() {
      let stat = [];
      const base = this.fatturatoContabile.anno_base;
      const confronto = this.fatturatoContabile.anno_confronto;

      for (const mese of this.mesi) {
        //Cerco il fatturato nell'anno base al mese mese
        let valore_base = this.fatturatoContabile.items.find((value) => {
          return value.anno == base && value.id_mese == mese.value;
        });
        if (!valore_base) valore_base = { totale: 0 };

        //Cerco il fatturato nell'anno di confronto al mese mese
        let valore_confronto = this.fatturatoContabile.items.find((value) => {
          return value.anno == confronto && value.id_mese == mese.value;
        });
        if (!valore_confronto) valore_confronto = { totale: 0 };

        //Calcolo la differenza
        let differenza = valore_confronto.totale - valore_base.totale;
        let differenza_percentuale = 0;
        if (valore_base.totale > 0) {
          differenza_percentuale = (differenza * 100) / valore_base.totale;
        }
        //Aggiungo alle statistiche
        stat.push({
          mese: mese.text,
          base: valore_base.totale,
          confronto: valore_confronto.totale,
          differenza: differenza,
          differenza_percentuale: differenza_percentuale,
        });
      }

      return stat;
    },
    fieldsTabellaFatturatoContabile() {
      const base = this.fatturatoContabile.anno_base;
      const confronto = this.fatturatoContabile.anno_confronto;

      const fields = [
        { key: "mese", thStyle: "text-align:start", tdClass: "text-start" },
        { key: `base`, label: `Fatturato ${base}`, sortable: true },
        {
          key: `confronto`,
          label: `Fatturato ${confronto}`,
          sortable: true,
        },
        { key: "differenza", label: "Differenza (€)", sortable: true },
        {
          key: "differenza_percentuale",
          label: "Differenza (%)",
          sortable: true,
        },
      ];
      return fields;
    },
    totaleTabellaFatturatoContabile() {
      let totaleBase = 0;
      let totaleConfronto = 0;
      this.tabellaFatturatoContabile.forEach((item) => {
        totaleBase += item.base;
        totaleConfronto += item.confronto;
      });

      let diffPerc = 0;
      if (totaleBase > 0) {
        diffPerc = ((totaleConfronto - totaleBase) * 100) / totaleBase;
      } else if (totaleConfronto > 0) {
        diffPerc = 100;
      }

      return {
        mese: "Totale",
        base: totaleBase,
        confronto: totaleConfronto,
        differenza: totaleConfronto - totaleBase,
        differenza_percentuale: diffPerc,
      };
    },
    tabellaDisdetteMese() {
      let stat = [];
      const base = this.disdetteMese.anno_base;
      const confronto = this.disdetteMese.anno_confronto;
      for (const mese of this.mesi) {
        //Cerco il fatturato nell'anno base al mese mese
        let valore_base = this.disdetteMese.items.find((value) => {
          return value.anno == base && value.mese == mese.value;
        });
        if (!valore_base) valore_base = { totale: 0 };

        //Cerco il fatturato nell'anno di confronto al mese mese
        let valore_confronto = this.disdetteMese.items.find((value) => {
          return value.anno == confronto && value.mese == mese.value;
        });
        if (!valore_confronto) valore_confronto = { totale: 0 };
        let differenza = valore_confronto.totale - valore_base.totale;
        let differenza_percentuale = 0;
        if (valore_base.totale > 0) {
          differenza_percentuale = (differenza * 100) / valore_base.totale;
        }
        stat.push({
          mese: mese.text,
          id_mese: mese.value,
          base: valore_base.totale,
          confronto: valore_confronto.totale,
          differenza: differenza,
          differenza_percentuale: differenza_percentuale,
        });
      }

      return stat;
    },
    fieldsTabellaDisdetteMese() {
      const base = this.disdetteMese.anno_base;
      const confronto = this.disdetteMese.anno_confronto;

      const fields = [
        { key: "mese", thStyle: "text-align:start", tdClass: "text-start" },
        { key: `base`, label: `Disdette ${base}`, sortable: true },
        {
          key: `confronto`,
          label: `Disdette ${confronto}`,
          sortable: true,
        },
        { key: "differenza", label: "Differenza (€)", sortable: true },
        {
          key: "differenza_percentuale",
          label: "Differenza (%)",
          sortable: true,
        },
      ];
      return fields;
    },
    totaleTabellaDisdette() {
      let totaleBase = 0;
      let totaleConfronto = 0;
      this.tabellaDisdetteMese.forEach((item) => {
        totaleBase += item.base;
        totaleConfronto += item.confronto;
      });

      let diffPerc = 0;
      if (totaleBase > 0) {
        diffPerc = ((totaleConfronto - totaleBase) * 100) / totaleBase;
      } else if (totaleConfronto > 0) {
        diffPerc = 100;
      }

      return {
        mese: "Totale",
        base: totaleBase,
        confronto: totaleConfronto,
        differenza: totaleConfronto - totaleBase,
        differenza_percentuale: diffPerc,
      };
    },
    tabellaFatturatoTot() {
      let stat = [];
      const base = this.fatturatoTot.anno_base;
      const confronto = this.fatturatoTot.anno_confronto;
      const mese = this.fatturatoTot.mese;

      let itemBase = this.fatturatoTot.items.find((value) => {
        return value.id_mese == mese && value.anno == base;
      });
      let itemConfronto = this.fatturatoTot.items.find((value) => {
        return value.id_mese == mese && value.anno == confronto;
      });

      const keys = ["mensili", "ordini", "rinnovi", "acconti", "notecredito"];
      for (const key of keys) {
        const totBase = itemBase ? itemBase[key] : 0;
        const totConfronto = itemConfronto ? itemConfronto[key] : 0;
        const diff = totConfronto - totBase;
        let diffPerc = 100;
        if (totBase > 0) {
          diffPerc = (diff * 100) / totBase;
        }
        stat.push({
          tipo: key,
          base: totBase,
          confronto: totConfronto,
          differenza: diff,
          differenza_percentuale: diffPerc,
        });
      }

      // for (const mese of this.mesi) {
      //   //Cerco il fatturato nell'anno base al mese mese
      //   const valore_base = this.fatturatoContabile.items.find((value) => {
      //     return value.anno == base && value.id_mese == mese.value;
      //   });
      //   if (!valore_base) valore_base = { totale: 0 };

      //   //Cerco il fatturato nell'anno di confronto al mese mese
      //   const valore_confronto = this.fatturatoContabile.items.find((value) => {
      //     return value.anno == confronto && value.id_mese == mese.value;
      //   });
      //   if (!valore_confronto) valore_confronto = { totale: 0 };
      //   let differenza = valore_confronto.totale - valore_base.totale;
      //   let differenza_percentuale = 0;
      //   if (valore_confronto.totale > 0) {
      //     differenza_percentuale = (differenza * 100) / valore_confronto.totale;
      //   }
      //   stat.push({
      //     mese: mese.text,
      //     "base": this.formatPrice(valore_base.totale),
      //     "confronto": this.formatPrice(
      //       valore_confronto.totale
      //     ),
      //     differenza: this.formatPrice(differenza),
      //     differenza_percentuale: this.formatPerc(differenza_percentuale),
      //   });
      // }
      return stat;
    },
    fieldsTabellaFatturatoTot() {
      const base = this.fatturatoTot.anno_base;
      const confronto = this.fatturatoTot.anno_confronto;

      const fields = [
        { key: "tipo", thStyle: "text-align:start", tdClass: "text-start" },
        { key: `base`, label: `${base}`, sortable: true },
        {
          key: `confronto`,
          label: `${confronto}`,
          sortable: true,
        },
        { key: "differenza", label: "Differenza (€)", sortable: true },
        {
          key: "differenza_percentuale",
          label: "Differenza (%)",
          sortable: true,
        },
      ];
      return fields;
    },
    totaleTabellaFatturatoTot() {
      let totaleBase = 0;
      let totaleConfronto = 0;
      this.tabellaFatturatoTot.forEach((item) => {
        totaleBase += item.base;
        totaleConfronto += item.confronto;
      });

      let diffPerc = 0;
      if (totaleBase > 0) {
        diffPerc = ((totaleConfronto - totaleBase) * 100) / totaleBase;
      } else if (totaleConfronto > 0) {
        diffPerc = 100;
      }

      return {
        tipo: "Totale",
        base: totaleBase,
        confronto: totaleConfronto,
        differenza: totaleConfronto - totaleBase,
        differenza_percentuale: diffPerc,
      };
    },
    tabellaLicenzeCic() {
      return this.licenzeCic.map((item) => {
        return {
          licenza: item.licenza,
          totale: item.conteggio,
        };
      });
    },
    fieldsTabellaLicenzeCic() {
      return [{ key: "licenza" }, { key: "totale", sortable: true }];
    },
    totaleLicenzeCic() {
      let tot = 0;
      this.tabellaLicenzeCic.forEach((item) => {
        tot += item.totale;
      });
      return {
        licenza: "Totale",
        totale: tot,
      };
    },
    tabellaClientiAbbonati() {
      let stat = [];
      const base = this.storicoClientiAbbonati.anno_base;
      const confronto = this.storicoClientiAbbonati.anno_confronto;

      for (const mese of this.mesi) {
        //Cerco i clienti abbonati (per profilo) nell'anno base al mese mese
        const abbonati_profilo_base = this.storicoClientiAbbonati.items.filter(
          (value) => {
            const year = value.periodo.substring(0, 4);
            const month = value.periodo.substring(5, 7);
            return year == base && month == mese.value;
          }
        );
        //Calcolo il totale del mese
        let totale_abbonati_profilo_base = 0;
        if (abbonati_profilo_base) {
          totale_abbonati_profilo_base = abbonati_profilo_base.reduce(
            (sum, actual) => {
              return (sum += actual.abbonati);
            },
            0
          );
        }

        //Cerco i clienti abbonati (per profilo) nell'anno confronto al mese mese
        const abbonati_profilo_confronto =
          this.storicoClientiAbbonati.items.filter((value) => {
            const year = value.periodo.substring(0, 4);
            const month = value.periodo.substring(5, 7);
            return year == confronto && month == mese.value;
          });
        //Calcolo il totale del mese
        let totale_abbonati_profilo_confronto = 0;
        if (abbonati_profilo_confronto) {
          totale_abbonati_profilo_confronto = abbonati_profilo_confronto.reduce(
            (sum, actual) => {
              return (sum += actual.abbonati);
            },
            0
          );
        }
        let diffPerc = 0;
        if (totale_abbonati_profilo_base > 0) {
          diffPerc =
            ((totale_abbonati_profilo_confronto -
              totale_abbonati_profilo_base) *
              100) /
            totale_abbonati_profilo_base;
        } else if (totale_abbonati_profilo_confronto > 0) {
          diffPerc = 100;
        }
        stat.push({
          mese: mese.text,
          base: totale_abbonati_profilo_base,
          confronto: totale_abbonati_profilo_confronto,
          differenza:
            totale_abbonati_profilo_confronto - totale_abbonati_profilo_base,
          differenza_percentuale: diffPerc,
        });
      }

      return stat;
    },
    fieldsTabellaClientiAbbonati() {
      const base = this.storicoClientiAbbonati.anno_base;
      const confronto = this.storicoClientiAbbonati.anno_confronto;

      const fields = [
        { key: "mese", thStyle: "text-align:start", tdClass: "text-start" },
        { key: `base`, label: `Abbonati ${base}`, sortable: true },
        {
          key: `confronto`,
          label: `Abbonati ${confronto}`,
          sortable: true,
        },
        { key: "differenza", label: "Differenza", sortable: true },
        {
          key: "differenza_percentuale",
          label: "Differenza(%)",
          sortable: true,
        },
      ];
      return fields;
    },
    totaleTabellaClientiAbbonati() {
      let totaleBase = 0;
      let totaleConfronto = 0;
      this.tabellaClientiAbbonati.forEach((item) => {
        totaleBase += item.base;
        totaleConfronto += item.confronto;
      });

      let diffPerc = 0;
      if (totaleBase > 0) {
        diffPerc = ((totaleConfronto - totaleBase) * 100) / totaleBase;
      } else if (totaleConfronto > 0) {
        diffPerc = 100;
      }

      return {
        mese: "Totale",
        base: totaleBase,
        confronto: totaleConfronto,
        differenza: totaleConfronto - totaleBase,
        differenza_percentuale: diffPerc,
      };
    },
    tabellaClientiAbbonatiPerProfilo() {
      let stat = [];
      const base = this.storicoClientiAbbonatiPerProfilo.anno_base;
      const confronto = this.storicoClientiAbbonatiPerProfilo.anno_confronto;
      const mese =
        this.storicoClientiAbbonatiPerProfilo.mese > 9
          ? this.storicoClientiAbbonatiPerProfilo.mese
          : "0" + this.storicoClientiAbbonatiPerProfilo.mese;

      for (const profilo of this.profiliLocale) {
        //Cerco i clienti abbonati (per profilo) nell'anno base al mese mese con profilo profilo
        const abbonati_profilo_base =
          this.storicoClientiAbbonatiPerProfilo.items.filter((value) => {
            const year = value.periodo.substring(0, 4);
            const month = value.periodo.substring(5, 7);
            return (
              year == base &&
              month == mese &&
              value.profilo_locale == profilo.id
            );
          });
        //Calcolo il totale del mese
        let totale_abbonati_profilo_base = 0;
        if (abbonati_profilo_base) {
          totale_abbonati_profilo_base = abbonati_profilo_base.reduce(
            (sum, actual) => {
              return (sum += actual.abbonati);
            },
            0
          );
        }

        //Cerco i clienti abbonati (per profilo) nell'anno base al mese mese con profilo profilo
        const abbonati_profilo_confronto =
          this.storicoClientiAbbonatiPerProfilo.items.filter((value) => {
            const year = value.periodo.substring(0, 4);
            const month = value.periodo.substring(5, 7);
            return (
              year == confronto &&
              month == mese &&
              value.profilo_locale == profilo.id
            );
          });
        //Calcolo il totale del mese
        let totale_abbonati_profilo_confronto = 0;
        if (abbonati_profilo_confronto) {
          totale_abbonati_profilo_confronto = abbonati_profilo_confronto.reduce(
            (sum, actual) => {
              return (sum += actual.abbonati);
            },
            0
          );
        }
        let diffPerc = 0;
        if (totale_abbonati_profilo_base > 0) {
          diffPerc =
            ((totale_abbonati_profilo_confronto -
              totale_abbonati_profilo_base) *
              100) /
            totale_abbonati_profilo_base;
        } else if (totale_abbonati_profilo_confronto > 0) {
          diffPerc = 100;
        }
        stat.push({
          profilo: profilo.descrizione,
          base: totale_abbonati_profilo_base,
          confronto: totale_abbonati_profilo_confronto,
          differenza:
            totale_abbonati_profilo_confronto - totale_abbonati_profilo_base,
          differenza_percentuale: diffPerc,
        });
      }

      return stat;
    },
    fieldsTabellaClientiAbbonatiPerProfilo() {
      const base = this.storicoClientiAbbonatiPerProfilo.anno_base;
      const confronto = this.storicoClientiAbbonatiPerProfilo.anno_confronto;
      const mese = this.storicoClientiAbbonatiPerProfilo.mese;

      const fields = [
        { key: "profilo", thStyle: "text-align:start", tdClass: "text-start" },
        { key: `base`, label: `${mese}/${base}`, sortable: true },
        {
          key: `confronto`,
          label: `${mese}/${confronto}`,
          sortable: true,
        },
        { key: "differenza", label: "Differenza", sortable: true },
        {
          key: "differenza_percentuale",
          label: "Differenza(%)",
          sortable: true,
        },
      ];
      return fields;
    },
    totaleTabellaClientiAbbonatiPerProfilo() {
      let totaleBase = 0;
      let totaleConfronto = 0;
      this.tabellaClientiAbbonatiPerProfilo.forEach((item) => {
        totaleBase += item.base;
        totaleConfronto += item.confronto;
      });

      let diffPerc = 0;
      if (totaleBase > 0) {
        diffPerc = ((totaleConfronto - totaleBase) * 100) / totaleBase;
      } else if (totaleConfronto > 0) {
        diffPerc = 100;
      }

      return {
        profilo: "Totale",
        base: totaleBase,
        confronto: totaleConfronto,
        differenza: totaleConfronto - totaleBase,
        differenza_percentuale: diffPerc,
      };
    },
    //Per i rinnovi annuali gli anni disponibili nella select sono
    //tutti quelli nel quale è presente almeno un rinnovo
    anniRinnoviAnnuali() {
      //Estraggo gli anni di tutti i rinnovi
      let anni = [];
      this.previsioneRinnovi.items.forEach((item) => {
        if (anni.indexOf(item.anno) == -1) anni.push(item.anno);
      });
      anni.sort();

      anni = anni.map((anno) => {
        return {
          value: anno,
          text: anno,
        };
      });
      return anni;
    },
    //Per i rinnovi annuali gli anni disponibili nella select sono
    //tutti quelli nel quale è presente almeno un rinnovo
    anniFatturatoContabilePerTipo() {
      //Estraggo gli anni di tutti i rinnovi
      let anni = [];
      this.fatturatoContabilePerTipo.items.forEach((item) => {
        if (anni.indexOf(item.anno) == -1) anni.push(item.anno);
      });
      anni.sort();

      anni = anni.map((anno) => {
        return {
          value: anno,
          text: anno,
        };
      });
      return anni;
    },
    profiliRinnoviAnnuali() {
      //Estraggo gli anni di tutti i rinnovi
      let profili = [];
      this.previsioneRinnovi.items.forEach((item) => {
        if (profili.indexOf(item.profilo) == -1) profili.push(item.profilo);
      });
      profili.sort();

      return profili;
    },
    tabellaPrevisioneRinnoviAnnuali() {
      const previsioni = [];
      //Restituisco un valore per ogni profilo
      this.profiliRinnoviAnnuali.forEach((profilo) => {
        const searchResult = this.previsioneRinnovi.items.find((item) => {
          return (
            item.id_mese == this.previsioneRinnovi.mese &&
            item.anno == this.previsioneRinnovi.anno &&
            item.profilo == profilo
          );
        });
        //Se non c'è un valore imposto a 0
        if (!searchResult) {
          previsioni.push({
            profilo: profilo,
            rinnovi: 0,
            totale: 0,
          });
        } else {
          previsioni.push({
            profilo: profilo,
            rinnovi: searchResult.totale_rinnovi,
            totale: searchResult.totale,
          });
        }
      });
      return previsioni;
    },
    fieldsTabellaPrevisioneRinnoviAnnuali() {
      const fields = [
        { key: "profilo", thStyle: "text-align:start", tdClass: "text-start" },
        { key: "rinnovi", sortable: true },
        { key: "totale", label: "Totale (€)", sortable: true },
      ];
      return fields;
    },
    totaleTabellaPrevisioneRinnoviAnnuali() {
      let totaleRinnovi = 0;
      let totalePrezzo = 0;

      this.tabellaPrevisioneRinnoviAnnuali.forEach((item) => {
        totaleRinnovi += item.rinnovi;
        totalePrezzo += item.totale;
      });

      return {
        profilo: "Totale",
        rinnovi: totaleRinnovi,
        totale: totalePrezzo,
      };
    },
    tabellaFatturatoContabilePerTipo() {
      const previsioni = [];
      //Restituisco un valore per ogni tipo
      this.tipiOrdine.forEach((tipo) => {
        const searchResult = this.fatturatoContabilePerTipo.items.find(
          (item) => {
            return (
              item.id_mese == this.fatturatoContabilePerTipo.mese &&
              item.anno == this.fatturatoContabilePerTipo.anno &&
              item.tipo == tipo.id
            );
          }
        );
        //Se non c'è un valore imposto a 0
        if (!searchResult) {
          previsioni.push({
            tipo: tipo.descrizione,
            totale: 0,
          });
        } else {
          previsioni.push({
            tipo: tipo.descrizione,
            totale: searchResult.totale,
          });
        }
      });
      //Unisco tutti quelli che non hanno tipo
      const senzaTipo = this.fatturatoContabilePerTipo.items.find((item) => {
        return (
          item.id_mese == this.fatturatoContabilePerTipo.mese &&
          item.anno == this.fatturatoContabilePerTipo.anno &&
          item.tipo == null
        );
      });
      if (!senzaTipo) {
        previsioni.push({
          tipo: "SENZA ORDINE (CUBE)",
          totale: 0,
        });
      } else {
        previsioni.push({
          tipo: "SENZA ORDINE (CUBE)",
          totale: senzaTipo.totale,
        });
      }

      return previsioni;
    },
    fieldsTabellaFatturatoContabilePerTipo() {
      const fields = [
        { key: "tipo", thStyle: "text-align:start", tdClass: "text-start" },
        // { key: "rinnovi", sortable: true },
        { key: "totale", label: "Totale (€)", sortable: true },
      ];
      return fields;
    },
    totaleTabellaFatturatoContabilePerTipo() {
      // let totaleRinnovi = 0;
      let totalePrezzo = 0;

      this.tabellaFatturatoContabilePerTipo.forEach((item) => {
        // totaleRinnovi += item.rinnovi;
        totalePrezzo += item.totale;
      });

      return {
        tipo: "Totale",
        // rinnovi: totaleRinnovi,
        totale: totalePrezzo,
      };
    },

    //Per i rinnovi annuali gli anni disponibili nella select sono
    //tutti quelli nel quale è presente almeno un rinnovo
    anniRinnoviMensili() {
      //Estraggo gli anni di tutti i rinnovi
      let anni = [];
      this.previsioneMensili.items.forEach((item) => {
        if (anni.indexOf(item.anno) == -1) anni.push(item.anno);
      });
      anni.sort();

      anni = anni.map((anno) => {
        return {
          value: anno,
          text: anno,
        };
      });
      return anni;
    },
    //Estraggo gli anni di tutti i mensili
    profiliRinnoviMensili() {
      let profili = [];
      this.previsioneMensili.items.forEach((item) => {
        if (profili.indexOf(item.profilo) == -1) profili.push(item.profilo);
      });
      profili.sort();

      return profili;
    },
    tabellaPrevisioneRinnoviMensili() {
      const previsioni = [];
      //Restituisco un valore per ogni profilo
      this.profiliRinnoviMensili.forEach((profilo) => {
        const searchResult = this.previsioneMensili.items.filter((item) => {
          return (
            item.mese_id == this.previsioneMensili.mese &&
            item.anno == this.previsioneMensili.anno &&
            item.profilo == profilo
          );
        });
        //Se non c'è un valore imposto a 0
        if (!searchResult.length > 0) {
          previsioni.push({
            profilo: profilo,
            rinnovi: 0,
            totale: 0,
          });
        } else {
          searchResult.forEach((result) => {
            previsioni.push({
              profilo: `${profilo} (${result.tipo_fattura})`,
              rinnovi: result.tot_mensili,
              totale: result.totale,
            });
          });
        }
      });
      return previsioni;
    },
    fieldsTabellaPrevisioneRinnoviMensili() {
      const fields = [
        { key: "profilo", thStyle: "text-align:start", tdClass: "text-start" },
        { key: "rinnovi", sortable: true },
        { key: "totale", label: "Totale (€)", sortable: true },
      ];
      return fields;
    },

    totaleTabellaPrevisioneRinnoviMensili() {
      let totaleRinnovi = 0;
      let totalePrezzo = 0;

      this.tabellaPrevisioneRinnoviMensili.forEach((item) => {
        totaleRinnovi += item.rinnovi;
        totalePrezzo += item.totale;
      });

      return {
        profilo: "Totale",
        rinnovi: totaleRinnovi,
        totale: totalePrezzo,
      };
    },
    anni() {
      //Init anni da 2020 a now
      let anni = [];
      const actualYear = new Date().getFullYear();
      for (let i = 2020; i <= actualYear; i++) {
        anni.push({ value: i, text: i });
      }
      return anni;
    },
    profiliLocale() {
      return this.$store.state.profili_locale;
    },
    tipiOrdine() {
      return this.$store.state.tipi_ordine;
    },
    opzioniGraficoFatturato() {
      return {
        chart: {
          id: "grafico-fatturato",
          type: "line",
        },
        // responsive: [
        //   {
        //     breakpoint: 1000,
        //     options: {
        //       chart: {
        //         type: "bar",
        //       },
        //     },
        //   },
        // ],
        yaxis: {
          labels: {
            formatter: (value) => {
              return value
                ? value.toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                  }) + "€"
                : "0,00€";
            },
          },
        },
        xaxis: {
          categories: [
            "Gennaio",
            "Febbraio",
            "Marzo",
            "Aprile",
            "Maggio",
            "Giugno",
            "Luglio",
            "Agosto",
            "Settembre",
            "Ottobre",
            "Novembre",
            "Dicembre",
          ],
        },
      };
    },
    serieGraficoFatturato() {
      // console.log(this.fatturatoContabile.items);

      let series = [];

      let minYear = this.fatturatoContabile.items.reduce((tot, value) => {
        return value.anno < tot ? value.anno : tot;
      }, Infinity);
      let maxYear = this.fatturatoContabile.items.reduce((tot, value) => {
        return value.anno > tot ? value.anno : tot;
      }, 0);

      if (minYear != Infinity && maxYear != 0) {
        //Per ogni anno presente
        for (let i = minYear; i <= maxYear; i++) {
          //Estraggo i valori nell'anno i
          const found = this.fatturatoContabile.items.filter((value) => {
            return value.anno == i;
          });
          // console.log(found);
          let serie = {
            name: i.toString(),
            data: [],
          };

          //Per ogni mese

          this.mesi.forEach((mese) => {
            // console.log(mese.value);
            // cerco in found il valore per questo mese
            let act = found.find((value) => {
              return value.id_mese == mese.value;
            });
            if (act) {
              serie.data.push(act.totale);
            } else {
              serie.data.push(null);
            }
          });

          series.push(serie);
        }
      }

      return series;
    },
    opzioniGraficoDisdette() {
      return {
        chart: {
          id: "grafico-disdette",
          type: "line",
        },
        // responsive: [
        //   {
        //     breakpoint: 1000,
        //     options: {
        //       chart: {
        //         type: "bar",
        //       },
        //     },
        //   },
        // ],
        yaxis: {
          labels: {
            formatter: (value) => {
              return value
                ? value.toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                  }) + "€"
                : "0,00€";
            },
          },
        },
        xaxis: {
          categories: [
            "Gennaio",
            "Febbraio",
            "Marzo",
            "Aprile",
            "Maggio",
            "Giugno",
            "Luglio",
            "Agosto",
            "Settembre",
            "Ottobre",
            "Novembre",
            "Dicembre",
          ],
        },
      };
    },
    serieGraficoDisdette() {
      // console.log(this.disdetteMese.items);

      let series = [];

      let minYear = this.disdetteMese.items.reduce((tot, value) => {
        return value.anno < tot ? value.anno : tot;
      }, Infinity);
      let maxYear = this.disdetteMese.items.reduce((tot, value) => {
        return value.anno > tot ? value.anno : tot;
      }, 0);

      if (minYear != Infinity && maxYear != 0) {
        //Per ogni anno presente
        for (let i = minYear; i <= maxYear; i++) {
          //Estraggo i valori nell'anno i
          const found = this.disdetteMese.items.filter((value) => {
            return value.anno == i;
          });
          // console.log(found);
          let serie = {
            name: i.toString(),
            data: [],
          };

          //Per ogni mese

          this.mesi.forEach((mese) => {
            // console.log(mese.value);
            // cerco in found il valore per questo mese
            let act = found.find((value) => {
              return value.mese == mese.value;
            });
            if (act) {
              serie.data.push(act.totale);
            } else {
              serie.data.push(null);
            }
          });

          series.push(serie);
        }
      }

      return series;
    },
    serieGraficoClientiAbbonatiPerProfilo() {
      let series = [];
      for (const profilo of this.profiliLocale) {
        let serie = [];
        for (const data of this.labelsGraficoClientiAbbonatiPerProfilo) {
          //Escludo i risultati precedenti al 1 ottobre 2022
          if (new Date(data) < new Date("2022-10-01")) continue;

          //Cerco il valore della statistica per questo profilo in questa data
          let stat = this.storicoClientiAbbonatiPerProfilo.items.find(
            (value) => {
              return (
                value.periodo == data && value.profilo_locale == profilo.id
              );
            }
          );
          //Se non c'è un valore imposto a 0
          if (!stat) {
            serie.push({
              x: data,
              y: null,
            });
            continue;
          }
          serie.push({
            x: data,
            y: stat.abbonati,
          });
        }
        series.push({
          name: profilo.descrizione,
          data: serie,
        });
      }

      return series;
    },
    labelsGraficoClientiAbbonatiPerProfilo() {
      let labels = [];
      for (const item of this.storicoClientiAbbonatiPerProfilo.items) {
        labels.push(item.periodo);
      }
      return labels.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    },
    opzioniGraficoClientiAbbonatiPerProfilo() {
      return {
        chart: {
          id: "grafico-abbonati-profilo",
          type: "area",
        },
        xaxis: {
          type: "datetime",
        },
      };
    },
    labelsGraficoFatturatoContabilePerTipoOrdine() {
      let labels = [];
      for (const item of this.fatturatoContabilePerTipo.items) {
        labels.push(
          new Date(item.anno, item.id_mese - 1, 1).toLocaleDateString("en-US")
        );
      }
      return labels.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    },
    opzioniGraficoFatturatoContabilePerTipoOrdine() {
      return {
        chart: {
          id: "grafico-fatturato-contabile-tipo",
          type: "line",
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          labels: {
            formatter: (value) => {
              return value
                ? value.toLocaleString("it-IT", {
                    minimumFractionDigits: 2,
                  }) + "€"
                : "0,00€";
            },
          },
        },
      };
    },
    serieGraficoFatturatoContabilePerTipoOrdine() {
      let series = [];
      let tipiVisualizzati = [
        { id: null, descrizione: "SENZA ORDINE (CUBE)" },
      ].concat(this.tipiOrdine);

      if (this.graficoTipoOrdine.tipo != -1) {
        tipiVisualizzati = tipiVisualizzati.filter(
          (tipo) => tipo.id == this.graficoTipoOrdine.tipo
        );
      }
      for (const tipo of tipiVisualizzati) {
        let serie = [];
        const date = this.labelsGraficoFatturatoContabilePerTipoOrdine.sort(
          (a, b) => new Date(a) - new Date(b)
        );
        for (const data of date) {
          //Escludo i risultati precedenti al 1 ottobre 2022
          // if (new Date(data) < new Date("2022-10-01")) continue;

          //Cerco il valore della statistica per questo profilo in questa data
          let stat = this.fatturatoContabilePerTipo.items.find((value) => {
            const dataTipo = new Date(data);
            return (
              value.id_mese == dataTipo.getMonth() + 1 &&
              value.anno == dataTipo.getFullYear() &&
              value.tipo == tipo.id
            );
          });
          //Se non c'è un valore imposto a 0
          if (!stat) {
            serie.push({
              x: data,
              y: this.graficoTipoOrdine.tipo != -1 ? 0 : null,
            });
            continue;
          }
          serie.push({
            x: data,
            y: stat.totale,
          });
        }
        series.push({
          name: tipo.descrizione,
          data: serie,
        });
      }

      return series;
    },
    labelsGraficoClientiAbbonati() {
      let labels = [];
      for (const item of this.storicoClientiAbbonati.items) {
        labels.push(item.periodo);
      }
      return labels.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
    },
    serieGraficoClientiAbbonati() {
      let serie = [];
      //Raggruppo per data sommando abbonati
      for (const data of this.labelsGraficoClientiAbbonati) {
        //Cerco tutti i valori in questa data
        const items = this.storicoClientiAbbonatiPerProfilo.items.filter(
          (value) => value.periodo == data
        );
        //Se non ho valori imposto a null
        if (!items || items.length == 0) {
          serie.push({
            x: data,
            y: null,
          });
          continue;
        }
        //Calcolo il totale
        let total = 0;
        for (const item of items) total += item.abbonati;
        serie.push({
          x: data,
          y: total,
        });
      }
      // this.storicoClientiAbbonatiPerProfilo.items.forEach((item) => {
      //   serie.push({
      //     x: item.periodo,
      //     y: item.abbonati,
      //   });
      // });
      return [
        {
          name: "Abbonati",
          data: serie,
        },
      ];
    },
    opzioniGraficoClientiAbbonati() {
      return {
        chart: {
          id: "grafico-abbonati",
          type: "area",
        },
        xaxis: {
          type: "datetime",
        },
      };
    },
  },
};
</script>
