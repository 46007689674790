<template>
  <div class="m-3">
    <!-- MODALE PDF -->
    <b-modal
      v-model="modalePDFAperto"
      title="Visualizza PDF"
      centered
      hide-footer
      size="sm"
    >
      <div class="d-flex flex-column gap-1">
        <b-button variant="primary" :href="pdfEndpointScaduto" target="_blank"
          >PDF Scaduti</b-button
        >
        <b-button variant="primary" :href="pdfEndpointScadenze" target="_blank"
          >PDF Scaduti e scadenze</b-button
        >
      </div>
    </b-modal>
    <!-- MODALE MAIL -->
    <b-modal
      v-model="modaleMailAperto"
      title="Invia Mail"
      centered
      hide-footer
      size="sm"
    >
      <div class="d-flex flex-column gap-1">
        <b-button variant="primary" @click="() => routeToMail(false)"
          >Mail Scaduti</b-button
        >
        <b-button variant="primary" @click="() => routeToMail(true)"
          >Mail Scaduti e scadenze</b-button
        >
      </div>
    </b-modal>
    <header>
      <h1>Scaduto {{ cliente.ragsoc }}</h1>
    </header>
    <!-- pulsanti apertura modali e operazioni -->
    <section>
      <!-- bottone modale info -->
      <b-button
        variant="danger"
        @click="modalePDFAperto = !modalePDFAperto"
        class="ms-2"
        title="Apri PDF"
      >
        <font-awesome-icon icon="fa-solid fa-file-pdf" />
      </b-button>
      <!-- bottone modale info -->
      <b-button
        variant="warning"
        @click="modaleMailAperto = !modaleMailAperto"
        class="ms-2"
        title="Invia Mail"
      >
        <font-awesome-icon icon="fa-solid fa-paper-plane" />
      </b-button>
    </section>
    <b-card class="background-secondary shadow mt-2">
      <b-table
        bordered
        :items="fatture"
        :fields="fields"
        style="color: var(--text-color) !important"
        stacked="sm"
        details-td-class="background-secondary"
      >
        <template #cell(pagamenti)="row">
          <b-button size="sm" @click="row.toggleDetails" class="mr-2">
            <font-awesome-icon
              :icon="
                (row.detailsShowing ? 'fa-regular' : 'fa-solid') + ' fa-eye'
              "
            />
          </b-button>
        </template>
        <template #cell(invoice_date_billed)="data">
          {{ formatDate(data.item.invoice_date_billed) }}
        </template>
        <template #cell(total_invoice)="data">
          {{ formatPrice(data.item.total_invoice) }}
        </template>
        <template #cell(total_invoice_iva)="data">
          {{ formatPrice(data.item.total_invoice_iva) }}
        </template>
        <template #cell(total_scaduto)="data">
          {{ formatPrice(data.item.total_scaduto) }}
        </template>
        <template #row-details="row">
          <b-card
            :title="'Pagamenti fattura ' + row.item.invoice_nr"
            class="background-secondary shadow"
          >
            <b-table
              :items="row.item.pagamenti"
              :fields="nested_fields"
              hover
              bordered
              style="color: var(--text-color) !important"
              stacked="sm"
            >
              <template #cell(date_scaduto)="data">
                {{ formatDate(data.item.date_scaduto) }}
              </template>
              <template #cell(total_scaduto)="data">
                {{ formatPrice(data.item.total_scaduto) }}
              </template>
              <template #cell(pagato)="data">
                <font-awesome-icon
                  :icon="
                    data.item.pagato == 1
                      ? 'fa-solid fa-check'
                      : 'fa-solid fa-xmark'
                  "
                />
              </template>
            </b-table>
          </b-card>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import PaginatedTable from "@/components/common/PaginatedTable.vue";
import * as scadutiRepo from "../services/Scaduti";
import * as clienteRepo from "../services/Cliente";

//   import router from "../router/index";

export default {
  name: "ScadutoCliente",
  components: {
    PaginatedTable,
  },
  data() {
    return {
      pagamenti: [],
      modalePDFAperto: false,
      modaleMailAperto: false,
      cliente: {},
      //   currentPage: 1,
      //   perPage: 10,
      //   search: "",
      //   userSearch: 0,
    };
  },
  watch: {
    //   query: {
    //     handler(val) {
    //       this.ricaricaPaginatedTable();
    //     },
    //     deep: true,
    //   },
  },
  async created() {
    this.$loading(true);
    const accessToken = await this.$auth.getTokenSilently();
    await this.caricaPagamenti(accessToken);
    await this.caricaCliente(accessToken);
    // await this.$store.dispatch("caricaUtenti", accessToken);
    this.$loading(false);
  },
  methods: {
    /**
     * Richiamato da click su pulsante mail
     *
     * Effettua routing alla pagina di invio mail del rinnovo attuale
     */
    routeToMail(in_scadenza = false) {
      this.$router.push({
        name: "mailScaduto",
        params: { id: this.id_cliente },
        query: { scadenza: in_scadenza },
      });
    },
    async caricaPagamenti(accessToken) {
      const response = await scadutiRepo.getBillsByIdTrace(
        accessToken,
        this.id_cliente
      );
      if (!response.data) {
        this.$toast.error("Errore caricamento pagamenti");
        return;
      }
      this.pagamenti = response.data;
    },
    async caricaCliente(accessToken) {
      try {
        const response = await clienteRepo.getAll(accessToken, {
          where: {
            id_trace: this.id_cliente,
          },
        });
        if (!response) {
          alert("Errore: cliente non trovato");
          return;
        }
        if (response.length > 0) {
          this.cliente = response[0];
        } else {
          this.cliente = {};
        }
      } catch (e) {
        alert("Errore caricamento cliente");
        console.log(e.response);
      }
    },
    dataIsPassata(data) {
      const now = new Date();
      const compare = new Date(data);
      return now > compare;
    },
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + "€"
      );
    },
    formatDate(date) {
      if (date == null) return "";
      let splitted = date.substring(0, 10).split("-");
      return splitted[2] + "-" + splitted[1] + "-" + splitted[0];
    },
  },
  computed: {
    //Endpoint per apertura pdf scaduto
    pdfEndpointScaduto() {
      return process.env.VUE_APP_API_HOST + "/pdf/scaduto/" + this.id_cliente;
    },
    //Endpoint per apertura pdf scadenze
    pdfEndpointScadenze() {
      return (
        process.env.VUE_APP_API_HOST +
        "/pdf/scaduto/" +
        this.id_cliente +
        "?scadenza=true"
      );
    },
    id_cliente() {
      return this.$route.params.id;
    },
    fatture() {
      const raggruppati = [];
      for (const pagamento of this.pagamenti) {
        if (!pagamento.invoice_id) continue;
        //Caso particolare per la fattura A_2022_0_ creata
        //in passaggio e => cube per contenere molteplici clienti
        if (
          pagamento.invoice_id == "A_2022_0_" ||
          pagamento.invoice_id.startsWith("N_")
        ) {
          const splitted = pagamento.id_scaduto.split("_");
          if (splitted.length > 0 && splitted[0] != this.id_cliente) continue;
        }
        //Controllo se ho già inserito un elemento di questa fatture
        const index = raggruppati.findIndex(
          (item) => item.invoice_id == pagamento.invoice_id
        );
        //Calcolo il
        //Se è il primo pagamento di questa fattura
        if (index == -1) {
          const tot = this.dataIsPassata(pagamento.date_scaduto)
            ? pagamento.total_scaduto
            : 0;
          raggruppati.push({
            invoice_id: pagamento.invoice_id,
            invoice_nr: pagamento.invoice_nr,
            invoice_date_billed: pagamento.invoice_date_billed,
            total_invoice: pagamento.total_invoice,
            total_invoice_iva: pagamento.total_invoice * 1.22,
            total_scaduto: tot,
            pagamenti: [
              {
                id_scaduto: pagamento.id_scaduto,
                payment_id_scaduto: pagamento.payment_id_scaduto,
                date_scaduto: pagamento.date_scaduto,
                total_scaduto: pagamento.total_scaduto,
                pagato: pagamento.pagato,
                _rowVariant:
                  pagamento.pagato == 1
                    ? "success"
                    : new Date(pagamento.date_scaduto) >= new Date()
                    ? "warning"
                    : "danger",
              },
            ],
            _rowVariant: tot > 0 ? "danger" : "warning",
          });
          //Altrimenti
        } else {
          raggruppati[index].total_scaduto = this.dataIsPassata(
            pagamento.date_scaduto
          )
            ? raggruppati[index].total_scaduto + pagamento.total_scaduto
            : raggruppati[index].total_scaduto;

          if (raggruppati[index].total_scaduto > 0) {
            raggruppati[index]["_rowVariant"] = "danger";
          }

          raggruppati[index].pagamenti.push({
            id_scaduto: pagamento.id_scaduto,
            payment_id_scaduto: pagamento.payment_id_scaduto,
            date_scaduto: pagamento.date_scaduto,
            total_scaduto: pagamento.total_scaduto,
            pagato: pagamento.pagato,
            _rowVariant:
              pagamento.pagato == 1
                ? "success"
                : new Date(pagamento.date_scaduto) >= new Date()
                ? "warning"
                : "danger",
          });
        }
      }
      return raggruppati;
    },
    utenti() {
      //   return [{ value: null, text: "Nessun utente" }].concat(
      //     ...this.$store.state.utenti.map((utente) => {
      //       return { value: utente.id, text: utente.name };
      //     })
      //   );
    },
    utenti_search() {
      //   return [{ value: 0, text: "Tutti gli utenti" }].concat(this.utenti);
    },
    fields() {
      return [
        { key: "pagamenti" },
        { key: "invoice_id", label: "ID Fattura", sortable: false },
        { key: "invoice_nr", label: "Num. Fattura", sortable: true },
        { key: "invoice_date_billed", label: "Data Fattura", sortable: true },
        {
          key: "total_invoice",
          label: "Imponibile",
          sortable: true,
          class: "text-end",
        },
        {
          key: "total_invoice_iva",
          label: "Totale",
          sortable: true,
          class: "text-end",
        },
        {
          key: "total_scaduto",
          label: "Totale Scaduto",
          sortable: true,
          class: "text-end",
        },
      ];
    },
    nested_fields() {
      return [
        { key: "id_scaduto", label: "ID Scaduto" },
        { key: "payment_id_scaduto", label: "Pagamento" },
        { key: "date_scaduto", label: "Data scadenza" },
        {
          key: "total_scaduto",
          label: "Totale",
          class: "text-end",
        },
        { key: "pagato", class: "text-center" },
      ];
    },
  },
};
</script>

<style>
/* .b-table-details.table-danger {
  background-color: white !important;
} */
</style>
