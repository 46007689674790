import ApiConnector from "./ApiConnector";
let connector = new ApiConnector(process.env.VUE_APP_API_HOST);
import axios from "axios";

export const getAll = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getAllArticolos(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getById = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloApi();
    apiInstance.getArticoloById(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getByIdWithInclude = (token, id, include) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloApi();

    let opts = {
      selectOptions: {
        options: {
          where: {
            id: id,
          },
          include: include,
        },
      },
    };
    apiInstance.getAllArticolos(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body[0]);
      }
    });
  });
};

export const getCount = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.countArticolos(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getPage = (token, page = 1, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getArticoloPage(page, opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getChildrens = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloApi();
    apiInstance.getArticoloChildrensById(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getTipiArticolo = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.TipoArticoloApi();
    apiInstance.getAllTipoArticolos(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const update = (token, id, params) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloApi();
    apiInstance.updateArticolo(id, params, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const uploadImage = (accessToken, id, imageFormData) => {
  //Request config
  const config = {
    url: `${process.env.VUE_APP_API_HOST}/articolo/${id}/immagine`,
    method: "PUT",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    data: imageFormData,
  };
  //Send request
  return axios.request(config);
};

export const tipi = (accessToken) => {
  //Request config
  const config = {
    url: `${process.env.VUE_APP_API_HOST}/tipoArticolo`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  //Send request
  return axios.request(config);
};
export const gruppi = (accessToken) => {
  //Request config
  const config = {
    url: `${process.env.VUE_APP_API_HOST}/gruppo`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  //Send request
  return axios.request(config);
};
export const macroTipi = (accessToken) => {
  //Request config
  const config = {
    url: `${process.env.VUE_APP_API_HOST}/macroTipoArticolo`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  //Send request
  return axios.request(config);
};
export const reparti = (accessToken) => {
  //Request config
  const config = {
    url: `${process.env.VUE_APP_API_HOST}/reparto`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  //Send request
  return axios.request(config);
};

export const modificaConfigurazioneRT = async (
  token,
  articolo,
  configurazione
) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return await axios.put(
    `${process.env.VUE_APP_API_HOST}/articolo/${articolo}/configurazionert`,
    configurazione,
    config
  );
};
