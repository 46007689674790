<template>
  <div>
    <b-modal
      v-model="open"
      centered
      :id="'allegati' + folder"
      :title="'Allegati ' + folder"
      hide-footer
    >
      <FileUpload
        :destination="destination"
        :id="folder"
        @newUpload="listFiles"
      ></FileUpload>
      <hr />
      <b-list-group>
        <b-list-group-item
          v-for="file in fileNames"
          :key="file"
          button
          class="d-flex justify-content-between"
        >
          <span>
            {{ file.length > 25 ? file.substring(0, 25 - 3) + "..." : file }}
          </span>
          <div>
            <b-button
              class="me-1"
              style="align: right"
              v-on:click="() => download(file)"
              variant="success"
            >
              <font-awesome-icon icon="download" class="fa-lg pointer">
              </font-awesome-icon>
            </b-button>
            <b-button
              variant="danger"
              style="align: right"
              v-on:click="() => remove(file)"
            >
              <b-icon icon="trash" class="fa-lg pointer"> </b-icon>
            </b-button>
          </div>
        </b-list-group-item>
      </b-list-group>
    </b-modal>
    <!-- <b-button >Allegati</b-button> -->
    <button
      type="button"
      class="btn btn-light m-2"
      v-b-tooltip.hover
      title="Allegati"
      v-b-modal="'allegati' + folder"
    >
      <font-awesome-icon
        :icon="empty ? ['far', 'file'] : 'file'"
        class="fa-lg pointer"
      >
      </font-awesome-icon>
    </button>
  </div>
</template>

<script>
import FileUpload from "@/components/common/FileUpload";
import axios from "axios";
export default {
  name: "Allegati",
  data() {
    return {
      loading: false,
      fileNames: [],
      open: false,
    };
  },
  components: {
    FileUpload,
  },
  props: {
    folder: {
      type: Number,
      required: true,
    },
    destination: {
      type: String,
      required: true,
    },
  },
  watch: {},
  async mounted() {
    this.listFiles();
  },
  computed: {
    empty() {
      return this.fileNames.length == 0;
    },
  },
  methods: {
    async listFiles() {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();

      //Request config
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      //Start loading
      this.loading = true;
      //Send request
      await axios
        .get(
          `${process.env.VUE_APP_API_HOST}/file/${this.destination}/${this.folder}`,
          config
        )
        .then((response) => {
          //Stop loading
          this.loading = false;
          this.fileNames = response.data.files;
        })
        .catch((error) => {
          //Stop loading
          this.loading = false;
          console.log(error);
        });
    },
    async download(filename) {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();

      //Request config
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      };
      //Start loading
      this.loading = true;
      //Send request
      await axios
        .get(
          `${process.env.VUE_APP_API_HOST}/file/${this.destination}/${this.folder}/${filename}`,
          config
        )
        .then((response) => {
          //Stop loading
          this.loading = false;

          //DOWNLOAD
          const url = window.URL.createObjectURL(response.data);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          // the filename you want
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          //FINE DOWNLOAD
        })
        .catch((error) => {
          //Stop loading
          this.loading = false;
          console.log(error);
        });
    },
    async remove(filename) {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();

      //Request config
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      //Start loading
      this.loading = true;
      //Send request
      await axios
        .delete(
          `${process.env.VUE_APP_API_HOST}/file/${this.destination}/${this.folder}/${filename}`,
          config
        )
        .then((response) => {
          //Stop loading
          this.loading = false;
          this.listFiles();
        })
        .catch((error) => {
          //Stop loading
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>
