import axios from "axios";

export const clientiAbbonati = (accessToken) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/abbonati`,
    config
  );
};

export const clientiAbbonatiPerProfilo = (accessToken) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/abbonatiPerProfilo`,
    config
  );
};

export const fatturatoContabile = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(JSON.stringify(options));
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      options: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/fatturato-contabile`,
    config
  );
};

export const fatturatoContabilePerTipo = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(JSON.stringify(options));
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      options: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/fatturato-contabile-per-tipo`,
    config
  );
};

export const fatturatoTot = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(JSON.stringify(options));
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      options: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/fatturato-tot`,
    config
  );
};

export const previsioneRinnovi = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(JSON.stringify(options));
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      options: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/previsione-rinnovi`,
    config
  );
};

export const previsioneMensili = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(JSON.stringify(options));
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      options: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/previsione-mensili`,
    config
  );
};

export const totScaduto = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(JSON.stringify(options));
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      options: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/tot-scaduto`,
    config
  );
};

export const totInsoluti = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(JSON.stringify(options));
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      options: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/tot-insoluti`,
    config
  );
};

export const totScadutoInsoluto = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(JSON.stringify(options));
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      options: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/tot-scaduto-insoluto`,
    config
  );
};

export const totCic = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(JSON.stringify(options));
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      options: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/tot-cic`,
    config
  );
};

export const storicoSelloutCic = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(JSON.stringify(options));
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      options: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/storico-sellout-cic`,
    config
  );
};

export const disdetteMese = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(JSON.stringify(options));
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      options: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/disdette-mese`,
    config
  );
};

export const disdetteMotivoMese = (accessToken, mese, anno) => {
  const obj = {
    where: {
      and: {
        id_mese: mese,
        anno: anno,
      },
    },
  };
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: obj }),
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/disdette-motivo-mese`,
    config
  );
};

export const storicoClientiAbbonati = (accessToken, options) => {
  // const encodedOptions = encodeURIComponent(
  //   JSON.stringify({ options: options })
  // );
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/storico/clienti-abbonati`,
    config
  );
};

export const licenzeCic = (accessToken, options) => {
  // const encodedOptions = encodeURIComponent(
  //   JSON.stringify({ options: options })
  // );
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/statistiche/h1/licenze-cic`,
    config
  );
};

export const centralinoChiamateCliente = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/chiamate-cliente`,
      config
    )
  ).data;
  return response;
};

export const centralinoHitsTotal = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/hits-total`,
      config
    )
  ).data;
  if (count > 0) {
    return response[0];
  }
  return {
    total: 0,
    count_not_found: 0,
    count_found: 0,
    perc_not_found: 0,
    perc_found: 0,
  };
};

export const centralinoHitsHistory = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/hits-history`,
      config
    )
  ).data;
  return response;
};

export const centralinoHitsHistory30Days = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/hits-history-30-days`,
      config
    )
  ).data;
  return response;
};

export const centralinoHitsHistory7Days = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/hits-history-7-days`,
      config
    )
  ).data;
  return response;
};

export const centralinoAssRedirectedTotal = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/ass-redirected-total`,
      config
    )
  ).data;
  if (count > 0) {
    return response[0];
  }
  return {
    total: 0,
    count_not_redirected: 0,
    count_redirected: 0,
    perc_not_redirected: 0,
    perc_redirected: 0,
  };
};

export const centralinoAssRedirectedHistory = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/ass-redirected-history`,
      config
    )
  ).data;
  return response;
};

export const centralinoAssRedirectedHistory30Days = async (
  accessToken,
  options
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/ass-redirected-history-30-days`,
      config
    )
  ).data;
  return response;
};

export const centralinoAssRedirectedHistory7Days = async (
  accessToken,
  options
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/ass-redirected-history-7-days`,
      config
    )
  ).data;
  return response;
};

export const centralinoChiamateGiorno = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/chiamate-giorno`,
      config
    )
  ).data;
  return response;
};

export const centralinoChiamateGiornoRep = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/chiamate-giorno-rep`,
      config
    )
  ).data;
  return response;
};

export const centralinoChiamateGiornoBase = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/chiamate-giorno-base`,
      config
    )
  ).data;
  return response;
};

export const top10ClientiChiamate = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/top-10-clienti-chiamate`,
      config
    )
  ).data;
  return response;
};

export const top10ClientiDurataTot = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(
      `${process.env.VUE_APP_API_HOST}/statistiche/h5/top-10-clienti-durata-tot`,
      config
    )
  ).data;
  return response;
};

export const registroChiamate = async (accessToken, options) => {
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: JSON.stringify({ options: options }),
    },
  };
  //Send request
  const [response, count] = (
    await axios.get(`${process.env.VUE_APP_API_HOST}/registroChiamate`, config)
  ).data;
  return response;
};
