<template>
  <div class="m-3">
    <b-card>
      <h1>Creazione campagna</h1>
      <div v-if="activeStep == 1">
        <b-card-text>
          Seleziona la modalità con la quale desideri generare il target per la
          campagna
        </b-card-text>
        <div class="row">
          <div class="col-6">
            <b-card
              :class="{ 'h-100': true, pointer: true, shadow: targetType == 3 }"
              :border-variant="targetType == 3 ? 'primary' : ''"
              :header-bg-variant="targetType == 3 ? 'primary' : ''"
              :header-text-variant="targetType == 3 ? 'white' : 'black'"
              header="Estrazione"
              align="center"
              @click="
                () => {
                  this.targetType = 3;
                }
              "
            >
              <b-card-text
                >Utilizzando questa modalità potrai selezionare il target
                utilizzando i dati presenti in T-Order e utilizzare i dati dei
                clienti per personalizzare il corpo della mail</b-card-text
              >
            </b-card>
          </div>
          <div class="col-6">
            <b-card
              :class="{ 'h-100': true, pointer: true, shadow: targetType == 4 }"
              :border-variant="targetType == 4 ? 'primary' : ''"
              :header-bg-variant="targetType == 4 ? 'primary' : ''"
              :header-text-variant="targetType == 4 ? 'white' : 'black'"
              header="Import"
              align="center"
              @click="
                () => {
                  this.targetType = 4;
                }
              "
            >
              <b-card-text
                >Utilizzando questa modalità potrai selezionare il target
                scrivendo manualmente gli indirizzi dei destinatari o importando
                un file .csv</b-card-text
              >
            </b-card>
          </div>
        </div>
        <b-button
          class="mt-4 float-end"
          variant="primary"
          size="lg"
          @click="
            () => {
              activeStep = 2;
            }
          "
          >Prosegui</b-button
        >
      </div>
      <div v-if="activeStep == 2 && targetType == 3">
        <b-card-text>
          Utilizza i filtri per estrarre il target, oppure
          <b-button variant="secondary" size="sm" @click="activeStep = 1"
            >torna indietro</b-button
          >
        </b-card-text>
        <div class="row">
          <div class="col col-md-4">
            <div class="row">
              <label>Filtra per:</label>
              <v-select
                @input="() => (filtri.filtro = null)"
                v-model="filtri.filtraPer"
                :options="opzioniFiltro"
                :reduce="(item) => item.value"
                label="text"
              >
              </v-select>
            </div>
            <div class="row">
              <label>Destinazione:</label>
              <v-select
                disabled
                v-model="filtri.destinazione"
                :options="[
                  { value: 3, text: 'Mail' },
                  { value: 4, text: 'SMS' },
                ]"
                :reduce="(item) => item.value"
                label="text"
              >
              </v-select>
            </div>
          </div>
          <div class="col col-md-8">
            <div class="row">
              <label>
                {{
                  opzioniFiltro.find((item) => item.value == filtri.filtraPer)
                    .text
                }}:
              </label>
              <v-select
                v-model="filtri.filtro"
                :options="opzioniFiltroSelezionato"
                :reduce="(item) => item.id"
                label="descrizione"
              >
              </v-select>
            </div>
            <div class="row">
              <label>Profilo contatto:</label>
              <v-select
                multiple
                v-model="filtri.profilo_contatto"
                :options="profiliContatto"
                :reduce="(item) => item.id"
                label="descrizione"
              >
              </v-select>
            </div>
          </div>
        </div>
        <TargetView
          class="mt-4"
          :target="target"
          :profiloContatto="filtri.profilo_contatto"
          :hideExport="false"
          v-on:export="handleTargetConfirm"
        ></TargetView>
      </div>
      <div v-if="activeStep == 2 && targetType == 4">
        <b-card-text>
          Inserisci gli indirizzi separati da una vigola, importa un file .csv
          oppure
          <b-button
            variant="secondary"
            size="sm"
            @click="
              () => {
                activeStep = 1;
                importTarget = [];
              }
            "
            >torna indietro</b-button
          >

          <b-form-checkbox v-model="importTargetManually" class="mt-2"
            >Voglio scrivere direttamente i destinatari</b-form-checkbox
          >
          <b-form class="mt-2" @submit="handleImportTargetSubmit">
            <b-form-group
              v-if="importTargetManually"
              id="input-group-1"
              label="Destinatari:"
              label-for="input-1"
            >
              <b-form-input
                multiple
                rows="10"
                id="input-1"
                type="email"
                placeholder="Destinatari"
                v-model="importTargetText"
                required
              ></b-form-input>
            </b-form-group>
            <div v-if="!importTargetManually">
              <b-form-group
                v-if="importTarget.length < 1"
                id="input-group-2"
                label="File .csv:"
                label-for="csvFileUpload"
              >
                <input
                  id="csvFileUpload"
                  class="form-control"
                  accept=".csv"
                  required=""
                  type="file"
                  @change="handleFileUpload"
                />
                <small
                  >Requisiti: La prima riga del file selezionato deve contenere
                  i titoli</small
                >
              </b-form-group>
              <div v-else>
                <b-table
                  :items="importTarget"
                  :per-page="10"
                  :current-page="importTargetPage"
                ></b-table>

                <div class="d-flex flex-column flex-md-row">
                  <b-pagination
                    :total-rows="importTarget.length"
                    :per-page="10"
                    v-model="importTargetPage"
                    class="customPagination"
                  ></b-pagination>
                  <b
                    class="mt-auto mb-auto ms-3"
                    style="color: var(--primary-color)"
                  >
                    {{ importTarget.length }} Risultati
                  </b>
                </div>
              </div>
            </div>

            <b-button
              type="submit"
              variant="primary"
              size="lg"
              class="mt-4 float-end"
              >Prosegui</b-button
            >
          </b-form>
        </b-card-text>
      </div>
      <div v-if="activeStep == 3">
        <b-card-text>
          Inserisci il corpo della mail oppure
          <b-button
            variant="secondary"
            size="sm"
            @click="
              () => {
                activeStep = 2;
                importTarget = [];
              }
            "
            >torna indietro</b-button
          >
        </b-card-text>
        <b-form-checkbox v-model="mailForm.preview"
          >Mostra/Nascondi anteprima</b-form-checkbox
        >
        <div class="row">
          <div class="col">
            <b-form @submit="handleMailFormSubmit">
              <b-form-group
                id="input-group-oggetto"
                label="Oggetto:"
                label-for="oggetto-mail"
              >
                <b-form-input
                  id="oggetto-mail"
                  v-model="mailForm.oggetto"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-contenuto"
                label="Contenuto:"
                label-for="contenuto-mail"
              >
                <b-form-textarea
                  id="contenuto-mail"
                  rows="20"
                  v-model="mailForm.contenuto"
                  required
                ></b-form-textarea>
                <b-button
                  type="submit"
                  size="lg"
                  variant="primary"
                  class="mt-2"
                  style="float: right"
                  >Invia</b-button
                >
                <!-- <v-md-editor
              lang="it"
              v-model="mailForm.contenuto"
              height="400px"
            ></v-md-editor> -->
              </b-form-group>
            </b-form>
          </div>
          <div v-if="mailForm.preview" class="col">
            <iframe :srcdoc="mailForm.contenuto" width="100%" height="100%">
              <!-- <v-md-preview-html :html="mailForm.contenuto"></v-md-preview-html> -->
            </iframe>
          </div>
        </div>
        <b-modal
          id="modale-invio"
          v-model="modaleConferma.aperto"
          hide-footer
          hide-header
          centered
        >
          <b-form @submit="handleFinalConfirmSubmit">
            <b-form-group
              id="input-group-data"
              label="Data di avvio:"
              label-for="avvio-campagna"
            >
              <b-form-input
                id="avvio-campagna"
                v-model="modaleConferma.data"
                type="datetime-local"
                min="2023-01-01T00:00"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-oggetto"
              label="Nome campagna:"
              label-for="nome-campagna"
            >
              <b-form-input
                id="nome-campagna"
                v-model="modaleConferma.nome"
                required
              ></b-form-input>
              <small
                >Scegli un nome significativo, sarà il riferimento per la
                campagna</small
              >
            </b-form-group>
            <div class="d-flex flex-row-reverse mt-2">
              <b-button type="submit" variant="primary">Invia</b-button>
            </div>
          </b-form>
        </b-modal>
      </div>
    </b-card>
  </div>
</template>

<script>
import * as campagnaRepo from "../services/Campagne";
import * as profiloContattoRepo from "../services/ProfiloContatto";
import * as articoliRepo from "../services/Articolo";
import TargetView from "../components/campagne/target.vue";

import router from "../router/index";

export default {
  name: "Campagna2",
  components: {
    TargetView,
  },
  data() {
    return {
      activeStep: 1,
      targetType: 3,
      importTargetText: "",
      importTargetManually: false,
      importTargetPage: 1,
      importTarget: [],
      finalTarget: [],
      filtri: {
        filtraPer: "articolo",
        filtro: null,
        profilo_contatto: [1],
        destinazione: 3,
      },
      mailForm: {
        oggetto: "",
        contenuto: "",
        preview: false,
      },
      opzioniFiltro: [
        { value: "articolo", text: "Articolo" },
        { value: "gruppo", text: "Gruppo" },
        { value: "tipo_articolo", text: "Tipo" },
        { value: "macro_tipo_articolo", text: "Macro Tipo" },
        { value: "reparto", text: "Reparto" },
      ],
      search: "",
      target: [],
      profili: [],
      reparti: [],
      tipi: [],
      gruppi: [],
      macroTipi: [],
      profiliContatto: [],
      modaleConferma: {
        aperto: false,
        nome: null,
        data: null,
      },
    };
  },
  async created() {
    this.$loading(true);
    const accessToken = await this.$auth.getTokenSilently();

    const promises = [
      this.$store.dispatch("caricaArticoli", accessToken),
      this.getTipi(accessToken),
      this.getGruppi(accessToken),
      this.getMacroTipi(accessToken),
      this.getReparti(accessToken),
      this.getProfiliContatto(accessToken),
    ];
    await Promise.all(promises);
    this.$loading(false);
  },
  watch: {
    query: {
      async handler(newQuery) {
        this.$loading(true);
        const accessToken = await this.$auth.getTokenSilently();
        if (this.filtri.filtro != null) await this.getData(accessToken);
        else this.target = [];
        this.$loading(false);
      },
    },
  },
  methods: {
    async handleFinalConfirmSubmit(event) {
      event.preventDefault();
      const isExtraction = this.targetType == 3;
      let campagna = {
        filtra_per: !isExtraction ? null : this.filtri.filtraPer,
        filtro: !isExtraction ? null : this.filtri.filtro,
        profilo_contatto: !isExtraction ? [] : this.filtri.profilo_contatto,
        destinazione_campagna: this.filtri.destinazione,
        oggetto: this.mailForm.oggetto,
        template: this.mailForm.contenuto,
        avviata: isExtraction ? false : true,
        tipo_campagna: this.targetType,
        nome: this.modaleConferma.nome,
        data: this.modaleConferma.data,
        coda: [],
      };
      //Se non è una campagna fatta con estrazione allora creo anche la coda
      if (!isExtraction) {
        campagna.coda = this.finalTarget.map((item) => {
          return {
            contenuto: item,
            destinazione: item.mail,
            data: this.modaleConferma.data,
          };
        });
      }
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      await campagnaRepo.launch(accessToken, campagna);
      this.$loading(false);
      this.$router.push({ path: "/contatti/campagne" });
    },
    handleMailFormSubmit(event) {
      event.preventDefault();
      this.modaleConferma.aperto = true;
    },
    handleImportTargetSubmit(event) {
      event.preventDefault();
      if (this.importTargetManually) {
        this.finalTarget = this.importTargetText.split(",").map((item) => {
          return { mail: item };
        });
      } else {
        this.finalTarget = this.importTarget;
      }
      this.activeStep = 3;
    },
    handleFileUpload(event) {
      if (event.target.files && event.target.files.length > 0) {
        const file = event.target.files[0];

        // FileReader Object
        var reader = new FileReader();

        // Read file as string
        reader.readAsText(file);
        reader.onload = (e) => {
          const data = e.target.result;
          const separator = prompt(
            "Inserisci il separatore dei campi (es. ; , |)",
            ";"
          );
          const lines = data.split("\n").map((item) => {
            item = item.replaceAll("\r", "");
            return item;
          });

          if (lines.length < 1) {
            alert("File .csv non valido, lunghezza inferiore a 1 riga!");
            return;
          }
          const titles = lines[0].split(separator);
          const csvData = [];
          const mailCol = prompt(
            `Quale colonna contiene gli indirizzi?\nColonne trovate: ${titles.join(
              ","
            )}`,
            titles[0]
          );
          if (!titles.includes(mailCol)) {
            alert(`File .csv non valido, colonna '${mailCol}' non trovata!`);
            return;
          }
          //Utilizzo la linea 0 per i titoli quindi parto a estrarre dalla riga 1
          for (let i = 1; i < lines.length; i++) {
            const cells = lines[i].split(separator);
            const obj = {};
            for (let j = 0; j < cells.length; j++) {
              obj[titles[j]] = cells[j];
            }
            csvData.push(obj);
          }
          this.importTarget = csvData;
        };
      }
    },
    handleTargetConfirm(target) {
      this.finalTarget = target;
      this.activeStep = 3;
    },
    async getData(accessToken) {
      this.target = (
        await campagnaRepo.getTarget(accessToken, this.query)
      ).data;
    },
    async getTipi(accessToken) {
      try {
        const response = await articoliRepo.tipi(accessToken);
        if (response.data) {
          this.tipi = response.data;
        }
      } catch (e) {
        this.$toast.error("Errore caricamento tipi");
      }
    },
    async getGruppi(accessToken) {
      try {
        const response = await articoliRepo.gruppi(accessToken);
        if (response.data) {
          this.gruppi = response.data;
        }
      } catch (e) {
        this.$toast.error("Errore caricamento gruppi");
      }
    },
    async getMacroTipi(accessToken) {
      try {
        const response = await articoliRepo.macroTipi(accessToken);
        if (response.data) {
          this.macroTipi = response.data;
        }
      } catch (e) {
        this.$toast.error("Errore caricamento macro tipi");
      }
    },
    async getReparti(accessToken) {
      try {
        const response = await articoliRepo.reparti(accessToken);
        if (response.data) {
          this.reparti = response.data;
        }
      } catch (e) {
        this.$toast.error("Errore caricamento reparti");
      }
    },
    async getProfiliContatto(accessToken) {
      try {
        const response = await profiloContattoRepo.getAll(accessToken, {});
        this.profiliContatto = response;
      } catch (e) {
        this.$toast.error("Errore caricamento profili contatto");
      }
    },
  },
  computed: {
    articoliSelect() {
      return this.$store.state.articoli;
    },
    opzioniFiltroSelezionato() {
      let opzioni = [];
      switch (this.filtri.filtraPer) {
        case "articolo":
          opzioni = this.articoliSelect.map((articolo) => {
            return {
              id: articolo.id_trace,
              descrizione: `${articolo.title} (${articolo.id_trace})`,
            };
          });
          break;
        case "gruppo":
          opzioni = this.gruppi.map((gruppo) => {
            return {
              id: gruppo.id_trace,
              descrizione: gruppo.descrizione,
            };
          });
          break;
        case "tipo_articolo":
          opzioni = this.tipi;
          break;
        case "macro_tipo_articolo":
          opzioni = this.macroTipi;
          break;
        case "reparto":
          opzioni = this.reparti;
          break;
      }
      return opzioni;
    },
    query() {
      if (!this.filtri.filtro) return {};
      return {
        order: ["cliente", "locale"],
        where: {
          [this.filtri.filtraPer]: this.filtri.filtro,
        },
      };
    },
  },
};
</script>
