<template>
  <div
    :class="
      'mt-3 shield col  d-flex flex-column justify-content-center p-2 shadow ' +
      activeClass
    "
    :style="'background-color: ' + backgroundColor + '; color: ' + textColor"
    @click="emitClick"
  >
    <span class="top d-flex flex-column justify-content-center">
      {{ count }}
    </span>
    <div class="bottom d-flex flex-column justify-content-center">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: "ShieldBadge",
  components: {},
  data() {
    return {};
  },
  props: ["count", "description", "backgroundColor", "textColor", "active"],
  computed: {
    activeClass() {
      return this.active ? "active" : "";
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.shield {
  /* border: 1px solid black; */
  border-radius: 50% 50% 50% 50% / 12% 12% 88% 88%;
  border: 6px solid orange;
  /* width: 10vw; */
  min-width: 70px;
  max-width: 150px;

  aspect-ratio: 1/1;
  font-weight: bold;
  cursor: pointer;
}

.shield:hover {
  /* box-shadow: 0 0.8rem 2rem rgba(0, 0, 0, 0.361) !important; */
  box-shadow: 0 0.8rem 2rem orange !important;
}

.shield.active {
  box-shadow: 0 0.8rem 2rem orange !important;
}

.shield .top {
  font-size: 3em;
  height: 45%;

  /* border: 1px solid black; */
}

.shield .bottom {
  font-size: 0.85em;
  height: 40%;
  width: 60%;
  margin: auto;
  /* border: 1px solid black; */
}

@media screen and (max-width: 768px) {
  .shield .top {
    font-size: 1.5em;
    /* font-size: 400%; */
    /* border: 1px solid black; */
  }

  .shield .bottom {
    font-size: 0.6em;
    /* font-size: 400%; */
    /* border: 1px solid black; */
  }
}
</style>
