<template>
  <div class="text-center">
    <div class="spinner-border" v-if="loading" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
    <table v-else class="table">
      <thead>
        <tr>
          <th scope="col">Titolo</th>
          <th scope="col">Matricola</th>
          <th scope="col">Attivazione</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="all in matricoleAttivazioni" v-bind:key="all.serial_id">
          <th scope="row">{{ all.order_loc_desc }}</th>
          <td>
            <input
              type="text"
              class="form-control"
              :value="all.serial_number"
            />
          </td>
          <td>
            <input
              type="date"
              class="form-control"
              :value="formatDate(all.activation_date)"
            />
          </td>
        </tr>
      </tbody>
      <tbody>
        <tr v-for="serial in soloMatricole" v-bind:key="serial.serial_id">
          <th scope="row">{{ serial.order_loc_desc }}</th>
          <td>
            <input
              type="text"
              class="form-control"
              :value="serial.serial_number"
            />
          </td>
          <td><input type="date" class="form-control" disabled /></td>
        </tr>
      </tbody>
      <tbody>
        <tr
          v-for="activation in soloAttivazioni"
          v-bind:key="activation.activation_id"
        >
          <th scope="row">{{ activation.order_loc_desc }}</th>
          <td><input type="text" class="form-control" disabled /></td>
          <td>
            <input
              type="date"
              class="form-control"
              :value="formatDate(activation.activation_date)"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "SerialActivationTable",
  props: {
    order_id: Number,
    editable: Boolean,
  },
  data() {
    return {
      serial_activation: [],
      loading: false,
    };
  },
  async created() {
    this.serial_activation = [];
    if (this.order_id != 0 && this.order_id != "") {
      const accessToken = await this.$auth.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      this.loading = true;
      axios
        .get(
          `${process.env.VUE_APP_API_HOST}/order/${this.order_id}/serialActivation`,
          config
        )
        .then((data) => {
          this.serial_activation = data.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    }
  },
  // watch: {
  //   order_id: {
  //     async handler(val) {
  //       console.log("SERIAL ACTIVATION TABLE");
  //       this.serial_activation = [];
  //       if (val != 0 && val != "") {
  //         const accessToken = await this.$auth.getTokenSilently();
  //         const config = {
  //           headers: { Authorization: `Bearer ${accessToken}` },
  //         };
  //         this.loading = true;
  //         axios
  //           .get(`${process.env.VUE_APP_API_HOST}/orders/${val}/serial`, config)
  //           .then((data) => {
  //             this.serial_activation = data.data["response"];
  //             this.loading = false;
  //           })
  //           .catch((err) => {
  //             this.loading = false;
  //             console.log(err);
  //           });
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    modificaMatricola(serial_id, matricola) {
      alert("MODIFICA MATRICOLA");
    },
    modificaAttivazione(activation_id, attivazione) {
      alert("MODIFICA ATTIVAZIONE");
    },
    formatDate(date) {
      if (date == null) return "";
      let splitted = date.substring(0, 10).split("-");
      return splitted[0] + "-" + splitted[1] + "-" + splitted[2];
    },
  },
  computed: {
    soloMatricole() {
      return this.serial_activation.filter((sa) => sa.activation_id == null);
    },
    soloAttivazioni() {
      return this.serial_activation.filter((sa) => sa.serial_id == null);
    },
    matricoleAttivazioni() {
      return this.serial_activation.filter(
        (sa) => sa.serial_id != null && sa.activation_id != null
      );
    },
  },
};
</script>
