<template>
  <div class="m-3">
    <h1><slot name="title"></slot></h1>

    <b-card>
      <b-form @submit="onSubmit">
        <label>Destinatario:</label>

        <b-input-group
          class="mb-2"
          label="Destinatario:"
          label-for="destinatario"
        >
          <template #prepend>
            <BottoneRubrica
              :defaultSearch="defaultSearch ? defaultSearch : ''"
              @mailSelected="mailSelectedEvent"
            ></BottoneRubrica>
          </template>
          <b-form-input
            id="destinatario"
            v-model="form.destinatario"
            type="email"
            placeholder="destinatario"
            required
            multiple
          ></b-form-input>
        </b-input-group>

        <b-form-group class="mb-2" label="CC:" label-for="cc">
          <b-form-input
            id="cc"
            v-model="form.cc"
            placeholder="cc"
          ></b-form-input>
        </b-form-group>

        <b-form-group class="mb-2" label="CCN:" label-for="ccn">
          <b-form-input
            id="ccn"
            v-model="form.ccn"
            placeholder="ccn"
          ></b-form-input>
        </b-form-group>

        <b-form-group class="mb-2" label="Oggetto:" label-for="oggetto">
          <b-form-input
            id="oggetto"
            v-model="form.oggetto"
            placeholder="oggetto"
            required
          ></b-form-input>
        </b-form-group>
        <!-- <b-form-group class="mb-2" label="Allegati:">
          
        </b-form-group> -->
        <div class="d-flex">
          <label class="mt-3 mb-2">Allegati:</label>
          <Allegati destination="temp" :folder="form.append"></Allegati>
        </div>

        <b-form-group class="mb-2" label="Messaggio:" label-for="messaggio">
          <b-form-textarea
            id="messaggio"
            v-model="form.messaggio"
            placeholder="messaggio"
            rows="6"
            required
          ></b-form-textarea>
        </b-form-group>
        <b-button variant="danger" class="me-2" v-on:click="annulla"
          >Annulla</b-button
        >
        <b-button type="submit" variant="primary"
          >&nbsp;&nbsp;Invia&nbsp;&nbsp;</b-button
        >
      </b-form>
    </b-card>
  </div>
</template>

<script>
import FileUpload from "@/components/common/FileUpload";
import BottoneRubrica from "@/components/common/BottoneRubrica";

import Allegati from "@/components/ordini/Allegati.vue";
import * as mailService from "../../services/Mail";
import axios from "axios";

export default {
  name: "Mail",
  components: {
    FileUpload,
    Allegati,
    BottoneRubrica,
  },
  data() {
    return {
      form: {
        destinatario: "",
        cc: "",
        ccn: "",
        oggetto: "",
        messaggio: "",
        append: "",
      },
      additional_body_params: {},
    };
  },
  props: [
    "idestinatario",
    "icc",
    "iccn",
    "ioggetto",
    "imessaggio",
    "iappend",
    "iadditional_body_params",
    "defaultSearch",
  ],
  watch: {
    idestinatario: function (newVal) {
      if (newVal) this.form.destinatario = newVal;
    },
    icc: function (newVal) {
      if (newVal) this.form.cc = newVal;
    },
    iccn: function (newVal) {
      if (newVal) this.form.ccn = newVal;
    },
    ioggetto: function (newVal) {
      if (newVal) this.form.oggetto = newVal;
    },
    imessaggio: function (newVal) {
      if (newVal) this.form.messaggio = newVal;
    },
    iappend: function (newVal) {
      if (newVal) this.form.append = newVal;
    },
    iadditional_body_params: function (newVal) {
      if (newVal) this.additional_body_params = newVal;
    },
  },
  created() {
    this.form.ccn = this.activeUserMail; //Inizializzo ccn con mail utente attivo
    if (this.idestinatario) this.form.destinatario = this.idestinatario;
    if (this.icc) this.form.cc = this.icc;
    if (this.iccn) this.form.ccn = this.activeUserMail;
    if (this.ioggetto) this.form.oggetto = this.ioggetto;
    if (this.imessaggio) this.form.messaggio = this.imessaggio;
    if (this.iadditional_body_params)
      this.additional_body_params = this.iadditional_body_params;
    if (this.iappend) {
      this.form.append = this.iappend;
    } else {
      //Se non è stato selezionata la cartella all'interno dei file temporanei da
      //allegare alla mail utilizzo come nome Date.now()
      this.form.append = Date.now();
    }
  },
  computed: {
    activeUserMail() {
      return this.$auth.user ? this.$auth.user.email : "";
    },
  },
  methods: {
    mailSelectedEvent(args) {
      if (this.form.destinatario.trim().length > 0)
        this.form.destinatario += ", ";
      this.form.destinatario += args;
      this.$toast.open(`${args} aggiunto ai destinatari`);
    },
    listFiles(arg) {
      console.log(arg);
    },
    async onSubmit(event) {
      event.preventDefault();

      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      try {
        const response = await mailService.sendMail(accessToken, {
          ...this.form,
          ...this.additional_body_params,
        });
        this.$loading(false);
        this.deleteTempFolder();
        this.$router.go(-1);
      } catch (e) {
        this.$loading(false);

        alert("Errore invio mail");
        console.log(e);
      }
    },
    async annulla() {
      this.deleteTempFolder();
      this.$router.go(-1);
    },
    async deleteTempFolder() {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();

      //Request config
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      //Send request
      await axios
        .delete(
          `${process.env.VUE_APP_API_HOST}/file/temp/${this.form.append}`,
          config
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style></style>
