<template>
  <div class="m-3">
    <h1>Articoli</h1>
    <div class="row">
      <div class="col-md-6 mb-2">
        <b-form-input
          type="search"
          placeholder="Inserire id, nome o id trace..."
          v-model="search"
        ></b-form-input>
        <b-form-checkbox v-model="inattivi">
          &nbsp; Mostra Inattivi
        </b-form-checkbox>
      </div>
      <div class="col-md-4">
        <b-form-select
          class="form-control"
          :options="gruppi_select"
          v-model="search_gruppo"
        ></b-form-select>
      </div>
    </div>

    <PaginatedTable
      :getPage="getPage"
      :getCount="getCount"
      :openRow="openRow"
      :key="refreshKey"
      pointer
    >
      <template #cell(prezzo)="data">
        {{ formatPrice(data.value) }}
      </template>
      <template #cell(import_trace)="data">
        <font-awesome-icon v-if="data.value" icon="fa-solid fa-check" />
        <font-awesome-icon v-else icon="fa-solid fa-xmark" />
      </template>
      <template #cell(immagine)="data">
        <img
          :src="getImagePath(data.value)"
          alt="image"
          width="50"
          height="50"
        />
      </template>
    </PaginatedTable>
  </div>
</template>

<script>
import PaginatedTable from "@/components/common/PaginatedTable.vue";
import * as articoloRepo from "../services/Articolo";
import * as gruppoRepo from "../services/Gruppo";

import BottonePDF from "@/components/rinnovi/BottonePDF.vue";

import router from "../router/index";

export default {
  name: "Articoli",
  components: {
    PaginatedTable,
    BottonePDF,
  },
  data() {
    return {
      search: "",
      search_gruppo: null,
      inattivi: false,
      refreshKey: 0,
      gruppi: [],
    };
  },
  watch: {
    query: {
      handler(val) {
        this.ricaricaPaginatedTable();
      },
      deep: true,
    },
  },
  async created() {
    await this.loadGruppi();
  },
  methods: {
    getImagePath(name) {
      let path = null;
      if (!name) return path;
      return (process.env.VUE_APP_API_HOST.replace("api", "") + name)
        .replace("../", "")
        .replace("uploads", "images");
    },
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + "€"
      );
    },
    async loadGruppi() {
      const accessToken = await this.$auth.getTokenSilently();
      this.gruppi = await gruppoRepo.getAll(accessToken, {});
    },
    async getPage(accessToken, page) {
      let articoli = await articoloRepo.getPage(accessToken, page, this.query);
      articoli = articoli.map((articolo) => {
        let prezzo = null;
        if (articolo.prezzos.length > 0)
          prezzo = articolo.prezzos[0].prz_listino;
        return {
          id: articolo.id,
          nome: articolo.title,
          prezzo: prezzo,
          trace: articolo.id_trace,
          import_trace: articolo.import_trace,
          immagine: articolo.immagine,
        };
      });
      return articoli;
    },
    async getCount(accessToken) {
      let count = await articoloRepo.getCount(accessToken, this.query);
      return count;
    },
    ricaricaPaginatedTable() {
      this.refreshKey += 1;
    },
    openRow(row, number, event) {
      router.push({ name: "schedaArticolo", params: { id: row["id"] } });
    },
  },
  computed: {
    gruppi_select() {
      return [{ value: null, text: "Tutti i gruppi" }].concat(
        this.gruppi.map((gruppo) => {
          return {
            value: gruppo.id_trace,
            text: gruppo.id_trace + " - " + gruppo.descrizione,
          };
        })
      );
    },
    query() {
      let query = {
        include: ["prezzos"],
        where: {
          attivo: !this.inattivi,
        },
      };
      if (this.search_gruppo != null) {
        query.where = {
          and: {
            ...query.where,
            gruppo: this.search_gruppo,
          },
        };
      }
      if (this.search.trim() != "") {
        query.where = {
          and: {
            ...query.where,
            or: {
              title: {
                substring: this.search,
              },
              id_trace: {
                substring: this.search,
              },
              id: {
                substring: this.search,
              },
            },
          },
        };
      }
      return query;
    },
  },
};
</script>
