<template>
  <div class="accordion" role="tablist">
    <AccordionCard
      v-if="this.mensili.length > 0"
      :identificatore="'mensili-' + this.locale"
      :accordion="'' + this.locale"
      titolo="Canoni mensili:"
      :append="totaleMensili + '€'"
      color="#F7F7F7"
      textcolor="black"
    >
      <b-table stacked="sm" striped hover :items="this.mensili">
        <template #cell(prezzo)="data">
          <div style="text-align: end">
            {{ formatPrice(data.value) }}
          </div>
        </template>
        <template #cell(installazione)="data">
          {{
            data.value ? new Date(data.value).toLocaleDateString("it-IT") : ""
          }}
        </template>
        <template #cell(scadenza)="data">
          {{
            data.value ? new Date(data.value).toLocaleDateString("it-IT") : ""
          }}
        </template>
        <template #cell(note_trace)="data">
          <div style="text-align: center">
            <b-icon
              v-b-tooltip.hover.left="data.value"
              icon="info-circle-fill"
              :variant="data.value ? 'info' : 'secondary'"
            >
            </b-icon>
          </div>
        </template>
      </b-table>
    </AccordionCard>
    <AccordionCard
      v-if="this.annuali.length > 0"
      :identificatore="'annuali-' + this.locale"
      :accordion="'' + this.locale"
      titolo="Canoni annuali:"
      :append="totaleAnnuali + ' €'"
      color="#F7F7F7"
      textcolor="black"
    >
      <b-table stacked="sm" striped hover :items="this.annuali">
        <template #cell(prezzo)="data">
          <div style="text-align: end">
            {{ formatPrice(data.value) }}
          </div>
        </template>
        <template #cell(installazione)="data">
          {{
            data.value ? new Date(data.value).toLocaleDateString("it-IT") : ""
          }}
        </template>
        <template #cell(scadenza)="data">
          {{
            data.value ? new Date(data.value).toLocaleDateString("it-IT") : ""
          }}
        </template>
        <template #cell(note_trace)="data">
          <div style="text-align: center">
            <b-icon
              v-b-tooltip.hover.left="data.value"
              icon="info-circle-fill"
              :variant="data.value ? 'info' : 'secondary'"
            >
            </b-icon>
          </div>
        </template>
      </b-table>
    </AccordionCard>
    <AccordionCard
      v-if="this.biennali.length > 0"
      :identificatore="'biennali-' + this.locale"
      :accordion="'' + this.locale"
      titolo="Canoni biennali:"
      :append="totaleBiennali + ' €'"
      color="#F7F7F7"
      textcolor="black"
    >
      <b-table stacked="sm" striped hover :items="this.biennali">
        <template #cell(prezzo)="data">
          <div style="text-align: end">
            {{ formatPrice(data.value) }}
          </div>
        </template>
        <template #cell(installazione)="data">
          {{
            data.value ? new Date(data.value).toLocaleDateString("it-IT") : ""
          }}
        </template>
        <template #cell(scadenza)="data">
          {{
            data.value ? new Date(data.value).toLocaleDateString("it-IT") : ""
          }}
        </template>
        <template #cell(note_trace)="data">
          <div style="text-align: center">
            <b-icon
              v-b-tooltip.hover.left="data.value"
              icon="info-circle-fill"
              :variant="data.value ? 'info' : 'secondary'"
            >
            </b-icon>
          </div>
        </template>
      </b-table>
    </AccordionCard>
    <AccordionCard
      v-if="this.prodotti.length > 0"
      :identificatore="'prodotti-' + this.locale"
      :accordion="'' + this.locale"
      titolo="Prodotti:"
      :append="totaleProdotti + ' €'"
      color="#F7F7F7"
      textcolor="black"
    >
      <b-table stacked="sm" striped hover :items="this.prodotti">
        <template #cell(prezzo)="data">
          <div style="text-align: end">
            {{ formatPrice(data.value) }}
          </div>
        </template>
        <template #cell(installazione)="data">
          {{
            data.value ? new Date(data.value).toLocaleDateString("it-IT") : ""
          }}
        </template>
        <template #cell(scadenza)="data">
          {{
            data.value ? new Date(data.value).toLocaleDateString("it-IT") : ""
          }}
        </template>
        <template #cell(note_trace)="data">
          <div style="text-align: center">
            <b-icon
              v-b-tooltip.hover.left="data.value"
              icon="info-circle-fill"
              :variant="data.value ? 'info' : 'secondary'"
            >
            </b-icon>
          </div>
        </template>
      </b-table>
    </AccordionCard>
    <AccordionCard
      v-if="this.disdetteMappate.length > 0"
      :identificatore="'disdette-' + this.locale"
      :accordion="'' + this.locale"
      titolo="Disdette:"
      :append="totaleDisdette + ' €'"
      color="red"
      textcolor="white"
    >
      <b-table
        stacked="sm"
        striped
        hover
        :items="this.disdetteMappate"
      ></b-table>
    </AccordionCard>
  </div>
</template>

<script>
import AccordionCard from "@/components/common/cards/AccordionCard.vue";
import * as traceRepo from "../../services/Trace";
import * as disdettaRepo from "../../services/Disdette";

export default {
  name: "Contratto",
  components: {
    AccordionCard,
  },
  props: ["cliente", "locale"],
  data() {
    return {
      contratto: [],
      disdette: [
        {
          codice_articolo: "codice_articolo",
          articolo: "articolo",
          prezzo: "prezzo",
          data_disdetta: "installazione",
          motivo: "scadenza",
        },
        {
          codice_articolo: "codice_articolo",
          articolo: "articolo",
          prezzo: "prezzo",
          data_disdetta: "installazione",
          motivo: "scadenza",
        },
      ],
    };
  },
  async created() {
    const accessToken = await this.$auth.getTokenSilently();
    this.contratto = await traceRepo.getContract(
      accessToken,
      this.cliente,
      this.locale
    );
    this.disdette = await disdettaRepo.getByLocationIdWithInclude(
      accessToken,
      this.locale,
      ["motivazione_motivazione", "articolo_articolo"]
    );
    this.$emit("total", this.totaleAnnualeLocale, this.locale);
  },
  methods: {
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + "€"
      );
    },
  },
  computed: {
    mensili() {
      return this.contratto
        .filter((value, index, array) => {
          return value.mth_1;
        })
        .map((value, index, array) => {
          return {
            articolo: `${value["title"]} - (${value["articolo_trace"]})`,
            prezzo: value["price"],
            installazione: value["installation_date"],
            scadenza: value["due_date_maintenance"],
            matricola: value["matricola"],
            note_trace: value["note"],
          };
        });
    },
    annuali() {
      return this.contratto
        .filter((value, index, array) => {
          return value.mth_12;
        })
        .map((value, index, array) => {
          return {
            articolo: `${value["title"]} - (${value["articolo_trace"]})`,
            prezzo: value["price"],
            installazione: value["installation_date"],
            scadenza: value["due_date_maintenance"],
            matricola: value["matricola"],
            note_trace: value["note"],
          };
        });
    },
    biennali() {
      return this.contratto
        .filter((value, index, array) => {
          return value.mth_24;
        })
        .map((value, index, array) => {
          return {
            articolo: `${value["title"]} - (${value["articolo_trace"]})`,
            prezzo: value["price"],
            installazione: value["installation_date"],
            scadenza: value["due_date_maintenance"],
            matricola: value["matricola"],
            note_trace: value["note"],
          };
        });
    },
    newyear() {
      return this.contratto
        .filter((value, index, array) => {
          return value.new_year;
        })
        .map((value, index, array) => {
          return {
            articolo: `${value["title"]} - (${value["articolo_trace"]})`,
            prezzo: value["price"],
            installazione: value["installation_date"],
            scadenza: value["due_date_maintenance"],
            matricola: value["matricola"],
            note_trace: value["note"],
          };
        });
    },
    prodotti() {
      return this.contratto
        .filter((value, index, array) => {
          return (
            !value.new_year && !value.mth_1 && !value.mth_12 && !value.mth_24
          );
        })
        .map((value, index, array) => {
          return {
            articolo: `${value["title"]} - (${value["articolo_trace"]})`,
            prezzo: value["price"],
            installazione: value["installation_date"],
            scadenza: value["due_date_maintenance"],
            matricola: value["matricola"],
            note_trace: value["note"],
          };
        });
    },
    totaleMensili() {
      let tot = 0;
      for (let prod of this.mensili) {
        tot += prod["prezzo"];
      }
      return tot;
    },
    totaleAnnuali() {
      let tot = 0;
      for (let prod of this.annuali) {
        tot += prod["prezzo"];
      }
      return tot;
    },
    totaleBiennali() {
      let tot = 0;
      for (let prod of this.biennali) {
        tot += prod["prezzo"];
      }
      return tot;
    },
    totaleNewYear() {
      let tot = 0;
      for (let prod of this.newyear) {
        tot += prod["prezzo"];
      }
      return tot;
    },
    totaleProdotti() {
      let tot = 0;
      for (let prod of this.prodotti) {
        tot += prod["prezzo"];
      }
      return tot;
    },
    totaleAnnualeLocale() {
      return (
        this.totaleMensili * 12 +
        this.totaleAnnuali +
        this.totaleBiennali / 2 +
        this.totaleNewYear +
        this.totaleProdotti
      );
    },
    disdetteMappate() {
      return this.disdette.map((disdetta) => {
        return {
          codice_articolo: disdetta.articolo_articolo
            ? disdetta.articolo_articolo.id_trace
            : "ND",
          articolo: disdetta.articolo_articolo
            ? disdetta.articolo_articolo.title
            : "ND",
          prezzo: disdetta.prezzo,
          data_disdetta: disdetta.data_cancellazione,
          motivo: disdetta.motivazione_motivazione
            ? disdetta.motivazione_motivazione.descrizione
            : "ND",
        };
      });
    },
    totaleDisdette() {
      return this.disdette.reduce((sum, item) => {
        return sum + item.prezzo;
      }, 0);
    },
  },
};
</script>
