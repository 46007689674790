<template>
  <div :class="'exagon-wrap col ' + activeClass">
    <div
      class="mt-3 exagon col d-flex flex-column justify-content-center p-2"
      :style="'background-color: ' + backgroundColor + '; color: ' + textColor"
      @click="emitClick"
    >
      <span class="top d-flex flex-column justify-content-center">
        {{ count }}
      </span>
      <div class="bottom d-flex flex-column justify-content-center">
        {{ description }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExagonBadge",
  components: {},
  data() {
    return {};
  },
  props: ["count", "description", "backgroundColor", "textColor", "active"],
  computed: {
    activeClass() {
      return this.active ? "active" : "";
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.exagon {
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);

  /* border: 1px solid black; */
  /* border-radius: 100%; */
  /* width: 10vw; */
  min-width: 70px;
  max-width: 150px;

  aspect-ratio: 1/1;
  font-weight: bold;
  cursor: pointer;
}

.exagon-wrap:hover {
  filter: drop-shadow(0 0.8rem 2rem rgba(0, 0, 0, 0.361)) !important;
}

.exagon-wrap.active {
  filter: drop-shadow(0 0.8rem 2rem rgba(0, 0, 0, 0.491)) !important;
}

.exagon .top {
  font-size: 3em;
  height: 40%;

  /* border: 1px solid black; */
}

.exagon .bottom {
  font-size: 0.85em;
  height: 40%;
  /* border: 1px solid black; */
}

.exagon-wrap {
  filter: drop-shadow(0 0.5rem 1rem rgba(0, 0, 0, 0.15)) !important;
}

@media screen and (max-width: 768px) {
  .exagon .top {
    font-size: 1.5em;
    /* font-size: 400%; */
    /* border: 1px solid black; */
  }

  .exagon .bottom {
    font-size: 0.6em;
    /* font-size: 400%; */
    /* border: 1px solid black; */
  }
}
</style>
