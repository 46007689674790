import axios from "axios";
import ApiConnector from "./ApiConnector";
let connector = new ApiConnector(process.env.VUE_APP_API_HOST);

export const getAll = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ClienteApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getAllClientes(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getById = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ClienteApi();
    apiInstance.getClienteById(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getCount = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ClienteApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.countClientes(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getPage = (token, page = 1, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ClienteApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getClientePage(page, opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const add = (token, cliente) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ClienteApi();
    apiInstance.addCliente(cliente, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const update = (token, id, cliente) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ClienteApi();
    apiInstance.updateCliente(id, cliente, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const search = (token, query) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    options: query,
  };

  const uriEncodedBody = encodeURI("body=" + JSON.stringify(body));
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/articoliClienti?${uriEncodedBody}`,
    config
  );
};

export const searchContatti = (token, query, profilo) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const body = {
    options: query,
  };

  const uriEncodedBody = encodeURI("body=" + JSON.stringify(body));
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/articoliClienti/contatti/${profilo}?${uriEncodedBody}`,
    config
  );
};
