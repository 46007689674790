<template>
  <tr>
    <td
      class="text-start"
      v-if="editable"
      v-on:click="rimuoviArticolo(articolo.id)"
    >
      <b-icon
        icon="trash"
        variant="danger"
        aria-hidden="true"
        class="pointer"
      ></b-icon>
    </td>
    <td v-else></td>
    <td class="text-start">{{ getArticleById(articolo.id).id_trace }}</td>
    <td class="text-start">{{ getArticleById(articolo.id).title }}</td>
    <td>
      <b-form-input
        class="text-end"
        type="number"
        min="1"
        v-model="articolo.quantita"
        :readonly="!editable"
      ></b-form-input>
    </td>
    <td>
      <b-input-group append="€">
        <b-form-input
          class="text-end"
          type="number"
          min="0"
          step="0.01"
          v-model="articolo.prezzo"
          :readonly="!scontabile || !editable"
        ></b-form-input>
      </b-input-group>
    </td>
    <td>
      <b-input-group append="%">
        <b-form-input
          class="text-end"
          type="number"
          min="0"
          max="100"
          step=".01"
          v-model="articolo.sconto"
          :readonly="!scontabile || !editable"
        ></b-form-input>
      </b-input-group>
    </td>
    <td>
      <b-input-group append="€">
        <b-form-input
          class="text-end"
          :value="
            articolo.prezzo * articolo.quantita -
            (articolo.sconto / 100) * articolo.prezzo * articolo.quantita
          "
          v-on:change="
            (value) => modificaScontoInBaseAlTotale(value, articolo.id)
          "
          :readonly="!scontabile || !editable"
        ></b-form-input>
      </b-input-group>
    </td>
  </tr>
</template>

<script>
export default {
  name: "CartItemsDesktopRow",
  props: [
    "articolo",
    "getArticleById",
    "modificaScontoInBaseAlTotale",
    "rimuoviArticolo",
    "editable",
    "scontabile",
  ],
};
</script>
