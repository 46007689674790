import ApiConnector from "./ApiConnector";
let connector = new ApiConnector(process.env.VUE_APP_API_HOST);

export const getContract = (token, cliente, locale) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.TTraceApi();

    apiInstance.getContract(cliente, locale, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};
