<template>
  <div class="m-3">
    <h1>Calendario Campagne</h1>
    <!-- Modale per visualizzare i dettagli della campagna -->
    <b-modal
      v-if="modaleCampagna.campagna.customData"
      id="modale-campagna"
      v-model="modaleCampagna.show"
      centered
      :title="modaleCampagna.campagna.customData.nome"
    >
      <p>
        <b-table-simple>
          <b-tbody>
            <b-tr>
              <b-th>ID</b-th>
              <b-td>{{ modaleCampagna.campagna.customData.id }}</b-td>
            </b-tr>
            <b-tr>
              <b-th>Nome</b-th>
              <b-td>{{ modaleCampagna.campagna.customData.nome }}</b-td>
            </b-tr>
            <b-tr>
              <b-th>Data</b-th>
              <b-td>{{ modaleCampagna.campagna.customData.data }}</b-td>
            </b-tr>
            <b-tr>
              <b-th>Oggetto</b-th>
              <b-td>{{ modaleCampagna.campagna.customData.oggetto }}</b-td>
            </b-tr>
            <b-tr>
              <b-th>Template</b-th>
              <b-td>{{ modaleCampagna.campagna.customData.template }}</b-td>
            </b-tr>
            <b-tr>
              <b-th>Avviata</b-th>
              <b-td>{{ modaleCampagna.campagna.customData.avviata }}</b-td>
            </b-tr>
            <b-tr>
              <b-th>Conclusa</b-th>
              <b-td>{{ modaleCampagna.campagna.customData.conclusa }}</b-td>
            </b-tr>
            <b-tr>
              <b-th>Tipo campagna</b-th>
              <b-td>{{
                modaleCampagna.campagna.customData.tipo_campagna
              }}</b-td>
            </b-tr>
            <b-tr>
              <b-th>Destinazione campagna</b-th>
              <b-td>{{
                modaleCampagna.campagna.customData.destinazione_campagna
              }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        {{ modaleCampagna.campagna.customData }}
      </p>
    </b-modal>
    <!-- calendario per visualizzare le campagne di uno specifico mese e anno -->
    <v-calendar
      class="custom-calendar max-w-full"
      :masks="masks"
      :attributes="attributes"
      disable-page-swipe
      is-expanded
      @update:from-page="setActivePeriod"
    >
      <template v-slot:day-content="{ day, attributes }">
        <div>
          <span class="day-label text-sm text-gray-900">{{ day.day }}</span>
          <div style="height: 20%; overflow-y: scroll">
            <b-card
              v-for="attr in attributes"
              :key="attr.key"
              :class="attr.customData.class"
              style="cursor: pointer"
              @click="goToCampagna(attr)"
            >
              <div class="d-flex flex-row justify-content-between">
                <div>
                  <b-icon icon="clock" />
                  {{
                    `${new Date(attr.targetDate.start).getHours()}:${new Date(
                      attr.targetDate.start
                    ).getMinutes()}`
                  }}
                </div>
                <b-icon icon="check-circle"></b-icon>
              </div>
              <b-card-body>
                <b-card-subtitle class="p-0 m-0">
                  {{ attr.customData.nome }}
                </b-card-subtitle>
              </b-card-body>
              <!-- <div>
                <b-button size="sm" variant="light"
                  ><b-icon icon="bullseye"></b-icon
                ></b-button>
              </div> -->
            </b-card>
          </div>
        </div>
      </template>
    </v-calendar>
  </div>
</template>

<script>
import * as campagneRepo from "../services/Campagne";

export default {
  data() {
    return {
      config: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
        calendar: {},
      },
      masks: {
        weekdays: "WWW",
      },
      attributes: [],
      modaleCampagna: {
        show: false,
        campagna: {},
      },
    };
  },
  created() {
    console.log("created");
    // this.getEvents(this.config.month, this.config.year);
  },
  methods: {
    goToCampagna(campagna) {
      this.modaleCampagna.show = true;
      this.modaleCampagna.campagna = campagna;
    },
    setActivePeriod(params) {
      console.log("setActivePeriod", params);
      this.config.year = params.year;
      this.config.month = params.month - 1;
      this.getEvents(this.config.month, this.config.year);
    },
    //Funzione per scaricare tutti gli eventi di un dato mese e anno
    async getEvents(month, year) {
      console.log("getEvents");
      console.log(month);
      console.log(year);
      const startOfMonth = new Date(year, month, 1);
      const endOfMonth = new Date(year, month + 1, 0);
      console.log(startOfMonth);
      console.log(endOfMonth);
      const accessToken = await this.$auth.getTokenSilently();
      const options = {
        where: {
          data: {
            gte: startOfMonth,
            lte: endOfMonth,
          },
        },
      };
      let campagne = await campagneRepo.getAll(accessToken, options);
      //modifica this.attributes con i dati delle campagne formattati per il calendario
      if (campagne.data)
        this.attributes = campagne.data.map((campagna) => {
          return {
            key: campagna.id,
            customData: {
              ...campagna,
              class: "bg-success text-white shadow p-2",
            },
            dates: new Date(campagna.data),
          };
        });

      console.log(campagne);
    },
  },
};
</script>

<style lang="postcss" scoped>
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  display: none;
}

.card-body {
  padding: 0px !important;
}

.card-body:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important !important;
}

/deep/ .custom-calendar.vc-container {
  --day-border: 1px solid #b8c2cc;
  --day-border-highlight: 1px solid #b8c2cc;
  --day-width: 90px;
  --day-height: auto;
  --day-min-height: 90px;
  --weekday-bg: #f8fafc;
  --weekday-border: 1px solid #eaeaea;

  border-radius: 0;
  width: 100%;

  & .vc-header {
    background-color: #f1f5f8;
    padding: 10px 0;
  }
  & .vc-weeks {
    padding: 0;
  }
  & .vc-weekday {
    background-color: var(--weekday-bg);
    border-bottom: var(--weekday-border);
    border-top: var(--weekday-border);
    padding: 5px 0;
  }
  & .vc-day {
    padding: 0 5px 3px 5px;
    text-align: left;
    height: var(--day-height);
    min-height: var(--day-min-height);
    min-width: var(--day-width);
    background-color: white;
    &.weekday-1,
    &.weekday-7 {
      background-color: #eff8ff;
    }
    &:not(.on-bottom) {
      border-bottom: var(--day-border);
      &.weekday-1 {
        border-bottom: var(--day-border-highlight);
      }
    }
    &:not(.on-right) {
      border-right: var(--day-border);
    }
  }
  & .vc-day-dots {
    margin-bottom: 5px;
  }
}
</style>
