<template>
  <div class="m-1">
    <b-modal
      v-model="modaleFatture"
      :title="'Fatture ' + actualRenewDescription"
      size="xl"
      centered
      hide-footer
    >
      <div class="d-flex mb-2">
        <b-button @click="generaFatture" variant="warning" class="me-2"
          >Genera fatture</b-button
        >

        <b-button variant="info" class="me-2" @click="downloadReport"
          >Scarica report</b-button
        >
        <a ref="download_button" style="display: none"></a>

        <b-button
          v-if="!streamAttivo"
          @click="startStream"
          style="background-color: #753bce"
          >Attiva Stream</b-button
        >
        <b-button v-else @click="stopStream" variant="danger"
          >Ferma Stream</b-button
        >
        <br />
        <b-spinner
          v-if="streamAttivo"
          variant="success"
          type="grow"
          class="ms-2"
        ></b-spinner>
      </div>
      <b-progress max="100" height="2rem" class="mb-2">
        <b-progress-bar :value="percentuale" variant="success">
          <strong>{{ percentuale.toFixed(2) }}%</strong>
        </b-progress-bar>
      </b-progress>
      <p
        ref="report_output"
        class="card p-2"
        style="
          height: 50vh;
          overflow: auto;
          background-color: black;
          color: white;
        "
      ></p>
    </b-modal>
    <b-modal
      v-model="modaleControlli"
      :title="'Controlli fatture di ' + searchMonth + '/' + searchYear"
      size="xl"
      centered
      hide-footer
    >
      <b-progress max="100" height="2rem" class="mb-2">
        <b-progress-bar :value="percentuale" variant="success">
          <strong>{{ percentuale.toFixed(2) }}%</strong>
        </b-progress-bar>
      </b-progress>
      <p
        ref="report_output"
        class="card p-2"
        style="
          height: 50vh;
          overflow: auto;
          background-color: black;
          color: white;
        "
      ></p>
    </b-modal>
    <h1 class="ps-2 pt-2">
      Rinnovi
      <span style="font-size: 60%">(Mensili/Trimestrali/Semestrali)</span>
    </h1>
    <div class="row ps-2">
      <div class="col-md-6">
        <div class="row">
          <div class="col mb-2">
            <div class="d-flex flex-column flex-md-row">
              <b-select
                :options="months"
                v-model="searchMonth"
                class="form-select form-select-sm"
              ></b-select>
              <b-select
                :options="years"
                v-model="searchYear"
                class="form-select form-select-sm"
              ></b-select>
              <b-select
                :options="profili_locale"
                v-model="searchProfile"
                class="form-select form-select-sm"
              ></b-select>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mb-2">
            <b-form-input
              type="search"
              placeholder="Inserire ricerca..."
              v-model="search"
            ></b-form-input>
          </div>
        </div>
      </div>

      <div class="col-md-2 m-auto">
        <div class="row">
          <b-button
            class="mb-2"
            @click="
              () => {
                modaleControlli = !modaleControlli;
                effettuaControlli(searchMonth, searchYear);
              }
            "
            variant="success"
            >Effettua controlli {{ searchMonth }}/{{ searchYear }}</b-button
          >
          <b-button
            class="mb-2"
            variant="warning"
            @click="modaleFatture = !modaleFatture"
            :disabled="!canGeneraFatture"
            >Genera fatture</b-button
          >
        </div>
      </div>
    </div>
    <BannerCountProfili
      class="ps-3"
      :getCount="countProfili"
      :key="bannerRefreshKey"
    ></BannerCountProfili>
    <PaginatedTable
      :getPage="getPage"
      :getCount="getCount"
      :openRow="openRow"
      :sort="sort"
      :fields="fields"
      :key="refreshKey"
      :refreshValue="refreshValue"
      pointer
    >
      <template #cell(fatturazione)="data">
        <b-select
          :options="tipi_fatturazione"
          v-model="data.value"
          class="form-select form-select-sm"
          v-on:change="
            modificaTipoFatturazione(data.item.IDrinnovo, data.value)
          "
        ></b-select>
      </template>
      <template #cell(profilo)="data">
        <b-select
          :options="profili_locale"
          v-model="data.item.profilo"
          class="form-select form-select-sm"
          v-on:change="modificaProfilo(data.item.insegna.id, data.item.profilo)"
        ></b-select>
      </template>
      <template #cell(insegna)="data">
        {{ data.item.insegna.nome }}
      </template>
      <template #cell(raggruppa)="data">
        <b-form-checkbox
          id="checkbox-1"
          v-model="data.value"
          name="checkbox-1"
          size="lg"
          v-on:change="
            modificaFatturazionePerCliente(data.item.IDrinnovo, data.value)
          "
        >
        </b-form-checkbox>
      </template>
      <template #cell(pdf)="data">
        <b-button
          class="btn-danger"
          :href="pdfEndpointCompleto + data.item.IDrinnovo"
          size="sm"
          target="_blank"
          ><font-awesome-icon icon="fa-solid fa-file-pdf"
        /></b-button>
      </template>
    </PaginatedTable>
  </div>
</template>

<script>
import PaginatedTable from "@/components/common/PaginatedTable.vue";
import BannerCountProfili from "@/components/rinnovi/BannerCountProfili.vue";
import BottonePDF from "@/components/rinnovi/BottonePDF.vue";

import * as clienteRepo from "../services/Cliente";
import * as mensiliRepo from "../services/RinnoviMensili";
import * as localeRepo from "../services/Locale";

import router from "../router/index";

export default {
  name: "ListaRinnoviMensili",
  components: {
    PaginatedTable,
    BottonePDF,
    BannerCountProfili,
  },
  sockets: {
    connect() {
      console.log("Socket connected");
    },
    fileData: function (data) {
      this.reportFatturazione = data;
      let p = this.$refs.report_output;
      p.innerHTML =
        "<span style='padding: 0px'>" +
        this.reportFatturazione.replaceAll("\n", "<br>") +
        "</span>";
      p.scrollTop = p.scrollHeight;
    },
    percentuale: function (data) {
      const splitted = data.split("\n");
      let last = 0;
      if (splitted.length > 0) {
        last = splitted[splitted.length - 2];
      }
      this.percentuale = parseFloat(last);
    },
    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      console.log("Socket message");
    },
  },
  data() {
    return {
      orderBy: [["id", "asc"]],
      search: "",
      refreshKey: 0,
      refreshValue: 0,
      searchMonth: null,
      searchYear: null,
      searchProfile: null,
      profiliLocale: [],
      modaleFatture: false,
      modaleControlli: false,
      reportFatturazione: "",
      streamAttivo: false,
      percentuale: 0,
      fullContent: "",
      bannerRefreshKey: 1,
      fields: [
        { key: "IDrinnovo", sortable: true },
        { key: "IDcliente", sortable: true },
        { key: "cliente", sortable: true },
        { key: "insegna", sortable: true },
        { key: "indirizzo", sortable: true },
        { key: "raggruppa", sortable: false },
        { key: "scaduti", sortable: false },
        { key: "fatturazione", sortable: false },
        { key: "profilo", sortable: false },
        { key: "pdf", sortable: false },
      ],
    };
  },
  watch: {
    query: {
      async handler(val) {
        await this.$store.dispatch("modificaPreferenzeFormRinnovoMensile", {
          search: this.search,
          searchMonth: this.searchMonth,
          searchYear: this.searchYear,
          searchProfile: this.searchProfile,
        });
        this.ricaricaPaginatedTable();
        this.ricaricaBanner();
      },
      deep: true,
    },
  },
  created() {
    //Scarico le preferenze salvate su vuex
    if (this.$store.state.preferences.forms.rinnovi_mensili) {
      this.search = this.$store.state.preferences.forms.rinnovi_mensili.search;
      this.searchMonth =
        this.$store.state.preferences.forms.rinnovi_mensili.searchMonth;
      this.searchYear =
        this.$store.state.preferences.forms.rinnovi_mensili.searchYear;
      this.searchProfile =
        this.$store.state.preferences.forms.rinnovi_mensili.searchProfile;
    }

    this.caricaProfiliLocale();
  },
  methods: {
    downloadReport() {
      this.$refs.download_button.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," +
          encodeURIComponent(this.reportFatturazione)
      );

      this.$refs.download_button.setAttribute("download", "report.txt");

      this.$refs.download_button.click();
    },
    async generaFatture() {
      this.stopStream();
      this.startStream();

      const accessToken = await this.$auth.getTokenSilently();
      await mensiliRepo.generaFatture(accessToken);
    },
    async effettuaControlli(mese, anno) {
      this.stopStream();
      this.startStream();

      const accessToken = await this.$auth.getTokenSilently();
      await mensiliRepo.effettuaControlli(
        accessToken,
        new Date(anno, mese - 1, 1)
      );
    },
    startStream() {
      this.streamAttivo = true;
      this.$socket.emit("startStreamReportMensile", { test: "ok" });
    },
    stopStream() {
      this.streamAttivo = false;
      this.$socket.emit("stopStreamReportMensile", { test: "ok" });
    },
    async countProfili() {
      const accessToken = await this.$auth.getTokenSilently();
      return (
        await mensiliRepo.totaleProfili(
          accessToken,
          this.searchMonth,
          this.searchYear
        )
      ).data;
    },
    async getPage(accessToken, page) {
      //   let clienti = await clienteRepo.getPage(
      //     accessToken,
      //     page,
      //     this.searchQuery
      //   );
      let rinnovi = await mensiliRepo.getPage(accessToken, page, this.query);
      //   for (let i = 0; i < clienti.length; i++)
      //     this.compattaIndirizzo(clienti[i]);
      rinnovi = rinnovi.map((rinnovo) => {
        return {
          IDrinnovo: rinnovo.id,
          IDcliente: rinnovo.cliente_cliente.id_trace,
          cliente: rinnovo.cliente_cliente.ragsoc,
          insegna: rinnovo.locale_locale,
          indirizzo:
            rinnovo.locale_locale.via +
            " " +
            rinnovo.locale_locale.citta +
            " (" +
            rinnovo.locale_locale.prov +
            ")",
          raggruppa: rinnovo.group_billing,
          scaduti: "TODO",
          fatturazione: rinnovo.tipo_fatturazione,
          profilo: rinnovo.locale_locale.profilo_locale,
          pdf: rinnovo,
        };
      });
      return rinnovi;
    },
    async getCount(accessToken) {
      //   return await clienteRepo.getCount(accessToken, this.countQuery);
      let count = await mensiliRepo.getCount(accessToken, this.countQuery);
      return count;
    },
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + "€"
      );
    },
    ricaricaPaginatedTable() {
      // this.refreshKey += 1;
      this.refreshValue += 1;
    },
    ricaricaBanner() {
      this.bannerRefreshKey += 1;
    },
    compattaIndirizzo(cliente) {
      cliente["indirizzo"] =
        cliente["via"] + ", " + cliente["citta"] + ", " + cliente["provincia"];
      delete cliente["via"];
      delete cliente["citta"];
      delete cliente["provincia"];
    },
    openRow(row, number, event) {
      router.push({ name: "rinnovoMensile", params: { id: row["IDrinnovo"] } });
    },
    async caricaProfiliLocale() {
      const accessToken = await this.$auth.getTokenSilently();
      await this.$store.dispatch("caricaProfiliLocale", accessToken);
      this.profiliLocale = this.$store.state.profili_locale;
    },
    async modificaProfilo(locale, profilo) {
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await localeRepo.update(accessToken, locale, {
          profilo_locale: profilo,
        });
        if (!response) {
          this.$toast.error("Errore modifica profilo");
          return;
        }
        this.$toast.open("Profilo modificato");
      } catch (e) {
        console.error(e);
        this.$toast.error("Errore modifica profilo");
      }
    },
    async modificaTipoFatturazione(rinnovo, tipo) {
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await mensiliRepo.update(accessToken, rinnovo, {
          tipo_fatturazione: tipo,
        });
        if (!response) {
          this.$toast.error("Errore modifica tipo di fatturazione");
          return;
        }
        this.$toast.open("Tipo di fatturazione modificato");
      } catch (e) {
        console.error(e);
        this.$toast.error("Errore modifica tipo di fatturazione");
      }
    },
    async modificaFatturazionePerCliente(rinnovo, stato) {
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await mensiliRepo.update(accessToken, rinnovo, {
          group_billing: stato,
        });
        if (!response) {
          this.$toast.error("Errore modifica fatturazione per cliente");
          return;
        }
        this.$toast.open("Fatturazione per cliente modificato");
      } catch (e) {
        console.error(e);
        this.$toast.error("Errore modifica fatturazione per cliente");
      }
    },
    haProposta(articoli) {
      let proposta = false;
      for (const articolo of articoli) {
        if (articolo.proponi) {
          proposta = true;
          break;
        }
      }
      return proposta;
    },
    sort(params) {
      let realParamsName = [];
      switch (params.sortBy) {
        case "IDrinnovo":
          realParamsName = ["id"];
          break;
        case "IDcliente":
          realParamsName = ["cliente_cliente", "id_trace"];
          break;
        case "cliente":
          realParamsName = ["cliente_cliente", "ragsoc"];
          break;
        case "insegna":
          realParamsName = ["locale_locale", "nome"];
          break;
        case "indirizzo":
          realParamsName = ["locale_locale", "via"];
          break;
      }
      this.orderBy = [[...realParamsName, params.sortDesc ? "desc" : "asc"]];
      this.ricaricaPaginatedTable();
    },
  },
  computed: {
    canGeneraFatture() {
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:rinnovo:mensile:generafatture"
          ) != -1
        );
      }
      return false;
    },
    reportText() {
      return this.resportFatturazione;
    },
    actualRenewDescription() {
      const date = new Date();
      return this.months[date.getMonth()].text + " " + date.getFullYear();
    },
    pdfEndpointCompleto() {
      return process.env.VUE_APP_API_HOST + "/pdf/mensile/";
    },
    countQuery() {
      let countQuery = {
        distinct: true,
        ...this.query,
      };
      return countQuery;
    },
    query() {
      let query = {
        include: [
          "cliente_cliente",
          {
            model: "locale",
            as: "locale_locale",
            include: ["configurazione_mensile_locale"],
          },
          // "articolo_mensiles",
          // "articolo_rinnovos",
        ],
        order: this.orderBy,
        where: {
          next_billing: {
            startsWith:
              this.searchYear +
              "-" +
              (this.searchMonth < 10
                ? "0" + this.searchMonth
                : this.searchMonth),
          },
        },
      };
      if (this.searchProfile) {
        query["where"] = {
          and: {
            ...query["where"],
            ["$locale_locale.profilo_locale$"]: this.searchProfile,
          },
        };
      }
      if (this.search != "") {
        query["where"] = {
          and: {
            ...query["where"],
            or: {
              ["$cliente_cliente.ragsoc$"]: {
                substring: this.search,
              },
              ["$cliente_cliente.id$"]: {
                substring: this.search,
              },
              ["$cliente_cliente.id_trace$"]: {
                substring: this.search,
              },
              ["$cliente_cliente.piva$"]: {
                substring: this.search,
              },
              id: {
                substring: this.search,
              },
            },
          },
        };
      }

      return query;
    },
    years() {
      const actualYear = new Date().getUTCFullYear();
      let years = [];
      for (let i = actualYear - 2; i < actualYear + 2; i++) {
        years.push({
          text: i,
          value: i,
        });
      }
      return years;
    },
    months() {
      return [
        { text: "Gennaio", value: 1 },
        { text: "Febbraio", value: 2 },
        { text: "Marzo", value: 3 },
        { text: "Aprile", value: 4 },
        { text: "Maggio", value: 5 },
        { text: "Giugno", value: 6 },
        { text: "Luglio", value: 7 },
        { text: "Agosto", value: 8 },
        { text: "Settembre", value: 9 },
        { text: "Ottobre", value: 10 },
        { text: "Novembre", value: 11 },
        { text: "Dicembre", value: 12 },
      ];
    },
    profili_locale() {
      return [{ text: "Tutti i profili", value: null }].concat(
        this.profiliLocale.map((item) => {
          return {
            text: item.descrizione,
            value: item.id,
          };
        })
      );
    },
    tipi_fatturazione() {
      return [
        // { text: "Selezionare..", value: null },
        { text: "Mensile", value: 1 },
        { text: "Trimestrale", value: 2 },
        { text: "Semestrale", value: 3 },
      ];
    },
  },
};
</script>
