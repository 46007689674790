<template>
  <b-card no-body class="mb-1">
    <b-card-header
      header-tag="header"
      :style="'background-color:' + color"
      class="p-1"
      role="tab"
    >
      <b-button
        class="w-100"
        block
        v-b-toggle="'accordion-' + identificatore"
        :style="'border:0px; background-color:' + color + ';color:' + textcolor"
      >
        <div class="d-flex justify-content-between">
          <span>{{ titolo }}</span>
          <span>{{ append }}</span>
        </div>
      </b-button>
    </b-card-header>
    <b-collapse
      :id="'accordion-' + identificatore"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <slot></slot>
      </b-card-body>
    </b-collapse>
  </b-card>
</template>

<script>
export default {
  name: "AccordionCard",
  props: [
    "identificatore",
    "accordion",
    "titolo",
    "append",
    "color",
    "textcolor"
  ]
};
</script>
