import ApiConnector from "./ApiConnector";
import axios from "axios";

let connector = new ApiConnector(process.env.VUE_APP_API_HOST);

export const getAll = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ExScadutiApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getAllExScadutis(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getPage = (token, page = 1, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ExScadutiApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getExScadutiPage(page, opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getCount = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ExScadutiApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.countExScadutis(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getTotals = (token) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return axios.get(`${process.env.VUE_APP_API_HOST}/exScaduti/totali`, config);
};

export const getByIdTrace = (token, id_trace) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/exScaduti/customer/${id_trace}`,
    config
  );
};

export const getList = (token) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return axios.get(`${process.env.VUE_APP_API_HOST}/exScaduti/list`, config);
};

export const getBillsByIdTrace = (token, id_trace) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/exScaduti/customer/bills/${id_trace}`,
    config
  );
};
