<template>
  <div class="m-3">
    <!-- MODALE MODIFICA CONTATTO -->
    <b-modal
      no-close-on-backdrop
      centered
      id="modale-modifica"
      v-model="form.modifica.aperto"
      title="Modifica Contatto"
      hide-footer
    >
      <ContactCreationForm
        :id="form.modifica.data.id"
        :cliente="form.modifica.data.cliente"
        :locale="form.modifica.data.locale"
        :reference="form.modifica.data.reference"
        :profiloContatto="form.modifica.data.profilo_contatto"
        :mail="form.modifica.data.mail"
        :telephone="form.modifica.data.telephone"
        v-on:contactDelete="
          () => {
            form.modifica.aperto = false;
            ricaricaPaginatedTable();
          }
        "
        v-on:contactCreation="
          () => {
            form.modifica.aperto = false;
            ricaricaPaginatedTable();
          }
        "
        v-on:contactUpdate="
          () => {
            form.modifica.aperto = false;
            ricaricaPaginatedTable();
          }
        "
      />

      <!-- <b-form
        @submit="(event) => modificaContatto(event, form.modifica.data)"
        :style="{ '--secondary-color': theme.light['secondary'] }"
      >
        <b-form-group
          id="input-group-1"
          label="Riferimento:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.modifica.data.reference"
            @input="
              () => {
                form.modifica.data.reference =
                  form.modifica.data.reference.toUpperCase();
              }
            "
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          class="mt-2"
          label="Profilo:"
          label-for="profilo"
        >
          <v-select
            id="profilo"
            :options="profili"
            :reduce="(item) => item.id"
            v-model="form.modifica.data.profilo_contatto"
            label="descrizione"
          ></v-select>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          class="mt-2"
          label="Telefono:"
          label-for="input-3"
        >
          <TelephoneInput id="input-3" v-model="form.modifica.data.telephone">
          </TelephoneInput>
        </b-form-group>

        <b-form-group
          id="input-group-4"
          class="mt-2"
          label="Mail:"
          label-for="input-4"
        >
          <b-form-input
            id="input-4"
            type="email"
            v-model="form.modifica.data.mail"
          ></b-form-input>
        </b-form-group>
        <div class="d-flex justify-content-between mt-4">
          <b-button
            variant="danger"
            @click="() => eliminaContatto(form.modifica.data.id)"
            >Elimina</b-button
          >
          <b-button type="submit" variant="primary">Conferma</b-button>
        </div>
      </b-form> -->
    </b-modal>
    <!-- MODALE CREAZIONE CONTATTO -->
    <b-modal
      no-close-on-backdrop
      centered
      id="modale-creazione"
      v-model="form.creazione.aperto"
      title="Creazione Contatto"
      hide-footer
    >
      <ContactCreationForm
        :telephone="form.creazione.data.telephone"
        v-on:contactDelete="
          () => {
            form.creazione.aperto = false;
            ricaricaPaginatedTable();
          }
        "
        v-on:contactCreation="
          () => {
            form.creazione.aperto = false;
            ricaricaPaginatedTable();
          }
        "
        v-on:contactUpdate="
          () => {
            form.creazione.aperto = false;
            ricaricaPaginatedTable();
          }
        "
      />
      <!-- <b-form
        @submit="(event) => creaContatto(event, form.creazione.data)"
        :style="{ '--secondary-color': theme.light['secondary'] }"
      >
        <b-form-group id="input-group-2" label="Cliente:" label-for="cliente">
          <v-select
            id="cliente"
            class="background-secondary"
            :options="clienti"
            :reduce="(item) => item.id"
            v-model="form.creazione.data.cliente"
            label="ragsoc"
            @input="
              () => {
                form.creazione.data.locale = null;
              }
            "
          ></v-select>
        </b-form-group>
        <b-form-group
          id="input-group-locale"
          label="Locale:"
          class="mt-2"
          label-for="locale"
        >
          <v-select
            id="locale"
            class="background-secondary"
            :disabled="form.creazione.data.cliente == null"
            :options="
              locali.filter(
                (locale) => locale.cliente == form.creazione.data.cliente
              )
            "
            :reduce="(item) => item.id"
            v-model="form.creazione.data.locale"
            label="nome"
          ></v-select>
        </b-form-group>
        <b-form-group
          id="input-group-1"
          label="Riferimento:"
          class="mt-2"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.creazione.data.reference"
            @input="
              () => {
                form.creazione.data.reference =
                  form.creazione.data.reference.toUpperCase();
              }
            "
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          class="mt-2"
          label="Profilo:"
          label-for="input-2"
        >
          <v-select
            id="profilo"
            class="background-secondary"
            :options="profili"
            :reduce="(item) => item.id"
            v-model="form.creazione.data.profilo_contatto"
            label="descrizione"
          ></v-select>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          class="mt-2"
          label="Telefono:"
          label-for="input-3"
        >
          <TelephoneInput id="input-3" v-model="form.creazione.data.telephone">
          </TelephoneInput>
        </b-form-group>

        <b-form-group
          id="input-group-4"
          class="mt-2"
          label="Mail:"
          label-for="input-4"
        >
          <b-form-input
            id="input-4"
            type="email"
            v-model="form.creazione.data.mail"
          ></b-form-input>
        </b-form-group>
        <div class="text-end">
          <b-button
            type="submit"
            class="mt-4"
            variant="primary"
            :disabled="
              !(
                form.creazione.data.cliente &&
                form.creazione.data.locale &&
                (form.creazione.data.telephone || form.creazione.data.mail) &&
                form.creazione.data.reference &&
                form.creazione.data.profilo_contatto
              )
            "
            >Conferma</b-button
          >
        </div>
      </b-form> -->
    </b-modal>
    <h1>Lista Contatti</h1>
    <div class="row">
      <div class="col-md-1 mb-2 text-center">
        <b-button
          class="rounded"
          variant="warning"
          @click="
            () => {
              form.creazione.aperto = true;
            }
          "
        >
          +&nbsp;Nuovo</b-button
        >
      </div>
      <div class="col-md-2 mb-2">
        <b-form-input
          type="search"
          placeholder="Inserire telefono, mail, reference..."
          v-model="search"
        ></b-form-input>
      </div>
      <div class="col-md-4">
        <v-select
          id="cliente"
          class="background-secondary"
          :options="clienti_select"
          :reduce="(item) => item.id"
          v-model="cliente_search"
          label="ragsoc"
        ></v-select>
      </div>
      <div class="col-md-3">
        <v-select
          id="locale"
          class="background-secondary"
          :options="locali_cliente_attivo"
          :reduce="(item) => item.id"
          v-model="locale_search"
          label="nome"
        ></v-select>
      </div>
      <div class="col-md-2 mb-2">
        <v-select
          id="profilo"
          class="background-secondary"
          :options="profili_select"
          :reduce="(item) => item.id"
          v-model="profilo_search"
          label="descrizione"
        ></v-select>
      </div>
    </div>

    <PaginatedTable
      :getPage="getPage"
      :getCount="getCount"
      :openRow="openRow"
      :fields="pageFields"
      :key="refreshKey"
      pointer
    >
      <template #cell(profilo_contatto_profilo_contatto)="data">
        {{ data.value.descrizione }}
      </template>

      <template #cell(cliente_cliente)="data">
        {{ data.value.ragsoc }}
      </template>

      <template #cell(locale_locale)="data">
        {{ data.value.nome }}
      </template>

      <template #cell(priorità)="data">
        <SelezionePrioritaTelefono
          :key="data.item.id"
          :telephone="data.item.telephone"
          v-on:updatePriority="ricaricaPaginatedTable"
        />
      </template>
      <!-- 
            <template #cell(import_trace)="data">
              <font-awesome-icon v-if="data.value" icon="fa-solid fa-check" />
              <font-awesome-icon v-else icon="fa-solid fa-xmark" />
            </template>
            <template #cell(immagine)="data">
              <img
                :src="getImagePath(data.value)"
                alt="image"
                width="50"
                height="50"
              />
            </template> -->
    </PaginatedTable>
  </div>
</template>

<script>
import PaginatedTable from "@/components/common/PaginatedTable.vue";
import TelephoneInput from "@/components/common/TelephoneInput";
import ContactCreationForm from "@/components/common/ContactCreationForm";
import SelezionePrioritaTelefono from "@/components/contatti/SelezionePrioritaTelefono";

import * as contattoRepo from "../services/Contatto";
import * as profiloContattoRepo from "../services/ProfiloContatto";
import * as clientiRepo from "../services/Cliente";
import * as localiRepo from "../services/Locale";

export default {
  name: "listaContatti",
  props: ["theme"],
  components: {
    PaginatedTable,
    TelephoneInput,
    ContactCreationForm,
    SelezionePrioritaTelefono,
  },
  data() {
    return {
      search: "",
      cliente_search: null,
      locale_search: null,
      profilo_search: null,
      clienti: [],
      locali: [],
      profili: [],
      refreshKey: 0,
      form: {
        modifica: {
          aperto: false,
          data: {
            id: null,
            cliente: null,
            locale: null,
            telephone: null,
            reference: null,
            profilo_contatto: null,
            mail: null,
          },
        },
        creazione: {
          aperto: false,
          data: {
            cliente: null,
            locale: null,
            telephone: null,
            reference: null,
            profilo_contatto: null,
            mail: null,
          },
        },
      },
    };
  },
  watch: {
    query: {
      handler(val) {
        this.ricaricaPaginatedTable();
      },
      deep: true,
    },
  },
  async created() {
    this.$loading(true);
    if (this.$route.query.telephone) {
      const accessToken = await this.$auth.getTokenSilently();
      let existingContactRequest = await contattoRepo.getTelephonePriority(
        accessToken,
        this.$route.query.telephone
      );
      if (existingContactRequest.data.length > 0) {
        const priorita = existingContactRequest.data.find(
          (item) => item.prioritario == 1
        );
        const cliente = await clientiRepo.getById(
          accessToken,
          priorita.cliente
        );
        console.log(cliente);
        window.open(
          `https://rcatrace.newchurchill.eu/cliente20.jsp?cod_anagra=${cliente.id_trace}`
        );
        window.close();
        return;
      }
      await this.getCustomers();
      await this.getLocations();
      await this.getProfili();
      this.$loading(false);
      this.form.creazione.aperto = true;
      this.form.creazione.data.telephone = this.$route.query.telephone;
    } else {
      await this.getCustomers();
      await this.getLocations();
      await this.getProfili();
      this.$loading(false);
    }
  },
  methods: {
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + "€"
      );
    },
    async getPage(accessToken, page) {
      let contatti = await contattoRepo.getPage(accessToken, page, this.query);

      return contatti;
    },
    async getCount(accessToken) {
      let count = await contattoRepo.getCount(accessToken, this.query);
      return count;
    },
    async getCustomers() {
      const accessToken = await this.$auth.getTokenSilently();
      this.clienti = await clientiRepo.getAll(accessToken);
    },
    async getLocations() {
      const accessToken = await this.$auth.getTokenSilently();
      this.locali = await localiRepo.getAll(accessToken);
    },
    async getProfili() {
      const accessToken = await this.$auth.getTokenSilently();
      this.profili = await profiloContattoRepo.getAll(accessToken);
    },
    ricaricaPaginatedTable() {
      this.refreshKey += 1;
    },
    openRow(row, number, event) {
      console.log(row);
      this.form.modifica.aperto = true;
      this.form.modifica.data = {
        id: row.id,
        cliente: row.cliente,
        locale: row.locale,
        telephone: row.telephone,
        reference: row.reference,
        profilo_contatto: row.profilo_contatto,
        mail: row.mail,
      };
    },
    // chiudiModale() {
    //   this.form.modifica.aperto = false;
    //   this.form.creazione.aperto = false;
    //   this.ricaricaPaginatedTable();
    // },
    async modificaContatto(event, data) {
      event.preventDefault();
      this.$loading(true);
      const updatePayload = {
        telephone: data.telephone ? data.telephone : null,
        mail: data.mail ? data.mail : null,
        reference: data.reference,
        profilo_contatto: data.profilo_contatto,
      };
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const updateResponse = await contattoRepo.update(
          accessToken,
          data.id,
          updatePayload
        );
        this.$toast.open("Contatto modificato!");
        this.ricaricaPaginatedTable();
      } catch (e) {
        console.log(e);
        this.$toast.error("Errore nella modifica del contatto!");
      }
      this.form.modifica.aperto = false;
      this.$loading(false);
    },

    async creaContatto(event, data) {
      event.preventDefault();
      this.$loading(true);
      const createPayload = {
        cliente: data.cliente,
        locale: data.locale,
        telephone: data.telephone ? data.telephone : null,
        mail: data.mail ? data.mail : null,
        reference: data.reference,
        profilo_contatto: data.profilo_contatto,
        priority: true,
      };
      try {
        const accessToken = await this.$auth.getTokenSilently();

        //Controllo se il numero di telefono esiste già per un altro cliente
        let existingContactRequest = await contattoRepo.getAll(accessToken, {
          include: ["cliente_cliente"],
          where: {
            and: {
              not: {
                cliente: data.cliente,
              },
              telephone: data.telephone,
            },
          },
        });
        console.log(existingContactRequest);
        //Se esiste già un contatto con lo stesso numero di telefono chiedo se
        //aggiornare la priorità
        if (existingContactRequest.length > 0) {
          const customers = new Set(
            existingContactRequest.map(
              (item) => "-" + item.cliente_cliente.ragsoc
            )
          );
          const h = this.$createElement;
          const label = h("div", {}, [
            h(
              "p",
              {},
              "Il numero di telefono è già associato ai seguenti clienti:"
            ),
            h(
              "ul",
              {},
              [...customers].map((item) => h("li", {}, item))
            ),
            h("div", {}, [
              h(
                "p",
                {},
                "Vuoi che il contatto attuale abbia priorità su quello già presente?"
              ),
              h(
                "small",
                {},
                "quando riceveremo una chiamata da questo numero di telefono, il cliente associato sarà quello del contatto con priorità"
              ),
            ]),
          ]);
          const confirm = await this.$bvModal.msgBoxConfirm([label], {
            title: "Priorità contatto",
            size: "md",
            buttonSize: "sm",
            // okVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          });
          if (confirm) {
            createPayload.priority = false;
          }
        }
        console.log(createPayload);
        return;
        const updateResponse = await contattoRepo.add(
          accessToken,
          createPayload
        );
        this.$toast.open("Contatto creato!");
        this.form.creazione.data = {
          cliente: null,
          locale: null,
          telephone: null,
          reference: null,
          profilo_contatto: null,
          mail: null,
        };
        this.ricaricaPaginatedTable();
      } catch (e) {
        this.$toast.error("Errore nella creazione del contatto: \n " + e);
      }
      this.form.creazione.aperto = false;
      this.$loading(false);
    },
    async eliminaContatto(id) {
      if (
        !confirm(
          "Cancellare il contatto?\nNon sarà possibile recuperarlo in seguito"
        )
      )
        return;
      this.$loading(true);
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const updateResponse = await contattoRepo.cancel(accessToken, id);
        this.$toast.open("Contatto eliminato!");

        this.ricaricaPaginatedTable();
      } catch (e) {
        console.log(e);
        this.$toast.error("Errore nell'eliminazione del contatto!");
      }
      this.form.modifica.aperto = false;
      this.$loading(false);
    },
  },
  computed: {
    pageFields() {
      return [
        // "id",
        "reference",
        { key: "profilo_contatto_profilo_contatto", label: "Profilo" },
        { key: "telephone", label: "Telefono" },
        "mail",
        { key: "cliente_cliente", label: "Cliente" },
        { key: "locale_locale", label: "Locale" },
        "id_trace",
        "priorità",
        // { key: "active", label: "Attivo" },
      ];
    },
    query() {
      let query = {
        include: [
          "cliente_cliente",
          "locale_locale",
          "profilo_contatto_profilo_contatto",
        ],
      };
      if (this.search.trim() != "") {
        query.where = {
          and: {
            or: {
              id: {
                substring: this.search,
              },
              reference: {
                substring: this.search,
              },
              telephone: {
                substring: this.search,
              },
              mail: {
                substring: this.search,
              },
              id_trace: {
                substring: this.search,
              },
            },
          },
        };
      }

      if (this.cliente_search) {
        query.where = {
          and: {
            ...query.where,
            cliente: this.cliente_search,
          },
        };
      }

      if (this.locale_search) {
        if (this.locale_search == -1) {
          query.where = {
            and: {
              ...query.where,
              "$locale_locale.obsoleto$": null,
            },
          };
        } else {
          query.where = {
            and: {
              ...query.where,
              locale: this.locale_search,
            },
          };
        }
      }

      if (this.profilo_search) {
        query.where = {
          and: {
            ...query.where,
            profilo_contatto: this.profilo_search,
          },
        };
      }
      return query;
    },
    clienti_select() {
      return [{ id: null, ragsoc: "Tutti i clienti" }].concat(this.clienti);
    },
    profili_select() {
      return [{ id: null, descrizione: "Tutti i profili" }].concat(
        this.profili
      );
    },
    locali_cliente_attivo() {
      const base = [
        { id: null, nome: "Tutti i locali" },
        { id: -1, nome: "Solo locali attivi" },
      ];
      if (!this.cliente_search) return base.concat(this.locali);
      return base.concat(
        this.locali.filter((locale) => locale.cliente == this.cliente_search)
      );
    },
  },
};
</script>
