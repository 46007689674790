var RcaApi = require('rca_api');

class ApiConnector {

    constructor(apiUrl = "http://localhost:3000"){
        this.apiUrl = apiUrl;
    }

    getClient(token = ""){
        var defaultClient = RcaApi.ApiClient.instance;
        // Configure Bearer (JWT) access token for authorization: BearerAuth
        var BearerAuth = defaultClient.authentications['BearerAuth'];
        BearerAuth.accessToken = token;
        defaultClient.basePath = this.apiUrl;
        return RcaApi;
    }
}

export default ApiConnector;