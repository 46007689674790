<template>
  <div class="m-1">
    <h1 class="ps-2 pt-2">
      Rinnovi <span style="font-size: 60%">(Annuali/Biennali/31-12)</span>
    </h1>

    <div class="row ps-2">
      <div class="col-md-6 mb-2">
        <div class="d-flex flex-column flex-md-row">
          <b-select
            :options="months"
            v-model="searchMonth"
            class="form-select form-select-sm"
          ></b-select>
          <b-select
            :options="years"
            v-model="searchYear"
            class="form-select form-select-sm"
          ></b-select>
          <b-select
            :options="profili_locale"
            v-model="searchProfile"
            class="form-select form-select-sm"
          ></b-select>
        </div>
      </div>
    </div>
    <div class="row ps-2">
      <div class="col-md-6 mb-2">
        <b-form-input
          type="search"
          placeholder="Inserire ricerca..."
          v-model="search"
        ></b-form-input>
      </div>
    </div>
    <div class="col-md-3 d-flex ps-2 mb-4">
      <span class="mt-auto mb-auto">Rinnovi per pagina:&nbsp;</span>
      <select
        class="mt-auto mb-auto"
        style="width: 50px"
        v-model="perPage"
        v-on:change="ricaricaPaginatedTable"
      >
        <option :value="10">10</option>
        <option :value="20">20</option>
        <option :value="50">50</option>
        <option :value="100">100</option>
      </select>
    </div>
    <BannerCountProfili
      class="ps-3"
      :getCount="countProfili"
      :key="bannerRefreshKey"
    ></BannerCountProfili>
    <PaginatedTable
      :getPage="getPage"
      :getCount="getCount"
      :openRow="openRow"
      :key="refreshKey"
      :fields="fields"
      :sort="sort"
      :refreshValue="refreshValue"
      :perPage="perPage"
      pointer
    >
      <template #cell(VP)="data">
        <div class="text-center">
          <font-awesome-icon
            v-if="data.item.VP"
            icon="fa-solid fa-certificate"
            style="color: green"
          />
        </div>
      </template>
      <template #cell(cliente)="data">
        <div class="one-liner">{{ data.item.cliente }}</div>
      </template>

      <template #cell(insegna)="data">
        <div class="one-liner">{{ data.item.insegna.nome }}</div>
      </template>
      <template #cell(indirizzo)="data">
        <div class="one-liner">{{ data.item.indirizzo }}</div>
      </template>
      <template #cell(totale)="data">
        <div style="text-align: right">
          {{ formatPrice(data.item.totale) }}
        </div>
      </template>
      <template #cell(mail)="data">
        <div class="text-center">
          <font-awesome-icon
            :id="'popover-' + data.index"
            :icon="
              data.item.mail.length > 0
                ? 'fa-solid fa-paper-plane'
                : 'fa-regular fa-paper-plane'
            "
            :style="{ color: getRinnovoMailStatusColor(data.item.mail) }"
          />

          <b-popover
            v-if="data.item.mail.length > 0"
            style="max-height: 80vh !important; overflow: scroll"
            :target="'popover-' + data.index"
            triggers="hover focus"
            placement="left"
          >
            <b-list-group>
              <b-list-group-item
                style="border: 1px solid black; margin-bottom: 10px; padding: 0"
                v-for="(mail, index) in data.item.mail"
                :key="index"
              >
                <b-table-simple tfoot-class="d-none">
                  <b-thead style="background-color: #f0f1f1; padding: 0">
                    <b-tr>
                      <b-th>Data</b-th>
                      <b-td>{{ getLocaleDateTime(mail.date, "it-IT") }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>Utente</b-th>
                      <b-td>{{ mail.user.nickname }}</b-td>
                    </b-tr>
                  </b-thead>

                  <b-tbody
                    v-if="!mail.tsmtp_reports || mail.tsmtp_reports.length == 0"
                  >
                    <b-tr>
                      <b-th colspan="2">
                        Le informazioni sull'invio saranno disponibili a breve,
                        <b-button
                          size="sm"
                          pill
                          variant="info"
                          @click="ricaricaPaginatedTable"
                          >clicca qui</b-button
                        >
                        per aggiornare.
                      </b-th>
                    </b-tr>
                  </b-tbody>
                  <b-tbody
                    v-for="(report, index) in mail.tsmtp_reports"
                    :key="index"
                  >
                    <b-tr>
                      <b-th>Oggetto</b-th>
                      <b-td>{{ report.subject }}</b-td>
                    </b-tr>

                    <b-tr>
                      <b-th>Destinatario</b-th>
                      <b-td>{{ report.recipient }}</b-td>
                    </b-tr>

                    <b-tr :style="{ color: getStatusColor(report.status) }">
                      <b-th>Stato</b-th>
                      <b-td style="font-weight: bold">{{
                        statusInfo[report.status].description
                      }}</b-td>
                    </b-tr>

                    <b-tr
                      v-if="report.error"
                      :style="{ color: getStatusColor(report.status) }"
                    >
                      <b-th>Errore</b-th>
                      <b-td>{{ report.error }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-list-group-item>
            </b-list-group>
          </b-popover>
        </div>
      </template>
      <template #cell(tacito)="data">
        <div class="text-center">
          <font-awesome-icon
            v-if="
              data.item.insegna.configurazione_rinnovo_locale
                ? data.item.insegna.configurazione_rinnovo_locale.tacito_rinnovo
                : false
            "
            icon="fa-solid fa-arrow-rotate-right"
          />
        </div>
      </template>
      <template #cell(profilo)="data">
        <b-select
          :options="profili_locale"
          v-model="data.item.profilo"
          class="form-select form-select-sm"
          v-on:change="modificaProfilo(data.item.insegna.id, data.item.profilo)"
        ></b-select>
      </template>
      <template #cell(pdf)="data">
        <BottonePDF :id="data.item.IDrinnovo"></BottonePDF>
      </template>
    </PaginatedTable>
  </div>
</template>

<script>
import PaginatedTable from "@/components/common/PaginatedTable.vue";

import * as annualiRepo from "../services/RinnoviAnnuali";
import * as localeRepo from "../services/Locale";

import BottonePDF from "@/components/rinnovi/BottonePDF.vue";
import BannerCountProfili from "@/components/rinnovi/BannerCountProfili.vue";

import router from "../router/index";

export default {
  name: "ListaRinnoviAnnuali",
  components: {
    PaginatedTable,
    BottonePDF,
    BannerCountProfili,
  },
  data() {
    return {
      orderBy: [["id", "asc"]],
      search: "",
      refreshKey: 0,
      refreshValue: 0,
      searchMonth: null,
      searchYear: null,
      searchProfile: null,
      profiliLocale: [],
      perPage: 10,
      bannerRefreshKey: 1,
      fields: [
        { key: "VP" },
        { key: "IDrinnovo", sortable: true },
        { key: "IDcliente", sortable: true },
        { key: "cliente", sortable: true },
        { key: "insegna", sortable: true },
        { key: "indirizzo", sortable: true },
        { key: "totale" },
        { key: "mail", label: `Mail`, sortable: false },
        { key: "tacito", sortable: false },
        { key: "scaduti", sortable: false },
        { key: "profilo", sortable: false },
        { key: "pdf", sortable: false },
      ],
    };
  },
  watch: {
    query: {
      async handler(val) {
        await this.$store.dispatch("modificaPreferenzeFormRinnovoAnnuale", {
          search: this.search,
          searchMonth: this.searchMonth,
          searchYear: this.searchYear,
          searchProfile: this.searchProfile,
          perPage: this.perPage,
        });
        this.ricaricaPaginatedTable();
        this.ricaricaBanner();
      },
      deep: true,
    },
    perPage: {
      async handler(val) {
        await this.$store.dispatch("modificaPreferenzeFormRinnovoAnnuale", {
          search: this.search,
          searchMonth: this.searchMonth,
          searchYear: this.searchYear,
          searchProfile: this.searchProfile,
          perPage: this.perPage,
        });
        // this.ricaricaPaginatedTable();
        // this.ricaricaBanner();
      },
    },
  },
  created() {
    //Scarico le preferenze salvate su vuex
    if (this.$store.state.preferences.forms.rinnovi_annuali) {
      this.search = this.$store.state.preferences.forms.rinnovi_annuali.search;
      this.searchMonth =
        this.$store.state.preferences.forms.rinnovi_annuali.searchMonth;
      this.searchYear =
        this.$store.state.preferences.forms.rinnovi_annuali.searchYear;
      this.searchProfile =
        this.$store.state.preferences.forms.rinnovi_annuali.searchProfile;
      this.perPage =
        this.$store.state.preferences.forms.rinnovi_annuali.perPage;
    }

    this.caricaProfiliLocale();
  },
  methods: {
    getStatusColor(status) {
      const info = this.statusInfo[status];
      if (info) return info.color;
      return "black";
    },
    getRinnovoMailStatusColor(mail_inviate) {
      if (mail_inviate.length === 0) return "black";
      let hasError = false;
      //estraggo la mail con la data più recente
      const last_mail = mail_inviate.reduce((prev, current) => {
        return prev.date > current.date ? prev : current;
      });

      let hasReport =
        last_mail.tsmtp_reports && last_mail.tsmtp_reports.length > 0;
      //controllo i report dell'ultima mail se ci sono degli errori
      if (last_mail.tsmtp_reports && last_mail.tsmtp_reports.length > 0) {
        for (const report of last_mail.tsmtp_reports) {
          const info = this.statusInfo[report.status];
          if (info) {
            if (!info.isPositive) {
              hasError = true;
              break;
            }
          }
        }
      }
      console.log(hasReport, hasError);
      return !hasReport ? "#31D2F2" : hasError ? "red" : "green";
    },
    getLocaleDateTime(date, locale = "it-IT") {
      console.log(date);
      const data = new Date(date);
      data.setHours(data.getHours() - 2);
      const giorno = data.toLocaleDateString(locale);
      const ora = data.toLocaleTimeString(locale);
      return `${giorno} ${ora}`;
    },
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + "€"
      );
    },
    sort(params) {
      let realParamsName = [];
      switch (params.sortBy) {
        case "IDrinnovo":
          realParamsName = ["id"];
          break;
        case "IDcliente":
          realParamsName = ["cliente_cliente", "id_trace"];
          break;
        case "cliente":
          realParamsName = ["cliente_cliente", "ragsoc"];
          break;
        case "insegna":
          realParamsName = ["locale_locale", "nome"];
          break;
        case "indirizzo":
          realParamsName = ["locale_locale", "via"];
          break;
      }
      this.orderBy = [[...realParamsName, params.sortDesc ? "desc" : "asc"]];
      this.ricaricaPaginatedTable();
    },
    async countProfili() {
      const accessToken = await this.$auth.getTokenSilently();
      return (
        await annualiRepo.totaleProfili(
          accessToken,
          this.searchMonth,
          this.searchYear
        )
      ).data;
    },
    ricaricaBanner() {
      this.bannerRefreshKey += 1;
    },
    async getPage(accessToken, page) {
      console.log("GET PAGE");
      let rinnovi = await annualiRepo.getPageOfXItems(
        accessToken,
        page,
        this.perPage,
        this.query
      );
      //   for (let i = 0; i < clienti.length; i++)
      //     this.compattaIndirizzo(clienti[i]);
      rinnovi = rinnovi.map((rinnovo) => {
        //Controllo se uno degli articoli è VP
        const hasVP = rinnovo.articolo_rinnovos.reduce((prev, articolo) => {
          return prev || articolo.articolo == 1408;
        }, false);
        //Calcolo il totale
        const totale = rinnovo.articolo_rinnovos.reduce((prev, articolo) => {
          return prev + (articolo.prezzo - articolo.sconto);
        }, 0);
        //Ordino le mail inviate per data
        rinnovo.mail_inviates = rinnovo.mail_inviates.sort((a, b) => {
          return new Date(b.date) - new Date(a.date);
        });
        return {
          IDrinnovo: rinnovo.id,
          VP: hasVP,
          totale: totale,
          IDcliente: rinnovo.cliente_cliente.id_trace,
          cliente: rinnovo.cliente_cliente.ragsoc,
          insegna: rinnovo.locale_locale,
          indirizzo:
            rinnovo.locale_locale.via +
            " " +
            rinnovo.locale_locale.citta +
            " (" +
            rinnovo.locale_locale.prov +
            ")",
          mail: rinnovo.mail_inviates,
          tacito: rinnovo.ricorrente,
          scaduti: "TODO",
          profilo: rinnovo.locale_locale.profilo_locale,
          pdf: rinnovo.articolo_rinnovos,
        };
      });

      return rinnovi;
    },
    async getCount(accessToken) {
      //   return await clienteRepo.getCount(accessToken, this.countQuery);
      let count = await annualiRepo.getCount(accessToken, this.countQuery);
      return count;
    },
    ricaricaPaginatedTable() {
      console.log("RICARICA");
      // this.refreshKey += 1;
      this.refreshValue += 1;
    },
    compattaIndirizzo(cliente) {
      cliente["indirizzo"] =
        cliente["via"] + ", " + cliente["citta"] + ", " + cliente["provincia"];
      delete cliente["via"];
      delete cliente["citta"];
      delete cliente["provincia"];
    },
    openRow(row, number, event) {
      router.push({ name: "rinnovoAnnuale", params: { id: row["IDrinnovo"] } });
    },
    async caricaProfiliLocale() {
      const accessToken = await this.$auth.getTokenSilently();
      await this.$store.dispatch("caricaProfiliLocale", accessToken);
      this.profiliLocale = this.$store.state.profili_locale;
    },
    async modificaProfilo(locale, profilo) {
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await localeRepo.update(accessToken, locale, {
          profilo_locale: profilo,
        });
        if (!response) {
          this.$toast.error("Errore modifica profilo");
          return;
        }
        this.$toast.open("Profilo modificato");
      } catch (e) {
        console.error(e);
        this.$toast.error("Errore modifica profilo");
      }
    },
    haProposta(articoli) {
      let proposta = false;
      for (const articolo of articoli) {
        if (articolo.proponi) {
          proposta = true;
          break;
        }
      }
      return proposta;
    },
  },
  computed: {
    statusInfo() {
      return {
        NEW: {
          color: "orange",
          isPositive: true,
          description: "La mail è stata messa in coda",
        },
        DEFER: {
          color: "orange",
          isPositive: false,
          description: "La mail è stata rimandata",
        },
        SUCCESS: {
          color: "green",
          isPositive: true,
          description: "La mail è stata inviata con successo",
        },
        OPEN: {
          color: "green",
          isPositive: true,
          description: "La mail è stata aperta",
        },
        CLICK: {
          color: "green",
          isPositive: true,
          description: "Link cliccato",
        },
        UNSUB: {
          color: "red",
          isPositive: false,
          description: "Il destinatario si è disiscritto",
        },
        REPORT: {
          color: "red",
          isPositive: false,
          description: "La mail è stata segnalata come spam",
        },
        ERROR: {
          color: "red",
          isPositive: false,
          description: "La mail ha generato un errore",
        },
        FAIL: {
          color: "red",
          isPositive: false,
          description: "La mail ha generato un errore",
        },
        SYSFAIL: {
          color: "red",
          isPositive: false,
          description: "La mail ha generato un errore",
        },
      };
    },
    pdfEndpointProposta() {
      return process.env.VUE_APP_API_HOST + "/pdf/rinnovo/proposta/";
    },
    pdfEndpointCompleto() {
      return process.env.VUE_APP_API_HOST + "/pdf/rinnovo/";
    },
    countQuery() {
      let query = {
        include: [
          "cliente_cliente",
          {
            model: "locale",
            as: "locale_locale",
          },
        ],
        where: {
          and: {
            completato: false,
            mese: {
              startsWith:
                this.searchYear +
                "-" +
                (this.searchMonth < 10
                  ? "0" + this.searchMonth
                  : this.searchMonth),
            },
          },
        },
      };
      if (this.searchProfile) {
        query["where"] = {
          and: {
            completato: false,
            ["$locale_locale.profilo_locale$"]: this.searchProfile,
            ...query["where"],
          },
        };
      }
      if (this.search != "") {
        query["where"] = {
          and: {
            completato: false,
            ...query["where"],

            or: {
              ["$cliente_cliente.ragsoc$"]: {
                substring: this.search,
              },
              ["$cliente_cliente.id$"]: {
                substring: this.search,
              },
              ["$cliente_cliente.id_trace$"]: {
                substring: this.search,
              },
              ["$cliente_cliente.piva$"]: {
                substring: this.search,
              },
              id: {
                substring: this.search,
              },
            },
          },
        };
      }

      return query;
    },
    query() {
      let query = {
        include: [
          { model: "cliente", as: "cliente_cliente" },
          {
            model: "articolo_rinnovo",
            as: "articolo_rinnovos",
            separate: true,
          },
          {
            model: "locale",
            as: "locale_locale",
            include: ["configurazione_rinnovo_locale"],
          },
          {
            model: "mail_inviate",
            as: "mail_inviates",
            separate: true,
            include: ["user", "tsmtp_reports"],
          },
        ],
        order: this.orderBy,
        where: {
          and: {
            completato: false,
            mese: {
              startsWith:
                this.searchYear +
                "-" +
                (this.searchMonth < 10
                  ? "0" + this.searchMonth
                  : this.searchMonth),
            },
          },
        },
        subQuery: false,
      };
      if (this.searchProfile) {
        //Nella seconda posizione di include deve esserci il locale
        query.include[2].where = {
          profilo_locale: this.searchProfile,
        };
        // query["where"] = {
        //   and: {
        //     completato: false,
        //     ["$locale_locale.profilo_locale$"]: this.searchProfile,
        //     ...query["where"],
        //   },
        // };
      }
      if (this.search != "") {
        query["where"] = {
          and: {
            completato: false,
            ...query["where"],
            or: {
              ["$cliente_cliente.ragsoc$"]: {
                substring: this.search,
              },
              ["$cliente_cliente.id$"]: {
                substring: this.search,
              },
              ["$cliente_cliente.id_trace$"]: {
                substring: this.search,
              },
              ["$cliente_cliente.piva$"]: {
                substring: this.search,
              },
              id: {
                substring: this.search,
              },
            },
          },
        };
      }

      return query;
    },
    years() {
      const actualYear = new Date().getUTCFullYear();
      let years = [];
      for (let i = actualYear - 2; i < actualYear + 3; i++) {
        years.push({
          text: i,
          value: i,
        });
      }
      return years;
    },
    months() {
      return [
        { text: "Gennaio", value: 1 },
        { text: "Febbraio", value: 2 },
        { text: "Marzo", value: 3 },
        { text: "Aprile", value: 4 },
        { text: "Maggio", value: 5 },
        { text: "Giugno", value: 6 },
        { text: "Luglio", value: 7 },
        { text: "Agosto", value: 8 },
        { text: "Settembre", value: 9 },
        { text: "Ottobre", value: 10 },
        { text: "Novembre", value: 11 },
        { text: "Dicembre", value: 12 },
      ];
    },
    profili_locale() {
      return [{ text: "Tutti i profili", value: null }].concat(
        this.profiliLocale.map((item) => {
          return {
            text: item.descrizione,
            value: item.id,
          };
        })
      );
    },
  },
};
</script>

<style>
.one-liner {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.popover {
  max-width: 90vw !important;
  max-height: 90vh !important;
  width: auto !important;
  overflow-y: scroll !important;
}
</style>
