<template>
  <b-container class="mt-4">
    <h1>Visualizzatore Log</h1>
    <b-row>
      <b-col cols="3">
        <b-form-group label="Tipo" label-for="tipo">
          <b-select
            id="tipo"
            :options="logTypes"
            v-model="logType"
            class="form-control"
          ></b-select>
        </b-form-group>
      </b-col>
      <b-col cols="9">
        <b-form-group label="Data" label-for="data">
          <b-form-datepicker id="data" v-model="logDate"></b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <div>
      <b-spinner
        v-if="active"
        variant="success"
        type="grow"
        class="streamStatus"
        small
      ></b-spinner>
      <div ref="console" class="console card shadow mt-4 p-2"></div>
    </div>

    {{ logType }}
    {{ logDate }}
  </b-container>
</template>

<script>
export default {
  name: "Logs",
  data() {
    return {
      logType: "error",
      logDate: null,
      active: false,
    };
  },
  created() {
    const date = new Date();
    const month =
      date.getMonth() + 1 > 9
        ? date.getMonth() + 1
        : "0" + (date.getMonth() + 1);
    const day = date.getDate() > 9 ? date.getDate() : "0" + date.getDate();
    this.logDate = `${date.getFullYear()}-${month}-${day}`;
    this.avviaStream();
  },
  watch: {
    logType(newValue, oldValue) {
      this.riavviaStream();
    },
    logDate(newValue, oldValue) {
      this.riavviaStream();
    },
  },
  methods: {
    riavviaStream() {
      console.log("riavvia");
      this.fermaStream();
      this.avviaStream();
    },
    avviaStream() {
      console.log(this.logType);
      console.log(this.logDate);
      this.$socket.emit("startLogStream", {
        type: this.logType,
        date: this.logDate,
      });
      this.active = true;
    },
    fermaStream() {
      this.$socket.emit("stopLogStream", {
        type: this.logType,
        date: this.logDate,
      });
      this.active = false;
    },
  },
  sockets: {
    connect() {
      console.log("Socket connected");
    },
    logData(data) {
      let p = this.$refs.console;
      if (data.length > 100000) data = data.slice(data.length - 100000);
      p.innerHTML =
        "$ " +
        data
          .replaceAll("  ", "&nbsp;&nbsp;&nbsp;&nbsp;") //json formatter
          .replaceAll("\n", "<br>$ ")
          .slice(0, -2);
      p.scrollTop = p.scrollHeight;
    },
    // Fired when the server sends something on the "messageChannel" channel.
    messageChannel(data) {
      console.log("Socket message");
    },
  },
  computed: {
    consoleContent() {
      let content = "prova";
      for (let i = 0; i < 100; i++) {
        content += "<br>prova" + i;
      }
      return content;
    },
    logTypes() {
      return [
        {
          text: "error",
          value: "error",
        },
        {
          text: "warn",
          value: "warn",
        },
        {
          text: "info",
          value: "info",
        },
        {
          text: "http",
          value: "http",
        },
        {
          text: "sequelize",
          value: "sequelize",
        },
        {
          text: "mail",
          value: "mail",
        },
        {
          text: "debug",
          value: "debug",
        },
        {
          text: "action",
          value: "action",
        },
      ];
    },
  },
};
</script>

<style scoped>
.console {
  height: 55vh;
  color: white;
  overflow: auto;
  background-color: black;
}

.streamStatus {
  z-index: 1;
  border: 1px solid white;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
}
</style>
