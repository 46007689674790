var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',{staticClass:"collapsable-tr",staticStyle:{"color":"var(--text-color) !important","border-bottom-color":"var(--secondary-color)"},attrs:{"data-v-tour":"OrderRow-1"}},[_c('td',{staticClass:"collapsable-td",staticStyle:{"width":"35%"},style:({ 'border-left': '10px solid ' + _vm.statusColor + ' !important' })},[_c('table',{staticClass:"table"},[_c('tr',[_vm._m(0),_c('td',{staticStyle:{"width":"23%"}},[_vm._v(_vm._s(_vm.ordine.id))]),_vm._m(1),_c('td',{staticStyle:{"width":"23%"}},[_vm._v(_vm._s(_vm.ordine.impegno_nr))])]),_c('tr',[_vm._m(2),_c('td',[_vm._v(_vm._s(_vm.formatDate(_vm.ordine.data)))]),_vm._m(3),_c('td',[_vm._v(_vm._s(_vm.formatDate(_vm.ordine.data_fatturazione)))])]),_c('tr',[_vm._m(4),_c('td',[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.ordine.totale))+" ")]),_vm._m(5),_c('td',[_vm._v(_vm._s(_vm.ordine.invoice_nr))])]),_c('tr',[_vm._m(6),_c('td',[_vm._v(_vm._s(_vm.ordine.utente_utente.name))]),_c('td',[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-light",staticStyle:{"border":"0px"},attrs:{"type":"button","title":"Generazione appalto"}},[(_vm.ordine.has_rid && _vm.ordine.rid_date != null)?_c('font-awesome-icon',{staticClass:"fa-lg pointer text-primary",attrs:{"icon":"thumbs-up"}}):(_vm.ordine.has_rid && _vm.ordine.rid_date == null)?_c('font-awesome-icon',{staticClass:"fa-lg pointer text-secondary disabled",attrs:{"icon":"exclamation-triangle"}}):_c('font-awesome-icon',{staticClass:"fa-lg pointer text-secondary disabled",attrs:{"icon":"exclamation-triangle"}})],1)]),_c('td',{directives:[{name:"b-modal",rawName:"v-b-modal.adminNoteModal",modifiers:{"adminNoteModal":true}}],on:{"click":function($event){return _vm.$emit(
              'showNoteAmministrative',
              _vm.ordine.note_amministrative,
              _vm.ordine.id
            )}}},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-light",staticStyle:{"border":"0px"},attrs:{"type":"button","title":"Note cliente"}},[_c('font-awesome-icon',{staticClass:"fa-lg pointer",class:_vm.ordine.note_amministrative != null &&
                _vm.ordine.note_amministrative != ''
                  ? 'text-primary'
                  : 'text-secondary disabled',attrs:{"icon":"info-circle"}})],1)]),_c('td',[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-light",staticStyle:{"border":"0px"},attrs:{"type":"button","title":"In attesa di pagamento"}},[_c('font-awesome-icon',{staticClass:"fa-lg pointer",class:_vm.ordine.wait_payment
                  ? 'text-primary'
                  : 'text-secondary disabled',attrs:{"icon":"money-bill-alt"}})],1)])])])]),_c('td',{staticClass:"collapsable-td",staticStyle:{"width":"35%"}},[_c('table',{staticClass:"table"},[_c('tr',[_c('td',[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticStyle:{"padding":"0px","color":"var(--text-color)"},attrs:{"title":"Apri scheda cliente","to":'/clienti/' + _vm.ordine.cliente_cliente.id}},[_vm._v(_vm._s(_vm.ordine.cliente_cliente.ragsoc))])],1)]),_c('hr'),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.descrizioneLocale)+" ")])]),_c('tr',[_c('td',[_vm._v(" "+_vm._s(_vm.indirizzoLocale)+" ")])])])]),_c('td',{staticClass:"collapsable-td",staticStyle:{"width":"15%"}},[_c('table',{staticClass:"table"},[_c('tr',[_vm._m(7),_c('td',{staticStyle:{"width":"50%"}},[_vm._v(" "+_vm._s(_vm.ordine.tecnico_utente ? _vm.ordine.tecnico_utente.name : "N.D.")+" ")])]),_c('tr',[_vm._m(8),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(_vm.ordine.data_consegna))+" ")])]),_c('tr',[_c('td',{directives:[{name:"b-modal",rawName:"v-b-modal.tecNoteModal",modifiers:{"tecNoteModal":true}}],staticClass:"text-center",on:{"click":function($event){return _vm.$emit('showNoteTecniche', _vm.ordine.note_tecniche, _vm.ordine.id)}}},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-light",attrs:{"type":"button","title":"Note tecniche"}},[_c('font-awesome-icon',{staticClass:"fa-lg pointer",class:_vm.ordine.note_tecniche != null && _vm.ordine.note_tecniche != ''
                  ? 'text-primary'
                  : 'text-secondary disabled',attrs:{"icon":"info-circle"}})],1)]),_c('td',{staticClass:"text-center"},[_c('MatricoleAttivazioni',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"ordine":_vm.ordine.id,"editable":_vm.canUpdateSerial,"title":"Matricole e Date di attivazione"}})],1),_c('td',[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-light",attrs:{"type":"button","title":"Import in trace"}},[_c('font-awesome-icon',{staticClass:"fa-lg pointer",class:_vm.ordine.is_closed ? 'text-primary' : 'text-secondary disabled',attrs:{"icon":"exchange-alt"}})],1)])])])]),_c('td',{staticClass:"collapsable-td",staticStyle:{"width":"15%"}},[_c('table',{staticClass:"text-center"},[_c('tr',[_c('td',{attrs:{"colspan":"4"}},[_c('SelectStato',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"ordine":_vm.ordine.id,"editable":_vm.canUpdateStatus,"title":"Stato"},on:{"edit-status":_vm.editStatus}})],1)]),_c('tr',[_c('td',[_c('button',{staticClass:"btn btn-light m-2",attrs:{"type":"button","id":'popover-' + _vm.ordine.id},on:{"click":_vm.sendMail}},[_c('font-awesome-icon',{staticClass:"fa-lg pointer",style:({
                color: _vm.getRinnovoMailStatusColor(_vm.ordine.mail_inviates),
              }),attrs:{"icon":_vm.ordine.mail_inviates.length > 0
                  ? 'fa-solid fa-paper-plane'
                  : 'fa-regular fa-paper-plane'}})],1),(_vm.ordine.mail_inviates.length > 0)?_c('b-popover',{staticStyle:{"max-height":"80vh !important","overflow":"scroll"},attrs:{"target":'popover-' + _vm.ordine.id,"triggers":"hover focus","placement":"left"}},[_c('b-list-group',_vm._l((_vm.ordine.mail_inviates),function(mail,index){return _c('b-list-group-item',{key:index,staticStyle:{"border":"1px solid black","margin-bottom":"10px","padding":"0","max-width":"50vw !important"}},[_c('b-table-simple',{attrs:{"tfoot-class":"d-none"}},[_c('b-thead',{staticStyle:{"background-color":"#f0f1f1","padding":"0"}},[_c('b-tr',[_c('b-th',[_vm._v("Data")]),_c('b-td',[_vm._v(_vm._s(_vm.getLocaleDateTime(mail.date, "it-IT")))])],1),_c('b-tr',[_c('b-th',[_vm._v("Utente")]),_c('b-td',[_vm._v(_vm._s(mail.user.nickname))])],1)],1),(
                      !mail.tsmtp_reports || mail.tsmtp_reports.length == 0
                    )?_c('b-tbody',[_c('b-tr',[_c('b-th',{attrs:{"colspan":"2"}},[_vm._v(" Le informazioni sull'invio saranno disponibili a breve, "),_c('b-button',{attrs:{"size":"sm","pill":"","variant":"info"},on:{"click":_vm.refresh}},[_vm._v("clicca qui")]),_vm._v(" per aggiornare. ")],1)],1)],1):_vm._e(),_vm._l((mail.tsmtp_reports),function(report,index){return _c('b-tbody',{key:index},[_c('b-tr',[_c('b-th',[_vm._v("Oggetto")]),_c('b-td',[_vm._v(_vm._s(report.subject))])],1),_c('b-tr',[_c('b-th',[_vm._v("Destinatario")]),_c('b-td',[_vm._v(_vm._s(report.recipient))])],1),_c('b-tr',{style:({ color: _vm.getStatusColor(report.status) })},[_c('b-th',[_vm._v("Stato")]),_c('b-td',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(_vm.statusInfo[report.status] && _vm.statusInfo[report.status].description))])],1),(report.error)?_c('b-tr',{style:({ color: _vm.getStatusColor(report.status) })},[_c('b-th',[_vm._v("Errore")]),_c('b-td',[_vm._v(_vm._s(report.error))])],1):_vm._e()],1)})],2)],1)}),1)],1):_vm._e()],1),_c('td',[_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-light m-2",attrs:{"href":_vm.pdfEndpoint + _vm.ordine.id,"target":"_blank","title":"PDF Ordine"}},[_c('font-awesome-icon',{staticClass:"fa-lg pointer text-danger",attrs:{"icon":"file-pdf"}})],1)]),_c('td',[_c('Allegati',{attrs:{"folder":_vm.ordine.id,"destination":"ordine"}})],1),_c('td',[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"btn btn-light m-2",attrs:{"type":"button","title":"Modifica","to":_vm.orderPath,"disabled":!_vm.canUpdateOrdine}},[_c('font-awesome-icon',{staticClass:"fa-lg pointer",attrs:{"icon":"edit"}})],1)],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"width":"23%"}},[_c('b',[_vm._v("Ordine:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"width":"23%"},attrs:{"colspan":"3"}},[_c('b',[_vm._v("Impegno:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Data:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"3"}},[_c('b',[_vm._v("Fatturazione:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Totale:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"3"}},[_c('b',[_vm._v("Fattura:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('b',[_vm._v("Utente:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{staticStyle:{"width":"50%"},attrs:{"colspan":"2"}},[_c('b',[_vm._v("Tecnico:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',{attrs:{"colspan":"2"}},[_c('b',[_vm._v("Consegna:")])])
}]

export { render, staticRenderFns }