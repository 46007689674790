var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"m-3"},[_c('b-modal',{attrs:{"no-close-on-backdrop":"","centered":"","id":"modale-modifica","title":"Modifica Contatto","hide-footer":""},model:{value:(_vm.form.modifica.aperto),callback:function ($$v) {_vm.$set(_vm.form.modifica, "aperto", $$v)},expression:"form.modifica.aperto"}},[_c('ContactCreationForm',{attrs:{"id":_vm.form.modifica.data.id,"cliente":_vm.form.modifica.data.cliente,"locale":_vm.form.modifica.data.locale,"reference":_vm.form.modifica.data.reference,"profiloContatto":_vm.form.modifica.data.profilo_contatto,"mail":_vm.form.modifica.data.mail,"telephone":_vm.form.modifica.data.telephone},on:{"contactDelete":() => {
          _vm.form.modifica.aperto = false;
          _vm.ricaricaPaginatedTable();
        },"contactCreation":() => {
          _vm.form.modifica.aperto = false;
          _vm.ricaricaPaginatedTable();
        },"contactUpdate":() => {
          _vm.form.modifica.aperto = false;
          _vm.ricaricaPaginatedTable();
        }}})],1),_c('b-modal',{attrs:{"no-close-on-backdrop":"","centered":"","id":"modale-creazione","title":"Creazione Contatto","hide-footer":""},model:{value:(_vm.form.creazione.aperto),callback:function ($$v) {_vm.$set(_vm.form.creazione, "aperto", $$v)},expression:"form.creazione.aperto"}},[_c('ContactCreationForm',{attrs:{"telephone":_vm.form.creazione.data.telephone},on:{"contactDelete":() => {
          _vm.form.creazione.aperto = false;
          _vm.ricaricaPaginatedTable();
        },"contactCreation":() => {
          _vm.form.creazione.aperto = false;
          _vm.ricaricaPaginatedTable();
        },"contactUpdate":() => {
          _vm.form.creazione.aperto = false;
          _vm.ricaricaPaginatedTable();
        }}})],1),_c('h1',[_vm._v("Lista Contatti")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-1 mb-2 text-center"},[_c('b-button',{staticClass:"rounded",attrs:{"variant":"warning"},on:{"click":() => {
            _vm.form.creazione.aperto = true;
          }}},[_vm._v(" + Nuovo")])],1),_c('div',{staticClass:"col-md-2 mb-2"},[_c('b-form-input',{attrs:{"type":"search","placeholder":"Inserire telefono, mail, reference..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"col-md-4"},[_c('v-select',{staticClass:"background-secondary",attrs:{"id":"cliente","options":_vm.clienti_select,"reduce":(item) => item.id,"label":"ragsoc"},model:{value:(_vm.cliente_search),callback:function ($$v) {_vm.cliente_search=$$v},expression:"cliente_search"}})],1),_c('div',{staticClass:"col-md-3"},[_c('v-select',{staticClass:"background-secondary",attrs:{"id":"locale","options":_vm.locali_cliente_attivo,"reduce":(item) => item.id,"label":"nome"},model:{value:(_vm.locale_search),callback:function ($$v) {_vm.locale_search=$$v},expression:"locale_search"}})],1),_c('div',{staticClass:"col-md-2 mb-2"},[_c('v-select',{staticClass:"background-secondary",attrs:{"id":"profilo","options":_vm.profili_select,"reduce":(item) => item.id,"label":"descrizione"},model:{value:(_vm.profilo_search),callback:function ($$v) {_vm.profilo_search=$$v},expression:"profilo_search"}})],1)]),_c('PaginatedTable',{key:_vm.refreshKey,attrs:{"getPage":_vm.getPage,"getCount":_vm.getCount,"openRow":_vm.openRow,"fields":_vm.pageFields,"pointer":""},scopedSlots:_vm._u([{key:"cell(profilo_contatto_profilo_contatto)",fn:function(data){return [_vm._v(" "+_vm._s(data.value.descrizione)+" ")]}},{key:"cell(cliente_cliente)",fn:function(data){return [_vm._v(" "+_vm._s(data.value.ragsoc)+" ")]}},{key:"cell(locale_locale)",fn:function(data){return [_vm._v(" "+_vm._s(data.value.nome)+" ")]}},{key:"cell(priorità)",fn:function(data){return [_c('SelezionePrioritaTelefono',{key:data.item.id,attrs:{"telephone":data.item.telephone},on:{"updatePriority":_vm.ricaricaPaginatedTable}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }