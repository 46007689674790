<template>
  <div class="text-center">
    <input
      type="file"
      id="file"
      @change="uploadFile"
      name="file"
      style="display: none"
    />
    <label v-if="!loading" for="file" class="w-100 btn btn-primary"
      >Carica File</label
    >
    <b-spinner v-else label="Spinning"></b-spinner>
    <b-modal
      v-model="showComplete"
      centered
      size="sm"
      id="success"
      title="Completato"
    >
      <p class="my-4">Upload Completato!</p>
    </b-modal>
    <b-modal v-model="showError" centered size="sm" id="error" title="Errore">
      <p class="my-4">{{ error }}</p>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FileUpload",
  data() {
    return {
      loading: false,
      showError: false,
      error: "",
      showComplete: false,
    };
  },
  props: {
    destination: {
      required: true,
    },
    id: {
      required: true,
    },
  },
  watch: {},
  async mounted() {},
  computed: {},
  methods: {
    async uploadFile(e) {
      //Extract file
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();
      //Create formData
      const formData = new FormData();
      //Append file
      formData.append("file", files[0]);
      //Request config
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      //Start loading
      this.loading = true;
      //Send request
      await axios
        .post(
          `${process.env.VUE_APP_API_HOST}/file/${this.destination}/${this.id}`,
          formData,
          config
        )
        .then((response) => {
          //Stop loading
          this.loading = false;
          //Show complete modal
          // this.showComplete = true;
          console.log(response);
          this.$emit("newUpload");
        })
        .catch((error) => {
          //Stop loading
          this.loading = false;
          //Show error modal
          this.showError = true;
          //Update error modal description
          this.error = error.response.data.errore;
        });
    },
  },
};
</script>
