<template>
  <!-- <div
    style="width: 90%"
    class="ms-auto me-auto d-flex flex-column flex-md-row justify-content-between"
  > -->
  <div style="width: 90%" class="ms-auto me-auto">
    <b-card-group>
      <b-card class="m-2 background-secondary" :title="titoli[0]">
        <b-card-text>
          <slot name="first"></slot>
        </b-card-text>
      </b-card>
      <b-card class="m-2 background-secondary" :title="titoli[1]">
        <b-card-text>
          <slot name="second"></slot>
        </b-card-text>
      </b-card>
      <b-card class="m-2 background-secondary" :title="titoli[2]">
        <b-card-text>
          <slot name="third"></slot>
        </b-card-text>
      </b-card>
    </b-card-group>
  </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "Tris",
  props: ["titoli"],
};
</script>

<style scoped>
.app-logo {
  width: 50%;
  height: auto;
}
</style>
