import ApiConnector from "./ApiConnector";
let connector = new ApiConnector(process.env.VUE_APP_API_HOST);

export const getByCustomerId = (token, customer_id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.LocaleApi();
    let opts = {
      selectOptions: { options: { where: { cliente: customer_id } } }, // SelectOptions | opzioni
    };
    apiInstance.getAllLocales(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const add = (token, locale) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.LocaleApi();
    apiInstance.addLocale(locale, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getAll = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.LocaleApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getAllLocales(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getCount = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.LocaleApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.countLocales(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getPage = (token, page = 1, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.LocaleApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getLocalePage(page, opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getById = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.LocaleApi();
    apiInstance.getLocaleById(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getByIdWithInclude = (token, id, include) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.LocaleApi();

    let opts = {
      selectOptions: {
        options: {
          where: {
            id: id,
          },
          include: include,
        },
      },
    };
    apiInstance.getAllLocales(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body[0]);
      }
    });
  });
};

export const update = (token, id, locale) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.LocaleApi();
    apiInstance.updateLocale(id, locale, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};
