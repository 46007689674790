<template>
  <div
    :class="
      'mt-3 circle col  d-flex flex-column justify-content-center p-2 shadow ' +
      activeClass
    "
    :style="cssProps"
    @click="emitClick"
  >
    <span class="top d-flex flex-column justify-content-center">
      {{ count }}
    </span>
    <div class="bottom d-flex flex-column justify-content-center">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CircleBadge",
  components: {},
  data() {
    return {};
  },
  props: [
    "count",
    "description",
    "backgroundColor",
    "textColor",
    "shadowActiveColor",
    "shadowHoverColor",
    "active",
  ],
  computed: {
    activeClass() {
      return this.active ? "active" : "";
    },
    cssProps() {
      return {
        "--shadow-active-color": this.shadowActiveColor,
        "--shadow-hover-color": this.shadowHoverColor,
        "--background-color": this.backgroundColor,
        "--text-color": this.textColor,
      };
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
};
</script>

<style scoped>
.circle {
  background-color: var(--background-color);
  color: var(--text-color);

  /* border: 1px solid black; */
  border-radius: 100%;
  /* width: 10vw; */
  min-width: 70px;
  max-width: 150px;

  aspect-ratio: 1/1;
  font-weight: bold;
  cursor: pointer;
}

.circle:hover {
  box-shadow: 0 0.8rem 2rem var(--shadow-hover-color) !important;
}

.circle.active {
  box-shadow: 0 0.8rem 2rem var(--shadow-active-color) !important;
}

.circle .top {
  font-size: 3em;
  height: 40%;

  /* border: 1px solid black; */
}

.circle .bottom {
  font-size: 0.85em;
  height: 40%;
  /* border: 1px solid black; */
}

@media screen and (max-width: 768px) {
  .circle .top {
    font-size: 1.5em;
    /* font-size: 400%; */
    /* border: 1px solid black; */
  }

  .circle .bottom {
    font-size: 0.6em;
    /* font-size: 400%; */
    /* border: 1px solid black; */
  }
}
</style>
