<template>
  <div>
    <b-button
      v-if="proposta"
      size="sm"
      variant="danger"
      :href="pdfEndpointProposta + id"
      target="_blank"
      >&nbsp;Proposta&nbsp;</b-button
    >
    <b-button
      v-else
      size="sm"
      variant="success"
      :href="pdfEndpointCompleto + id"
      target="_blank"
      >Completo</b-button
    >
  </div>
</template>

<script>
import * as rinnoviAnnualiRepo from "../../services/RinnoviAnnuali";

export default {
  name: "BottonePDF",
  components: {},
  data() {
    return {
      articoli_rinnovo: {},
    };
  },
  props: ["id"],
  async created() {
    await this.loadData();
  },
  watch: {
    id: function (newval, oldval) {
      this.loadData();
    },
  },
  computed: {
    pdfEndpointProposta() {
      return process.env.VUE_APP_API_HOST + "/pdf/rinnovo/proposta/";
    },
    pdfEndpointCompleto() {
      return process.env.VUE_APP_API_HOST + "/pdf/rinnovo/";
    },
    proposta() {
      let proposta = false;
      for (const index in this.articoli_rinnovo) {
        if (this.articoli_rinnovo[index].proponi) {
          proposta = true;
          break;
        }
      }
      return proposta;
    },
  },
  methods: {
    async loadData() {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      const include = ["articolo_rinnovos"];
      const response = await rinnoviAnnualiRepo.getByIdWithInclude(
        accessToken,
        this.id,
        include
      );
      this.articoli_rinnovo = response.articolo_rinnovos;
      this.$loading(false);
    },
  },
};
</script>
