<template>
  <div>
    <b-button v-b-modal="'serialActivation' + ordine" class="btn btn-light m-2">
      <font-awesome-icon
        icon="book-open"
        :style="icon_style"
        class="fa-lg pointer"
      >
      </font-awesome-icon>
    </b-button>

    <b-modal
      :id="'serialActivation' + ordine"
      :title="'Matricole/Attivazioni Ordine n° ' + ordine"
      size="lg"
      hide-footer
      centered
    >
      <!-- MATRICOLE E ATTIVAZIONI -->
      <div
        class="d-flex flex-column flex-md-row mb-2"
        v-for="item of matricoleAttivazioni"
        :key="item.articolo_ordine + item.prg"
      >
        <!-- nome articolo -->
        <b-form-input
          readonly
          :value="tagliaNome(item.nome_articolo)"
          class="w-100"
        ></b-form-input>
        <!-- attivazione -->
        <b-form-input
          :readonly="!editable || orderData.stato == 7"
          type="date"
          class="flex-shrink-1"
          v-model="item.attivazione"
          v-on:change="() => inserisciAttivazione(item)"
        ></b-form-input>
        <!-- matricola -->
        <b-form-input
          :readonly="!editable || orderData.stato == 7"
          type="text"
          class="flex-shrink-1"
          v-model="item.matricola"
          v-on:change="() => inserisciMatricola(item)"
        ></b-form-input>
      </div>
      <!-- FINE MATRICOLE E ATTIVAZIONI -->
      <!-- MATRICOLE -->
      <div
        class="d-flex flex-column flex-md-row mb-2"
        v-for="item of soloMatricole"
        :key="item.articolo_ordine + item.prg"
      >
        <!-- nome articolo -->
        <b-form-input
          readonly
          :value="tagliaNome(item.nome_articolo)"
          class="w-100"
        ></b-form-input>
        <!-- attivazione (readonly) -->
        <b-form-input type="date" class="flex-shrink-1" readonly></b-form-input>
        <!-- matricola -->
        <b-form-input
          :readonly="!editable || orderData.stato == 7"
          type="text"
          class="flex-shrink-1"
          v-model="item.matricola"
          v-on:change="() => inserisciMatricola(item)"
        ></b-form-input>
      </div>
      <!-- FINE MATRICOLE -->
      <!-- ATTIVAZIONI -->
      <div
        class="d-flex flex-column flex-md-row mb-2"
        v-for="item of soloAttivazioni"
        :key="item.articolo_ordine + item.prg"
      >
        <!-- nome articolo -->
        <b-form-input
          readonly
          :value="tagliaNome(item.nome_articolo)"
          class="w-100"
        ></b-form-input>
        <!-- attivazione -->
        <b-form-input
          :readonly="!editable || orderData.stato == 7"
          type="date"
          class="flex-shrink-1"
          v-model="item.attivazione"
          v-on:change="() => inserisciAttivazione(item)"
        ></b-form-input>
        <!-- matricola (readonly) -->
        <b-form-input type="text" class="flex-shrink-1" readonly></b-form-input>
      </div>
      <!-- FINE ATTIVAZIONI -->
    </b-modal>
  </div>
</template>

<script>
import * as ordineRepo from "../../services/Ordine";

export default {
  name: "MatricoleAttivazioni",
  data() {
    return {
      loading: false,
      serialActivation: [],
      orderData: {},
    };
  },
  components: {},
  props: {
    ordine: {
      type: Number,
    },
    editable: {
      type: Boolean,
    },
  },
  watch: {},
  async mounted() {
    this.getSerialActivation();
    this.getOrder();
  },
  computed: {
    empty() {
      return this.serialActivation.length == 0;
    },
    icon_style() {
      let icon = {
        color: "#0EDD3A",
      };
      //Controllo inserimento
      for (let item of this.serialActivation) {
        if (item.matricola_richiesta && this.isNullOrEmpty(item.matricola)) {
          icon.color = "#FFCD56";
        }
        if (
          item.attivazione_richiesta &&
          this.isNullOrEmpty(item.attivazione)
        ) {
          icon.color = "#FFCD56";
        }
      }
      //Controllo vuoto
      if (this.empty) icon.color = "#E5E6E7";
      return icon;
    },
    soloMatricole() {
      return this.serialActivation.filter(
        (item) =>
          item.matricola_richiesta == 1 && item.attivazione_richiesta == 0
      );
    },
    soloAttivazioni() {
      return this.serialActivation.filter(
        (item) =>
          item.matricola_richiesta == 0 && item.attivazione_richiesta == 1
      );
    },
    matricoleAttivazioni() {
      return this.serialActivation.filter(
        (item) =>
          item.matricola_richiesta == 1 && item.attivazione_richiesta == 1
      );
    },
  },
  methods: {
    isNullOrEmpty(arg) {
      return arg == null || arg == "";
    },
    async getOrder() {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();
      this.orderData = await ordineRepo.getById(accessToken, this.ordine);
      // this.orderData.stato = 1;
    },
    async getSerialActivation() {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();
      this.serialActivation = await ordineRepo.getSerialActivation(
        accessToken,
        this.ordine
      );
    },
    tagliaNome(nome) {
      return nome.length > 35 ? nome.substring(0, 35 - 3) + "..." : nome;
    },
    async inserisciAttivazione(attivazione) {
      const data = {
        articolo_ordine: attivazione.articolo_ordine,
        prg: attivazione.prg,
        attivazione: attivazione.attivazione,
      };
      const accessToken = await this.$auth.getTokenSilently();

      if (attivazione.attivazione_esistente) {
        this.loading = true;
        try {
          const response = await ordineRepo.editActivation(accessToken, data);
          this.loading = false;
          this.$toast.open("Attivazione modificata");
        } catch (error) {
          this.loading = false;
          console.log(error.response);

          this.$toast.error(
            "Errore modifica attivazione: \n" +
              error.response.body.error.details[0].message
          );
        }
      } else {
        this.loading = true;
        try {
          const response = await ordineRepo.addActivation(accessToken, data);
          console.log(response);
          this.$toast.open("Attivazione inserita");
          this.loading = false;
          this.getSerialActivation();
        } catch (error) {
          this.loading = false;
          console.log(error.response.error);

          console.log(error.response.body.error);

          let message = error.response.body.error;
          if (typeof message != "string" && error.response.body.error.details) {
            message = error.response.body.error.details[0].message;
          }

          this.$toast.error("Errore inserimento attivazione: \n" + message);
        }
      }
    },
    async inserisciMatricola(matricola) {
      const data = {
        articolo_ordine: matricola.articolo_ordine,
        prg: matricola.prg,
        matricola: matricola.matricola,
      };
      const accessToken = await this.$auth.getTokenSilently();

      if (matricola.matricola_esistente) {
        console.log("MODIFICA MATRICOLA");
        this.loading = true;
        try {
          const response = await ordineRepo.editSerial(accessToken, data);
          this.loading = false;
          this.$toast.open("Matricola modificata");
        } catch (error) {
          this.loading = false;
          this.$toast.error("Errore modifica matricola");
        }
      } else {
        console.log("CREA MATRICOLA");
        this.loading = true;
        try {
          const response = await ordineRepo.addSerial(accessToken, data);
          this.loading = false;
          this.$toast.open("Matricola inserita");
          this.getSerialActivation();
        } catch (error) {
          this.loading = false;
          this.$toast.error("Errore inserimento matricola");
        }
      }
    },
  },
};
</script>
