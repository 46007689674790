<template>
  <b-card bg-variant="light" header="Pagamento" class="text-start">
    <!-- TOTALI ORDINE -->
    <div class="d-flex flex-column flex-md-row justify-content-between">
      <b-input-group prepend="Listino" append="€" class="m-1">
        <b-form-input
          :value="listino"
          style="text-align: right"
          readonly
        ></b-form-input>
      </b-input-group>
      <span></span>
      <b-input-group prepend="Sconto" append="%" class="m-1">
        <b-form-input
          :value="form.cart.sconto"
          style="text-align: right"
          readonly
        ></b-form-input>
      </b-input-group>
      <span></span>
      <b-input-group prepend="Totale" append="€" class="m-1">
        <b-form-input
          :value="totale"
          style="text-align: right"
          readonly
        ></b-form-input>
      </b-input-group>
    </div>
    <!-- FINE TOTALI ORDINE -->
    <!-- FATTURAZIONE -->
    <div class="m-1 mt-4">
      <h5>Fatturazione</h5>
      <select
        v-model="form.payment.billing"
        class="form-control mb-2"
        :disabled="!canUpdatePagamento || !editable"
      >
        <option v-if="!form.cart.canoni" value="1">Singola fatturazione</option>
        <option v-if="form.cart.canoni" value="1">Mensile</option>
        <option v-if="form.cart.canoni" value="2">Trimestrale</option>
        <option v-if="form.cart.canoni" value="3">Semestrale</option>
      </select>
      <b-input-group class="mb-2">
        <b-input-group-prepend is-text style="width: 10%">
          <b-form-checkbox
            v-model="form.payment.alternativeBilling.active"
            type="checkbox"
            aria-label="Checkbox for following text input"
            :disabled="!canUpdatePagamento || !editable"
          >
            <span class="ms-1">Fatt. alternativa</span>
          </b-form-checkbox>
        </b-input-group-prepend>
        <select
          v-model="form.payment.alternativeBilling.id"
          class="form-control"
          v-if="!form.payment.alternativeBilling.active"
          disabled
        >
          <option v-if="!form.payment.alternativeBilling.active" :value="null">
            ---
          </option>
        </select>
        <v-select
          v-else
          style="width: 90%"
          class="h-100"
          id="fatt_alt"
          :options="clienti"
          :reduce="(item) => item.id"
          v-model="form.payment.alternativeBilling.id"
          label="ragsoc"
          :disabled="!canUpdatePagamento || !editable"
        ></v-select>
      </b-input-group>
      <b-input-group class="mb-2">
        <b-input-group-prepend is-text>
          <b-form-checkbox
            type="checkbox"
            v-model="form.payment.grenke"
            @change="(value) => setGrenke(value)"
            :disabled="!canUpdatePagamento || !editable"
          ></b-form-checkbox>
        </b-input-group-prepend>
        <b-form-input readonly value="GRENKE"></b-form-input>
      </b-input-group>
    </div>
    <!-- FINE FATTURAZIONE -->

    <!-- ACCONTO -->
    <div class="m-1 mt-4">
      <h5>Acconto</h5>
      <div class="d-flex flex-column flex-md-row">
        <b-input-group
          id="input-group-1"
          label="Acconto:"
          label-for="input-1"
          class="mb-2"
          append="€"
        >
          <b-input-group-prepend is-text>
            <b-form-checkbox
              type="checkbox"
              v-model="form.payment.acconto.active"
              :disabled="!canUpdatePagamento || !editable"
              @input="
                () => {
                  if (!form.payment.acconto.active) resetDownPayment();
                }
              "
            ></b-form-checkbox>
          </b-input-group-prepend>
          <select
            class="form-control"
            :disabled="
              !form.payment.acconto.active || !canUpdatePagamento || !editable
            "
            v-model="form.payment.acconto.pagamento"
          >
            <option :value="null">Metodo di pagamento acconto</option>
            <option
              v-for="pagamento in metodiDiPagamento"
              :key="pagamento.cod_pag_NEW"
              :value="pagamento.cod_pag_NEW"
            >
              {{ pagamento.des_pag }}
            </option>
          </select>
          <b-form-input
            id="input-1"
            type="number"
            step="0.01"
            min="0"
            v-model="form.payment.acconto.totale"
            placeholder="Acconto"
            class="text-end"
            :readonly="
              !form.payment.acconto.active || !canUpdatePagamento || !editable
            "
          ></b-form-input>
        </b-input-group>
      </div>
    </div>
    <!-- FINE ACCONTO -->
    <!-- PAGAMENTO -->
    <div class="m-1 mt-4">
      <h5>Pagamento</h5>
      <select
        class="form-control"
        v-model="form.payment.type"
        :disabled="!canUpdatePagamento || !editable"
      >
        <option :value="null">Metodo di pagamento</option>
        <option
          v-for="pagamento in metodiDiPagamento"
          :key="pagamento.cod_pag_NEW"
          :value="pagamento.cod_pag_NEW"
        >
          {{ pagamento.des_pag }}
        </option>
      </select>
      <b-table striped hover :items="ratePerTabella"></b-table>
      <b-form-group
        id="input-group-2"
        label="Note Pagamento:"
        label-for="input-2"
      >
        <b-form-textarea
          id="input-2"
          v-model="form.payment.payment_note"
          placeholder="Note Pagamento"
          :readonly="!canUpdatePagamento || !editable"
        ></b-form-textarea>
      </b-form-group>
    </div>
    <!-- FINE PAGAMENTO -->
    <!-- IBAN -->
    <div class="m-1 mt-4">
      <h5>IBAN</h5>
      <p>
        <i>
          Le modifiche all'iban vengono salvate immediatamente, modificare con
          cautela!
        </i>
      </p>
      <div class="row">
        <div class="col-md-4">
          <b-form-group
            id="input-group-7"
            label="Cliente"
            label-for="iban-cliente"
          >
            <b-form-input
              id="iban-cliente"
              placeholder="IBAN Cliente"
              v-model="ibanCliente"
              :class="classiInputIbanCliente"
              :readonly="!canUpdatePagamento || !clienteSelezionato"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            id="input-group-7"
            label="Locale"
            label-for="iban-locale"
          >
            <b-form-input
              id="iban-locale"
              placeholder="IBAN Locale"
              v-model="ibanLocale"
              :class="classiInputIbanLocale"
              :readonly="!canUpdatePagamento || !localeSelezionato"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <b-form-group
            id="input-group-7"
            label="Fatturazione alternativa"
            label-for="iban-fatt-alt"
          >
            <b-form-input
              id="iban-fatt-alt"
              placeholder="IBAN Fatturazione alternativa"
              v-model="ibanFattAlt"
              :class="classiInputIbanFattAlt"
              :readonly="!canUpdatePagamento || !fattAltSelezionato"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
    </div>
    <!-- FINE IBAN -->
  </b-card>
</template>

<script>
import * as clienteRepo from "../../services/Cliente";
import * as localeRepo from "../../services/Locale";

export default {
  props: { form: Object, editable: Boolean },
  name: "OrderFormPayment",
  data() {
    return {
      rate: [],
      iban: {
        cliente: null,
        locale: null,
        fattAlt: null,
      },
    };
  },
  watch: {
    billingCustomerForWatching(newValue) {
      this.form.payment.grenke = this.form.payment.grenke
        ? true
        : newValue == 34528;
    },
  },
  form: {
    handler(val) {
      this.$emit("update", val);
    },
    deep: true,
  },
  methods: {
    resetDownPayment() {
      this.form.payment.acconto.pagamento = null;
      this.form.payment.acconto.totale = 0;
    },
    async modificaIbanCliente(cliente, iban) {
      try {
        const accessToken = await this.$auth.getTokenSilently();
        await clienteRepo.update(accessToken, cliente, { iban: iban });
        this.$toast.open("Iban cliente modificato correttamente");
      } catch (e) {
        this.$toast.error("Errore modifica iban cliente.");
        console.error(e);
      }
    },
    async modificaIbanLocale(locale, iban) {
      try {
        const accessToken = await this.$auth.getTokenSilently();
        await localeRepo.update(accessToken, locale, { iban: iban });
        this.$toast.open("Iban locale modificato correttamente");
      } catch (e) {
        this.$toast.error("Errore modifica iban locale.");
        console.error(e);
      }
    },
    //Seleziona fatturazione alternativa grenke quando viene selezionato flag grenke
    setGrenke(isGrenke) {
      if (isGrenke) {
        this.form.payment.alternativeBilling.active = true;
        this.form.payment.alternativeBilling.id = 34528;
      }
    },
  },
  computed: {
    canUpdatePagamento() {
      if (!this.$route.params.id) return true;
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:ordine:pagamento"
          ) != -1
        );
      }
      return false;
    },
    cliente() {
      return this.$store.state.clienti.find((value) => {
        return value.id == this.fattAltSelezionato;
      });
    },
    cliente_search() {
      return this.$store.state.clienti_search[this.fattAltSelezionato];
    },
    listino() {
      let listino = 0;
      for (let item of this.form.cart.items) {
        listino +=
          item.quantita * item.prezzo -
          (item.sconto / 100) * item.prezzo * item.quantita;
      }
      return listino;
    },
    totale() {
      return this.listino - (this.form.cart.sconto / 100) * this.listino;
    },
    totaleRateizzazione() {
      return 1.22 * this.totale - this.form.payment.acconto.totale;
    },
    billingCustomerForWatching() {
      return this.form.payment.alternativeBilling.id;
    },
    ratePerTabella() {
      let metodo = this.metodiDiPagamentoSearch[this.form.payment.type];
      let rate = [];
      if (metodo && metodo.num_rate > 0) {
        let perc = 100.0 / metodo.num_rate;
        for (let i = 0; i < metodo.num_rate; i++) {
          rate.push({
            "Percentuale Rata": perc + "%",
            "Totale Rata": euroFormatter.format(
              (1 / metodo.num_rate) * this.totaleRateizzazione
            ),
          });
        }
      }
      return rate;
    },
    metodiDiPagamentoSearch() {
      return this.$store.state.metodi_di_pagamento_search;
    },
    metodiDiPagamento() {
      return this.$store.state.metodi_di_pagamento;
    },
    clienti() {
      return this.$store.state.clienti;
    },
    clienteSelezionato() {
      return this.form.customer.selected && this.form.customer.id
        ? this.form.customer.id
        : false;
    },
    localeSelezionato() {
      return this.form.location.selected && this.form.location.id
        ? this.form.location.id
        : false;
    },
    fattAltSelezionato() {
      return (
        this.form.payment.alternativeBilling.active &&
        (this.form.payment.alternativeBilling.id
          ? this.form.payment.alternativeBilling.id
          : false)
      );
    },
    ibanCliente: {
      get() {
        if (this.clienteSelezionato) {
          let customer =
            this.$store.state.clienti_search[this.clienteSelezionato];
          if (customer) return customer.iban;
        }

        return null;
      },
      set: debounce(function (iban) {
        iban = iban ? iban : null;
        //Modifico iban cliente in storage
        this.$store.dispatch("modificaIbanCliente", {
          cliente: this.clienteSelezionato,
          iban: iban,
        });
        //Modifico iban cliente in db
        this.modificaIbanCliente(this.clienteSelezionato, iban);
      }, 250),
    },
    ibanLocale: {
      get() {
        if (this.localeSelezionato && this.clienteSelezionato) {
          let location =
            this.$store.state.locali_search[this.clienteSelezionato][
              this.localeSelezionato
            ];

          if (location) return location.iban;
        }

        return null;
      },
      set: debounce(function (iban) {
        iban = iban ? iban : null;
        //Modifico iban locale in storage
        this.$store.dispatch("modificaIbanLocale", {
          locale: this.localeSelezionato,
          iban: iban,
        });
        //Modifico iban locale in db
        this.modificaIbanLocale(this.localeSelezionato, iban);
      }, 250),
    },
    ibanFattAlt: {
      get() {
        if (this.fattAltSelezionato) {
          let customer =
            this.$store.state.clienti_search[this.fattAltSelezionato];
          if (customer) return customer.iban;
        }
        return null;
      },
      set: debounce(function (iban) {
        iban = iban ? iban : null;
        //Modifico iban cliente in storage
        this.$store.dispatch("modificaIbanCliente", {
          cliente: this.fattAltSelezionato,
          iban: iban,
        });
        //Modifico iban fatt alt in db
        this.modificaIbanCliente(this.fattAltSelezionato, iban);
      }, 250),
    },
    usaIbanCliente() {
      return !this.usaIbanLocale && !this.usaIbanFattAlt;
    },
    usaIbanLocale() {
      return (
        !this.usaIbanFattAlt && !!this.localeSelezionato && !!this.ibanLocale
      );
    },
    usaIbanFattAlt() {
      return !!this.fattAltSelezionato;
    },
    //Classi del campo di input iban cliente
    classiInputIbanCliente() {
      let classes = "form-control";
      if (this.usaIbanCliente) {
        if (this.clienteSelezionato && this.ibanCliente) {
          classes += " border border-2 border-success";
        } else {
          classes += " border border-2 border-danger";
        }
      }
      return classes;
    },
    //Classi del campo di input iban locale
    classiInputIbanLocale() {
      let classes = "form-control";
      if (this.usaIbanLocale) {
        if (this.localeSelezionato && this.ibanLocale) {
          classes += " border border-2 border-success";
        } else {
          classes += " border border-2 border-danger";
        }
      }
      return classes;
    },
    //Classi del campo di input iban fatt alt
    classiInputIbanFattAlt() {
      let classes = "form-control";
      if (this.usaIbanFattAlt) {
        if (this.fattAltSelezionato && this.ibanFattAlt) {
          classes += " border border-2 border-success";
        } else {
          classes += " border border-2 border-danger";
        }
      }
      return classes;
    },
  },
};

const euroFormatter = new Intl.NumberFormat("it-IT", {
  style: "currency",
  currency: "EUR",
});

/**
 * Applica effetto debounce ad una funzione
 */
function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}
</script>
