var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"text-align":"right","font-size":"1.3rem","cursor":"pointer"}},[_c('b-button',{on:{"click":_vm.ricaricaPaginatedTable}},[_c('b-icon',{attrs:{"icon":"arrow-clockwise"}}),_vm._v(" Aggiorna ")],1)],1),_c('b-modal',{attrs:{"id":"modale-template","hide-footer":"","hide-header":"","centered":"","size":"lg"},model:{value:(_vm.modaleTemplate.aperto),callback:function ($$v) {_vm.$set(_vm.modaleTemplate, "aperto", $$v)},expression:"modaleTemplate.aperto"}},[_c('div',{staticStyle:{"height":"90vh"}},[_c('iframe',{attrs:{"srcdoc":_vm.modaleTemplate.data,"width":"100%","height":"100%"}})])]),_c('b-modal',{attrs:{"id":"modale-filtri","hide-footer":"","centered":"","size":"md","title":"Filtri campagna"},model:{value:(_vm.modaleFiltri.aperto),callback:function ($$v) {_vm.$set(_vm.modaleFiltri, "aperto", $$v)},expression:"modaleFiltri.aperto"}},[_c('b-table',{attrs:{"stacked":"","items":_vm.modaleFiltri.data,"fields":[
        'filtra_per',
        'filtro',
        { key: 'profili_filtro_campagnas', label: 'Profilo locale' },
      ]},scopedSlots:_vm._u([{key:"cell(profili_filtro_campagnas)",fn:function(data){return [_vm._v(" "+_vm._s(data.value .map((item) => item.profilo_contatto_profilo_contatto.descrizione) .join(", "))+" ")]}}])})],1),_c('b-modal',{attrs:{"id":"modale-statistiche","hide-footer":"","hide-header":"","centered":"","size":"sm"},model:{value:(_vm.modaleStatistiche.aperto),callback:function ($$v) {_vm.$set(_vm.modaleStatistiche, "aperto", $$v)},expression:"modaleStatistiche.aperto"}},[_c('b-table',{attrs:{"items":_vm.modaleStatistiche.data,"fields":['descrizione', 'valore']}})],1),_c('b-modal',{attrs:{"id":"modale-statistiche-realtime","hide-footer":"","hide-header":"","centered":"","size":"xl"},model:{value:(_vm.modaleStatisticheRealtime.aperto),callback:function ($$v) {_vm.$set(_vm.modaleStatisticheRealtime, "aperto", $$v)},expression:"modaleStatisticheRealtime.aperto"}},[_c('TargetView',{attrs:{"target":_vm.modaleStatisticheRealtime.target,"profiloContatto":_vm.modaleStatisticheRealtime.profilo_contatto,"hideExport":true},on:{"export":null}})],1),_c('PaginatedTable',{key:_vm.refreshKey,attrs:{"getPage":_vm.getPage,"getCount":_vm.getCount,"openRow":() => {},"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(data)",fn:function(data){return [_c('span',{style:({
          color: new Date(data.value) > Date.now() ? 'orange' : 'green',
          fontWeight: 'bold',
        })},[_vm._v(_vm._s(new Date(data.value).toLocaleString("it-IT")))])]}},{key:"cell(tipo_campagna_tipo_campagna)",fn:function(data){return [_vm._v(" "+_vm._s(data.value.descrizione)+" ")]}},{key:"cell(destinazione_campagna_destinazione_campagna)",fn:function(data){return [_vm._v(" "+_vm._s(data.value.descrizione)+" ")]}},{key:"cell(filtro_campagnas)",fn:function(data){return [_c('div',{staticStyle:{"font-size":"1.5rem"}},[(data.value.length > 0)?_c('b-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"funnel"},on:{"click":() => _vm.apriModaleFiltri(data.value)}}):_vm._e()],1)]}},{key:"cell(template)",fn:function(data){return [_c('div',{staticStyle:{"font-size":"1.5rem"}},[_c('b-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"braces"},on:{"click":() => _vm.apriModaleTemplate(data.value)}})],1)]}},{key:"cell(statistiche_campagnas)",fn:function(data){return [(
          data.item.tipo_campagna_tipo_campagna.id == 3 &&
          data.item.avviata == false
        )?_c('div',{staticStyle:{"font-size":"1.5rem"}},[_c('b-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"unlock"},on:{"click":() => _vm.apriModaleStatisticheRealtime(data.item)}})],1):_c('div',{staticStyle:{"font-size":"1.5rem"}},[_c('b-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"lock"},on:{"click":() => _vm.apriModaleStatistiche(data.value)}})],1)]}},{key:"cell(elimina)",fn:function(data){return [_c('b-button',{attrs:{"disabled":new Date(data.item.data) <= Date.now(),"variant":"danger"},on:{"click":() => _vm.eliminaCampagna(data.item)}},[_c('b-icon',{staticStyle:{"cursor":"pointer"},attrs:{"icon":"trash"}})],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }