<template>
  <tr
    data-v-tour="OrderRow-1"
    class="collapsable-tr"
    style="
      color: var(--text-color) !important;
      border-bottom-color: var(--secondary-color);
    "
  >
    <td
      style="width: 35%"
      :style="{ 'border-left': '10px solid ' + statusColor + ' !important' }"
      class="collapsable-td"
    >
      <table class="table">
        <tr>
          <td style="width: 23%">
            <b>Ordine:</b>
          </td>
          <td style="width: 23%">{{ ordine.id }}</td>
          <td style="width: 23%" colspan="3"><b>Impegno:</b></td>
          <td style="width: 23%">{{ ordine.impegno_nr }}</td>
        </tr>
        <tr>
          <td><b>Data:</b></td>
          <td>{{ formatDate(ordine.data) }}</td>
          <td colspan="3"><b>Fatturazione:</b></td>
          <td>{{ formatDate(ordine.data_fatturazione) }}</td>
        </tr>
        <tr>
          <td><b>Totale:</b></td>
          <td>
            {{ formatPrice(ordine.totale) }}
          </td>
          <td colspan="3"><b>Fattura:</b></td>
          <td>{{ ordine.invoice_nr }}</td>
        </tr>
        <tr>
          <td><b>Utente:</b></td>
          <td>{{ ordine.utente_utente.name }}</td>
          <td>
            <button
              type="button"
              class="btn btn-light"
              v-b-tooltip.hover
              title="Generazione appalto"
              style="border: 0px"
            >
              <font-awesome-icon
                v-if="ordine.has_rid && ordine.rid_date != null"
                icon="thumbs-up"
                class="fa-lg pointer text-primary"
              >
              </font-awesome-icon>
              <font-awesome-icon
                v-else-if="ordine.has_rid && ordine.rid_date == null"
                icon="exclamation-triangle"
                class="fa-lg pointer text-secondary disabled"
              >
              </font-awesome-icon>
              <font-awesome-icon
                v-else
                icon="exclamation-triangle"
                class="fa-lg pointer text-secondary disabled"
              >
              </font-awesome-icon>
            </button>
          </td>
          <td
            v-b-modal.adminNoteModal
            v-on:click="
              $emit(
                'showNoteAmministrative',
                ordine.note_amministrative,
                ordine.id
              )
            "
          >
            <button
              type="button"
              class="btn btn-light"
              v-b-tooltip.hover
              title="Note cliente"
              style="border: 0px"
            >
              <font-awesome-icon
                icon="info-circle"
                class="fa-lg pointer"
                :class="
                  ordine.note_amministrative != null &&
                  ordine.note_amministrative != ''
                    ? 'text-primary'
                    : 'text-secondary disabled'
                "
              >
              </font-awesome-icon>
            </button>
          </td>
          <td>
            <button
              type="button"
              class="btn btn-light"
              v-b-tooltip.hover
              title="In attesa di pagamento"
              style="border: 0px"
            >
              <font-awesome-icon
                icon="money-bill-alt"
                class="fa-lg pointer"
                :class="
                  ordine.wait_payment
                    ? 'text-primary'
                    : 'text-secondary disabled'
                "
              >
              </font-awesome-icon>
            </button>
          </td>
        </tr>
      </table>
    </td>
    <td style="width: 35%" class="collapsable-td">
      <table class="table">
        <tr>
          <td>
            <router-link
              v-b-tooltip.hover
              title="Apri scheda cliente"
              style="padding: 0px; color: var(--text-color)"
              :to="'/clienti/' + ordine.cliente_cliente.id"
              >{{ ordine.cliente_cliente.ragsoc }}</router-link
            >
          </td>
        </tr>
        <hr />
        <tr>
          <td>
            {{ descrizioneLocale }}
          </td>
        </tr>
        <tr>
          <td>
            {{ indirizzoLocale }}
          </td>
        </tr>
      </table>
    </td>
    <td style="width: 15%" class="collapsable-td">
      <table class="table">
        <tr>
          <td colspan="2" style="width: 50%"><b>Tecnico:</b></td>
          <td style="width: 50%">
            {{ ordine.tecnico_utente ? ordine.tecnico_utente.name : "N.D." }}
          </td>
        </tr>
        <tr>
          <td colspan="2"><b>Consegna:</b></td>
          <td>
            {{ formatDate(ordine.data_consegna) }}
          </td>
        </tr>
        <tr>
          <td
            class="text-center"
            v-b-modal.tecNoteModal
            v-on:click="
              $emit('showNoteTecniche', ordine.note_tecniche, ordine.id)
            "
          >
            <button
              type="button"
              class="btn btn-light"
              v-b-tooltip.hover
              title="Note tecniche"
            >
              <font-awesome-icon
                icon="info-circle"
                class="fa-lg pointer"
                :class="
                  ordine.note_tecniche != null && ordine.note_tecniche != ''
                    ? 'text-primary'
                    : 'text-secondary disabled'
                "
              >
              </font-awesome-icon>
            </button>
          </td>
          <td class="text-center">
            <MatricoleAttivazioni
              :ordine="ordine.id"
              :editable="canUpdateSerial"
              v-b-tooltip.hover
              title="Matricole e Date di attivazione"
            ></MatricoleAttivazioni>
            <!-- <b-button
              v-b-modal.modal-1
              variant="outline-light"
              v-b-tooltip.hover
              title="Matricole e attivazioni"
              v-on:click="$emit('showSerialActivation', ordine.order_id)"
              :class="
                matricoleAttivazioni.length > 0 ? 'text-primary' : ' disabled'
              "
              ><font-awesome-icon icon="pencil-alt" class="fa-lg pointer">
              </font-awesome-icon
            ></b-button> -->
          </td>
          <td>
            <button
              type="button"
              class="btn btn-light"
              v-b-tooltip.hover
              title="Import in trace"
            >
              <font-awesome-icon
                icon="exchange-alt"
                class="fa-lg pointer"
                :class="
                  ordine.is_closed ? 'text-primary' : 'text-secondary disabled'
                "
              >
              </font-awesome-icon>
            </button>
          </td>
        </tr>
      </table>
    </td>
    <td style="width: 15%" class="collapsable-td">
      <table class="text-center">
        <tr>
          <td colspan="4">
            <SelectStato
              :ordine="ordine.id"
              :editable="canUpdateStatus"
              v-on:edit-status="editStatus"
              v-b-tooltip.hover
              title="Stato"
            ></SelectStato>
          </td>
        </tr>
        <tr>
          <td>
            <button
              type="button"
              :id="'popover-' + ordine.id"
              class="btn btn-light m-2"
              v-on:click="sendMail"
            >
              <font-awesome-icon
                :icon="
                  ordine.mail_inviates.length > 0
                    ? 'fa-solid fa-paper-plane'
                    : 'fa-regular fa-paper-plane'
                "
                class="fa-lg pointer"
                :style="{
                  color: getRinnovoMailStatusColor(ordine.mail_inviates),
                }"
              >
              </font-awesome-icon>
            </button>

            <b-popover
              v-if="ordine.mail_inviates.length > 0"
              style="max-height: 80vh !important; overflow: scroll"
              :target="'popover-' + ordine.id"
              triggers="hover focus"
              placement="left"
            >
              <b-list-group>
                <b-list-group-item
                  style="
                    border: 1px solid black;
                    margin-bottom: 10px;
                    padding: 0;
                    max-width: 50vw !important;
                  "
                  v-for="(mail, index) in ordine.mail_inviates"
                  :key="index"
                >
                  <b-table-simple tfoot-class="d-none">
                    <b-thead style="background-color: #f0f1f1; padding: 0">
                      <b-tr>
                        <b-th>Data</b-th>
                        <b-td>{{ getLocaleDateTime(mail.date, "it-IT") }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th>Utente</b-th>
                        <b-td>{{ mail.user.nickname }}</b-td>
                      </b-tr>
                    </b-thead>

                    <b-tbody
                      v-if="
                        !mail.tsmtp_reports || mail.tsmtp_reports.length == 0
                      "
                    >
                      <b-tr>
                        <b-th colspan="2">
                          Le informazioni sull'invio saranno disponibili a
                          breve,
                          <b-button
                            size="sm"
                            pill
                            variant="info"
                            @click="refresh"
                            >clicca qui</b-button
                          >
                          per aggiornare.
                        </b-th>
                      </b-tr>
                    </b-tbody>
                    <b-tbody
                      v-for="(report, index) in mail.tsmtp_reports"
                      :key="index"
                    >
                      <b-tr>
                        <b-th>Oggetto</b-th>
                        <b-td>{{ report.subject }}</b-td>
                      </b-tr>

                      <b-tr>
                        <b-th>Destinatario</b-th>
                        <b-td>{{ report.recipient }}</b-td>
                      </b-tr>

                      <b-tr :style="{ color: getStatusColor(report.status) }">
                        <b-th>Stato</b-th>
                        <b-td style="font-weight: bold">{{
                          statusInfo[report.status] &&
                          statusInfo[report.status].description
                        }}</b-td>
                      </b-tr>

                      <b-tr
                        v-if="report.error"
                        :style="{ color: getStatusColor(report.status) }"
                      >
                        <b-th>Errore</b-th>
                        <b-td>{{ report.error }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-list-group-item>
              </b-list-group>
            </b-popover>
          </td>
          <td>
            <a
              class="btn btn-light m-2"
              :href="pdfEndpoint + ordine.id"
              target="_blank"
              v-b-tooltip.hover
              title="PDF Ordine"
            >
              <font-awesome-icon
                icon="file-pdf"
                class="fa-lg pointer text-danger"
              >
              </font-awesome-icon>
            </a>
          </td>
          <td>
            <Allegati :folder="ordine.id" destination="ordine"></Allegati>
          </td>
          <td>
            <b-button
              type="button"
              class="btn btn-light m-2"
              v-b-tooltip.hover
              title="Modifica"
              :to="orderPath"
              :disabled="!canUpdateOrdine"
            >
              <font-awesome-icon icon="edit" class="fa-lg pointer">
              </font-awesome-icon>
            </b-button>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</template>

<script>
import axios from "axios";
import Allegati from "@/components/ordini/Allegati.vue";
import MatricoleAttivazioni from "@/components/ordini/MatricoleAttivazioni.vue";
import SelectStato from "@/components/ordini/SelectStato.vue";

export default {
  name: "OrderRow",
  components: { Allegati, MatricoleAttivazioni, SelectStato },
  props: {
    ordine: Object,
    users: Array,
    status: Array,
  },
  data() {
    return {
      matricoleAttivazioni: [],
      //   serialActivationStatus: [],
      //   count: 0,
      //   clienti: [],
      //   locali: [],
      //   loadedAllCustomers: false,
      //   allCustomers: {},
      //   modaleMatricole: {
      //     order_id: 0,
      //   },
      //   modaleNotePrivate: {
      //     customer: "",
      //     nota: "",
      //     order: 0,
      //   },
      //   modaleNoteTecniche: {
      //     customer: "",
      //     nota: "",
      //     order: 0,
      //   },
      //   modaleInfo: {
      //     customer: "",
      //     scaduti: [],
      //     in_scadenza: [],
      //   },
      //   modaleMail: {
      //     customer: "",
      //     tipologia: "totale",
      //     form: {
      //       cc: "",
      //       ccn: "",
      //       contatti: "",
      //       text: "Gentile Cliente, \ndi seguito sono indicate le partite scadute per le quali vi preghiamo di provvedere al saldo.\nPer i pagamenti a mezzo bonifico le coordinate sono le seguenti:\nIBAN IT72I0503433120000000014912\npresso BANCO BPM S.p.A. - Ag. Gaggiano (MI)\nCordiali saluti",
      //     },
      //   },
      //   htmlGenerato: {
      //     customer_id: "",
      //     htmlScaduti: "",
      //     htmlInScadenza: "",
      //   },
    };
  },
  async created() {
    //Ordino le mail inviate per data
    this.ordine.mail_inviates = this.ordine.mail_inviates.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    });
    // //OTTENGO TOKEN DI AUTENTICAZIONE
    // const accessToken = await this.$auth.getTokenSilently();
    // const config = {
    //   headers: { Authorization: `Bearer ${accessToken}` },
    // };
    // //COSTRUISCO LA RICHIESTA PER LO STATO DELLE MATRICOLE E ATTIVAZIONI
    // let serialActivationStatusRequest = await axios.get(
    //   `${process.env.VUE_APP_API_HOST}/order/${this.ordine.order_id}/serialActivation`,
    //   config
    // );
    // this.matricoleAttivazioni = serialActivationStatusRequest.data;
  },
  watch: {
    ordine: {
      handler(val) {
        // console.log(this.ordine);
      },
      deep: true,
    },
  },
  methods: {
    getStatusColor(status) {
      const info = this.statusInfo[status];
      if (info) return info.color;
      return "black";
    },
    getRinnovoMailStatusColor(mail_inviate) {
      if (mail_inviate.length === 0) return "black";
      let hasError = false;
      //estraggo la mail con la data più recente
      const last_mail = mail_inviate.reduce((prev, current) => {
        return prev.date > current.date ? prev : current;
      });

      let hasReport =
        last_mail.tsmtp_reports && last_mail.tsmtp_reports.length > 0;
      //controllo i report dell'ultima mail se ci sono degli errori
      if (last_mail.tsmtp_reports && last_mail.tsmtp_reports.length > 0) {
        for (const report of last_mail.tsmtp_reports) {
          const info = this.statusInfo[report.status];
          if (info) {
            if (!info.isPositive) {
              hasError = true;
              break;
            }
          }
        }
      }
      return !hasReport ? "#31D2F2" : hasError ? "red" : "green";
    },
    getLocaleDateTime(date, locale = "it-IT") {
      const data = new Date(date);
      data.setHours(data.getHours() - 2);
      const giorno = data.toLocaleDateString(locale);
      const ora = data.toLocaleTimeString(locale);
      return `${giorno} ${ora}`;
    },
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + " €"
      );
    },
    formatDate(date) {
      if (date == null) return "";
      let splitted = date.substring(0, 10).split("-");
      return splitted[2] + "/" + splitted[1] + "/" + splitted[0];
    },
    refresh() {
      this.$emit("refresh");
    },
    editStatus(ordine, nuovoStato) {
      this.$emit("edit-status", ordine, nuovoStato);
    },
    sendMail() {
      this.$router.push({ name: "mailOrdine", params: { id: this.ordine.id } });
    },
  },
  computed: {
    statusInfo() {
      return {
        SUCCESS: {
          color: "green",
          isPositive: true,
          description: "La mail è stata inviata con successo",
        },
        OPEN: {
          color: "green",
          isPositive: true,
          description: "La mail è stata aperta",
        },
        CLICK: {
          color: "green",
          isPositive: true,
          description: "Link cliccato",
        },
        NEW: {
          color: "orange",
          isPositive: true,
          description: "La mail è stata messa in coda",
        },
        DEFER: {
          color: "orange",
          isPositive: false,
          description: "La mail è stata rimandata",
        },
        ERROR: {
          color: "red",
          isPositive: false,
          description: "La mail ha generato un errore",
        },
        UNSUB: {
          color: "red",
          isPositive: false,
          description: "Il destinatario si è disiscritto",
        },
        REPORT: {
          color: "red",
          isPositive: false,
          description: "La mail è stata segnalata come spam",
        },
        FAIL: {
          color: "red",
          isPositive: false,
          description: "La mail ha generato un errore",
        },
        FAIL: {
          color: "red",
          isPositive: false,
          description: "La mail ha generato un errore",
        },
      };
    },
    canUpdateSerial() {
      return (
        this.$auth.user["https://rca-api/permissions"].indexOf(
          "update:ordine:serialactivation"
        ) != -1
      );
    },
    canUpdateOrdine() {
      return (
        this.$auth.user["https://rca-api/permissions"].indexOf(
          "update:ordine"
        ) != -1
      );
    },
    canUpdateStatus() {
      return (
        this.$auth.user["https://rca-api/permissions"].indexOf(
          "update:order_status"
        ) != -1
      );
    },
    pdfEndpoint() {
      return process.env.VUE_APP_API_HOST + "/pdf/ordine/";
    },
    orderPath() {
      return "ordini/" + this.ordine.id + "/cliente";
    },
    statusColor() {
      if (this.ordine.stato == 1 && this.ordine.cancellato) return "black";
      for (var key in this.status)
        if (this.status[key]["id"] == this.ordine.stato)
          return this.status[key]["color"];
      return "#ff0000";
    },
    descrizioneLocale() {
      if (!this.ordine.locale_locale) return "";
      return this.ordine.locale_locale.loc_desc;
    },
    indirizzoLocale() {
      if (!this.ordine.locale_locale) return "";
      return (
        this.ordine.locale_locale.via + " " + this.ordine.locale_locale.citta
      );
    },
  },
};
</script>

<style scoped>
/* schermi sotto i 767px */
@media all and (max-width: 767px) {
  .collapsable-table,
  .collapsable-thead,
  .collapsable-tbody,
  .collapsable-th,
  .collapsable-td,
  .collapsable-tr {
    display: block;
    width: 100% !important;
  }
}

/* schermi sopra i 767px */
@media all and (min-width: 767px) {
  /* bordo riga arrotondato */
  .collapsable-tr > .collapsable-td:first-of-type {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  .collapsable-tr > .collapsable-td:last-of-type {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }
}

/* override default css bootstrap property that leaves black lines in rows */
.table > :not(:first-child) {
  border: 0px !important;
}

.table {
  color: var(--text-color) !important;
}

.disabled {
  opacity: 0.1;
}

.table {
  margin: 0px;
}

.spaceUnder {
  padding-bottom: 100px;
}
</style>
