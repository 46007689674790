import axios from "axios";

export const getAll = async (accessToken, options) => {
  console.log("QUI");
  const encodedOptions = encodeURIComponent(
    JSON.stringify({ options: options })
  );
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: encodedOptions,
    },
  };
  const response = await axios.get(
    `${process.env.VUE_APP_API_HOST}/categoriaLocale`,
    config
  );
  //Send request
  return response.data;
};
