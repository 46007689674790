<template>
  <div>
    <b-spinner v-if="!loading_complete"></b-spinner>
    <b-form v-else @submit="onSubmit" :style="{ '--secondary-color': 'white' }">
      <b-form-group
        id="input-cliente"
        label="Cliente:"
        label-for="cliente"
        class="mb-2"
      >
        <v-select
          :disabled="id != null"
          id="cliente"
          class="background-secondary"
          :options="clienti"
          :reduce="(item) => item.id"
          v-model="form.cliente"
          label="ragsoc"
          @input="
            () => {
              form.locale = null;
            }
          "
        ></v-select>
      </b-form-group>
      <b-form-group
        id="input-cliente"
        label="Locale:"
        label-for="cliente"
        class="mb-2"
      >
        <v-select
          :disabled="id != null"
          id="locale"
          class="background-secondary"
          :options="locali_cliente"
          :reduce="(item) => item.id"
          v-model="form.locale"
          label="nome"
        ></v-select>
      </b-form-group>
      <b-form-group
        id="input-group-2"
        label="Referente:"
        label-for="input-2"
        class="mb-2"
      >
        <b-form-input
          id="input-2"
          v-model="form.reference"
          placeholder="Referente"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-3"
        label="Profilo:"
        label-for="input-3"
        class="mb-2"
      >
        <select
          id="input-3"
          v-model="form.profilo_contatto"
          class="form-control"
        >
          <option
            v-for="profilo in profili"
            :key="profilo.id"
            :value="profilo.id"
          >
            {{ profilo.descrizione }}
          </option>
        </select>
      </b-form-group>

      <b-form-group
        id="input-group-4"
        label="Mail:"
        label-for="input-4"
        class="mb-2"
      >
        <b-form-input
          id="input-4"
          v-model="form.mail"
          placeholder="Mail"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-5"
        label="Telefono:"
        label-for="input-5"
        class="mb-2"
      >
        <TelephoneInput id="input-5" v-model="form.telephone"></TelephoneInput>
      </b-form-group>

      <div class="d-flex flex-row justify-content-between">
        <b-button
          v-if="id"
          @click="() => eliminaContatto(id)"
          variant="danger"
          class="mt-2"
          >Elimina</b-button
        >
        <b-button type="submit" variant="primary" class="mt-2">
          Conferma
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import TelephoneInput from "./TelephoneInput.vue";
import axios from "axios";

import * as contattiRepo from "../../services/Contatto";

export default {
  name: "ContactCreationForm",
  components: {
    TelephoneInput,
  },
  props: [
    "id",
    "cliente",
    "locale",
    "reference",
    "profiloContatto",
    "mail",
    "telephone",
  ],
  async created() {
    this.$loading(true);
    const accessToken = await this.$auth.getTokenSilently();
    await this.caricaProfili();
    await this.$store.dispatch("caricaClienti", accessToken);
    await this.$store.dispatch("caricaLocali", accessToken);
    this.$loading(false);
    this.loading_complete = true;
  },
  data() {
    return {
      loading_complete: false,
      form: {
        cliente: this.cliente ? this.cliente : null,
        locale: this.locale ? this.locale : null,
        reference: this.reference ? this.reference : null,
        profilo_contatto: this.profiloContatto ? this.profiloContatto : 1,
        mail: this.mail ? this.mail : null,
        telephone: this.telephone ? this.telephone : null,
      },
      profili: [],
    };
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      if (this.id) {
        this.modificaContatto(this.id);
      } else {
        this.creaContatto();
      }
    },
    async caricaProfili() {
      //OTTENGO TOKEN DI AUTENTICAZIONE
      const accessToken = await this.$auth.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      //COSTRUISCO LA RICHIESTA PER I PROFILI
      let contactProfileRequest = await axios.get(
        `${process.env.VUE_APP_API_HOST}/profiloContatto`,
        config
      );

      this.profili = contactProfileRequest.data;
    },
    async eliminaContatto(id) {
      //OTTENGO TOKEN DI AUTENTICAZIONE
      const accessToken = await this.$auth.getTokenSilently();
      try {
        //ELIMINO IL CONTATTO
        await contattiRepo.cancel(accessToken, id);

        //EMETTO EVENTO DI CANCELLAZIONE
        this.$emit("contactDelete", id);
      } catch (e) {
        if (!e.details || e.details.length == 0) {
          if (typeof e === "string") {
            this.$toast.error(
              "Errore durante la cancellazione del contatto: " + e
            );
          } else {
            this.$toast.error("Errore durante la cancellazione del contatto");
          }
        } else {
          this.$toast.error(
            "Errore durante la cancellazione del contatto: " +
              e.details[0].message
          );
        }
      }
    },
    async modificaContatto(id) {
      if (!this.form.mail && !this.form.telephone) {
        this.$toast.error("Inserire mail o telefono");
        return;
      }
      //OTTENGO TOKEN DI AUTENTICAZIONE
      const accessToken = await this.$auth.getTokenSilently();
      try {
        //MODIFICO IL CONTATTO
        await contattiRepo.update(accessToken, id, {
          // cliente: this.form.cliente, //backend non accetta modifica del cliente
          // locale: this.form.locale, //backend non accetta modifica del locale
          reference: this.form.reference ? this.form.reference : null,
          profilo_contatto: this.form.profilo_contatto,
          mail: this.form.mail ? this.form.mail : null,
          telephone: this.form.telephone ? this.form.telephone : null,
        });

        //EMETTO EVENTO DI MODIFICA
        this.$emit("contactUpdate", id);
      } catch (e) {
        if (!e.details || e.details.length == 0) {
          if (typeof e === "string") {
            this.$toast.error("Errore durante la modifica del contatto: " + e);
          } else {
            this.$toast.error("Errore durante la modifica del contatto");
          }
        } else {
          this.$toast.error(
            "Errore durante la modifica del contatto: " + e.details[0].message
          );
        }
      }
    },
    async creaContatto() {
      if (!this.form.mail && !this.form.telephone) {
        this.$toast.error("Inserire mail o telefono");
        return;
      }
      try {
        //OTTENGO TOKEN DI AUTENTICAZIONE
        const accessToken = await this.$auth.getTokenSilently();

        //AGGIUNGO IL CONTATTO
        const response = await contattiRepo.add(accessToken, {
          cliente: this.form.cliente,
          locale: this.form.locale,
          reference: this.form.reference ? this.form.reference : null,
          profilo_contatto: this.form.profilo_contatto,
          mail: this.form.mail ? this.form.mail : null,
          telephone: this.form.telephone ? this.form.telephone : null,
        });

        //EMETTO EVENTO DI CREAZIONE
        if (response.id) {
          this.$emit("contactCreation", response.id);
        }
      } catch (e) {
        if (!e.details || e.details.length == 0) {
          if (typeof e === "string") {
            this.$toast.error("Errore durante la creazione del contatto: " + e);
          } else {
            this.$toast.error("Errore durante la creazione del contatto");
          }
        } else {
          this.$toast.error(
            "Errore durante la creazione del contatto: " + e.details[0].message
          );
        }
      }
    },
    async findDuplicate(telephone) {
      //OTTENGO TOKEN DI AUTENTICAZIONE
      const accessToken = await this.$auth.getTokenSilently();
      const response = await contattiRepo.getAll(accessToken, {
        where: {
          telephone: {
            endsWith: telephone,
          },
        },
      });
      return response;
    },
  },
  computed: {
    currentTelephone() {
      return this.form.telephone;
    },
    clienti_search() {
      return this.$store.state.clienti_search;
    },
    clienti() {
      return this.$store.state.clienti;
    },
    locali_cliente() {
      if (this.form.cliente) {
        const locali = this.$store.state.locali_search[this.form.cliente];
        return Object.entries(locali ? locali : []).map(
          ([key, value]) => value
        );
      }
      return [];
    },
  },
};
</script>
