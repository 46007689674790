<template>
  <div class="m-3">
    <!-- MODALE DETTAGLI -->
    <b-modal
      size="md"
      v-model="modaleDettagli.aperto"
      title="Articolo Contratto"
    >
      <b-table
        striped
        stacked
        :items="[modaleDettagli.articolo]"
        :fields="modaleDettagli.fields"
      >
        <template #cell(data_installazione)="data">
          {{
            (() => {
              let date = new Date(data.value);
              if (date != "Invalid Date")
                return Intl.DateTimeFormat("it").format(date);
            })()
          }}
        </template>

        <template #cell(data_fiscalizzazione)="data">
          {{
            (() => {
              let date = new Date(data.value);
              if (date != "Invalid Date")
                return Intl.DateTimeFormat("it").format(date);
            })()
          }}
        </template>

        <template #cell(scadenza)="data">
          <div v-if="data.item.data_fiscalizzazione">
            {{
              (() => {
                let date = new Date(data.item.data_fiscalizzazione);
                date.setDate(date.getDate() + 2922);
                return Intl.DateTimeFormat("it").format(date);
              })()
            }}
          </div>
        </template>
      </b-table>

      <template #modal-footer>
        <div style="width: 100%; display: flex; justify-content: space-between">
          <b-button
            style="background-color: #cc3468"
            :href="modaleDettagli.trace_url"
            target="_blank"
            >Apri in Trace ↗</b-button
          >
          <b-button v-on:click="modaleDettagli.aperto = !modaleDettagli.aperto"
            >Chiudi</b-button
          >
        </div>
      </template>
    </b-modal>
    <!-- MODALE NUOVO AGGIORNAMENTO -->
    <b-modal
      v-model="modaleAggiornamento.aperto"
      no-close-on-backdrop
      hide-header
      hide-footer
      centered
    >
      <p>
        <label style="font-weight: bold"
          >Chi ha eseguito l'aggiornamento?</label
        >
        <b-form-select
          class="form-control mt-2"
          v-model="modaleAggiornamento.form.tecnico"
          :options="utenti_select"
        ></b-form-select>
      </p>
      <div class="d-flex justify-content-between">
        <b-button variant="danger" v-on:click="annullaAggiornamento"
          >Annulla</b-button
        >
        <b-button variant="success" v-on:click="confermaAggiornamento"
          >Conferma</b-button
        >
      </div>
    </b-modal>
    <!-- MODALE AGGIORNAMENTI PASSATI -->
    <b-modal
      v-model="modaleAggiornamentiEffettuati.aperto"
      title="Aggiornamenti effettuati"
    >
      <b-table
        :items="modaleAggiornamentiEffettuati.aggiornamenti"
        :fields="['versione_firmware', 'tecnico', 'data']"
      >
        <template #cell(versione_firmware)="data">
          {{
            modaleAggiornamentiEffettuati.versioni_firmwares.find(
              (item) => item.id == data.value
            ).descrizione
          }}
        </template>
        <template #cell(tecnico)="data">
          {{ $store.state.utenti.find((item) => item.id == data.value).name }}
        </template>
        <template #cell(data)="data">
          {{ new Date(data.value).toLocaleDateString("it") }}
        </template>
      </b-table>
    </b-modal>
    <!-- MODALE AZZERAMENTI -->
    <b-modal
      v-model="modaleAzzeramenti.aperto"
      title="Aggiornamento azzeramenti"
      hide-footer
    >
      <b-form @submit="aggiornaAzzeramenti">
        <b-form-group label="Azzeramenti" label-for="azzeramenti">
          <b-form-input
            id="azzeramenti"
            v-model.number="modaleAzzeramenti.azzeramenti"
            placeholder="Inserisci il numero di azzeramenti"
            type="number"
            step="1"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Data Azzeramento" label-for="data">
          <b-form-input
            id="data"
            v-model="modaleAzzeramenti.data"
            type="date"
            required
          ></b-form-input>
        </b-form-group>
        <b-button
          class="mt-2"
          style="float: right"
          type="submit"
          variant="primary"
          >Salva</b-button
        >
      </b-form>
    </b-modal>
    <h1>Registratori RT</h1>
    <b-card class="mb-4 background-secondary">
      <div class="row">
        <div class="col-md-3 mb-2">
          <b-form-input
            type="search"
            placeholder="Cod. cli, ragsoc, p.iva o matricola..."
            v-model="search"
          ></b-form-input>
        </div>
        <div class="col-md-3 mb-2">
          <b-form-select
            class="form-control"
            :options="
              [{ id: null, descrizione: 'Tutti i profili..' }].concat(
                profili_locale
              )
            "
            value-field="id"
            text-field="descrizione"
            v-model="search_profilo_locale"
          ></b-form-select>
        </div>
        <div class="col-md-5">
          <b-input-group>
            <b-form-select
              class="form-control"
              :options="
                [{ id: null, title: 'Tutti i modelli..' }].concat(articoli)
              "
              value-field="id"
              text-field="title"
              v-model="search_articolo"
            ></b-form-select>

            <template #append>
              <b-form-select
                class="form-control"
                :options="versioni_select"
                value-field="id"
                text-field="descrizione"
                v-model="search_versione"
              ></b-form-select>
            </template>
          </b-input-group>
        </div>
        <div class="col-md-1 text-center">
          <b-button
            v-on:click="downloadCSV"
            v-b-tooltip.hover.bottom="'Scarica file .csv'"
            style="background-color: green"
            ><font-awesome-icon icon="fa-file-csv" size="lg"
          /></b-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label>Data di installazione:</label>
          <b-input-group prepend="Dal">
            <b-form-datepicker
              reset-button
              v-model="search_installazione.dal"
              id="installazione-dal"
            ></b-form-datepicker>
          </b-input-group>
        </div>
        <div class="col-md-3">
          <label></label>
          <b-input-group prepend="Al">
            <b-form-datepicker
              reset-button
              v-model="search_installazione.al"
              id="installazione-al"
            ></b-form-datepicker>
          </b-input-group>
        </div>
        <div class="col-md-3">
          <label>Giorni di attività rimanenti:</label>
          <b-form-input v-model="search_giorni_rimanenti"></b-form-input>
        </div>
        <div class="col-md-3">
          <label>Periodo di attività rimanente:</label>
          <b-form-select
            class="form-control"
            :options="periodi_rimanenti"
            value-field="giorni_rimanenti"
            text-field="percentuale"
            v-model="search_giorni_rimanenti"
          ></b-form-select>
        </div>
      </div>
    </b-card>

    <div class="row mt-2">
      <div class="col-md-3 d-flex">
        <span class="mt-auto mb-auto">Articoli per pagina:&nbsp;</span>
        <select
          class="mt-auto mb-auto"
          style="width: 50px"
          v-model="perPage"
          v-on:change="ricaricaPaginatedTable"
        >
          <option :value="10">10</option>
          <option :value="20">20</option>
          <option :value="50">50</option>
          <option :value="100">100</option>
        </select>
      </div>
    </div>
    <PaginatedTable
      :getPage="getPage"
      :getCount="getCount"
      :openRow="openRow"
      :key="refreshKey"
      :refreshValue="refreshValue"
      :fields="fields"
      :sort="sort"
      :perPage="perPage"
      noLocalSorting
      pointer
    >
      <template #cell(ragione_sociale)="data">
        <div class="one-liner">{{ data.value }}</div>
      </template>

      <template #cell(modello)="data">
        <div class="one-liner">{{ data.value }}</div>
      </template>
      <template #cell(versione_firmware)="data">
        <b-form-select
          class="form-control"
          :options="
            [{ id: null, descrizione: 'Non definita' }].concat(
              data.value.versioni
            )
          "
          value-field="id"
          text-field="descrizione"
          v-model="data.value.attiva"
          v-on:change="
            updateVersioneFirmware(data.item.articolo.prg, data.value.attiva)
          "
        >
        </b-form-select>
      </template>
      <template #cell(aggiornamenti_firmware)="data">
        <b-button
          v-on:click="() => apriModaleAggiornamentiPassati(data.item)"
          style="background-color: #cc3468"
          >{{ data.value.length }} agg.</b-button
        >
      </template>
      <template #cell(azzeramenti)="data">
        <b-button
          v-on:click="() => apriModaleAzzeramenti(data.item)"
          style="background-color: #cc3468"
          >{{ data.item.azzeramenti.rimanenti }} rimanenti</b-button
        >
      </template>
      <template #cell(giorni_rimanenti)="data">
        <div v-if="data.item.articolo.giorni_passati">
          {{ 2922 - parseInt(data.item.articolo.giorni_passati) }}
        </div>
        <div v-else>Inserire censimento</div>
      </template>
      <!-- 
        <template #cell(import_trace)="data">
          <font-awesome-icon v-if="data.value" icon="fa-solid fa-check" />
          <font-awesome-icon v-else icon="fa-solid fa-xmark" />
        </template>
        <template #cell(immagine)="data">
          <img
            :src="getImagePath(data.value)"
            alt="image"
            width="50"
            height="50"
          />
        </template> -->
    </PaginatedTable>
  </div>
</template>

<script>
import PaginatedTable from "@/components/common/PaginatedTable.vue";
import * as articoloContrattoRepo from "../services/ArticoloContratto";
import * as aggiornamentoFirmwareRepo from "../services/AggiornamentoFirmware";
import * as articoloRepo from "../services/Articolo";
import BottonePDF from "@/components/rinnovi/BottonePDF.vue";

export default {
  name: "RegistratoriRT",
  components: {
    PaginatedTable,
    BottonePDF,
  },
  data() {
    return {
      articoli: [],
      versioni: [],
      modaleAggiornamento: {
        aperto: false,
        form: {
          prg: null,
          tecnico: null,
          versione: null,
        },
        versioniDisponibili: [],
      },
      modaleAzzeramenti: {
        aperto: false,
        azzeramenti: null,
        data: null,
        prg: null,
      },
      modaleAggiornamentiEffettuati: {
        aperto: false,
        aggiornamenti: [],
        versioni_firmwares: [],
      },
      search: "",
      search_articolo: null,
      search_versione: -1,
      search_installazione: {
        dal: "",
        al: "",
      },
      search_giorni_rimanenti: null,
      search_profilo_locale: null,
      inattivi: false,
      refreshKey: 0,
      refreshValue: 0,
      perPage: 10,
      fields: [
        { key: "codice_cliente", label: "Cod. cli." },
        { key: "ragione_sociale", sortable: true },
        // { key: "partita_iva" },
        { key: "modello", sortable: true },
        { key: "matricola" },
        { key: "versione_firmware", label: "Versione FW" },
        {
          key: "aggiornamenti_firmware",
          label: "Agg. FW",
          tdClass: "text-center",
        },
        { key: "azzeramenti", tdClass: "text-center" },
        {
          key: "giorni_rimanenti",
          label: "Giorni alla fine del certificato",
          tdClass: "text-center",
        },
      ],
      orderBy: [["locale_locale", "cliente_cliente", "ragsoc", "ASC"]],
      modaleDettagli: {
        aperto: false,
        articolo: null,
        trace_url: null,
        fields: [
          { key: "locale_locale.cliente_cliente.ragsoc", label: "Ragsoc" },
          { key: "locale_locale.nome", label: "Locale" },
          { key: "locale_locale.via", label: "Indirizzo" },
          { key: "locale_locale.citta", label: "Città" },
          { key: "articolo_articolo.title", label: "Modello" },
          "matricola",
          { key: "data_installazione", label: "Data Attivazione" },
          { key: "data_fiscalizzazione", label: "Data Censimento" },
          { key: "Scadenza" },
          "note",
        ],
      },
    };
  },
  watch: {
    query: {
      handler(val) {
        this.ricaricaPaginatedTable();
      },
      deep: true,
    },
    search_articolo(val) {
      if (this.search_versione != null && this.search_versione != -1)
        this.search_versione = -1;
      const articolo = this.articoli.find((a) => a.id === val);
      if (articolo) {
        this.versioni = articolo.versione_firmwares;
      } else {
        this.versioni = [];
      }
    },
  },
  async created() {
    this.$loading(true);
    const token = await this.$auth.getTokenSilently();
    await this.$store.dispatch("caricaUtenti", token);
    await this.$store.dispatch("caricaProfiliLocale", token);
    await this.loadArticoli();
    this.$loading(false);
  },
  methods: {
    async aggiornaAzzeramenti(event) {
      this.$loading(true);
      event.preventDefault();
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await articoloContrattoRepo.modificaArticoloContratto(
          accessToken,
          this.modaleAzzeramenti.prg,
          {
            azzeramenti: this.modaleAzzeramenti.azzeramenti,
            data_azzeramento: this.modaleAzzeramenti.data,
          }
        );
        this.ricaricaPaginatedTable();
      } catch (e) {
        this.$toast.error(
          "Si è verificato un errore durante l'aggiornamento degli azzeramenti"
        );
        console.log(e);
      } finally {
        this.modaleAzzeramenti.aperto = false;
        this.$loading(false);
      }
    },
    async downloadCSV() {
      this.$toast.info("Download in corso...");
      const accessToken = await this.$auth.getTokenSilently();
      const response = await articoloContrattoRepo.getAllAsCSV(
        accessToken,
        this.query
      );
      if (response.data) {
        download("ricerca.csv", response.data);
      } else {
        this.$toast.error(
          "Si è verificato un errore durante il download del file CSV"
        );
      }
    },
    apriModaleAzzeramenti(row) {
      this.modaleAzzeramenti.aperto = true;
      this.modaleAzzeramenti.data = row.azzeramenti.data;
      this.modaleAzzeramenti.azzeramenti = row.azzeramenti.tot;
      this.modaleAzzeramenti.prg = row.articolo.prg;
    },
    apriModaleAggiornamentiPassati(row) {
      console.log(row);
      this.modaleAggiornamentiEffettuati.aperto = true;
      this.modaleAggiornamentiEffettuati.aggiornamenti =
        row.aggiornamenti_firmware;
      this.modaleAggiornamentiEffettuati.versioni_firmwares =
        row.versione_firmware.versioni;
    },
    resetFormAggiornamento() {
      this.modaleAggiornamento.form.prg = null;
      this.modaleAggiornamento.form.tecnico = null;
      this.modaleAggiornamento.form.versione = null;
    },
    async confermaAggiornamento() {
      const formData = this.modaleAggiornamento.form;
      if (formData.prg && formData.tecnico && formData.versione) {
        const aggiornamentoFirmware = {
          articolo_contratto: formData.prg,
          tecnico: formData.tecnico,
          versione_firmware: formData.versione,
        };
        try {
          const token = await this.$auth.getTokenSilently();
          const response = aggiornamentoFirmwareRepo.creaAggiornamentoFirmware(
            token,
            aggiornamentoFirmware
          );
          console.log(response);
          this.modaleAggiornamento.aperto = false;
          this.ricaricaPaginatedTable();
        } catch (e) {
          this.$toast.error(e.message);
        }
      } else {
        this.$toast.error("Compilare tutti i campi");
      }
    },
    annullaAggiornamento() {
      this.modaleAggiornamento.aperto = false;
      this.ricaricaPaginatedTable();
    },
    sort(params) {
      let realParamsName = [];
      console.log(params);
      switch (params.sortBy) {
        case "modello":
          realParamsName = ["articolo_articolo", "title"];
          break;
        case "ragione_sociale":
          realParamsName = ["locale_locale", "cliente_cliente", "ragsoc"];
          break;
      }
      if (realParamsName.length > 0) {
        this.orderBy = [[...realParamsName, params.sortDesc ? "desc" : "asc"]];
      } else {
        this.orderBy = [["locale_locale", "cliente_cliente", "ragsoc", "asc"]];
      }
      this.ricaricaPaginatedTable();
    },
    updateVersioneFirmware(prg, versione) {
      this.modaleAggiornamento.aperto = true;
      this.modaleAggiornamento.form.prg = prg;
      this.modaleAggiornamento.form.versione = versione;
    },
    getImagePath(name) {
      let path = null;
      if (!name) return path;
      return (process.env.VUE_APP_API_HOST.replace("api", "") + name)
        .replace("../", "")
        .replace("uploads", "images");
    },
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + "€"
      );
    },
    async loadArticoli() {
      try {
        const accessToken = await this.$auth.getTokenSilently();
        this.articoli = await articoloRepo.getAll(accessToken, {
          where: {
            tipo_articolo: 5,
          },
          include: "versione_firmwares",
        });
        //Ordino per titolo
        this.articoli.sort((a, b) => a.title.localeCompare(b.title));
      } catch (e) {
        this.$toast.error("Errore caricamento articoli");
        console.log(e);
      }
    },
    async getPage(accessToken, page) {
      let articoli = await articoloContrattoRepo.getPageOfXItems(
        accessToken,
        page,
        this.perPage,
        this.query
      );
      return articoli.data.map((articolo) => {
        const cliente = articolo.locale_locale.cliente_cliente;
        return {
          articolo: articolo,
          codice_cliente: cliente.id_trace,
          ragione_sociale: cliente.ragsoc,
          partita_iva: cliente.piva,
          modello: articolo.articolo_articolo.title,
          matricola: articolo.matricola,
          versione_firmware: {
            attiva:
              articolo.aggiornamento_firmwares.length > 0
                ? articolo.aggiornamento_firmwares[
                    articolo.aggiornamento_firmwares.length - 1
                  ].versione_firmware
                : null,
            versioni: articolo.articolo_articolo.versione_firmwares,
          },
          aggiornamenti_firmware: articolo.aggiornamento_firmwares,
          azzeramenti: {
            rimanenti: articolo.azzeramenti_rimanenti,
            tot: articolo.azzeramenti,
            data: articolo.data_azzeramento,
          },
        };
      });
    },
    async getCount(accessToken) {
      let count = await articoloContrattoRepo.getCount(accessToken, this.query);
      return count.data;
    },
    ricaricaPaginatedTable() {
      // this.refreshKey += 1;
      this.refreshValue += 1;
    },
    openRow(row, number, event) {
      console.log(row);
      console.log(number);
      console.log(event);
      this.modaleDettagli.articolo = row.articolo;
      const traceBaseUrl =
        "https://rcatrace.newchurchill.eu/cliente20.jsp?cod_anagra=";
      this.modaleDettagli.trace_url = `${traceBaseUrl}${row.articolo.locale_locale.cliente_cliente.id_trace}`;
      this.modaleDettagli.aperto = true;
      // router.push({ name: "schedaArticolo", params: { id: row["id"] } });
    },
  },
  computed: {
    periodi_rimanenti() {
      const VITA_REGISTRATORI = 2922;
      const periodi = [
        {
          percentuale: "0%",
          giorni_rimanenti: 0,
        },
      ];
      for (let i = 10; i < 100; i += 10) {
        periodi.push({
          percentuale: "Sotto il " + i + "%",
          giorni_rimanenti: Math.round((i / 100) * VITA_REGISTRATORI),
        });
      }
      periodi.push({
        percentuale: "100%",
        giorni_rimanenti: null,
      });
      return periodi;
    },
    versioni_select() {
      return [
        { id: -1, descrizione: "Tutte le versioni.." },
        { id: null, descrizione: "Versione non definita" },
      ].concat(this.versioni);
    },
    utenti_select() {
      return [{ value: null, text: "Seleziona tecnico..." }].concat(
        ...this.$store.state.utenti
          .filter((utente) => !utente.name.startsWith("*"))
          .map((utente) => {
            return { value: utente.id, text: utente.name };
          })
      );
    },
    articoli_select() {
      return this.articoli;
    },
    profili_locale() {
      return this.$store.state.profili_locale;
    },
    gruppi_select() {
      return [{ value: null, text: "Tutti i gruppi" }].concat(
        this.gruppi.map((gruppo) => {
          return {
            value: gruppo.id_trace,
            text: gruppo.id_trace + " - " + gruppo.descrizione,
          };
        })
      );
    },
    query() {
      console.log("reload query");
      const query = {
        include: [
          {
            model: "articolo",
            as: "articolo_articolo",
            include: {
              model: "versione_firmware",
              as: "versione_firmwares",
              separate: true,
            },
          },
          {
            model: "aggiornamento_firmware",
            as: "aggiornamento_firmwares",
            separate: true,
            order: [["data", "ASC"]],
          },
          {
            model: "locale",
            as: "locale_locale",
            include: ["cliente_cliente"],
          },
        ],
        where: {
          ["$articolo_articolo.tipo_articolo$"]: 5,
        },
        order: this.orderBy,
        subQuery: false,
      };
      if (this.search.trim() != "") {
        query.where = {
          and: {
            ...query.where,
            or: {
              ["$locale_locale.cliente_cliente.ragsoc$"]: {
                substring: this.search,
              },
              ["$locale_locale.cliente_cliente.piva$"]: {
                substring: this.search,
              },
              ["$locale_locale.cliente_cliente.id_trace$"]: {
                substring: this.search,
              },
              ["$articolo_articolo.title$"]: {
                substring: this.search,
              },
              matricola: {
                substring: this.search,
              },
            },
          },
        };
      }

      if (this.search_articolo) {
        query.where = {
          and: {
            ...query.where,
            articolo: this.search_articolo,
          },
        };
      }

      if (this.search_installazione.dal) {
        query.where = {
          and: {
            ...query.where,
            data_installazione: {
              gte: this.search_installazione.dal,
            },
          },
        };
      }

      if (this.search_installazione.al) {
        query.where = {
          and: {
            ...query.where,
            data_installazione: {
              lte: this.search_installazione.al,
            },
          },
        };
      }

      if (this.search_profilo_locale) {
        query.where = {
          and: {
            ...query.where,
            ["$locale_locale.profilo_locale$"]: this.search_profilo_locale,
          },
        };
      }

      if (!query.additionalData) query.additionalData = {};
      query.additionalData.versione_attiva = this.search_versione;
      query.additionalData.giorni_rimanenti = this.search_giorni_rimanenti;

      return query;
    },
  },
};

function download(filename, text) {
  var element = document.createElement("a");
  element.setAttribute(
    "href",
    "data:text/plain;charset=utf-8," + encodeURIComponent(text)
  );
  element.setAttribute("download", filename);

  element.style.display = "none";
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
</script>

<style>
.one-liner {
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
