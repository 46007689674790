import axios from "axios";

export const getAll = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(
    JSON.stringify({ options: options })
  );
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: encodedOptions,
    },
  };
  //Send request
  return axios.get(`${process.env.VUE_APP_API_HOST}/campagna`, config);
};

export const getCount = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(
    JSON.stringify({ options: options })
  );
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: encodedOptions,
    },
  };
  //Send request
  return axios.get(`${process.env.VUE_APP_API_HOST}/campagna/count`, config);
};

export const getPage = (accessToken, page = 1, options) => {
  const encodedOptions = encodeURIComponent(
    JSON.stringify({ options: options })
  );
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/campagna/pagina/${page}`,
    config
  );
};

export const getTarget = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(
    JSON.stringify({ options: options })
  );
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: encodedOptions,
    },
  };
  //Send request
  return axios.get(`${process.env.VUE_APP_API_HOST}/campagna/target`, config);
};

export const launch = async (token, data) => {
  const isExtraction = data.tipo_campagna == 3;
  //Inserisco la campagna
  const campagna = {
    nome: data.nome,
    data: data.data,
    oggetto: data.oggetto,
    template: data.template,
    avviata: data.avviata,
    tipo_campagna: data.tipo_campagna,
    destinazione_campagna: data.destinazione_campagna,
  };
  const campagnaCreata = await creaCampagna(token, campagna);
  const idCampagna = campagnaCreata ? campagnaCreata.data.id : null;
  if (!idCampagna) throw new Error("Impossibile generare la campagna");
  //Se non è una campagna per estrazione
  if (!isExtraction) {
    //Creo la coda
    const coda_campagna = data.coda.map((item) => {
      return {
        destinazione: item.destinazione,
        contenuto: JSON.stringify(item.contenuto),
        campagna: idCampagna,
      };
    });
    await creaCodaCampagna(token, {
      coda: coda_campagna,
    });
    //Salvo le statistiche
    const statistica_campagna = {
      descrizione: "Comunicazioni effettive",
      valore: coda_campagna.length,
      campagna: idCampagna,
    };
    await creaStatisticaCampagna(token, statistica_campagna);
    return;
  }
  //Salvo i filtri della campagna
  const filtro_campagna = {
    filtra_per: data.filtra_per,
    filtro: data.filtro ? data.filtro.toString() : null,
    campagna: idCampagna,
  };
  const filtroCampagnaCreato = await creaFiltroCampagna(token, filtro_campagna);

  //Salvo i profili locali legati ai filtri della campagna
  const profili_filtro_campagna = data.profilo_contatto;
  for (const profilo of profili_filtro_campagna) {
    const profilo_filtro_campagna = {
      filtro_campagna: filtroCampagnaCreato
        ? filtroCampagnaCreato.data.id
        : null,
      profilo_contatto: profilo,
    };
    await creaProfiloFiltroCampagna(token, profilo_filtro_campagna);
  }
};

export const avviaCampagnaMail = async (
  token,
  nomeCampagna,
  query,
  profilo,
  oggetto,
  testo
) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const data = {
    nomeCampagna: nomeCampagna,
    options: query,
    profiloContatto: profilo,
    oggetto: oggetto,
    testo: testo,
  };
  //Send request
  return await axios.post(
    `${process.env.VUE_APP_API_HOST}/mail/list`,
    data,
    config
  );
};

export const creaCampagna = async (token, campagna) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return await axios.post(
    `${process.env.VUE_APP_API_HOST}/campagna`,
    campagna,
    config
  );
};

export const creaFiltroCampagna = async (token, filtroCampagna) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return await axios.post(
    `${process.env.VUE_APP_API_HOST}/filtroCampagna`,
    filtroCampagna,
    config
  );
};

export const creaProfiloFiltroCampagna = async (
  token,
  profiloFiltroCampagna
) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return await axios.post(
    `${process.env.VUE_APP_API_HOST}/profiliFiltroCampagna`,
    profiloFiltroCampagna,
    config
  );
};

export const creaCodaCampagna = async (token, coda) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return await axios.post(
    `${process.env.VUE_APP_API_HOST}/codaCampagna/bulk`,
    coda,
    config
  );
};

export const creaStatisticaCampagna = async (token, statistica) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return await axios.post(
    `${process.env.VUE_APP_API_HOST}/statisticheCampagna`,
    statistica,
    config
  );
};

export const eliminaCampagna = async (token, idCampagna) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.delete(
    `${process.env.VUE_APP_API_HOST}/campagna/${idCampagna}`,
    config
  );
};
