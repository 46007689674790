<template>
  <div class="m-3">
    <h1>Locali</h1>
    <div class="row">
      <div class="col-md-3 mb-2">
        <b-form-input
          type="search"
          placeholder="Inserire id, nome, via..."
          v-model="search"
        ></b-form-input>
      </div>
      <div class="col-md-3 mb-2">
        <v-select
          id="profilo"
          class="background-secondary"
          :options="profili"
          :reduce="(item) => item.id"
          v-model="profilo_search"
          label="descrizione"
        ></v-select>
      </div>
      <div class="col-md-6">
        <v-select
          id="cliente"
          class="background-secondary"
          :options="clienti"
          :reduce="(item) => item.id"
          v-model="cliente_search"
          label="ragsoc"
        ></v-select>
      </div>
    </div>
    {{ profilo_search }}

    <PaginatedTable
      :getPage="getPage"
      :getCount="getCount"
      :openRow="openRow"
      :key="refreshKey"
      pointer
    >
      <!-- <template #cell(prezzo)="data">
          {{ formatPrice(data.value) }}
        </template>
        <template #cell(import_trace)="data">
          <font-awesome-icon v-if="data.value" icon="fa-solid fa-check" />
          <font-awesome-icon v-else icon="fa-solid fa-xmark" />
        </template>
        <template #cell(immagine)="data">
          <img
            :src="getImagePath(data.value)"
            alt="image"
            width="50"
            height="50"
          />
        </template> -->
    </PaginatedTable>
  </div>
</template>

<script>
import PaginatedTable from "@/components/common/PaginatedTable.vue";
import * as localeRepo from "../services/Locale";
import * as profiloLocaleRepo from "../services/ProfiloLocale";
import * as clientiRepo from "../services/Cliente";

import router from "../router/index";

export default {
  name: "Locali",
  components: {
    PaginatedTable,
  },
  data() {
    return {
      search: "",
      cliente_search: null,
      profilo_search: null,
      clienti: [],
      profili: [],
      refreshKey: 0,
    };
  },
  watch: {
    query: {
      handler(val) {
        this.ricaricaPaginatedTable();
      },
      deep: true,
    },
  },
  async created() {
    this.$loading(true);
    await this.getCustomers();
    await this.getProfili();
    this.$loading(false);
  },
  methods: {
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + "€"
      );
    },
    async getPage(accessToken, page) {
      let locali = await localeRepo.getPage(accessToken, page, this.query);
      locali = locali.map((locale) => {
        return {
          id: locale.id,
          cliente: locale.cliente_cliente
            ? locale.cliente_cliente.ragsoc
            : "ND",
          locale: locale.nome,
          profilo: locale.profilo_locale_profilo_locale
            ? locale.profilo_locale_profilo_locale.descrizione
            : "ND",
          via: locale.via,
          città: locale.citta,
          cap: locale.cap,
          provincia: locale.prov,
          piva: locale.piva,
          obsoleto: locale.obsoleto,
        };
      });
      return locali;
    },
    async getCount(accessToken) {
      let count = await localeRepo.getCount(accessToken, this.query);
      return count;
    },
    async getCustomers() {
      const accessToken = await this.$auth.getTokenSilently();
      this.clienti = [{ id: null, ragsoc: "Tutti i clienti" }].concat(
        await clientiRepo.getAll(accessToken)
      );
    },
    async getProfili() {
      const accessToken = await this.$auth.getTokenSilently();
      this.profili = [{ id: null, descrizione: "Tutti i profili" }].concat(
        await profiloLocaleRepo.getAll(accessToken, { where: { attivo: true } })
      );
    },
    ricaricaPaginatedTable() {
      this.refreshKey += 1;
    },
    openRow(row, number, event) {
      router.push({ name: "schedaLocale", params: { id: row["id"] } });
    },
  },
  computed: {
    query() {
      let query = {
        include: ["cliente_cliente", "profilo_locale_profilo_locale"],
      };
      if (this.search.trim() != "") {
        query.where = {
          and: {
            or: {
              id: {
                substring: this.search,
              },
              nome: {
                substring: this.search,
              },
              via: {
                substring: this.search,
              },
              citta: {
                substring: this.search,
              },
              cap: {
                substring: this.search,
              },
              prov: {
                substring: this.search,
              },
              piva: {
                substring: this.search,
              },
            },
          },
        };
      }
      if (this.cliente_search) {
        query.where = {
          and: {
            ...query.where,
            cliente: this.cliente_search,
          },
        };
      }
      if (this.profilo_search) {
        query.where = {
          and: {
            ...query.where,
            profilo_locale: this.profilo_search,
          },
        };
      }
      return query;
    },
  },
};
</script>
