<template>
  <div class="m-3 card p-4 background-secondary">
    <h1>{{ gruppo.descrizione }}</h1>

    <b-form @submit="onSubmit">
      <!-- id_trace -->
      <b-form-group id="input-group-5" label="ID Trace:" label-for="id_trace">
        <b-form-input
          id="id_trace"
          placeholder="Inserire id trace gruppo"
          v-model="gruppo.id_trace"
          readonly
        ></b-form-input>
      </b-form-group>
      <!-- tipo -->
      <b-form-group
        id="input-group-3"
        label="Tipo Gruppo:"
        label-for="tipo"
        class="mt-2"
      >
        <b-form-select
          id="tipo"
          class="form-control"
          v-model="gruppo.tipo_gruppo"
          :options="tipi_select"
          :disabled="!canUpdate"
          required
        ></b-form-select>
      </b-form-group>
      <!-- checkboxes -->
      <b-form-group id="input-group-6" class="mt-3" :disabled="!canUpdate">
        <b-form-checkbox v-model="gruppo.aggiungi_a_rinnovo"
          >Articolo inseribile nei rinnovi</b-form-checkbox
        >
        <b-form-checkbox v-model="gruppo.aggiungi_a_cliente"
          >Articolo inseribile in articoli cliente ordine</b-form-checkbox
        >
        <b-form-checkbox v-model="gruppo.mostra_a_zero"
          >Mostra articolo con prezzo a 0 nei contratti</b-form-checkbox
        >
        <b-form-checkbox v-model="gruppo.importa_a_zero"
          >Importa in trace con prezzo a 0</b-form-checkbox
        >
        <b-form-checkbox v-model="gruppo.not_ratei_risconti"
          >Escludere da ratei e risconti</b-form-checkbox
        >
      </b-form-group>

      <b-button v-if="canUpdate" type="submit" variant="primary" class="mt-2"
        >Conferma modifiche</b-button
      >
    </b-form>
  </div>
</template>

<script>
import * as gruppoRepo from "../../services/Gruppo";

export default {
  name: "Gruppo",
  data() {
    return {
      gruppo: {},
      tipi: [],
    };
  },
  async created() {
    await this.loadGruppo();
    await this.loadTipiGruppo();
  },
  computed: {
    canUpdate() {
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:gruppo"
          ) != -1
        );
      }
      return false;
    },
    id() {
      return parseInt(this.$route.params.id);
    },
    tipi_select() {
      return this.tipi.map((tipo) => {
        return {
          value: tipo.id,
          text: tipo.descrizione,
        };
      });
    },
  },
  methods: {
    async loadGruppo() {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      this.gruppo = await gruppoRepo.getById(accessToken, this.id);
      this.$loading(false);
    },
    async loadTipiGruppo() {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      this.tipi = await gruppoRepo.getTipiGruppo(accessToken);
      this.$loading(false);
    },
    async onSubmit(event) {
      event.preventDefault();

      //Controllo che il tipo sia != 4
      if (this.gruppo.tipo_gruppo == 4) {
        this.$toast.error("Selezionare un tipo di gruppo prima di confermare");
        return;
      }
      //Creo una copia con solo i parametri necessari
      const copy = (({
        tipo_gruppo,
        aggiungi_a_cliente,
        aggiungi_a_rinnovo,
        mostra_a_zero,
        importa_a_zero,
        not_ratei_risconti,
      }) => ({
        tipo_gruppo,
        aggiungi_a_cliente,
        aggiungi_a_rinnovo,
        mostra_a_zero,
        importa_a_zero,
        not_ratei_risconti,
      }))(this.gruppo);

      try {
        this.$loading(true);
        const accessToken = await this.$auth.getTokenSilently();
        await gruppoRepo.update(accessToken, this.gruppo.id, copy);
        this.$toast.open("Gruppo modificato correttamente");
      } catch (e) {
        this.$toast.error("Errore modifica gruppo: " + e.message);
      }
      this.$loading(false);
    },
  },
};
</script>
