<template>
  <b-card
    :title="getArticleById(articolo.id).title"
    :sub-title="getArticleById(articolo.id).id_trace"
    class="mb-3"
  >
    <b-icon
      icon="trash"
      variant="danger"
      aria-hidden="true"
      class="pointer position-absolute"
      style="top: 10px; right: 10px"
      v-on:click="rimuoviArticolo(articolo.id)"
      v-if="editable"
    ></b-icon>
    {{ scontabile }}
    <b-card-text>
      <table class="table">
        <tbody>
          <tr>
            <th scope="col">Quantità</th>
            <td>
              <b-form-input
                class="text-end"
                type="number"
                min="1"
                v-model="articolo.quantita"
                :readonly="!editable"
              ></b-form-input>
            </td>
          </tr>
          <tr>
            <th scope="col">Listino</th>
            <td>
              <b-input-group append=".€">
                <b-form-input
                  class="text-end"
                  type="number"
                  min="0"
                  step="0.01"
                  v-model="articolo.prezzo"
                  :readonly="!scontabile || !editable"
                ></b-form-input>
              </b-input-group>
            </td>
          </tr>
          <tr>
            <th scope="col">Sconto</th>
            <td>
              <b-input-group append="%">
                <b-form-input
                  class="text-end"
                  type="number"
                  min="0"
                  max="100"
                  step=".01"
                  v-model="articolo.sconto"
                  :readonly="!scontabile || !editable"
                ></b-form-input>
              </b-input-group>
            </td>
          </tr>
          <tr>
            <th scope="col">Totale</th>
            <td>
              <b-input-group append=" .€">
                <b-form-input
                  class="text-end"
                  :value="
                    articolo.prezzo * articolo.quantita -
                    (articolo.sconto / 100) *
                      articolo.prezzo *
                      articolo.quantita
                  "
                  v-on:focusout="
                    (e) =>
                      modificaScontoInBaseAlTotale(e.target.value, articolo.id)
                  "
                  :readonly="!scontabile || !editable"
                ></b-form-input>
              </b-input-group>
            </td>
          </tr>
        </tbody>
      </table>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "CartItemsMobileRow",
  props: [
    "articolo",
    "getArticleById",
    "modificaScontoInBaseAlTotale",
    "rimuoviArticolo",
    "editable",
    "scontabile",
  ],
};
</script>
