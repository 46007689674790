<template>
  <div>
    <b-card class="m-3 mt-4 background-secondary">
      <b-tabs content-class="mt-3" class="text-color">
        <b-tab title="Generale" active>
          <div class="row mb-3">
            <div class="col-lg-6 col-md-6 mb-2 mb-lg-0">
              <b-card class="background-secondary">
                <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <h3>Chiamate ricevute</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h4>
                          {{
                            this.chiamate_giorno.reduce(
                              (act, next) => act + next.chiamate,
                              0
                            )
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <b-button size="lg">S</b-button> -->
                  </div>
                </div>
              </b-card>
            </div>
            <div class="col-lg-6 col-md-6 mb-2 mb-lg-0">
              <b-card class="background-secondary">
                <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <h3>Media giornaliera</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h4>
                          {{
                            Math.round(
                              this.chiamate_giorno.reduce(
                                (act, next) => act + next.chiamate,
                                0
                              ) / this.chiamate_giorno.length
                            )
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <b-button size="lg">S</b-button> -->
                  </div>
                </div>
              </b-card>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-card class="background-secondary">
                <apexchart
                  height="300"
                  :options="optionsChiamateGiorno"
                  :series="seriesChiamateGiorno"
                ></apexchart>
              </b-card>
            </div>
          </div>
        </b-tab>
        <b-tab title="Settimanale">
          <div class="row mb-3">
            <div class="col-lg-6 col-md-6 mb-2 mb-lg-0">
              <b-card class="background-secondary">
                <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <h3>Chiamate ricevute</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h4>
                          {{
                            this.chiamate_giorno_base.reduce(
                              (act, next) => act + next.chiamate,
                              0
                            )
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <b-button size="lg">S</b-button> -->
                  </div>
                </div>
              </b-card>
            </div>
            <div class="col-lg-6 col-md-6 mb-2 mb-lg-0">
              <b-card class="background-secondary">
                <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <h3>Media giornaliera</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h4>
                          {{
                            Math.round(
                              this.chiamate_giorno_base.reduce(
                                (act, next) => act + next.chiamate,
                                0
                              ) / this.chiamate_giorno_base.length
                            )
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <b-button size="lg">S</b-button> -->
                  </div>
                </div>
              </b-card>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-card class="background-secondary">
                <apexchart
                  height="300"
                  :options="optionsChiamateGiornoBase"
                  :series="seriesChiamateGiornoBase"
                ></apexchart>
              </b-card>
            </div>
          </div>
        </b-tab>
        <b-tab title="Reperibilità">
          <div class="row mb-3">
            <div class="col-lg-6 col-md-6 mb-2 mb-lg-0">
              <b-card class="background-secondary">
                <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <h3>Chiamate ricevute</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h4>
                          {{
                            this.chiamate_giorno_rep.reduce(
                              (act, next) => act + next.chiamate,
                              0
                            )
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <b-button size="lg">S</b-button> -->
                  </div>
                </div>
              </b-card>
            </div>
            <div class="col-lg-6 col-md-6 mb-2 mb-lg-0">
              <b-card class="background-secondary">
                <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <h3>Media giornaliera</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h4>
                          {{
                            Math.round(
                              this.chiamate_giorno_rep.reduce(
                                (act, next) => act + next.chiamate,
                                0
                              ) / this.chiamate_giorno_rep.length
                            )
                          }}
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <b-button size="lg">S</b-button> -->
                  </div>
                </div>
              </b-card>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <b-card class="background-secondary">
                <apexchart
                  height="300"
                  :options="optionsChiamateGiornoRep"
                  :series="seriesChiamateGiornoRep"
                ></apexchart>
              </b-card>
            </div>
          </div>
        </b-tab>
        <b-tab title="Contatti">
          <div class="row mb-3">
            <div class="col-lg-4 col-md-6 mb-2 mb-lg-0">
              <b-card class="background-secondary">
                <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <h3>Chiamate ricevute</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h4>{{ this.hits_total.total }}</h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <b-button size="lg">S</b-button> -->
                  </div>
                </div>
              </b-card>
            </div>
            <div class="col-lg-4 col-md-6 mb-2 mb-lg-0">
              <b-card class="background-secondary">
                <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <h3>Contatti presenti</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h4>
                          {{ this.hits_total.count_found }} ({{
                            this.hits_total.perc_found
                          }}%)
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <b-button size="lg">S</b-button> -->
                  </div>
                </div>
              </b-card>
            </div>
            <div class="col-lg-4 col-md-6 mb-2 mb-lg-0">
              <b-card class="background-secondary">
                <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <h3>Contatti non presenti</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h4>
                          {{ this.hits_total.count_not_found }} ({{
                            this.hits_total.perc_not_found
                          }}%)
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <b-button size="lg">S</b-button> -->
                  </div>
                </div>
              </b-card>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <b-card class="background-secondary">
                <apexchart
                  :options="optionsHitsHistory7Days"
                  :series="seriesHitsHistory7days"
                ></apexchart>
              </b-card>
            </div>
            <div class="col-md-6">
              <b-card class="background-secondary">
                <apexchart
                  :options="optionsHitsHistory30Days"
                  :series="seriesHitsHistory30days"
                ></apexchart>
              </b-card>
            </div>
          </div>
        </b-tab>
        <b-tab title="Assistenza">
          <div class="row m-1 mt-4 mb-3">
            <div class="col-lg-4 col-md-6 mb-2 mb-lg-0">
              <b-card class="background-secondary">
                <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <h3>Richieste di assistenza</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h4>{{ this.ass_redirected_total.total }}</h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <b-button size="lg">S</b-button> -->
                  </div>
                </div>
              </b-card>
            </div>
            <div class="col-lg-4 col-md-6 mb-2 mb-lg-0">
              <b-card class="background-secondary">
                <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <h3>Richieste ammesse</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h4>
                          {{ this.ass_redirected_total.count_not_redirected }}
                          ({{ this.ass_redirected_total.perc_not_redirected }}%)
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <b-button size="lg">S</b-button> -->
                  </div>
                </div>
              </b-card>
            </div>
            <div class="col-lg-4 col-md-6 mb-2 mb-lg-0">
              <b-card class="background-secondary">
                <div class="row">
                  <div class="col-9">
                    <div class="row">
                      <div class="col">
                        <h3>Richieste reindirizzate</h3>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <h4>
                          {{ this.ass_redirected_total.count_redirected }} ({{
                            this.ass_redirected_total.perc_redirected
                          }}%)
                        </h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 align-self-center">
                    <!-- <b-button size="lg">S</b-button> -->
                  </div>
                </div>
              </b-card>
            </div>
          </div>
          <div class="row m-1">
            <div class="col-md-6">
              <b-card class="background-secondary">
                <apexchart
                  :options="optionsAssRedirectedHistory7Days"
                  :series="seriesAssRedirectedHistory7days"
                ></apexchart>
              </b-card>
            </div>
            <div class="col-md-6">
              <b-card class="background-secondary">
                <apexchart
                  :options="optionsAssRedirectedHistory30Days"
                  :series="seriesAssRedirectedHistory30days"
                ></apexchart>
              </b-card>
            </div>
          </div>
        </b-tab>
        <b-tab title="Clienti">
          <div class="row m-1 mt-4">
            <div class="col-md-6">
              <b-card class="background-secondary">
                <h3>Top 10 clienti per numero di chiamate</h3>
                <b-table
                  id="top-10-clienti-chiamate"
                  :fields="this.top10ClientiChiamateFields"
                  :items="this.top_10_clienti_chiamate"
                >
                  <template #cell(durata_tot)="data">
                    {{
                      new Date(data.value * 1000)
                        .toISOString()
                        .substring(11, 19)
                    }}
                  </template>
                  <template #cell(durata_media)="data">
                    {{
                      new Date(data.value * 1000)
                        .toISOString()
                        .substring(11, 19)
                    }}
                  </template>
                </b-table>
              </b-card>
            </div>
            <div class="col-md-6">
              <b-card class="background-secondary">
                <h3>Top 10 clienti per durata totale</h3>
                <b-table
                  id="top-10-clienti-chiamate"
                  :fields="this.top10ClientiDurataTotFields"
                  :items="this.top_10_clienti_durata_tot"
                >
                  <template #cell(durata_tot)="data">
                    {{
                      new Date(data.value * 1000)
                        .toISOString()
                        .substring(11, 19)
                    }}
                  </template>
                  <template #cell(durata_media)="data">
                    {{
                      new Date(data.value * 1000)
                        .toISOString()
                        .substring(11, 19)
                    }}
                  </template>
                </b-table>
              </b-card>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import * as statisticheService from "../services/Statistiche";

export default {
  name: "StatisticheCentralino",
  components: {},
  data: function () {
    return {
      registro_chiamate: [],
      chiamate_cliente: [],
      hits_history: [],
      hits_history_30_days: [],
      hits_history_7_days: [],
      ass_redirected_history: [],
      ass_redirected_history_30_days: [],
      ass_redirected_history_7_days: [],
      hits_total: {
        total: 0,
        count_not_found: 0,
        count_found: 0,
        perc_not_found: 0,
        perc_found: 0,
      },
      ass_redirected_total: {
        total: 0,
        count_not_redirected: 0,
        count_redirected: 0,
        perc_not_redirected: 0,
        perc_redirected: 0,
      },
      top_10_clienti_chiamate: [],
      top_10_clienti_durata_tot: [],
      chiamate_giorno: [],
      chiamate_giorno_rep: [],
      chiamate_giorno_base: [],
    };
  },
  async created() {
    const accessToken = await this.$auth.getTokenSilently();
    await this.downloadAll(accessToken);
  },
  methods: {
    async downloadAll(token) {
      this.downloadRegistroChiamate(token);
      this.downloadChiamateCliente(token);
      this.downloadHitsHistory(token);
      this.downloadHitsHistory7Days(token);
      this.downloadHitsHistory30Days(token);
      this.downloadAssRedirectedHistory(token);
      this.downloadAssRedirectedHistory7Days(token);
      this.downloadAssRedirectedHistory30Days(token);
      this.downloadHitsTotal(token);
      this.downloadAssRedirectedTotal(token);
      this.downloadTop10ClientiChiamate(token);
      this.downloadTop10ClientiDurataTot(token);
      this.downloadChiamateGiorno(token);
      this.downloadChiamateGiornoRep(token);
      this.downloadChiamateGiornoBase(token);
    },
    async downloadRegistroChiamate(token) {
      this.registro_chiamate = await statisticheService.registroChiamate(token);
    },
    async downloadChiamateCliente(token) {
      this.chiamate_cliente =
        await statisticheService.centralinoChiamateCliente(token);
    },
    async downloadHitsHistory(token) {
      this.hits_history = await statisticheService.centralinoHitsHistory(token);
    },
    async downloadHitsHistory7Days(token) {
      this.hits_history_7_days =
        await statisticheService.centralinoHitsHistory7Days(token);
    },
    async downloadHitsHistory30Days(token) {
      this.hits_history_30_days =
        await statisticheService.centralinoHitsHistory30Days(token);
    },
    async downloadAssRedirectedHistory(token) {
      this.ass_redirected_history =
        await statisticheService.centralinoAssRedirectedHistory(token);
    },
    async downloadHitsTotal(token) {
      this.hits_total = await statisticheService.centralinoHitsTotal(token);
    },
    async downloadAssRedirectedHistory7Days(token) {
      this.ass_redirected_history_7_days =
        await statisticheService.centralinoAssRedirectedHistory7Days(token);
    },
    async downloadAssRedirectedHistory30Days(token) {
      this.ass_redirected_history_30_days =
        await statisticheService.centralinoAssRedirectedHistory30Days(token);
    },
    async downloadAssRedirectedTotal(token) {
      this.ass_redirected_total =
        await statisticheService.centralinoAssRedirectedTotal(token);
    },
    async downloadTop10ClientiChiamate(token) {
      this.top_10_clienti_chiamate =
        await statisticheService.top10ClientiChiamate(token);
    },
    async downloadTop10ClientiDurataTot(token) {
      this.top_10_clienti_durata_tot =
        await statisticheService.top10ClientiDurataTot(token);
    },
    async downloadChiamateGiorno(token) {
      this.chiamate_giorno = await statisticheService.centralinoChiamateGiorno(
        token
      );
    },
    async downloadChiamateGiornoRep(token) {
      this.chiamate_giorno_rep =
        await statisticheService.centralinoChiamateGiornoRep(token);
    },
    async downloadChiamateGiornoBase(token) {
      this.chiamate_giorno_base =
        await statisticheService.centralinoChiamateGiornoBase(token);
    },
  },
  computed: {
    optionsHitsHistory7Days() {
      return {
        title: {
          text: "Ultimi 7 giorni",
          style: {
            color: "var(--title-color)",
            fontSize: "28px",
            fontWeight: 500,
            fontFamily: "Avenir, Helvetica, Arial, sans-serif;",
          },
        },
        stroke: {
          width: [0, 0, 2],
          dashArray: [0, 0, 2],
        },
        chart: {
          id: "hits-history-7-days",
          stacked: true,
        },
        colors: ["#28a745", "#dc3545", "#000"],
        xaxis: {
          categories: this.hits_history_7_days.map((hit) =>
            new Date(hit.date).toLocaleDateString("it-IT")
          ),
        },
      };
    },
    seriesHitsHistory7days() {
      return [
        {
          type: "bar",
          name: "Contatto trovato",
          data: this.hits_history_7_days.map((hit) => hit.count_found),
        },
        {
          type: "bar",
          name: "Contatto non trovato",
          data: this.hits_history_7_days.map((hit) => hit.count_not_found),
        },
      ];
    },
    optionsHitsHistory30Days() {
      return {
        title: {
          text: "Ultimi 30 giorni",
          style: {
            color: "var(--title-color)",
            fontSize: "28px",
            fontWeight: 500,
            fontFamily: "Avenir, Helvetica, Arial, sans-serif;",
          },
        },
        stroke: {
          width: [0, 0, 2],
          curve: "smooth",
        },
        chart: {
          id: "hits-history-30-days",
          stacked: true,
        },
        colors: ["#28a745", "#dc3545", "#000"],
        xaxis: {
          labels: {
            show: false,
          },
          categories: this.hits_history_30_days.map((hit) =>
            new Date(hit.date).toLocaleDateString("it-IT")
          ),
        },
      };
    },
    seriesHitsHistory30days() {
      return [
        {
          type: "bar",
          name: "Contatto trovato",
          data: this.hits_history_30_days.map((hit) => hit.count_found),
        },
        {
          type: "bar",
          name: "Contatto non trovato",
          data: this.hits_history_30_days.map((hit) => hit.count_not_found),
        },
      ];
    },
    optionsAssRedirectedHistory7Days() {
      return {
        title: {
          text: "Ultimi 7 giorni",
          style: {
            color: "var(--title-color)",
            fontSize: "28px",
            fontWeight: 500,
            fontFamily: "Avenir, Helvetica, Arial, sans-serif;",
          },
        },
        stroke: {
          width: [0, 0, 2],
          curve: "smooth",
        },
        chart: {
          id: "ass-redirected-history-7-days",
          // type: "bar",
          stacked: true,
        },
        colors: ["#28a745", "#dc3545"],
        xaxis: {
          categories: this.ass_redirected_history_7_days.map((hit) =>
            new Date(hit.date).toLocaleDateString("it-IT")
          ),
        },
      };
    },
    seriesAssRedirectedHistory7days() {
      return [
        {
          type: "bar",
          name: "Ammesso",
          data: this.ass_redirected_history_7_days.map(
            (hit) => hit.count_not_redirected
          ),
        },
        {
          type: "bar",
          name: "Reindirizzato",
          data: this.ass_redirected_history_7_days.map(
            (hit) => hit.count_redirected
          ),
        },
      ];
    },
    optionsAssRedirectedHistory30Days() {
      return {
        title: {
          text: "Ultimi 30 giorni",
          style: {
            color: "var(--title-color)",
            fontSize: "28px",
            fontWeight: 500,
            fontFamily: "Avenir, Helvetica, Arial, sans-serif;",
          },
        },
        stroke: {
          width: [0, 0, 2],
          curve: "smooth",
        },
        chart: {
          id: "ass-redirected-history-30-days",
          stacked: true,
        },
        colors: ["#28a745", "#dc3545"],
        xaxis: {
          labels: {
            show: false,
          },
          categories: this.ass_redirected_history_30_days.map((hit) =>
            new Date(hit.date).toLocaleDateString("it-IT")
          ),
        },
      };
    },
    seriesAssRedirectedHistory30days() {
      return [
        {
          type: "bar",
          name: "Ammesso",
          data: this.ass_redirected_history_30_days.map(
            (hit) => hit.count_not_redirected
          ),
        },
        {
          type: "bar",
          name: "Reindirizzato",
          data: this.ass_redirected_history_30_days.map(
            (hit) => hit.count_redirected
          ),
        },
      ];
    },
    top10ClientiChiamateFields() {
      return [
        { key: "ragsoc", label: "Cliente" },
        "chiamate",
        { key: "durata_tot", label: "Durata Totale" },
        { key: "durata_media", label: "Durata Media" },
      ];
    },
    top10ClientiDurataTotFields() {
      return [
        { key: "ragsoc", label: "Cliente" },
        "chiamate",
        { key: "durata_tot", label: "Durata Totale" },
        { key: "durata_media", label: "Durata Media" },
      ];
    },
    filledSerieChiamateGiorno() {
      return fillSerieEmpyDaysWith0(
        this.chiamate_giorno.map((chiamate) => {
          return [new Date(chiamate.data).getTime(), chiamate.chiamate];
        })
      );
    },
    optionsChiamateGiorno() {
      return {
        title: {
          text: "Chiamate al giorno",
          style: {
            color: "var(--title-color)",
            fontSize: "28px",
            fontWeight: 500,
            fontFamily: "Avenir, Helvetica, Arial, sans-serif;",
          },
        },
        stroke: {
          curve: "smooth",
        },
        chart: {
          type: "area",
          id: "registro-chiamate",
        },
        dataLabels: {
          style: {
            colors: [
              (a) => {
                const date = new Date(
                  this.filledSerieChiamateGiorno[a.dataPointIndex][0]
                );
                if (date.getDay() == 6 || date.getDay() == 0) return "red";
                return "green";
              },
            ],
          },
        },
        colors: ["#28a745"],
        xaxis: {
          type: "category",
          categories: this.filledSerieChiamateGiorno.map((item) => item[0]),
          labels: {
            rotate: 0,
            hideOverlappingLabels: true,
            formatter: function (val) {
              return new Date(val).toLocaleDateString("it-IT", {
                weekday: "short",
                day: "numeric",
                month: "short",
              });
            },
          },
        },
        annotations: {
          xaxis: getAllSundaysBetweenDates(this.chiamate_giorno).map((date) => {
            return {
              x: date.toLocaleDateString("it-IT", {
                weekday: "short",
                day: "numeric",
                month: "short",
              }),
              borderColor: "grey",
              strokeDashArray: 0,
            };
          }),
        },
      };
    },
    seriesChiamateGiorno() {
      return [
        {
          name: "Chiamate",
          data: this.filledSerieChiamateGiorno.map((item) => item[1]),
        },
      ];
    },
    optionsChiamateGiornoRep() {
      return {
        title: {
          text: "Chiamate al giorno reperibilità",
          style: {
            color: "var(--title-color)",
            fontSize: "28px",
            fontWeight: 500,
            fontFamily: "Avenir, Helvetica, Arial, sans-serif;",
          },
        },
        stroke: {
          curve: "smooth",
        },
        chart: {
          type: "area",
          id: "registro-chiamate",
        },
        colors: ["#28a745", "#dc3545"],
        xaxis: {
          type: "datetime",
        },
      };
    },
    seriesChiamateGiornoRep() {
      return [
        {
          name: "Chiamate",
          data: this.chiamate_giorno_rep.map((chiamate) => {
            return {
              x: new Date(chiamate.data).getTime(),
              y: chiamate.chiamate,
            };
          }),
        },
      ];
    },
    optionsChiamateGiornoBase() {
      return {
        title: {
          text: "Chiamate al giorno settimanali",
          style: {
            color: "var(--title-color)",
            fontSize: "28px",
            fontWeight: 500,
            fontFamily: "Avenir, Helvetica, Arial, sans-serif;",
          },
        },
        stroke: {
          curve: "smooth",
        },
        chart: {
          type: "area",
          id: "registro-chiamate",
          // stacked: true,
        },
        colors: ["#28a745", "#dc3545"],
        xaxis: {
          type: "datetime",
          // categories: this.chiamate_giorno.map((hit) =>
          //   new Date(hit.data).toLocaleDateString("it-IT")
          // ),
        },
      };
    },
    seriesChiamateGiornoBase() {
      return [
        {
          name: "Chiamate",
          data: this.chiamate_giorno_base.map((chiamate) => {
            return {
              x: new Date(chiamate.data).getTime(),
              y: chiamate.chiamate,
            };
          }),
        },
      ];
    },
  },
};

//Prende una serie nel formato [[x,y],[x,y],[x,y]] dove x
//è il timestamp di una data e la riempie impostando a 0 i valori
//di x che non sono presenti
function fillSerieEmpyDaysWith0(serie) {
  if (serie.length < 2) return serie;

  //Costruisco una mappa dove la chiave è la data e il valore è il numero di chiamate
  const existingDaysMap = {};
  for (const chiamata of serie) {
    const date = new Date(chiamata[0]);
    existingDaysMap[
      Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())
    ] = chiamata[1];
  }

  const startDate = new Date(serie[0][0]);
  const endDate = new Date(serie[serie.length - 1][0]);
  const utcStartDate = new Date(
    Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())
  );
  const utcEndDate = new Date(
    Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate())
  );
  let currentDate = utcStartDate;
  const newSerie = [];
  while (currentDate <= utcEndDate) {
    //Se c'è un valore per quella data lo inserisco
    if (existingDaysMap[currentDate.getTime()]) {
      newSerie.push([
        currentDate.getTime(),
        existingDaysMap[currentDate.getTime()],
      ]);
    } else {
      newSerie.push([currentDate.getTime(), 0]);
    }
    currentDate = new Date(
      Date.UTC(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + 1
      )
    );
  }
  console.log("newserie", newSerie);

  return newSerie;
}

const generateColors = (data) => {
  return data.map((d, idx) => {
    const date = new Date(d[0]);

    let color =
      date.getDay() == 5 || date.getDay() == 6 ? "#22c55f" : "#ef4544";

    return {
      offset: (idx / data.length) * 100,
      color,
      opacity: 1,
    };
  });
};

function getTrend(arr1, arr2) {
  if (arr1.length != arr2.length)
    throw new Error("La lunghezza dei due array non corrisponde");

  const trend = [];
  const length = arr1.length;

  for (let i = 0; i <= length - 1; i++) {
    const first = arr1[i];
    const second = arr2[i];
    if (second == 0) {
      trend.push(0);
    } else {
      trend.push(first / second);
    }
  }

  return trend;
}

function averageTrend(trend) {
  const average = [...trend];
  let previousPureValue = average[0];
  for (let i = 1; i < average.length; i++) {
    if (average[i] > previousPureValue) {
      previousPureValue = average[i];
      average[i] = average[i] / 2;
    } else if (average[i] < previousPureValue) {
      previousPureValue = average[i];
      average[i] = average[i] * 2;
    } else {
      average[i] = average[i - 1];
    }
  }
  return average;
}

//Returns an array of dates with the sundays between two dates
function getAllSundaysBetweenDates(dates) {
  if (!dates || dates.length < 2) return [];
  const startDate = new Date(dates[0].data);
  const endDate = new Date(dates[dates.length - 1].data);
  console.log(startDate);
  console.log(endDate);
  let sundayArray = [];
  let currentDate = startDate;
  while (currentDate <= endDate) {
    if (currentDate.getDay() == 0) {
      sundayArray.push(currentDate);
    }
    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + 1
    );
  }
  console.log(sundayArray);
  return sundayArray;
}

function lineaRettaCentrale(array) {
  const sum = array.reduce((acc, val) => acc + val, 0);
  const center = sum / array.length;

  const result = array.map(() => center);
  return result;
}

function lineaRettaInclinata(array, start = 0) {
  const n = array.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;

  for (let i = 0; i < n; i++) {
    sumX += i;
    sumY += array[i];
    sumXY += i * array[i];
    sumXX += i * i;
  }

  const m = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  const q = (sumY - m * sumX) / n;

  const result = [];
  for (let i = 0; i < n; i++) {
    const y = m * i + q;
    result.push(y + start);
  }

  return result;
}
</script>
