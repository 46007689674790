import ApiConnector from "./ApiConnector";
let connector = new ApiConnector(process.env.VUE_APP_API_HOST);

export const getAll = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.UtenteApi();
    let opts = {
      selectOptions: { options: options } // SelectOptions | opzioni
    };
    apiInstance.getAllUtentes(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getCount = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.UtenteApi();
    let opts = {
      selectOptions: { options: options } // SelectOptions | opzioni
    };
    apiInstance.countUtentes(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};
