<template>
  <b-navbar toggleable="lg" fixed="top" class="p-2 shadow-sm colored">
    <b-navbar-brand href="#">
      <img src="/logo.png" alt="Logo" width="100" height="39" />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="isLogged">
        <b-nav-item href="#" to="/">Home</b-nav-item>
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>Statistiche</em>
          </template>
          <b-dropdown-item href="#" to="/statistiche/tecniche"
            >Tecniche</b-dropdown-item
          >
          <b-dropdown-item
            href="#"
            to="/statistiche/admin"
            v-if="privilegi['read:stat:admin']"
            >Amministrazione</b-dropdown-item
          >
          <b-dropdown-item href="#" to="/statistiche/centralino"
            >Centralino</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          v-if="privilegi['read:articolo'] || privilegi['read:gruppo']"
          right
        >
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>Gestione</em>
          </template>
          <b-dropdown-item
            href="#"
            to="/articoli"
            v-if="privilegi['read:articolo']"
            >Articoli</b-dropdown-item
          >
          <b-dropdown-item href="#" to="/gruppi" v-if="privilegi['read:gruppo']"
            >Gruppi</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          v-if="
            privilegi['read:cliente'] ||
            privilegi['read:locale'] ||
            privilegi['read:scaduti'] ||
            privilegi['read:insoluti']
          "
          right
        >
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>Clienti</em>
          </template>
          <b-dropdown-item
            href="#"
            to="/clienti"
            v-if="privilegi['read:cliente']"
            >Contratti</b-dropdown-item
          >
          <b-dropdown-item href="#" to="/locali" v-if="privilegi['read:locale']"
            >Locali</b-dropdown-item
          >
          <b-dropdown-item
            href="#"
            to="/scaduti"
            v-if="privilegi['read:scaduti']"
            >Scaduti</b-dropdown-item
          >
          <b-dropdown-item
            href="#"
            to="/insoluti"
            v-if="privilegi['read:insoluti']"
            >Insoluti</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>Documenti</em>
          </template>
          <b-dropdown-item href="#" to="/ordini">Lista</b-dropdown-item>
          <b-dropdown-item
            href="#"
            to="/ordini/create/cliente"
            v-if="privilegi['create:ordine']"
            >Nuovo</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          v-if="
            privilegi['read:rinnovo:annuale'] ||
            privilegi['read:rinnovo:mensile']
          "
          right
        >
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em>Rinnovi</em>
          </template>
          <b-dropdown-item
            href="#"
            to="/rinnovi/annuali"
            v-if="privilegi['read:rinnovo:annuale']"
            >Annuali/Biennali</b-dropdown-item
          >
          <b-dropdown-item
            href="#"
            to="/rinnovi/mensili"
            v-if="privilegi['read:rinnovo:mensile']"
            >Mensili/Trimestrali/Semestrali</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item-dropdown right>
          <template #button-content>
            <em>Comunicazione</em>
          </template>
          <b-dropdown-item href="#" to="/contatti">Contatti</b-dropdown-item>
          <b-dropdown-item href="#" to="/contatti/campagne"
            >Campagne</b-dropdown-item
          >
          <b-dropdown-item href="#" to="/contatti/campagne/new"
            >Nuova Campagna</b-dropdown-item
          >
        </b-nav-item-dropdown>
        <b-nav-item href="#" to="/rt">Registratori RT</b-nav-item>

        <b-nav-item href="https://forms.gle/UQBXGJsd1vLzsQ217" target="_blank"
          >Segnalazioni</b-nav-item
        >
      </b-navbar-nav>
      <!-- Right aligned nav items -->
      <b-navbar-nav class="ms-auto me-3 border rounded">
        <b-nav-item-dropdown left v-if="isLogged">
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <em style="color: white">{{ loginDesc }}</em>
          </template>
          <b-dropdown-item href="#" to="/profile">Profilo</b-dropdown-item>
          <b-dropdown-item href="#" to="/logs">Logs</b-dropdown-item>
          <b-dropdown-item href="#" @click="logout">Esci</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item v-else href="#" @click="login">
          <em style="color: white">{{ loginDesc }}</em>
        </b-nav-item>
      </b-navbar-nav>
      <!-- <b-button v-b-tooltip.hover.left="syncMessage" variant="light"
        ><font-awesome-icon icon="fa-solid fa-rotate"
      /></b-button> -->
      <b-button
        v-if="dark"
        v-b-tooltip.hover.left="'light mode'"
        class="background-secondary me-2"
        style="color: var(--text-color)"
        @click="emitDarkMode(false)"
        ><font-awesome-icon icon="fa-solid fa-moon"
      /></b-button>
      <b-button
        v-else
        v-b-tooltip.hover.left="'dark mode'"
        class="background-secondary me-2"
        style="color: var(--text-color)"
        @click="emitDarkMode(true)"
        ><font-awesome-icon icon="fa-solid fa-sun"
      /></b-button>
      <b-button
        v-b-tooltip.hover.left="'Schermo intero'"
        class="background-secondary"
        style="color: var(--text-color)"
        @click="fullscreen()"
        ><font-awesome-icon icon="fa-solid fa-expand"
      /></b-button>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
  props: ["dark"],
  data() {
    return {
      syncTimestamp: "",
    };
  },
  created() {
    setInterval(this.getNextRinnoviCronTimestamp, 1000);
  },
  methods: {
    fullscreen() {
      const element = document.querySelector("body");
      if (element.requestFullscreen) element.requestFullscreen();
      else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
      else if (element.webkitRequestFullscreen)
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      else if (element.msRequestFullscreen) element.msRequestFullscreen();
    },
    emitDarkMode(status) {
      this.$emit("updatedarkmode", status);
    },
    login() {
      this.$auth.loginWithRedirect();
    },
    logout() {
      this.$auth.logout();
      this.$router.push({ path: "/" });
    },
    getNextRinnoviCronTimestamp() {
      const now = new Date();
      const cronScheduleTime = 10;

      now.setMinutes(
        cronScheduleTime * Math.ceil(now.getMinutes() / cronScheduleTime)
      );
      if (now.getMinutes() >= 60) {
        hour++;
        now.setHours(now.getHours() + 1);
        now.setMinutes(now.minutes() - 60);
      }
      now.setSeconds(0);
      this.syncTimestamp = now;
    },
  },
  computed: {
    privilegi() {
      const privilegi = {
        "read:stat:admin": false,
        "read:articolo": false,
        "read:cliente": false,
        "read:gruppo": false,
        "read:locale": false,
        "read:ordine": false,
        "read:scaduti": false,
        "read:insoluti": false,
        "read:rinnovo:annuale": false,
        "read:rinnovo:mensile": false,
        "create:ordine": false,
      };
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        Object.keys(privilegi).forEach((privilegio) => {
          privilegi[privilegio] =
            this.$auth.user["https://rca-api/permissions"].indexOf(
              privilegio
            ) != -1;
        });
      }
      return privilegi;
    },
    canRead() {
      if (this.$auth.user && this.$auth.user["https://rca-api/roles"]) {
        return (
          this.$auth.user["https://rca-api/roles"].roles.indexOf(
            "Administrator"
          ) != -1
        );
      }
      return false;
    },
    loginDesc() {
      return this.isLogged ? this.$auth.user.nickname : "Accedi";
    },
    isLogged() {
      return this.$auth.isAuthenticated;
    },
    syncMessage() {
      return `Prossima sincronizzazione: ${this.syncTimestamp}`;
    },
  },
};
</script>

<style>
.colored {
  background: linear-gradient(
    45deg,
    var(--secondary-color) 70%,
    var(--primary-color) 70% 100%
  );
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

body:fullscreen {
  overflow: scroll !important;
}
body:-ms-fullscreen {
  overflow: scroll !important;
}
body:-webkit-full-screen {
  overflow: scroll !important;
}
body:-moz-full-screen {
  overflow: scroll !important;
}
</style>
