<template>
  <div class="m-3">
    <h1>Creazione Campagna</h1>
    <div>
      <div v-if="step == 1" class="card p-4">
        <h4>1. Selezione del target</h4>

        <label>Cerca per:</label>
        <v-select
          label="descrizione"
          id="type"
          :options="types"
          :reduce="(item) => item.id"
          v-model="type"
          style="min-width: 100px"
        ></v-select>
        <label class="mt-2"
          >{{ types.find((tipo) => tipo.id == type).descrizione }}:</label
        >
        <v-select
          v-if="type == 1"
          label="title"
          id="articoli"
          :reduce="(item) => item.id_trace"
          v-model="articolo"
          :options="articoliSelect"
        ></v-select>
        <v-select
          v-if="type == 2"
          label="descrizione"
          id="group"
          :reduce="(item) => item.id_trace"
          v-model="gruppo"
          :options="gruppi"
        ></v-select>
        <v-select
          v-if="type == 3"
          label="descrizione"
          v-model="tipo"
          :reduce="(item) => item.id"
          id="tipi"
          :options="tipi"
        ></v-select>
        <v-select
          v-if="type == 4"
          label="descrizione"
          v-model="macroTipo"
          :reduce="(item) => item.id"
          id="macrotipi"
          :options="macroTipi"
        ></v-select>
        <v-select
          v-if="type == 5"
          label="descrizione"
          v-model="reparto"
          :reduce="(item) => item.id"
          id="reparti"
          :options="reparti"
        ></v-select>
        <b-button variant="primary" class="mt-4" @click="cercaClienti"
          >Cerca</b-button
        >
      </div>
      <div v-if="step == 2" class="card p-4">
        <div class="d-flex justify-content-between">
          <h4>2. Selezione destinazioni</h4>
          <b-button @click="showModalLocali = true" variant="secondary"
            >{{ clienti.length }} Locali corrispondenti</b-button
          >
        </div>

        <label>Profilo contatto:</label>
        <v-select
          label="descrizione"
          id="profili"
          :reduce="(item) => item.id"
          v-model="profilo"
          :options="profiliContatto"
        ></v-select>
        <label>Destinazione:</label>
        <v-select
          label="descrizione"
          id="destinazioni"
          :reduce="(item) => item.id"
          v-model="destinazione"
          :options="destinazioni"
        ></v-select>
        <div class="d-flex justify-content-between mt-4">
          <b-button
            @click="
              () => {
                step = 1;
              }
            "
            >Indietro</b-button
          >
          <b-button variant="primary" @click="cercaContatti"
            >Componi mail</b-button
          >
        </div>
      </div>
      <div v-if="step == 3" class="card p-4">
        <div class="row">
          <div class="col">
            <div class="d-flex justify-content-between">
              <h4>3. Composizione Messaggio</h4>
              <b-button @click="showModalContatti = true" variant="secondary"
                >{{ contattiTabella.length }} Contatti corrispondenti</b-button
              >
            </div>
            <b-form @submit="onFormSubmit">
              <b-form-group
                id="input-group-oggetto"
                label="Oggetto:"
                label-for="oggetto-mail"
              >
                <b-form-input
                  id="oggetto-mail"
                  v-model="mailForm.oggetto"
                  required
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-contenuto"
                label="Contenuto:"
                label-for="contenuto-mail"
              >
                <b-form-textarea
                  id="contenuto-mail"
                  rows="10"
                  v-model="mailForm.contenuto"
                  required
                ></b-form-textarea>
                <!-- <v-md-editor
              lang="it"
              v-model="mailForm.contenuto"
              height="400px"
            ></v-md-editor> -->
              </b-form-group>
            </b-form>
            <div class="d-flex justify-content-between mt-4">
              <b-button
                @click="
                  () => {
                    step = 2;
                  }
                "
                >Indietro</b-button
              >

              <b-button v-b-modal.preview-modal>Anteprima</b-button>
              <b-button variant="primary" @click="openSendModal"
                >Invia</b-button
              >
            </div>
          </div>
          <div class="col">
            <iframe :srcdoc="mailForm.contenuto" width="100%" height="100%">
              <!-- <v-md-preview-html :html="mailForm.contenuto"></v-md-preview-html> -->
            </iframe>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      id="modale-invio"
      v-model="modaleInvio.aperto"
      hide-footer
      hide-header
      centered
    >
      <b-form
        @submit="
          (e) => {
            e.preventDefault();
            send();
          }
        "
      >
        <b-form-group
          id="input-group-oggetto"
          label="Nome campagna:"
          label-for="nome-campagna"
        >
          <b-form-input
            id="nome-campagna"
            v-model="modaleInvio.nomeCampagna"
            required
          ></b-form-input>
          <small
            >Scegli un nome significativo, sarà il riferimento per la
            campagna</small
          >
        </b-form-group>
        <div class="d-flex flex-row-reverse mt-2">
          <b-button type="submit" variant="primary">Invia</b-button>
        </div>
      </b-form>
    </b-modal>

    <b-modal
      v-model="showModalLocali"
      id="customers-modal"
      key="customers-modal"
      size="xl"
      title="Locali corrispondenti"
    >
      <div v-if="clienti.length > 0" class="mt-2">
        <b-select
          class="btn btn-secondary btn-sm"
          :options="[
            { value: 10, text: '10' },
            { value: 20, text: '20' },
          ]"
          v-model="perPage"
        ></b-select>
        Risultati per pagina
        <b-table
          stacked="lg"
          striped
          hover
          :items="clienti"
          :fields="fields"
          :currentPage="currentPage"
          :perPage="perPage"
        >
          <template #cell(_cliente)="data">
            {{ data.value.ragsoc }}
          </template>
          <template #cell(_locale)="data">
            {{ data.value.nome }}
          </template>
          <template #cell(_articolo)="data">
            {{ data.value.title }} ({{ data.value.id_trace }})
          </template>
          <template #cell(_gruppo)="data">
            {{ data.value.descrizione }}
          </template>
          <template #cell(_tipo)="data">
            {{ data.value.descrizione }}
          </template>
          <template #cell(_macroTipo)="data">
            {{ data.value.descrizione }}
          </template>
          <template #cell(_reparto)="data">
            {{ data.value.descrizione }}
          </template>
        </b-table>
        <div class="d-flex">
          <b-pagination
            class="customPagination"
            v-model="currentPage"
            :total-rows="clienti.length"
            :per-page="perPage"
          ></b-pagination>
          <b class="mt-auto mb-auto ms-3" style="color: var(--primary-color)">
            {{ clienti.length }} Risultati
          </b>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="showModalContatti"
      id="contatti-modal"
      key="contatti-modal"
      size="xl"
      title="Contatti corrispondenti"
    >
      <div v-if="contattiTabella.length > 0" class="mt-2">
        <b-select
          class="btn btn-secondary btn-sm"
          :options="[
            { value: 10, text: '10' },
            { value: 20, text: '20' },
          ]"
          v-model="perPage"
        ></b-select>
        Risultati per pagina
        <b-table
          stacked="xl"
          striped
          hover
          :items="contattiTabella"
          :currentPage="currentPage"
          :perPage="perPage"
        >
          <template #cell(articolo)="data">
            <b-button
              class="w-100"
              @click="() => openDetails(data.value, 'articolo')"
              >{{ data.value.title }}</b-button
            >
          </template>
          <template #cell(cliente)="data">
            <b-button
              class="w-100"
              @click="() => openDetails(data.value, 'cliente')"
              >{{ data.value.ragsoc }}</b-button
            >
          </template>
          <template #cell(locale)="data">
            <b-button
              class="w-100"
              @click="() => openDetails(data.value, 'locale')"
              >{{ data.value.nome }}</b-button
            >
          </template>
          <!-- <template #cell(_cliente)="data">
            {{ data.value.ragsoc }}
          </template>
          <template #cell(_locale)="data">
            {{ data.value.nome }}
          </template>
          <template #cell(_articolo)="data">
            {{ data.value.title }} ({{ data.value.id_trace }})
          </template>
          <template #cell(_gruppo)="data">
            {{ data.value.descrizione }}
          </template>
          <template #cell(_tipo)="data">
            {{ data.value.descrizione }}
          </template>
          <template #cell(_macroTipo)="data">
            {{ data.value.descrizione }}
          </template>
          <template #cell(_reparto)="data">
            {{ data.value.descrizione }}
          </template> -->
        </b-table>
        <div class="d-flex">
          <b-pagination
            class="customPagination"
            v-model="currentPage"
            :total-rows="contattiTabella.length"
            :per-page="perPage"
          ></b-pagination>
          <b class="mt-auto mb-auto ms-3" style="color: var(--primary-color)">
            {{ contattiTabella.length }} Risultati
          </b>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="detailsOpen" title="Dettagli">
      <table>
        <tr>
          <th class="w-100">Riferimento</th>
          <th class="w-100">Valore</th>
        </tr>
        <tr v-for="entry in Object.entries(detailsData)">
          <td>[{{ detailsSection + "." + entry[0] }}]</td>
          <td>{{ entry[1] }}</td>
        </tr>
      </table>
      <!-- <b-table stacked :items="[detailsData]"> </b-table> -->
    </b-modal>
    <b-modal title="Anteprima" id="preview-modal" size="lg">
      <v-md-preview-html :html="mailForm.contenuto"></v-md-preview-html>
    </b-modal>
  </div>
</template>

<script>
import * as profiloContattoRepo from "../services/ProfiloContatto";
import * as clienteRepo from "../services/Cliente";
import * as articoliRepo from "../services/Articolo";
import * as campagneRepo from "../services/Campagne";

export default {
  name: "CreazioneCampagna",
  components: {
    //   PaginatedTable,
  },
  data() {
    return {
      showModalLocali: false,
      showModalContatti: false,
      modaleInvio: {
        aperto: false,
        nomeCampagna: null,
      },
      step: 1,
      clienti: [],
      perPage: 10,
      currentPage: 1,
      fields: [
        "_cliente",
        "_locale",
        "_articolo",
        "_gruppo",
        "_tipo",
        "_macroTipo",
        "_reparto",
        "date_import_trace",
      ],
      type: 1,
      types: [
        {
          id: 1,
          descrizione: "Articolo",
        },
        {
          id: 2,
          descrizione: "Gruppo",
        },
        {
          id: 3,
          descrizione: "Tipo",
        },
        {
          id: 4,
          descrizione: "Macro Tipo",
        },
        {
          id: 5,
          descrizione: "Reparto",
        },
      ],
      tipi: [],
      gruppi: [],
      macroTipi: [],
      articolo: null,
      gruppo: null,
      tipo: null,
      macroTipo: null,
      reparto: null,
      profiliContatto: [],
      profilo: 1,
      destinazioni: [
        { id: 1, descrizione: "Mail" },
        { id: 2, descrizione: "SMS" },
      ],
      destinazione: 1,
      contatti: [],
      detailsSection: null,
      detailsOpen: false,
      detailsData: [],
      mailForm: {
        oggetto: "Comunicazione da RCA SRL",
        contenuto:
          "Gentile [[cliente.ragsoc]],\n\nRiceve questa mail perchè il locale [[locale.nome]] risulta utilizzare l'articolo [[articolo.title]]",
      },
    };
  },
  async created() {
    this.$loading(true);
    const accessToken = await this.$auth.getTokenSilently();

    const promises = [
      this.$store.dispatch("caricaArticoli", accessToken),
      this.$store.dispatch("caricaClienti", accessToken),
      this.$store.dispatch("caricaLocali", accessToken),
      this.getTipi(accessToken),
      this.getGruppi(accessToken),
      this.getMacroTipi(accessToken),
      this.getReparti(accessToken),
      this.getProfiliContatto(accessToken),
    ];
    await Promise.all(promises);
    this.$loading(false);
  },
  methods: {
    async openSendModal() {
      (this.modaleInvio.nomeCampagna = null), (this.modaleInvio.aperto = true);
    },
    async send() {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      await campagneRepo.avviaCampagnaMail(
        accessToken,
        this.modaleInvio.nomeCampagna,
        this.searchQuery,
        this.profilo,
        this.mailForm.oggetto,
        this.mailForm.contenuto
      );
      this.$loading(false);
      this.$toast.open("Campagna avviata con successo!");
      this.$router.push({ name: "listaCampagne" });
    },
    onFormSubmit(data) {
      console.log(data);
    },
    async cercaClienti() {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      const response = await clienteRepo.search(accessToken, this.searchQuery);
      if (response.data) {
        this.mapData(response.data);
        this.clienti = response.data;
      }

      this.step = 2;
      this.$loading(false);
    },
    async cercaContatti() {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();

      const response = await clienteRepo.searchContatti(
        accessToken,
        this.searchQuery,
        this.profilo
      );
      if (response.data) {
        this.mapData(response.data, true);
        this.contatti = response.data;
      }
      this.step = 3;

      this.$loading(false);
    },
    //Collega cliente, locale, articolo, gruppo, ecc. in base al'id
    async mapData(data, override = false) {
      data.forEach((row) => {
        row[override ? "locale" : "_locale"] = this.storeLocali[row.cliente]
          ? this.storeLocali[row.cliente][row.locale]
          : null;

        row[override ? "cliente" : "_cliente"] = this.storeClienti[row.cliente];

        row[override ? "articolo" : "_articolo"] = this.articoliSelect.find(
          (articolo) => articolo.id_trace == row.articolo
        );
        if (row.gruppo)
          row["_gruppo"] = this.gruppi.find(
            (gruppo) => gruppo.id_trace == row.gruppo
          );
        if (row.tipo_articolo)
          row["_tipo"] = this.tipi.find((tipo) => tipo.id == row.tipo_articolo);
        if (row.macro_tipo_articolo)
          row["_macroTipo"] = this.macroTipi.find(
            (macroTipo) => macroTipo.id == row.macro_tipo_articolo
          );
        if (row.reparto)
          row["_reparto"] = this.reparti.find(
            (reparto) => reparto.id == row.reparto
          );
      });
    },
    async getTipi(accessToken) {
      try {
        const response = await articoliRepo.tipi(accessToken);
        if (response.data) {
          this.tipi = response.data;
        }
      } catch (e) {
        this.$toast.error("Errore caricamento tipi");
      }
    },
    async getGruppi(accessToken) {
      try {
        const response = await articoliRepo.gruppi(accessToken);
        console.log(response);
        if (response.data) {
          this.gruppi = response.data;
        }
      } catch (e) {
        this.$toast.error("Errore caricamento gruppi");
      }
    },
    async getMacroTipi(accessToken) {
      try {
        const response = await articoliRepo.macroTipi(accessToken);
        if (response.data) {
          this.macroTipi = response.data;
        }
      } catch (e) {
        this.$toast.error("Errore caricamento macro tipi");
      }
    },
    async getReparti(accessToken) {
      try {
        const response = await articoliRepo.reparti(accessToken);
        if (response.data) {
          this.reparti = response.data;
        }
      } catch (e) {
        this.$toast.error("Errore caricamento reparti");
      }
    },
    async getProfiliContatto(accessToken) {
      try {
        const response = await profiloContattoRepo.getAll(accessToken, {});
        this.profiliContatto = response;
      } catch (e) {
        this.$toast.error("Errore caricamento profili contatto");
      }
    },
    openDetails(data, section) {
      this.detailsSection = section;
      this.detailsData = data;
      this.detailsOpen = true;
    },
  },
  computed: {
    articoliSelect() {
      return this.$store.state.articoli;
    },
    storeClienti() {
      return this.$store.state.clienti_search;
    },
    storeLocali() {
      return this.$store.state.locali_search;
    },
    searchQuery() {
      let articleQuery = {};
      switch (this.type) {
        case 1:
          articleQuery = {
            where: { articolo: this.articolo },
          };
          break;
        case 2:
          articleQuery = {
            where: { gruppo: this.gruppo },
          };
          break;
        case 3:
          articleQuery = {
            where: { tipo_articolo: this.tipo },
          };
          break;
        case 4:
          articleQuery = {
            where: { macro_tipo_articolo: this.macroTipo },
          };
          break;
        case 5:
          articleQuery = {
            where: { reparto: this.reparto },
          };
          break;
        default:
          console.error("TIPO NON DEFINITO");
      }
      return articleQuery;
    },
    contattiTabella() {
      return this.contatti.filter((contatto) => {
        return (
          (this.destinazione == 1 && contatto.mail) ||
          (this.destinazione == 2 && contatto.telefono)
        );
      });
    },
    //   query() {
    //     let query = {
    //       include: ["cliente_cliente", "profilo_locale_profilo_locale"],
    //     };
    //     if (this.search.trim() != "") {
    //       query.where = {
    //         and: {
    //           or: {
    //             id: {
    //               substring: this.search,
    //             },
    //             nome: {
    //               substring: this.search,
    //             },
    //             via: {
    //               substring: this.search,
    //             },
    //             citta: {
    //               substring: this.search,
    //             },
    //             cap: {
    //               substring: this.search,
    //             },
    //             prov: {
    //               substring: this.search,
    //             },
    //             piva: {
    //               substring: this.search,
    //             },
    //           },
    //         },
    //       };
    //     }
    //     if (this.cliente_search) {
    //       query.where = {
    //         and: {
    //           ...query.where,
    //           cliente: this.cliente_search,
    //         },
    //       };
    //     }
    //     if (this.profilo_search) {
    //       query.where = {
    //         and: {
    //           ...query.where,
    //           profilo_locale: this.profilo_search,
    //         },
    //       };
    //     }
    //     return query;
    //   },
  },
};
</script>
