<template>
  <div class="m-3">
    <!-- MODALE NOTE AMMINISTRATIVE -->
    <b-modal
      id="adminNoteModal"
      @ok="
        modificaNoteAmministrative(
          modaleNoteAmministrative.order,
          modaleNoteAmministrative.nota
        )
      "
    >
      <template #modal-title> Note amministrative </template>
      <textarea
        class="form-control"
        v-model="modaleNoteAmministrative.nota"
      ></textarea>
      <template #modal-cancel> Chiudi </template>
      <template #modal-ok>
        <span>Conferma</span>
      </template>
    </b-modal>
    <!-- FINE MODALE NOTE AMMINISTRATIVE -->
    <!-- MODALE NOTE TECNICHE -->
    <b-modal
      id="tecNoteModal"
      @ok="
        modificaNoteTecniche(modaleNoteTecniche.order, modaleNoteTecniche.nota)
      "
    >
      <template #modal-title> Note tecniche </template>
      <textarea
        class="form-control"
        v-model="modaleNoteTecniche.nota"
      ></textarea>
      <template #modal-cancel> Chiudi </template>
      <template #modal-ok>
        <span @focus="alert('focus')">Conferma</span>
      </template>
    </b-modal>
    <!-- FINE MODALE NOTE TECNICHE -->
    <h1>Documenti</h1>
    <b-modal id="modal-1" size="lg" title="Matricole e Attivazioni">
      <SerialActivationTable
        :order_id="modaleMatricole.order_id"
      ></SerialActivationTable>
    </b-modal>

    <!-- TOGGLER FILTRI -->
    <button
      class="btn btn-light btn-sm mb-2 background-secondary"
      type="button"
      style="border: 0px"
      data-bs-toggle="collapse"
      data-bs-target="#filtersCollapse"
      aria-expanded="false"
      aria-controls="filtersCollapse"
      v-on:click="form.toggleStatus = !form.toggleStatus"
      v-if="!isMobile"
    >
      <font-awesome-icon
        v-if="form.toggleStatus"
        icon="chevron-up"
        style="color: var(--primary-color)"
        class="fa-lg"
      >
      </font-awesome-icon>

      <font-awesome-icon
        v-else
        icon="chevron-down"
        style="color: var(--primary-color)"
        class="fa-lg"
      >
      </font-awesome-icon>
    </button>
    <b-button
      v-else
      style="
        position: fixed;
        bottom: 20px;
        right: 20px;
        z-index: 1;
        border-radius: 100%;
      "
      class="background-secondary"
      v-on:click="form.toggleStatus = !form.toggleStatus"
      variant="success"
      size="lg"
    >
      <font-awesome-icon icon="fa-solid fa-list" style="margin-top: 7px" />
    </b-button>
    <!-- FINE TOGGLER FILTRI -->

    <!-- COLLAPSE FILTRI -->
    <component
      :is="isMobile ? 'b-sidebar' : 'b-collapse'"
      :title="isMobile ? 'Filtri' : ''"
      :class="{ 'p-3': !isMobile }"
      class="background-secondary card"
      v-model="form.toggleStatus"
      id="filtersCollapse"
      data-v-step="1"
    >
      <!-- FILTRI -->
      <div id="filters" class="row m-2 m-md-0">
        <!-- FILTRI PER STATO -->
        <div
          class="container col-md-7"
          style="align-self: center"
          data-v-step="2"
        >
          <div class="row row-cols-1 row-cols-md-2 row-cols-lg-3">
            <div class="col mb-2">
              <label class="switch">
                <input
                  type="checkbox"
                  v-on:click="toggleAllStatus()"
                  v-model="form.all_status"
                />
                <span class="slider round"></span>
              </label>
              <label class="ps-2">TUTTI</label>
            </div>
            <div class="col mb-2">
              <label class="switch">
                <input
                  type="checkbox"
                  :checked="form.activeStates.includes(5)"
                  v-on:click="toggleStatus(5)"
                />
                <span class="slider round"></span>
              </label>
              <label class="ps-2">IMPEGNI</label>
            </div>

            <div class="col mb-2">
              <label class="switch">
                <input
                  type="checkbox"
                  :checked="form.activeStates.includes(1)"
                  v-on:click="toggleStatus(1)"
                />
                <span class="slider round"></span>
              </label>
              <label class="ps-2">ARCHIVIATI</label>
            </div>

            <div class="col mb-2">
              <label class="switch">
                <input
                  type="checkbox"
                  :checked="form.activeStates.includes(2)"
                  v-on:click="toggleStatus(2)"
                />
                <span class="slider round"></span>
              </label>
              <label class="ps-2">PREVENTIVO</label>
            </div>
            <div class="col mb-2">
              <label class="switch">
                <input
                  type="checkbox"
                  :checked="form.activeStates.includes(6)"
                  v-on:click="toggleStatus(6)"
                />
                <span class="slider round"></span>
              </label>
              <label class="ps-2">LAVORAZIONE</label>
            </div>
            <div class="col mb-2">
              <label class="switch">
                <input type="checkbox" v-model="form.showDeleted" />
                <span class="slider round"></span>
              </label>
              <label class="ps-2">CANCELLATI</label>
            </div>
            <div class="col mb-2">
              <label class="switch">
                <input
                  type="checkbox"
                  :checked="form.activeStates.includes(3)"
                  v-on:click="toggleStatus(3)"
                />
                <span class="slider round"></span>
              </label>
              <label class="ps-2">ORDINE</label>
            </div>
            <div class="col mb-2">
              <label class="switch">
                <input
                  type="checkbox"
                  :checked="form.activeStates.includes(7)"
                  v-on:click="toggleStatus(7)"
                />
                <span class="slider round"></span>
              </label>
              <label class="ps-2">EVASI</label>
            </div>
            <div class="col mb-2">
              <label class="switch">
                <input type="checkbox" v-model="form.showBilled" />
                <span class="slider round"></span>
              </label>
              <label class="ps-2">FATTURATI</label>
            </div>
          </div>
        </div>
        <!-- FINE FILTRI PER STATO -->

        <!-- ALTRI FILTRI -->
        <div class="container col-md-5" data-v-step="3">
          <div class="row row-cols-1 row-cols-md-2">
            <!-- FILTRO PER UTENTE -->
            <div class="col mb-2">
              <div class="form-floating">
                <UserSelect
                  :users="users"
                  identifier="user-select"
                  id="form-user"
                  :active="parseInt(form.utente)"
                  v-model="form.utente"
                ></UserSelect>
                <label for="form-user">Utente</label>
              </div>
            </div>
            <!-- FINE FILTRO PER UTENTE -->
            <!-- FILTRO PER TECNICO -->
            <div class="col mb-2">
              <div class="form-floating">
                <UserSelect
                  :users="users"
                  identifier="tecnician-select"
                  id="form-tecnician"
                  :active="parseInt(form.tecnico)"
                  v-model="form.tecnico"
                ></UserSelect>
                <label for="form-tecnician">Tecnico</label>
              </div>
            </div>
            <!-- FINE FILTRO PER TECNICO -->
            <!-- FILTRO PER RAGIONE SOCIALE -->
            <div class="col mb-2">
              <div class="form-floating">
                <input
                  type="string"
                  class="form-control"
                  id="form-ragsoc"
                  v-model="form.ragsoc"
                />
                <label for="form-ragsoc">Ragione sociale</label>
              </div>
            </div>
            <!-- FINE FILTRO PER RAGIONE SOCIALE -->
            <!-- FILTRO PER NUMERO ORDINE -->
            <div class="col mb-2">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="form-order"
                  v-model="form.ordine"
                />
                <label for="form-order">ID Ordine</label>
              </div>
            </div>
            <!-- FINE FILTRO PER NUMERO ORDINE -->
          </div>
        </div>
        <!-- FINE ALTRI FILTRI -->
      </div>
    </component>
    <!-- FINE COLLAPSE FILTRI -->
    <!-- IMPAGINAZIONE -->
    <!-- <div class="d-flex flex-column flex-md-row">
      <b-pagination
        v-model="paginaAttiva"
        :total-rows="countOrdini"
        per-page="10"
        aria-controls="my-table"
        class="customPagination"
        first-number
        last-number
      ></b-pagination>
      <ul>
        <b-button
           class="ms-2"
          style="margin-top: 10px; background-color: var(--primary-color)"
          >{{ countOrdini }} ordini trovati</b-button
        >
      </ul>
    </div> -->

    <!-- FINE IMPAGINAZIONE -->
    <div style="width: 100%; text-align: center;">
      <img v-if="paginaOrdini && paginaOrdini.length == 0" src="/john_not_found.gif" ></img>
    </div>
    <table
      class="table table-hover text-start collapsable-table mb-2"
      style="
        border-collapse: separate;
        border-spacing: 1px 20px;
        color: var(--text-color);
      "
    >
      <thead class="background-secondary">
        <tr class="d-none d-md-table-row">
          <th scope="col" data-v-step="4">Informazioni amministrative</th>
          <th scope="col" data-v-step="5">Cliente e destinazione</th>
          <th scope="col" data-v-step="6">Info. tecniche</th>
          <th scope="col" data-v-step="7">Azioni</th>
        </tr>
      </thead>
      <tbody class="collapsable-tbody">
        <tr
          is="OrderRow"
          v-for="ordine in paginaOrdini"
          :key="ordine.id"
          :name="ordine.id"
          :status="states"
          :users="users"
          :ordine="ordine"
          style="color: var(--text-color)"
          class="mb-4 background-secondary"
          v-on:showSerialActivation="updateSerialActivationID"
          v-on:showNoteAmministrative="configuramodaleNoteAmministrative"
          v-on:showNoteTecniche="configuraModaleNoteTecniche"
          v-on:edit-status="() => loadPage()"
          v-on:refresh="() => loadPage()"
        ></tr>
      </tbody>
    </table>

    <!-- IMPAGINAZIONE -->
    <div class="d-flex flex-column flex-md-row">
      <b-pagination
        v-model="paginaAttiva"
        :total-rows="countOrdini"
        per-page="10"
        aria-controls="my-table"
        class="customPagination"
      ></b-pagination>
      <ul>
        <b-button
          class="ms-2"
          style="margin-top: 10px; background-color: var(--primary-color)"
          >{{ countOrdini }} ordini trovati</b-button
        >
      </ul>
    </div>

    <!-- FINE IMPAGINAZIONE -->
    <OrderListTour></OrderListTour>
  </div>
</template>

<script>
import * as ordineRepo from "../services/Ordine";
import * as utenteRepo from "../services/Utente";
import * as clienteRepo from "../services/Cliente";

import UserSelect from "@/components/common/UserSelect.vue";
import OrderRow from "@/components/ordini/OrderRow.vue";
import SerialActivationTable from "@/components/common/SerialActivationTable.vue";
import OrderListTour from "@/components/tours/OrderListTour.vue";

export default {
  components: {
    UserSelect,
    OrderRow,
    SerialActivationTable,
    OrderListTour,
  },
  name: "Api",
  data() {
    return {
      windowWidth: 0,
      steps: [
        {
          target: '[data-v-step="1"]',
          content: "In questa sezione è possibile filtrare i risultati",
          params: {
            placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
        {
          target: '[data-v-step="2"]',
          content:
            "Tramite gli switch è possibile selezionare o deselezionare gli stati",
          params: {
            placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
        {
          target: '[data-v-step="3"]',
          content:
            "Ulteriori filtri di ricerca sono utente, tecnico, ragione sociale e id ordine",
          params: {
            placement: "top", // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          },
        },
      ],
      modaleNoteAmministrative: {
        nota: "",
        order: 0,
      },
      modaleNoteTecniche: {
        nota: "",
        order: 0,
      },
      ordini: [],
      countOrdini: 0,
      pagine: 2,
      paginaAttiva: 1,
      form: {
        activeStates: [],
        showDeleted: false,
        showBilled: false,
        toggleStatus: true,
        ragsoc: "",
        ordine: "",
        utente: 0,
        tecnico: 0,
        all_status: false,
      },
      modaleMatricole: {
        order_id: 0,
      },
      states: [
        { id: 1, desc: "ARCHIVIATO", color: "#BEBEBE" },
        { id: 2, desc: "PREVENTIVO", color: "#FF6182" },
        { id: 3, desc: "ORDINE", color: "#FFCD56" },
        { id: 5, desc: "IMPEGNO", color: "#AA40FF" },
        { id: 6, desc: "LAVORAZIONE", color: "#36A2EB" },
        { id: 7, desc: "EVASO", color: "#0EDD3A" },
      ],
      users: [],
      paginaOrdini: null,
    };
  },
  async created() {
    //Inizializzo windowWidth
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
    //Se l'utente ha delle preferenze e le preferenze non sono state modificate
    //le carico su vuex
    if (!this.$store.state.preferences.forms.ordini_modificato) {
      if (this.$auth.user["https://rca-api/user_metadata"].preferenze.ordini) {
        await this.$store.dispatch(
          "modificaPreferenzeFormOrdine",
          this.$auth.user["https://rca-api/user_metadata"].preferenze.ordini
        );
      }
    }

    //Scarico le preferenze salvate su vuex
    if (this.$store.state.preferences.forms.ordini) {
      //Se ci sono preferenze salvate nell'utente le aggiungo al form
      if (this.$store.state.preferences.forms.ordini)
        this.form = this.$store.state.preferences.forms.ordini;
    }

    try {
      this.loadPage();
      const accessToken = await this.$auth.getTokenSilently();
      this.users = await utenteRepo.getAll(accessToken, {});
    } catch (e) {
      console.error(
        `Error: the server responded with '${e.response.status}: ${e.response.statusText}'`
      );
      this.users = [];
    }

    //Nascondi sidebar filtri automaticamente all'apertura
    // if (this.isMobile) this.form.toggleStatus = false;
  },
  methods: {
    async loadPage(page = this.paginaAttiva) {
      this.$loading(true);
      try {
        const accessToken = await this.$auth.getTokenSilently();
        let ordini = await ordineRepo.getPage(
          accessToken,
          this.paginaAttiva,
          this.filtersRequestJSON
        );
        let count = await ordineRepo.getCount(
          accessToken,
          this.filtersRequestJSON
        );

        this.paginaAttiva = page;
        this.paginaOrdini = ordini;
        this.countOrdini = count.count;
        this.pagine = Math.ceil(count.count / 10);
      } catch (e) {
        console.error(
          `Error: the server responded with '${e.response.status}: ${e.response.statusText}'`
        );
        this.paginaOrdini = [];
        this.count = 0;
      }
      this.$loading(false);
    },
    toggleStatus(id) {
      if (this.form.activeStates.includes(id)) {
        let index = this.form.activeStates.indexOf(id);
        this.form.activeStates.splice(index, 1);
      } else {
        this.form.activeStates.push(id);
      }
    },
    toggleAllStatus() {
      if (!this.form.all_status) {
        this.form.activeStates = [1, 2, 3, 5, 6, 7];
      } else {
        this.form.activeStates = [];
      }
    },
    updateSerialActivationID(order_id) {
      this.modaleMatricole.order_id = order_id;
    },
    configuramodaleNoteAmministrative(nota, ordine) {
      this.modaleNoteAmministrative.nota = nota;
      this.modaleNoteAmministrative.order = ordine;
    },
    configuraModaleNoteTecniche(nota, ordine) {
      this.modaleNoteTecniche.nota = nota;
      this.modaleNoteTecniche.order = ordine;
    },
    async modificaNoteAmministrative(ordine, note) {
      //Se la nota è vuota la trasformo in null
      if (note.trim() == "") note = null;
      //Modifico db
      const accessToken = await this.$auth.getTokenSilently();
      await ordineRepo.update(accessToken, ordine, {
        note_amministrative: note,
      });
      //Modifico dati locali
      for (let i = 0; i < this.paginaOrdini.length; i++)
        if (this.paginaOrdini[i].id == ordine) {
          this.paginaOrdini[i].note_amministrative = note;
          break;
        }
    },
    async modificaNoteTecniche(ordine, note) {
      //Se la nota è vuota la trasformo in null
      if (note.trim() == "") note = null;
      //Modifico db
      try {
        const accessToken = await this.$auth.getTokenSilently();
        await ordineRepo.update(accessToken, ordine, {
          note_tecniche: note,
        });
      } catch (e) {
        this.$toast.error(e.message);
        return;
      }
      //Modifico dati locali
      for (let i = 0; i < this.paginaOrdini.length; i++)
        if (this.paginaOrdini[i].id == ordine)
          this.paginaOrdini[i].note_tecniche = note;

      this.$toast.open("Note modificate correttamente");
    },
  },
  watch: {
    form: {
      async handler(val) {
        await this.$store.dispatch("modificaPreferenzeFormOrdine", val, true);
        this.loadPage(1);
      },
      deep: true,
    },
    paginaAttiva(newPage, oldPage) {
      if (newPage != oldPage) this.loadPage();
    },
  },
  computed: {
    isMobile() {
      return this.windowWidth < 767;
    },
    pagination() {
      let pages = [];
      for (let i = this.paginaAttiva - 2; i < this.paginaAttiva + 3; i++) {
        //escludo la prima e l'ultima pagina, che vengono sempre mostrate a parte
        if (i > 1 && i < this.pagine) {
          pages.push(i);
        }
      }
      return pages;
    },
    puntiniIniziali() {
      return this.pagination[0] != 2 && this.pagine != 1 ? true : false;
    },
    puntiniFinali() {
      return this.pagination[this.pagination.length - 1] != this.pagine - 1 &&
        this.pagine != 1
        ? true
        : false;
    },
    filtersRequestJSON() {
      console.log("QUI");
      //TODO: sistemare filtri archiviati e cancellati
      //archiviato e cancellato => stato = 1
      //archiviato => stato = 1 and cancellato = 0
      //cancellato => stato = 1 and cancellato =
      let json = {
        include: [
          "cliente_cliente",
          "locale_locale",
          "utente_utente",
          "tecnico_utente",
          {
            model: "mail_inviate",
            as: "mail_inviates",
            separate: true,
            include: ["user", "tsmtp_reports"],
          },
        ],
        where: {
          stato: {
            in: this.form.activeStates,
          },
          cancellato: false,
        },
        order: [["id", "DESC"]],
        subQuery: false,
      };
      if (this.form.showDeleted) delete json.where.cancellato;
      if (this.form.utente != 0) json.where["utente"] = this.form.utente;
      if (this.form.tecnico != 0) json.where["tecnico"] = this.form.tecnico;
      if (this.form.ragsoc != "")
        json.where["$cliente_cliente.ragsoc$"] = {
          substring: this.form.ragsoc,
        };
      if (this.form.ordine != "") {
        if (!isNaN(parseInt(this.form.ordine)))
          json.where["id"] = parseInt(this.form.ordine);
      }
      if (!this.form.showBilled) json.where["data_fatturazione"] = { eq: null };
      // if(!this.form.showDeleted)
      // json.where["deleted"] = {"eq":0}
      console.log(json);
      return json;
    },
  },
};
</script>

<style scoped>
/* SWITCH */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--primary-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
