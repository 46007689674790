<template>
  <div class="d-flex flex-column flex-md-row">
    <div class="ms-2 me-2" v-for="item of count" :key="item.descrizione">
      {{ item.descrizione + ": " + item.totale }}
    </div>
  </div>
</template>

<script>
import * as rinnoviAnnualiRepo from "../../services/RinnoviAnnuali";

export default {
  name: "BannerCountProfili",
  components: {},
  data() {
    return {
      count: {},
    };
  },
  props: {
    getCount: {
      type: Function,
      required: true,
    },
  },
  async created() {
    this.count = await this.getCount();
  },
  methods: {},
};
</script>
