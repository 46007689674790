<template>
  <b-card bg-variant="light" header="Contatti" class="text-start">
    <!-- MODALE NUOVO CONTATTO -->
    <b-modal
      id="modal-newcontact"
      title="Creazione Contatto"
      ok-only
      ok-variant="secondary"
      ok-title="Chiudi"
      no-close-on-backdrop
      hide-footer
    >
      <ContactCreationForm
        :cliente="form.customer.id"
        :locale="form.location.id"
        @contactCreation="contactCreated"
      ></ContactCreationForm>
    </b-modal>
    <!-- FINE MODALE NUOVO CONTATTO -->
    <!-- CONTATTI CLIENTE -->
    <h5 class="mb-2" v-if="contattiCliente.length > 0">
      {{ ragioneSociale }} (cliente)
    </h5>
    <div
      class="d-flex flex-column flex-md-row mb-1"
      v-for="(contatto, indice) in contattiCliente"
      :key="contatto.id"
    >
      <b-form-input
        v-model="contattiCliente[indice].reference"
        :disabled="!canUpdateContact"
      ></b-form-input>
      <select
        v-model="contattiCliente[indice].profilo_contatto"
        class="form-control"
        :disabled="!canUpdateContact"
      >
        <option
          v-for="profilo in profili"
          :key="profilo.id"
          :value="profilo.id"
        >
          {{ profilo.descrizione }}
        </option>
      </select>
      <b-form-input
        v-model="contattiCliente[indice].mail"
        :disabled="!canUpdateContact"
      ></b-form-input>
      <TelephoneInput
        v-model="contattiCliente[indice].telephone"
        :disabled="!canUpdateContact"
      ></TelephoneInput>
      <b-button
        variant="success"
        @click="() => modificaContatto(contattiCliente[indice])"
        v-if="canUpdateContact"
        >OK</b-button
      >
    </div>
    <!-- FINE CONTATTI CLIENTE -->
    <!-- CONTATTI LOCALE -->
    <h5 class="mb-2 mt-2" v-if="contattiLocale.length > 0">
      {{ descrizioneLocale }} (locale)
    </h5>
    <div
      class="d-flex flex-column flex-md-row mb-1"
      v-for="(contatto, indice) in contattiLocale"
      :key="contatto.id"
    >
      <b-form-input
        v-model="contattiLocale[indice].reference"
        :disabled="!canUpdateContact"
      ></b-form-input>
      <select
        v-model="contattiLocale[indice].profilo_contatto"
        class="form-control"
        :disabled="!canUpdateContact"
      >
        <option
          v-for="profilo in profili"
          :key="profilo.id"
          :value="profilo.id"
        >
          {{ profilo.descrizione }}
        </option>
      </select>
      <b-form-input
        v-model="contattiLocale[indice].mail"
        :disabled="!canUpdateContact"
      ></b-form-input>
      <TelephoneInput
        v-model="contattiLocale[indice].telephone"
        :disabled="!canUpdateContact"
      ></TelephoneInput>
      <b-button
        variant="success"
        @click="() => modificaContatto(contattiLocale[indice])"
        v-if="canUpdateContact"
        >OK</b-button
      >
    </div>
    <!-- FINE CONTATTI LOCALE -->
    <!-- BOTTONE APERTURA MODALE -->
    <b-button
      v-if="canUpdateContact"
      v-b-modal.modal-newcontact
      variant="primary"
      class="mt-3"
      >Nuovo contatto</b-button
    >
    <!-- FINE BOTTONE APERTURA MODALE -->
  </b-card>
</template>

<script>
import TelephoneInput from "../common/TelephoneInput";
import ContactCreationForm from "../common/ContactCreationForm";

import * as contattoRepo from "../../services/Contatto";

import axios from "axios";

export default {
  props: { form: Object },
  components: {
    TelephoneInput,
    ContactCreationForm,
  },
  name: "OrderFormContact",
  async created() {
    this.caricaContatti();
  },
  data() {
    return {
      setup: false,
      nuovoContatto: {
        locale: 0,
        reference: "",
        profile_id: "",
        mail: "",
        telephone: "",
      },
    };
  },
  methods: {
    async caricaContatti() {
      //OTTENGO TOKEN DI AUTENTICAZIONE
      const accessToken = await this.$auth.getTokenSilently();
      this.form.contact.list = await contattoRepo.getByCustomerId(
        accessToken,
        this.form.customer.id
      );
      this.setup = true;
    },
    async caricaProfili() {
      //OTTENGO TOKEN DI AUTENTICAZIONE
      const accessToken = await this.$auth.getTokenSilently();
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };

      //COSTRUISCO LA RICHIESTA PER I PROFILI
      let contactProfileRequest = await axios.get(
        `${process.env.VUE_APP_API_HOST}/profilo_contatto/`,
        config
      );

      this.profili = contactProfileRequest.data;
    },
    async contactCreated(id) {
      this.$bvModal.hide("modal-newcontact");
      await this.caricaContatti();
    },
    async modificaContatto(contatto) {
      if (!this.setup) return;
      const data = {
        mail: contatto.mail ? contatto.mail : null,
        telephone: contatto.telephone ? contatto.telephone : null,
        reference: contatto.reference ? contatto.reference : null,
        profilo_contatto: contatto.profilo_contatto,
      };
      try {
        const accessToken = await this.$auth.getTokenSilently();
        await contattoRepo.update(accessToken, contatto.id, data);
        this.$toast.open("Contatto modificato!");
      } catch (e) {
        console.error(e);
        this.$toast.error("Errore modifica contatto");
      }
    },
  },
  computed: {
    canUpdateContact() {
      if (!this.$route.params.id) return true;
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:ordine:contatto"
          ) != -1
        );
      }
      return false;
    },
    contattiCliente: {
      get() {
        return this.form.contact.list.filter(
          (contatto) => contatto.all_location
        );
      },
    },
    contattiLocale: {
      get() {
        return this.form.contact.list.filter(
          (contatto) =>
            !contatto.all_location && contatto.locale == this.form.location.id
        );
      },
    },
    descrizioneLocale() {
      let response = "ND";
      const locale =
        this.$store.state.locali_search[this.form.customer.id][
          this.form.location.id
        ];
      if (locale) response = locale.nome;

      return response;
    },
    ragioneSociale() {
      let response = "ND";
      const cliente = this.$store.state.clienti_search[this.form.customer.id];
      if (cliente) response = cliente.ragsoc;

      return response;
    },
    profili() {
      return this.$store.state.profili_contatto;
    },
  },
};
</script>
