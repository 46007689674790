<template>
  <div>
    <b-form @submit="onSubmit">
      <!-- <b-form-group
        id="input-group-1"
        label="*Nome locale:"
        label-for="input-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.customer_locale"
          type="text"
          placeholder="Nome locale"
          required
        ></b-form-input>
      </b-form-group> -->

      <b-form-group
        id="input-group-2"
        label="*Ragione sociale:"
        label-for="input-2"
      >
        <b-form-input
          :maxlength="80"
          id="input-2"
          v-model="form.ragsoc"
          placeholder="Ragione sociale"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-9" label="*Telefono:" label-for="input-9">
        <b-form-input
          :maxlength="18"
          id="input-9"
          v-model="form.tel"
          placeholder="Telefono"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-10" label="*Mail:" label-for="input-10">
        <b-form-input
          :maxlength="255"
          id="input-10"
          v-model="form.mail"
          placeholder="Mail"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-3"
        label="Partita iva:"
        label-for="input-3"
        description="necessario per inserire locali"
      >
        <b-form-input
          :maxlength="16"
          id="input-3"
          v-model="form.piva"
          placeholder="Partita iva"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-4"
        label="Codice fiscale:"
        label-for="input-4"
      >
        <b-form-input
          :maxlength="16"
          id="input-4"
          v-model="form.codfisc"
          placeholder="Codice fiscale"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-5" label="Sede legale:" label-for="input-5">
        <b-form-input
          :maxlength="255"
          id="input-5"
          v-model="form.via"
          placeholder="Sede legale"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-6" label="Cap:" label-for="input-6">
        <b-form-input
          :maxlength="10"
          id="input-6"
          v-model="form.cap"
          placeholder="Cap"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-7" label="Città:" label-for="input-7">
        <b-form-input
          :maxlength="100"
          id="input-7"
          v-model="form.citta"
          placeholder="Città"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-8" label="Provincia:" label-for="input-8">
        <b-form-input
          :maxlength="50"
          id="input-8"
          v-model="form.provincia"
          placeholder="Provincia"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-11" label="Sdi:" label-for="input-11">
        <b-form-input
          :maxlength="7"
          id="input-11"
          v-model="form.sdi"
          placeholder="Sdi"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-100" label="Pec:" label-for="input-100">
        <b-form-input
          :maxlength="100"
          id="input-100"
          v-model="form.pec"
          placeholder="Pec"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-12"
        label="Note Private:"
        label-for="input-12"
      >
        <b-form-textarea
          :maxlength="150"
          id="input-12"
          v-model="form.notepriv"
          placeholder="Note Private"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
        id="input-group-13"
        label="Note Cliente:"
        label-for="input-13"
      >
        <b-form-textarea
          id="input-13"
          v-model="form.notecli"
          placeholder="Note Cliente"
        ></b-form-textarea>
      </b-form-group>
      <div>
        <input type="checkbox" v-model="form.iva_esente" />
        <label>&nbsp;Iva Esente</label>
      </div>
      <div class="d-flex flex-row-reverse">
        <b-button type="submit" variant="primary" class="mt-2"
          >Conferma</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import * as clienteRepo from "../../services/Cliente";

export default {
  name: "CustomerCreationForm",
  data() {
    return {
      form: {
        id: "",
        id_trace: "",
        ragsoc: "",
        piva: "",
        codfisc: "",
        tel: "",
        mail: "",
        notecli: "",
        notepriv: "",
        pec: "",
        sdi: "",
        iban: "",
        via: "",
        cap: "",
        citta: "",
        provincia: "",
        iva_esente: false,
      },
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      // alert(JSON.stringify(this.form));
      if (this.form.piva.trim() !== "" && !this.pivaOK()) {
        this.$toast.error("La partita iva inserita non è valida");
        return;
      }
      this.creaCliente();
    },
    async creaCliente() {
      const accessToken = await this.$auth.getTokenSilently();
      let notEmpty = {};
      for (var key in this.form) {
        if (this.form[key] !== "") notEmpty[key] = this.form[key];
      }

      // if (notEmpty["cap"]) notEmpty["cap"] = parseInt(notEmpty["cap"]);

      let response = await clienteRepo.add(accessToken, notEmpty);

      if (!response.error)
        this.$emit("customerCreation", response.id, {
          ...this.form,
          ...response,
        });
    },
    pivaOK() {
      if (this.form.iva_esente) return true;
      return this.verificaPartitaIva(this.form.piva);
    },
    verificaPartitaIva(piva) {
      if (piva.length != 11) return false;
      const splitted = piva.split("");
      const splittedIntegers = splitted.map((number) => parseInt(number));

      let x = 0;
      let y = 0;
      for (let i = 0; i < splittedIntegers.length - 1; i++) {
        if ((i + 1) % 2 != 0) {
          x += splittedIntegers[i];
        } else {
          splittedIntegers[i] *= 2;
          y +=
            splittedIntegers[i] > 9
              ? splittedIntegers[i] - 9
              : splittedIntegers[i];
        }
      }
      const t = (x + y) % 10;
      const c = (10 - t) % 10;
      return c == splittedIntegers[10];
    },
  },
};
</script>
