<template>
  <div class="text-start">
    <h3 v-if="this.software.length > 0" class="mt-4 ms-1">Software</h3>
    <div v-for="articolo in this.software" :key="articolo.id">
      <CartItemsMobileRow
        :articolo="articolo"
        :getArticleById="getArticleById"
        :modificaScontoInBaseAlTotale="modificaScontoInBaseAlTotale"
        :rimuoviArticolo="rimuoviArticolo"
        :editable="editable"
        :scontabile="scontabile"
      ></CartItemsMobileRow>
    </div>

    <h3 v-if="this.hardware.length > 0" class="mt-4 ms-1">Hardware</h3>
    <div v-for="articolo in this.hardware" :key="articolo.id">
      <CartItemsMobileRow
        :articolo="articolo"
        :getArticleById="getArticleById"
        :modificaScontoInBaseAlTotale="modificaScontoInBaseAlTotale"
        :rimuoviArticolo="rimuoviArticolo"
        :editable="editable"
        :scontabile="scontabile"
      ></CartItemsMobileRow>
    </div>

    <h3 v-if="this.servizi.length > 0" class="mt-4 ms-1">Servizi</h3>
    <div v-for="articolo in this.servizi" :key="articolo.id">
      <CartItemsMobileRow
        :articolo="articolo"
        :getArticleById="getArticleById"
        :modificaScontoInBaseAlTotale="modificaScontoInBaseAlTotale"
        :rimuoviArticolo="rimuoviArticolo"
        :editable="editable"
        :scontabile="scontabile"
      ></CartItemsMobileRow>
    </div>

    <h3 v-if="this.riscatti.length > 0" class="mt-4 ms-1">Riscatti</h3>
    <div v-for="articolo in this.riscatti" :key="articolo.id">
      <CartItemsMobileRow
        :articolo="articolo"
        :getArticleById="getArticleById"
        :modificaScontoInBaseAlTotale="modificaScontoInBaseAlTotale"
        :rimuoviArticolo="rimuoviArticolo"
        :editable="editable"
        :scontabile="scontabile"
      ></CartItemsMobileRow>
    </div>
  </div>
</template>

<script>
import CartItemsMobileRow from "@/components/ordini/CartItemsMobileRow.vue";
export default {
  components: { CartItemsMobileRow },
  name: "CartItemsMobile",
  props: [
    "software",
    "hardware",
    "servizi",
    "riscatti",
    "getArticleById",
    "modificaScontoInBaseAlTotale",
    "rimuoviArticolo",
    "editable",
    "scontabile",
  ],
};
</script>
