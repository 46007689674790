<template>
  <div class="text-center container-fluid" style="min-height: 100vh">
    <!-- ADMIN ONLY -->
    <div v-if="true && isAdmin" class="row m-md-2 pt-4">
      <div class="col-12">
        <div class="card p-4 text-start shadow-lg background-secondary">
          <div class="pb-2" style="font-weight: bold">
            Pannello amministratore
          </div>
          <span>Utente:</span>
          <v-select
            v-model="admin.user"
            :options="admin.users"
            @input="loadUserData"
            :reduce="(value) => value.code"
          ></v-select>
          <span>Ruolo:</span>
          <v-select
            v-model="admin.type"
            :options="[
              { label: 'Utente', code: 'utente' },
              { label: 'Tecnico', code: 'tecnico' },
            ]"
            @input="loadUserData"
            :reduce="(value) => value.code"
          ></v-select>
        </div>
      </div>
    </div>
    <!-- CIRCLE BADGES -->
    <div class="row mt-4">
      <div
        :class="
          (mostraAbbonati ? 'col-md-8' : 'col-md-12') +
          ' d-flex justify-content-center'
        "
      >
        <!-- <div class="col-lg-2"></div> -->
        <CircleBadge
          class="m-md-2"
          backgroundColor="#aa40ff"
          textColor="white"
          shadowActiveColor="#aa40ff"
          shadowHoverColor="#0000005c"
          :count="impegno_creato.length"
          description="IMPEGNO CREATO"
          :active="active == 0"
          @click="() => setActive(0)"
        ></CircleBadge>
        <CircleBadge
          class="m-md-2"
          backgroundColor="#36A2EB"
          textColor="white"
          shadowActiveColor="#36A2EB"
          shadowHoverColor="#0000005c"
          :count="in_lavorazione.length"
          description="IN LAVORAZIONE"
          :active="active == 1"
          @click="() => setActive(1)"
        ></CircleBadge>
        <CircleBadge
          class="m-md-2"
          backgroundColor="red"
          textColor="white"
          shadowActiveColor="red"
          shadowHoverColor="#0000005c"
          :count="attesa_pagamento.length"
          description="IN ATTESA DI PAGAMENTO"
          :active="active == 2"
          @click="() => setActive(2)"
        ></CircleBadge>
        <CircleBadge
          class="m-md-2"
          backgroundColor="#0EDD3A"
          textColor="white"
          shadowActiveColor="#0EDD3A"
          shadowHoverColor="#0000005c"
          :count="evasi.length"
          description="EVASI"
          :active="active == 3"
          @click="() => setActive(3)"
        ></CircleBadge>
        <!-- <ExagonBadge
        backgroundColor="lightblue"
        textColor="white"
        :count="evasi.length"
        description="PROVA"
        :active="active == 4"
        @click="() => setActive(4)"
      ></ExagonBadge> -->
        <!-- <div class="col-lg-2"></div> -->
      </div>
      <div v-if="mostraAbbonati" class="col-md-4 d-flex justify-content-center">
        <!-- <div class="col-lg-2"></div> -->
        <ShieldBadge
          class="m-md-2"
          backgroundColor="orange"
          textColor="white"
          :count="abbonati.count"
          description="CLIENTI ABBONATI"
          :active="active == 4"
          @click="() => setActive(4)"
        ></ShieldBadge>
      </div>
    </div>

    <div class="row justify-content-center m-md-2">
      <div class="col-12 text-start">
        <Transition name="fade" mode="out-in" :duration="150">
          <div
            class="card p-4 mt-4 shadow-lg background-secondary"
            v-if="active == 0"
            key="table-0"
          >
            <div style="font-weight: bold" class="mb-2">Impegni assegnati</div>
            <div class="row">
              <div class="col-md-4">
                <b-input
                  class="mb-4 col-md-4"
                  placeholder="inserire num. ordine, data consegna o ragione sociale.."
                  v-model="filter[0]"
                ></b-input>
              </div>
              <div class="col-md-4">
                <b-pagination
                  v-model="pagina[0]"
                  :total-rows="rows[0]"
                  :per-page="perPage[0]"
                  aria-controls="impegno-creato"
                  pills
                ></b-pagination>
              </div>
            </div>

            <b-table
              id="impegno-creato"
              :items="impegno_creato"
              :fields="fields"
              :per-page="perPage[0]"
              :current-page="pagina[0]"
              :filter="filter[0]"
              style="color: var(--text-color)"
              stacked="sm"
              show-empty
              bordered
            >
              <template #cell(id_ordine)="data">
                <b-link
                  v-b-tooltip.hover
                  title="Apri PDF ordine"
                  target="_blank"
                  style="padding: 0px"
                  :href="pdfLink(data.value)"
                  >{{ data.value }}</b-link
                >
              </template>
              <template #empty>
                <div class="d-flex justify-content-center">
                  0 impegni assegnati
                </div>
              </template>
            </b-table>
          </div>

          <div
            class="card p-4 mt-4 shadow-lg background-secondary"
            v-if="active == 1"
            key="table-1"
          >
            <div style="font-weight: bold" class="mb-2">
              In lavorazione assegnati
            </div>
            <div class="row">
              <div class="col-md-4">
                <b-input
                  class="mb-4 col-md-4"
                  placeholder="inserire num. ordine, data consegna o ragione sociale.."
                  v-model="filter[1]"
                ></b-input>
              </div>
              <div class="col-md-4">
                <b-pagination
                  v-model="pagina[1]"
                  :total-rows="rows[1]"
                  :per-page="perPage[1]"
                  aria-controls="in-lavorazione"
                  pills
                ></b-pagination>
              </div>
            </div>

            <b-table
              id="in-lavorazione"
              :items="in_lavorazione"
              :fields="fields"
              :per-page="perPage[1]"
              :current-page="pagina[1]"
              :filter="filter[1]"
              style="color: var(--text-color)"
              stacked="sm"
              show-empty
              bordered
            >
              <template #cell(id_ordine)="data">
                <b-link
                  v-b-tooltip.hover
                  title="Apri PDF ordine"
                  target="_blank"
                  style="padding: 0px"
                  :href="pdfLink(data.value)"
                  >{{ data.value }}</b-link
                >
              </template>
              <template #empty>
                <div class="d-flex justify-content-center">
                  0 in lavorazione assegnati
                </div>
              </template>
            </b-table>
          </div>

          <div
            class="card p-4 mt-4 shadow-lg background-secondary"
            v-if="active == 2"
            key="table-2"
          >
            <div style="font-weight: bold" class="mb-2">
              In attesa di pagamento assegnati
            </div>
            <div class="row">
              <div class="col-md-4">
                <b-input
                  class="mb-4 col-md-4"
                  placeholder="inserire num. ordine, data consegna o ragione sociale.."
                  v-model="filter[2]"
                ></b-input>
              </div>
              <div class="col-md-4">
                <b-pagination
                  v-model="pagina[2]"
                  :total-rows="rows[2]"
                  :per-page="perPage[2]"
                  aria-controls="wait-payment"
                  pills
                ></b-pagination>
              </div>
            </div>

            <b-table
              id="wait-payment"
              :items="attesa_pagamento"
              :fields="fields"
              :per-page="perPage[2]"
              :current-page="pagina[2]"
              :filter="filter[2]"
              style="color: var(--text-color)"
              stacked="sm"
              show-empty
              bordered
            >
              <template #cell(id_ordine)="data">
                <b-link
                  v-b-tooltip.hover
                  title="Apri PDF ordine"
                  target="_blank"
                  style="padding: 0px"
                  :href="pdfLink(data.value)"
                  >{{ data.value }}</b-link
                >
              </template>
              <template #empty>
                <div class="d-flex justify-content-center">
                  0 in attesa di pagamento assegnati
                </div>
              </template>
            </b-table>
          </div>

          <!-- <div class="mt-4" v-if="active == 2" key="table-2">
            <b-input
              class="mb-4"
              placeholder="inserire num. ordine, data consegna o ragione sociale.."
              v-model="filter[2]"
            ></b-input>

            <b-table
              id="attesa-pagamento"
              :items="attesa_pagamento"
              :fields="fields"
              :per-page="perPage[2]"
              :current-page="pagina[2]"
              :filter="filter[2]"
              stacked="sm"
              striped
              hover
              show-empty
            >
              <template #empty>
                <div class="d-flex justify-content-center">
                  0 ordini in attesa di pagamento assegnati
                </div>
              </template>
            </b-table>
            <b-pagination
              v-model="pagina[2]"
              :total-rows="rows[2]"
              :per-page="perPage[2]"
              aria-controls="attesa-pagamento"
            ></b-pagination>
          </div> -->

          <div v-if="active == 3" key="table-3">
            <div class="card p-4 mt-4 shadow-lg background-secondary">
              <div style="font-weight: bold" class="mb-2">Evasi assegnati</div>
              <div class="row">
                <div class="col-md-4">
                  <b-input
                    class="mb-4 col-md-4"
                    placeholder="inserire num. ordine, data consegna o ragione sociale.."
                    v-model="filter[3]"
                  ></b-input>
                </div>
                <div class="col-md-4">
                  <b-pagination
                    v-model="pagina[3]"
                    :total-rows="count[3]"
                    :per-page="perPage[3]"
                    aria-controls="evasi"
                    pills
                  ></b-pagination>
                </div>
              </div>

              <b-table
                id="evasi"
                :items="evasi"
                :fields="fields"
                :per-page="perPage[3]"
                :current-page="pagina[3]"
                :filter="filter[3]"
                style="color: var(--text-color)"
                stacked="sm"
                show-empty
                bordered
                @filtered="(_, num) => onFiltered(3, num)"
              >
                <template #cell(id_ordine)="data">
                  <b-link
                    v-b-tooltip.hover
                    title="Apri PDF ordine"
                    target="_blank"
                    style="padding: 0px"
                    :href="pdfLink(data.value)"
                    >{{ data.value }}</b-link
                  >
                </template>
                <template #empty>
                  <div class="d-flex justify-content-center">
                    0 evasi assegnati
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row mt-3">
              <div class="col-lg-7 mt-2">
                <div class="card p-4 shadow-lg background-secondary">
                  <div class="pt-2" style="font-weight: bold">
                    Andamento mensile consegne ordini assegnati
                  </div>
                  <apexchart
                    class="h-100 p-4"
                    :options="optStatAndamentoAssegnazione"
                    :series="serieStatAndamentoAssegnazione"
                  ></apexchart>
                </div>
              </div>
              <div class="col-lg-5 mt-2">
                <div class="card p-4 h-100 shadow-lg background-secondary">
                  <div class="pt-2" style="font-weight: bold">
                    Assegnati per tipo ordine
                  </div>
                  <apexchart
                    class="mt-4"
                    :options="opzioniStatistiche2"
                    :series="statTipoOrdine[1]"
                  >
                  </apexchart>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-12 mt-2">
                <div class="card p-4 shadow-lg background-secondary">
                  <div class="pt-2" style="font-weight: bold">
                    Andamento generale ordini assegnati
                  </div>
                  <apexchart
                    type="area"
                    height="350"
                    :options="optStatAndamentoAssegnazione2"
                    :series="seriesStatAndamentoAssegnazione2"
                  ></apexchart>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-lg-12 mt-2">
                <div class="card p-4 shadow-lg background-secondary">
                  <div class="pt-2" style="font-weight: bold">
                    Profilo locale ordini assegnati
                  </div>
                  <apexchart
                    type="treemap"
                    height="350"
                    :series="seriesProfiloLocale"
                    :options="optProfiloLocale"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>

          <div v-if="active == 4" key="table-3">
            <div class="card p-4 mt-4 shadow-lg background-secondary">
              <div style="font-weight: bold" class="mb-2">Clienti abbonati</div>
              <div class="row">
                <div class="col-md-4">
                  <b-input
                    class="mb-4 col-md-4"
                    placeholder="cerca.."
                    v-model="abbonati.filter"
                  ></b-input>
                </div>
                <div class="col-md-4">
                  <b-pagination
                    v-model="abbonati.page"
                    :total-rows="abbonati.count"
                    :per-page="abbonati.perPage"
                    aria-controls="abbonati"
                    pills
                  ></b-pagination>
                </div>
              </div>
              <b-table
                id="abbonati"
                :items="clientiAbbonatiRows"
                :fields="clientiAbbonatiFields"
                :per-page="abbonati.perPage"
                :current-page="abbonati.page"
                :filter="abbonati.filter"
                style="color: var(--text-color)"
                stacked="sm"
                show-empty
                bordered
                @filtered="(_, num) => onAbbonatiFiltered(num)"
              >
                <template #cell(cliente)="data">
                  <router-link
                    v-b-tooltip.hover
                    title="Apri scheda cliente"
                    style="padding: 0px"
                    :to="'/clienti/' + data.value"
                    >{{ data.value }}</router-link
                  >
                </template>
                <template #cell(ragsoc)="data">
                  <div>
                    {{ getFixedLengthString(data.value, 40) }}
                  </div>
                </template>
                <template #cell(nome)="data">
                  <div>
                    {{ getFixedLengthString(data.value, 40) }}
                  </div>
                </template>
                <template #cell(scaduto)="data">
                  <div
                    :class="{
                      'text-danger': data.value && data.value > 0,
                      'text-warning': data.value && data.value < 0,
                    }"
                  >
                    {{
                      data.value
                        ? data.value.toLocaleString("it-IT", {
                            style: "currency",
                            currency: "EUR",
                          }) /* $2,500.00 */
                        : "0 €"
                    }}
                  </div>
                </template>
                <template #empty>
                  <div class="d-flex justify-content-center">
                    0 clienti abbonati
                  </div>
                </template>
              </b-table>
            </div>
            <div class="row mt-3">
              <div class="col-lg-6 mt-2">
                <div class="card p-4 background-secondary">
                  <div style="font-weight: bold" class="mb-2">
                    Clienti abbonati per profilo
                  </div>

                  <b-table
                    id="abbonati-grouped"
                    :items="clientiAbbonatiGroupedRows"
                    :fields="clientiAbbonatiGroupedFields"
                    stacked="sm"
                    style="color: var(--text-color)"
                    show-empty
                    bordered
                  >
                  </b-table>
                </div>
              </div>
              <div class="col-lg-6 mt-2">
                <div class="card p-4 h-100 background-secondary">
                  <div style="font-weight: bold" class="mb-2">
                    Clienti abbonati per profilo
                  </div>
                  <apexchart
                    class="mt-4"
                    :options="opzioniStatisticheAbbonatiGrouped"
                    :series="statAbbonatiGrouped[1]"
                  >
                  </apexchart>
                </div>
              </div>
            </div>
          </div>

          <!-- <div v-if="active == 4">
            <div class="card p-4 mt-4 shadow-lg" style="background-color: #fff">
              eccolo
            </div>
          </div> -->
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import CircleBadge from "@/components/statistiche/CircleBadge.vue";
import ShieldBadge from "@/components/statistiche/ShieldBadge.vue";
import ExagonBadge from "@/components/statistiche/ExagonBadge.vue";

import * as orderService from "../services/Ordine";
import * as statisticheService from "../services/Statistiche";

export default {
  name: "Statistiche",
  components: {
    CircleBadge,
    ShieldBadge,
    ExagonBadge,
  },
  data: function () {
    return {
      admin: {
        user: null,
        type: null,
        users: [
          { label: "Andrea", code: 1 },
          { label: "Luca", code: 2 },
          { label: "Alessandro", code: 4 },
          { label: "Domenico", code: 6 },
          { label: "Elena", code: 8 },
          { label: "Sissi", code: 12 },
          { label: "Enrico", code: 13 },
          { label: "Viviana", code: 14 },
          { label: "Antonella", code: 15 },
          { label: "Nino", code: 16 },
          { label: "Marco", code: 17 },
          { label: "Spinelli", code: 18 },
          { label: "Vicari", code: 20 },
          { label: "Caramella", code: 21 },
          { label: "Remo", code: 23 },
          { label: "Salva", code: 24 },
        ],
      },
      abbonati: {
        clienti: [],
        count: 0,
        page: 1,
        perPage: 10,
        grouped: [],
      },
      id_utente: null,
      active: 0,
      pagina: [1, 1, 1, 1],
      perPage: [10, 10, 10, 10],
      filter: [null, null, null, null],
      count: [0, 0, 0, 0],
      ordini: [],
    };
  },
  async created() {
    const accessToken = await this.$auth.getTokenSilently();
    await this.$store.dispatch("caricaTotaleScaduti", accessToken);
    await this.loadUserData();
    await this.loadCustomerData();
  },
  methods: {
    async loadUserData() {
      this.$loading(true);
      let user = this.admin.user;
      let type = this.admin.type;

      if (!user)
        user = this.$auth.user["https://rca-api/user_metadata"].matching_id;
      if (!type) type = "tecnico";
      const query = {
        include: [
          "cliente_cliente",
          "tipo_tipo_ordine",
          {
            model: "locale",
            as: "locale_locale",
            include: ["profilo_locale_profilo_locale"],
          },
        ],
        where: {
          and: {
            [type]: user,
            stato: {
              in: [5, 6, 7],
            },
          },
        },
      };
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const data = await orderService.getAll(accessToken, query);
        this.ordini = data;

        //setup count
        this.count = this.rows;
      } catch (e) {
        console.error(e);
        this.$loading(false);
      }
      this.$loading(false);
    },
    async loadCustomerData() {
      const accessToken = await this.$auth.getTokenSilently();
      const data = await statisticheService.clientiAbbonati(accessToken);
      const data2 = await statisticheService.clientiAbbonatiPerProfilo(
        accessToken
      );
      const [clienti, count] = data.data;
      const [grouped] = data2.data;

      this.abbonati.clienti = clienti.map((cliente) => {
        return { ...cliente, scaduto: this.totaleScaduti[cliente.id_trace] };
      });
      this.abbonati.count = count;
      this.abbonati.grouped = grouped;
    },
    getFixedLengthString(string, length) {
      let result = string ? string : "";
      //Se la stringa è vuota più lunga di length caratteri
      if (result.length > length) {
        //taglio la stringa a length - 3 caratteri
        result = result.substr(0, length - 3) + "...";
      }
      return result;
    },
    pdfLink(ordine) {
      return process.env.VUE_APP_API_HOST + "/pdf/ordine/" + ordine;
    },
    setActive(active) {
      this.active = active;
    },
    onFiltered(id, num) {
      //Nuovo count
      let newCount = [
        this.count[0],
        this.count[1],
        this.count[2],
        this.count[3],
      ];
      newCount[id] = num;
      this.count = newCount;

      //Nuova pagina attiva (1)
      let newPage = [
        this.pagina[0],
        this.pagina[1],
        this.pagina[2],
        this.pagina[3],
      ];
      newPage[id] = 1;
      this.pagina = newPage;
    },
    onAbbonatiFiltered(num) {
      this.abbonati.page = 1;
      this.abbonati.count = num;
    },
    formatDate(date) {
      if (!date) return "";
      let splitted = date.substring(0, 10).split("-");
      return splitted[2] + "/" + splitted[1] + "/" + splitted[0];
    },
  },
  computed: {
    isAdmin() {
      if (this.$auth.user && this.$auth.user["https://rca-api/roles"]) {
        return (
          this.$auth.user["https://rca-api/roles"].roles.indexOf(
            "Administrator"
          ) != -1
        );
      }
      return false;
    },
    mostraAbbonati() {
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "read:stat:abbonati"
          ) != -1
        );
      }
      return false;
    },
    rows() {
      return [
        this.impegno_creato.length,
        this.in_lavorazione.length,
        this.attesa_pagamento.length,
        this.evasi.length,
      ];
    },
    fields() {
      return [
        { key: "id_ordine", label: "ID Ordine", sortable: true },
        { key: "Data Consegna", sortable: true },
        { key: "Cliente", sortable: true },
      ];
    },
    clientiAbbonatiFields() {
      return [
        { key: "id_trace", sortable: true, thStyle: { width: "5%" } },
        { key: "prg", sortable: true, thStyle: { width: "5%" } },
        { key: "cliente", sortable: true, thStyle: { width: "5%" } },
        { key: "locale", sortable: true, thStyle: { width: "5%" } },
        { key: "ragsoc", sortable: true, thStyle: { width: "30%" } },
        {
          key: "nome",
          label: "Nome locale",
          sortable: true,
          thStyle: { width: "30%" },
        },
        { key: "scaduto", sortable: true, thStyle: { width: "10%" } },

        // { key: "Data Consegna", sortable: true },
        // { key: "Cliente", sortable: true },
      ];
    },
    impegno_creato() {
      return this.ordini
        .filter((value) => value.stato == 5)
        .map((ordine) => {
          return {
            id_ordine: ordine.id,
            "Data Consegna": this.formatDate(ordine.data_consegna),
            Cliente: ordine.cliente_cliente.ragsoc,
          };
        });
    },
    in_lavorazione() {
      return this.ordini
        .filter((value) => value.stato == 6)
        .map((ordine) => {
          return {
            id_ordine: ordine.id,
            "Data Consegna": this.formatDate(ordine.data_consegna),
            Cliente: ordine.cliente_cliente.ragsoc,
          };
        });
    },
    attesa_pagamento() {
      return this.ordini
        .filter((value) => value.wait_payment)
        .map((ordine) => {
          return {
            id_ordine: ordine.id,
            "Data Consegna": this.formatDate(ordine.data_consegna),
            Cliente: ordine.cliente_cliente.ragsoc,
          };
        });
    },
    evasi() {
      return this.ordini
        .filter((value) => value.stato == 7)
        .map((ordine) => {
          return {
            id_ordine: ordine.id,
            "Data Consegna": this.formatDate(ordine.data_consegna),
            Cliente: ordine.cliente_cliente.ragsoc,
          };
        });
    },
    evasiFull() {
      return this.ordini.filter((value) => value.stato == 7);
    },
    statAndamentoAssegnazione() {
      let evasi = {};
      for (const evaso of this.evasiFull) {
        if (evaso.data_consegna) {
          const date = evaso.data_consegna.substr(0, 7);
          if (!evasi[date]) evasi[date] = 0;
          evasi[date]++;
        }
      }

      const categorie = [];
      const serie = [];

      Object.entries(evasi).forEach(([key]) => {
        categorie.push(key);
      });

      categorie.sort();

      categorie.forEach((value) => {
        serie.push(evasi[value]);
      });

      return [categorie, serie];
    },
    statTipoOrdine() {
      let evasi = {};

      for (const evaso of this.evasiFull) {
        if (evaso.tipo_tipo_ordine) {
          if (!evasi[evaso.tipo_tipo_ordine.descrizione])
            evasi[evaso.tipo_tipo_ordine.descrizione] = 0;
          evasi[evaso.tipo_tipo_ordine.descrizione]++;
        }
      }

      const data = [];
      const labels = [];

      Object.entries(evasi).forEach(([key, value]) => {
        data.push(key);
        labels.push(value);
      });
      return [data, labels];
    },
    seriesProfiloLocale() {
      let evasi = {};

      for (const evaso of this.evasiFull) {
        if (
          evaso.locale_locale &&
          evaso.locale_locale.profilo_locale_profilo_locale
        ) {
          if (
            !evasi[
              evaso.locale_locale.profilo_locale_profilo_locale.descrizione
            ]
          )
            evasi[
              evaso.locale_locale.profilo_locale_profilo_locale.descrizione
            ] = 0;
          evasi[
            evaso.locale_locale.profilo_locale_profilo_locale.descrizione
          ]++;
        }
      }
      let returnObj = [];
      Object.entries(evasi).forEach(([key, value]) => {
        returnObj.push({
          x: key,
          y: value,
        });
      });

      return [
        {
          data: returnObj,
        },
      ];
    },
    optProfiloLocale() {
      return {
        legend: {
          show: false,
        },
        chart: {
          type: "treemap",
        },
      };
    },
    optStatAndamentoAssegnazione() {
      return {
        chart: {
          id: "grafico-evasi",
          type: "area",
        },
        // responsive: [
        //   {
        //     breakpoint: 1000,
        //     options: {
        //       chart: {
        //         type: "bar",
        //       },
        //     },
        //   },
        // ],
        fill: {
          gradient: {
            enabled: true,
            opacityFrom: 0.6,
            opacityTo: 0.2,
          },
        },
        xaxis: {
          categories: this.statAndamentoAssegnazione[0],
        },
      };
    },
    optStatAndamentoAssegnazione2() {
      return {
        chart: {
          id: "area-datetime",
          type: "area",
          height: 350,
          zoom: {
            autoScaleYaxis: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          style: "hollow",
        },
        xaxis: {
          type: "datetime",
          tickAmount: 6,
        },
        tooltip: {
          x: {
            format: "dd MMM yyyy",
          },
        },
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
      };
    },
    seriesStatAndamentoAssegnazione2() {
      let evasi = {};

      for (const evaso of this.evasiFull) {
        if (evaso.data) {
          const date = new Date(evaso.data).getTime();
          if (!evasi[date]) evasi[date] = 0;

          evasi[date]++;
        }
      }

      const mapped = Object.entries(evasi).map(([time, value]) => {
        return [parseInt(time), value];
      });

      const sorted = mapped.sort((a, b) => a[0] - b[0]);

      return [
        {
          name: "ordini",
          data: sorted,
        },
      ];
    },
    opzioniStatistiche2() {
      return {
        chart: {
          id: "grafico-evasi-2",
          type: "donut",
        },
        fill: {
          gradient: {
            enabled: true,
            opacityFrom: 0.6,
            opacityTo: 0.2,
          },
        },

        legend: {
          show: false,
        },

        labels: this.statTipoOrdine[0],
      };
    },
    serieStatAndamentoAssegnazione() {
      return [
        {
          name: "consegne",
          data: this.statAndamentoAssegnazione[1],
        },
      ];
    },
    clientiAbbonatiRows() {
      return this.abbonati.clienti;
    },
    clientiAbbonatiGroupedRows() {
      return this.abbonati.grouped;
    },
    clientiAbbonatiGroupedFields() {
      return [
        { key: "descrizione", label: "Profilo", sortable: true },
        { key: "totale", label: "Abbonati", sortable: true },
      ];
    },

    statAbbonatiGrouped() {
      let labels = [];
      let values = [];

      this.abbonati.grouped.forEach((row) => {
        labels.push(row.descrizione ? row.descrizione : "---");
        values.push(row.totale);
      });

      return [labels, values];
    },
    opzioniStatisticheAbbonatiGrouped() {
      return {
        chart: {
          id: "grafico-abbonati-grouped",
          type: "donut",
        },
        fill: {
          gradient: {
            enabled: true,
            opacityFrom: 0.6,
            opacityTo: 0.2,
          },
        },

        legend: {
          show: false,
        },

        labels: this.statAbbonatiGrouped[0],
      };
    },
    totaleScaduti() {
      return this.$store.state.totale_scaduti;
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
