<template>
  <b-form-group
    :id="'input-group-' + identificatore"
    :label="descrizione + ':'"
    :label-for="'input-' + identificatore"
  >
    <b-form-textarea
      :id="'input-' + identificatore"
      :placeholder="descrizione"
      @input="input"
      :value="value"
      :readonly="readonly"
      required
    ></b-form-textarea>
  </b-form-group>
</template>

<script>
export default {
  name: "TextareaField",
  props: ["value", "identificatore", "descrizione", "readonly"],
  data() {
    return {
      content: this.value
    };
  },
  methods: {
    input(value) {
      this.$emit("input", value);
    }
  }
};
</script>
