<template>
  <div>
    <b-button v-b-modal.rubrica>Rubrica</b-button>

    <b-modal
      id="rubrica"
      size="xl"
      v-model="aperto"
      centered
      title="Rubrica"
      hide-footer
    >
      <RubricaContatti
        :defaultSearch="defaultSearch ? defaultSearch : ''"
        @mailSelected="feedForwardMailSelectedEvent"
      ></RubricaContatti>
    </b-modal>
  </div>
</template>

<script>
import Rubrica from "./Rubrica.vue";
import RubricaContatti from "./RubricaContatti.vue";

export default {
  name: "BottoneRubrica",
  props: ["defaultSearch"],
  components: { Rubrica, RubricaContatti },
  data() {
    return {
      aperto: false,
      cliente: null,
      locale: null,
    };
  },
  methods: {
    feedForwardMailSelectedEvent(args) {
      this.$emit("mailSelected", args);
    },
  },
};
</script>
