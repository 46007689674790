<template>
  <div>
    <div class="background-secondary p-2 shadow">
      <b-table
        stacked="md"
        :items="items"
        @row-clicked="openRow"
        :fields="fields"
        v-bind="$attrs"
        v-on="$listeners"
        custom-prop="any"
        :style="'cursor:' + (pointer ? 'pointer' : 'default')"
        @sort-changed="customSort"
        style="color: var(--text-color) !important"
        :no-local-sorting="noLocalSorting"
        bordered
      >
        <template
          v-for="(_, slotName) of $scopedSlots"
          v-slot:[slotName]="scope"
        >
          <slot :name="slotName" v-bind="scope" />
        </template>
      </b-table>
    </div>
    <div class="d-flex flex-column flex-md-row">
      <b-pagination
        v-model="paginaAttiva"
        :total-rows="numeroPagine * itemsPerPage"
        :per-page="itemsPerPage"
        aria-controls="my-table"
        first-number
        last-number
        class="customPagination"
      ></b-pagination>
      <b class="mt-auto mb-auto ms-3" style="color: var(--primary-color)">
        {{ totale }} Risultati
      </b>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    getPage: Function,
    getCount: Function,
    openRow: Function,
    fields: Array,
    slots: String,
    pointer: Boolean,
    sort: Function,
    refreshValue: Number,
    perPage: Number,
    noLocalSorting: Boolean,
  },
  name: "PaginatedTable",
  data() {
    return {
      paginaAttiva: 1,
      numeroPagine: 1,
      items: [],
      totale: 0,
      itemsPerPage: 10,
    };
  },
  async created() {
    this.fullLoad();
  },
  watch: {
    paginaAttiva(newPage, oldPage) {
      if (newPage != oldPage) this.loadPage(newPage);
    },
    refreshValue(newValue, oldValue) {
      if (newValue != oldValue) this.fullLoad();
    },
  },
  methods: {
    nextPage() {
      if (this.paginaAttiva < this.numeroPagine) {
        this.paginaAttiva++;
        this.loadPage(this.paginaAttiva);
      }
    },
    previousPage() {
      if (this.paginaAttiva > 1) {
        this.paginaAttiva--;
        this.loadPage(this.paginaAttiva);
      }
    },
    async fullLoad() {
      this.itemsPerPage = this.perPage ? this.perPage : 10;
      await this.loadPagination(), await this.loadPage(1);
    },
    async loadPagination() {
      const accessToken = await this.$auth.getTokenSilently();
      let count = await this.getCount(accessToken);
      this.numeroPagine = Math.ceil(count["count"] / this.itemsPerPage);
      this.totale = parseInt(count["count"]);
    },
    async loadPage(page) {
      try {
        this.$loading(true);
        const accessToken = await this.$auth.getTokenSilently();
        this.items = await this.getPage(accessToken, page);
        this.$loading(false);
      } catch (e) {
        console.error(e);
        this.$loading(false);
      }
    },
    customSort(...props) {
      if (this.sort) {
        this.sort(...props);
        this.paginaAttiva = 1;
        this.loadPagination();
      }
    },
  },
};
</script>
