<template>
  <b-form-select
    v-model="actualStatus"
    v-on:change="editStatus"
    :options="selectOptions"
    class="form-control"
    :disabled="!editable"
  ></b-form-select>
</template>

<script>
import axios from "axios";

import * as ordineRepo from "../../services/Ordine";

export default {
  name: "SelectStato",
  data() {
    return {
      loading: false,
      actualStatus: null,
      availableStatus: [],
      initialStatus: null,
    };
  },
  components: {},
  props: {
    ordine: {
      type: Number,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
  },
  watch: {},
  async mounted() {
    this.getActualStatus();
    this.getAvailableStatus();
  },
  computed: {
    selectOptions() {
      return this.availableStatus.map((status) => {
        return {
          value: status.status_id,
          text: status.status_loc_desc,
        };
      });
    },
  },
  methods: {
    async getActualStatus() {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();
      //Send request
      try {
        const statusResponse = await ordineRepo.getById(
          accessToken,
          this.ordine
        );
        if (statusResponse) {
          this.actualStatus = statusResponse.stato;
          this.initialStatus = statusResponse.stato;
          if (statusResponse.stato == 1 && statusResponse.cancellato) {
            this.actualStatus = 0;
            this.initialStatus = 0;
          }
        } else {
          this.actualStatus = null;
        }
        //Error handling
      } catch (e) {
        alert("Errore caricamento stato attuale");
        this.actualStatus = null;
        return;
      }
    },
    async getAvailableStatus() {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();
      //Send request
      try {
        const statusResponse = await ordineRepo.getAvailableStatus(
          accessToken,
          this.ordine
        );
        if (statusResponse) {
          this.availableStatus = statusResponse;
        } else {
          this.availableStatus = [];
        }
        //Error handling
      } catch (e) {
        alert("Errore caricamento stati disponibili");
        this.availableStatus = [];
        return;
      }
    },
    async editStatus(oldone, newone) {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();
      //Send request
      try {
        await ordineRepo.editStatus(
          accessToken,
          this.ordine,
          this.actualStatus
        );
        await this.getActualStatus();
        await this.getAvailableStatus();
        this.$emit("edit-status", this.ordine, this.actualStatus);
        //Error handling
      } catch (e) {
        this.actualStatus = this.initialStatus;
        const error = e.response.body.error;
        this.$toast.error(error);
        return;
      }
    },
  },
};
</script>
