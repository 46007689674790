import ApiConnector from "./ApiConnector";
import axios from "axios";

let connector = new ApiConnector(process.env.VUE_APP_API_HOST);

export const getByOrderId = (token, ordine) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloClienteOrdineApi();
    let opts = {
      selectOptions: { options: { where: { ordine: ordine } } }, // SelectOptions | opzioni
    };
    apiInstance.getAllArticoloClienteOrdines(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const add = (token, articolo_cliente) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloClienteOrdineApi();
    apiInstance.addArticoloClienteOrdine(
      articolo_cliente,
      (error, data, response) => {
        if (error) {
          return reject(error);
        } else {
          resolve(response.body);
        }
      }
    );
  });
};

export const deleteByOrderId = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloClienteOrdineApi();
    apiInstance.deleteArticoloClienteOrdineByOrderId(
      id,
      (error, data, response) => {
        if (error) {
          return reject(error);
        } else {
          resolve(response.body);
        }
      }
    );
  });
};
