<template>
  <b-card bg-variant="light" header="Dettagli ordine" class="text-start">
    <b-form>
      <b-form-group
        id="input-group-1"
        label="Tecnico:"
        label-for="input-1"
        class="mb-2"
      >
        <select
          class="form-control"
          v-model="form.details.technician"
          :disabled="!canUpdateDettagli || !detailsEditable"
        >
          <option :value="null">Seleziona tecnico</option>
          <option v-for="utente in users" :key="utente.id" :value="utente.id">
            {{ utente.name }}
          </option>
        </select>
      </b-form-group>

      <b-form-group
        id="input-group-2"
        label="Canale di vendita:"
        label-for="input-2"
        class="mb-2"
      >
        <select
          class="form-control"
          v-model="form.details.type"
          :disabled="!canUpdateDettagli || !detailsEditable"
        >
          <option :value="null">Seleziona tipologia</option>
          <option v-for="tipo in tipiOrdine" :key="tipo.id" :value="tipo.id">
            {{ tipo.descrizione }}
          </option>
        </select>
      </b-form-group>

      <b-form-group
        id="input-group-3"
        label="Data consegna:"
        label-for="input-3"
        class="mb-2"
      >
        <b-form-datepicker
          id="input-3"
          v-model="form.details.escape_date"
          class="mb-2"
          :disabled="!canUpdateDettagli || !detailsEditable"
        ></b-form-datepicker>
      </b-form-group>

      <b-form-group
        id="input-group-4"
        label="Note tecniche:"
        label-for="input-4"
        class="mb-2"
      >
        <b-form-textarea
          id="input-4"
          v-model="form.details.tec_note"
          placeholder="Note tecniche"
          :disabled="!canUpdateDettagli || !detailsEditable"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
        id="input-group-5"
        label="Note amministrative:"
        label-for="input-5"
        class="mb-2"
      >
        <b-form-textarea
          id="input-5"
          v-model="form.details.admin_note"
          placeholder="Note amministrative"
          :disabled="!canUpdateDettagli || !detailsEditable"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group
        id="input-group-6"
        label="Testo aggiuntivo corpo fattura:"
        label-for="input-6"
        class="mb-2"
      >
        <b-form-textarea
          id="input-6"
          v-model="form.details.billing_note"
          placeholder="Testo aggiuntivo corpo fattura"
          :disabled="!canUpdateDettagli || !detailsEditable"
        ></b-form-textarea>
      </b-form-group>

      <b-input-group class="mb-2">
        <b-input-group-prepend is-text>
          <b-form-checkbox
            type="checkbox"
            v-model="form.details.wait_payment"
            :disabled="!canUpdateDettagli || !detailsEditable"
          ></b-form-checkbox>
        </b-input-group-prepend>
        <b-form-input readonly value="IN ATTESA DI PAGAMENTO"></b-form-input>
      </b-input-group>

      <b-input-group class="mb-2">
        <b-input-group-prepend is-text>
          <b-form-checkbox
            type="checkbox"
            v-model="form.details.invia_comunicazione"
            :disabled="!canUpdateDettagli || !detailsEditable"
          ></b-form-checkbox>
        </b-input-group-prepend>
        <b-form-input readonly value="INVIA MAIL DI BENVENUTO"></b-form-input>
      </b-input-group>
    </b-form>
  </b-card>
</template>

<script>
export default {
  props: { form: Object, detailsEditable: Boolean },
  name: "OrderFormDetails",
  data() {
    return {};
  },
  form: {
    handler(val) {
      this.$emit("update", val);
    },
    deep: true,
  },
  computed: {
    canUpdateDettagli() {
      if (!this.$route.params.id) return true;
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:ordine:dettagli"
          ) != -1
        );
      }
      return false;
    },
    tipiOrdine() {
      return this.$store.state.tipi_ordine;
    },
    users() {
      return this.$store.state.utenti.filter(
        (utente) => !utente.name.startsWith("*")
      );
    },
  },
};
</script>
