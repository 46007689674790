import ApiConnector from "./ApiConnector";
let connector = new ApiConnector(process.env.VUE_APP_API_HOST);
import axios from "axios";

export const getByCustomerId = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ContattoApi();
    let opts = {
      selectOptions: { options: { where: { cliente: id } } }, // SelectOptions | opzioni
    };
    apiInstance.getAllContattos(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const add = (token, contatto) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ContattoApi();
    apiInstance.addContatto(contatto, (error, data, response) => {
      if (error) {
        if (response.body.error) {
          return reject(response.body.error);
        }
        reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const cancel = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ContattoApi();
    apiInstance.deleteContattoById(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getAll = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ContattoApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getAllContattos(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const update = (token, id, contatto) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ContattoApi();
    apiInstance.updateContatto(id, contatto, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getCount = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ContattoApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.countContattos(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getPage = (token, page = 1, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ContattoApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getContattoPage(page, opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getTelephonePriority = (token, telephone) => {
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/contatto/priorita/${telephone}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const setTelephonePriority = (token, telephone, cliente) => {
  return axios.post(
    `${process.env.VUE_APP_API_HOST}/contatto/priorita`,
    {
      telephone: telephone,
      cliente: cliente,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
