<template>
  <b-card bg-variant="light" header="Articoli Cliente">
    <b-form-group
      description="Gli articoli inseriti in questa sezione saranno importati in trace senza essere fatturati."
      label="Aggiungi un articolo:"
      label-for="select-articoli"
    >
      <v-select
        id="select-articoli"
        :options="articoliSelect"
        @input="add"
        label="title"
        :disabled="!canUpdateArticoliCliente"
      >
        <template v-slot:option="option">
          <div class="d-flex justify-content-between">
            <span>{{ option.title }}</span> <span>{{ option.id_trace }}</span>
          </div>
        </template>
      </v-select>
    </b-form-group>
    <b-table :items="articoliTabella" stacked="md">
      <template #cell(articolo)="data">
        <div class="text-start">{{ data.value }}</div>
      </template>
      <template #cell(attivazione)="data">
        <b-form-datepicker
          v-model="data.item.attivazione"
          :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
          }"
          @input="change(data.index, data.field.key, data.item.attivazione)"
          reset-button
          close-button
          :disabled="!canUpdateArticoliCliente"
        ></b-form-datepicker>
      </template>
      <template #cell(matricola)="data">
        <b-input
          v-model="data.item.matricola"
          @input="change(data.index, data.field.key, data.item.matricola)"
          :disabled="!canUpdateArticoliCliente"
        ></b-input>
      </template>
      <template #cell(cancella)="data">
        <b-button
          @click="del(data.value)"
          variant="light"
          :disabled="!canUpdateArticoliCliente"
        >
          <b-icon icon="trash" variant="danger" aria-hidden="true"></b-icon>
        </b-button>
      </template>
    </b-table>
  </b-card>
</template>

<script>
export default {
  props: { form: Object },
  name: "OrderFormArticoliCliente",
  form: {
    handler(val) {
      this.$emit("update", val);
    },
    deep: true,
  },
  data() {
    return {
      articoli: [],
    };
  },
  async created() {
    this.$loading(true);
    const accessToken = await this.$auth.getTokenSilently();
    await this.$store.dispatch("caricaArticoli", accessToken);
    this.articoli = this.$store.state.articoli;
    this.$loading(false);
  },
  methods: {
    getArticleById(id) {
      return this.$store.state.articoli_search[id];
    },
    change(index, field, value) {
      this.form.articoliCliente.items[index][field] = value;
    },
    del(id) {
      if (this.form.articoliCliente) {
        //Cerco l'indice dell'articolo da eliminare
        const index = this.form.articoliCliente.items.findIndex(
          (value) => value.articolo == id
        );
        //Elimino l'articolo
        this.form.articoliCliente.items.splice(index, 1);
      }
    },
    add(articolo) {
      const data = {
        articolo: articolo.id,
        attivazione: null,
        matricola: "",
      };
      this.form.articoliCliente.items.push(data);
    },
  },
  computed: {
    canUpdateArticoliCliente() {
      if (!this.$route.params.id) return true;

      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:ordine:articolicliente"
          ) != -1
        );
      }
      return false;
    },
    articoliTabella() {
      return this.form.articoliCliente.items.map((row) => {
        let article = this.getArticleById(row.articolo);

        return {
          articolo: article ? article.title : row.articolo,
          attivazione: row.attivazione,
          matricola: row.matricola,
          cancella: row.articolo,
        };
      });
    },
    articoliSelect() {
      return this.articoli.filter((value) => {
        return value.gruppo_gruppo && value.gruppo_gruppo.aggiungi_a_cliente;
      });
    },
  },
};
</script>
