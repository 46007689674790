<template>
  <div v-if="dataReady">
    <!-- CARICAMENTO MODALI -->
    <div>
      <!-- MODALE DISDETTA -->
      <b-modal
        id="modale-disdetta"
        v-model="disdetta.modaleAperto"
        :title="'Disdetta Articolo ' + disdetta.id"
        @ok="effettuaDisdetta"
        centered
      >
        <b-form>
          <b-form-group label="Motivazione:" label-for="motivazione">
            <b-form-select
              class="form-control"
              v-model="disdetta.motivazione"
              :options="motivazioniDisdetta"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Note:" label-for="note">
            <b-form-textarea
              id="note"
              v-model="disdetta.note"
              placeholder="Inserisci note"
              required
            ></b-form-textarea>
          </b-form-group>
        </b-form>
        <!-- Usa b-form modal-footer slot per sovrascrivere bottoni ok e annulla -->
        <template #modal-footer="{ ok, cancel }">
          <b-button variant="danger" @click="cancel()"> Annulla </b-button>
          <b-button
            :disabled="disdetta.id == null || disdetta.motivazione == null"
            variant="success"
            @click="ok()"
          >
            Conferma
          </b-button>
        </template>
      </b-modal>

      <!-- MODALE DISDETTA TOTALE -->
      <b-modal
        id="modale-disdetta"
        v-model="disdettaTot.modaleAperto"
        title="Disdetta Totale"
        @ok="effettuaDisdettaTot"
        centered
      >
        <b-form>
          <b-form-group label="Motivazione:" label-for="motivazione">
            <b-form-select
              class="form-control"
              v-model="disdettaTot.motivazione"
              :options="motivazioniDisdetta"
            ></b-form-select>
          </b-form-group>
          <b-form-group label="Note:" label-for="note">
            <b-form-textarea
              id="note"
              v-model="disdettaTot.note"
              placeholder="Inserisci note"
              required
            ></b-form-textarea>
          </b-form-group>
        </b-form>
        <!-- Usa b-form modal-footer slot per sovrascrivere bottoni ok e annulla -->
        <template #modal-footer="{ ok, cancel }">
          <b-button variant="danger" @click="cancel()"> Annulla </b-button>
          <b-button
            :disabled="disdettaTot.motivazione == null"
            variant="success"
            @click="ok()"
          >
            Conferma
          </b-button>
        </template>
      </b-modal>

      <!-- MODALE INFO -->
      <b-modal
        id="modale-info"
        v-model="modalConfigAperto"
        size="xl"
        class="mt-2"
        title="Informazioni Rinnovo"
        centered
        hide-footer
      >
        <form class="row g-3">
          <div class="col-md-6">
            <label for="pagamento" class="form-label">Pagamento</label>
            <b-select
              class="form-control"
              id="pagamento"
              :options="metodiPagamento"
              v-model="configurazioneRinnovoLocale.pagamento"
              v-on:change="modificaConfigurazioneRinnovoLocale"
            ></b-select>
          </div>

          <div class="col-md-6">
            <label for="fatt_alt" class="form-label"
              >Fatturazione Alternativa</label
            >
            <v-select
              id="fatt_alt"
              :options="clienti"
              :reduce="(item) => item.value"
              v-model="configurazioneRinnovoLocale.fatturazione_alternativa"
              v-on:input="modificaConfigurazioneRinnovoLocale"
              label="text"
            ></v-select>
            <!-- <b-select
              class="form-control"
              id="fatt_alt"
              :options="clienti"
              v-model="configurazioneRinnovoLocale.fatturazione_alternativa"
              v-on:change="modificaConfigurazioneRinnovoLocale"
            ></b-select> -->
          </div>

          <div class="col-md-12">
            <label for="note" class="form-label">Note</label>
            <textarea
              type="text"
              class="form-control"
              v-model="configurazioneRinnovoLocale.note"
              v-on:change="modificaConfigurazioneRinnovoLocale"
              id="note"
            >
            </textarea>
          </div>

          <div class="col-md-4">
            <label for="iban" class="form-label">IBAN Cliente</label>
            <input
              type="text"
              :class="classiInputIbanCliente"
              id="iban"
              v-model="ibanCliente"
            />
            <div class="form-text" v-if="usaIbanCliente">
              utlizzato in fattura
            </div>
          </div>
          <div class="col-md-4">
            <label for="iban" class="form-label">IBAN Locale</label>
            <input
              type="text"
              :class="classiInputIbanLocale"
              id="iban"
              v-model="ibanLocale"
            />
            <div class="form-text" v-if="usaIbanLocale">
              utlizzato in fattura
            </div>
          </div>
          <div class="col-md-4">
            <label for="iban" class="form-label"
              >IBAN Fatturazione alternativa</label
            >
            <input
              type="text"
              :class="classiInputIbanFattAlt"
              id="iban"
              :disabled="
                configurazioneRinnovoLocale.fatturazione_alternativa
                  ? false
                  : true
              "
              v-model="ibanFatturazioneAlternativa"
            />
            <div class="form-text" v-if="usaIbanFattAlt">
              utlizzato in fattura
            </div>
          </div>

          <div class="col-md-6">
            <label class="switch">
              <input
                type="checkbox"
                v-model="configurazioneRinnovoLocale.tacito_rinnovo"
                v-on:change="modificaConfigurazioneRinnovoLocale"
              />
              <span class="slider round"></span>
            </label>
            <label class="ps-2">&nbsp;Tacito Rinnovo </label>
          </div>
        </form>
      </b-modal>

      <!-- MODALE PROPOSTA -->
      <b-modal
        id="modale-proposta"
        v-model="modalPropostaAperto"
        size="sm"
        class="mt-2"
        title="PDF"
        centered
        hide-footer
      >
        <div class="d-flex flex-column">
          <b-button
            class="m-2"
            :disabled="!haProposta"
            :href="pdfEndpointProposta"
            target="_blank"
            >PDF Proposta</b-button
          >

          <b-button class="m-2" :href="pdfEndpointCompleto" target="_blank"
            >PDF Completo</b-button
          >
        </div>
      </b-modal>

      <!-- MODALE MAIL -->
      <b-modal
        id="modale-mail"
        v-model="modalMailAperto"
        size="sm"
        class="mt-2"
        title="Mail"
        centered
        hide-footer
      >
        <div class="d-flex flex-column">
          <b-button class="m-2" :disabled="!haProposta" @click="routeToMail"
            >Mail Proposta</b-button
          >

          <b-button class="m-2" @click="routeToMailCompleto"
            >Mail Completo</b-button
          >
        </div>
      </b-modal>

      <!-- MODALE ARTICOLI -->
      <b-modal
        id="modale-articolo"
        v-model="modalArticoloAperto"
        size="md"
        class="mt-2"
        title="Aggiungi Articolo"
        centered
        hide-footer
      >
        <div class="d-flex flex-column">
          <b-form @submit="aggiungiArticolo">
            <b-form-group label="Articolo:" label-for="articolo">
              <ArticleSelect
                id="articolo"
                v-model="articleForm.articolo"
                v-on:input="setArticlePrice"
                non_canoni
              ></ArticleSelect>
            </b-form-group>

            <b-form-group label="Prezzo:" label-for="prezzo">
              <b-form-input
                id="prezzo"
                type="number"
                min="0"
                step="0.01"
                v-model="articleForm.prezzo"
                placeholder="Prezzo"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Sconto:" label-for="sconto">
              <b-form-input
                id="sconto"
                type="number"
                min="0"
                step="0.01"
                v-model="articleForm.sconto"
                placeholder="Sconto"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Matricola:" label-for="matricola">
              <b-form-input
                id="matricola"
                v-model="articleForm.matricola"
                placeholder="Matricola"
              ></b-form-input>
            </b-form-group>

            <b-form-group label="Scadenza:" label-for="scadenza">
              <b-form-input
                id="scadenza"
                type="date"
                v-model="articleForm.data_scadenza"
                placeholder="Scadenza"
                required
                readonly
              ></b-form-input>
            </b-form-group>

            <div class="text-end mt-3">
              <b-button
                :disabled="articleForm.articolo == null"
                type="submit"
                variant="primary"
                >Conferma</b-button
              >
            </div>
          </b-form>
        </div>
      </b-modal>

      <!-- MODALE CAMBIO PIANO -->
      <b-modal
        id="modale-cambio-piano"
        v-model="cambioPiano.modaleAperto"
        size="lg"
        class="mt-2"
        title="Cambio Piano"
        @ok="effettuaCambioPiano"
        centered
      >
        <div>
          <b-form-group label="Articolo:" label-for="select-cambio-piano">
            <v-select
              id="select-cambio-piano"
              :options="cambioPiano.articoli"
              v-model="cambioPiano.articolo"
              @input="aggiornaPrezzoArticoloCambioPiano"
              label="title"
            ></v-select>
          </b-form-group>
          <div class="row mt-2">
            <b-form-group
              class="col-md-6"
              label="Prezzo:"
              label-for="prezzo-cambio-piano"
            >
              <b-form-input
                id="prezzo-cambio-piano"
                v-model="cambioPiano.prezzo"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              class="col-md-6"
              label="Matricola:"
              label-for="matricola-cambio-piano"
            >
              <b-form-input
                id="matricola-cambio-piano"
                v-model="cambioPiano.matricola"
              ></b-form-input>
            </b-form-group>
          </div>
          <b-form-checkbox class="mt-2" v-model="cambioPiano.anticipa">
            &nbsp; ANTICIPA SCADENZA AD OGGI
          </b-form-checkbox>
        </div>
        <template #modal-footer="{ ok, cancel }">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="md" variant="danger" @click="cancel()">
            Annulla
          </b-button>
          <b-button
            :disabled="cambioPiano.articolo == null || cambioPiano.prezzo == ''"
            size="md"
            variant="success"
            @click="ok()"
          >
            Conferma
          </b-button>
        </template>
      </b-modal>
    </div>

    <!-- MAIN  -->
    <main class="m-3">
      <!-- titolo e sottotitolo -->
      <header>
        <h1>Rinnovo {{ rinnovo.id }}</h1>
        <h5>{{ sottoTitolo }}</h5>
      </header>

      <!-- pulsanti apertura modali e operazioni -->
      <section>
        <!-- bottone modale info -->
        <b-button
          variant="primary"
          title="Informazioni Rinnovo"
          v-b-modal.modale-info
          @click="modalConfigAperto = !modalConfigAperto"
        >
          <font-awesome-icon icon="fa-solid fa-info-circle" />
        </b-button>

        <!-- bottone routing mail -->
        <b-button
          variant="warning"
          class="ms-2"
          @click="modalMailAperto = !modalMailAperto"
          title="Invia Mail"
        >
          <font-awesome-icon icon="fa-solid fa-paper-plane" />
        </b-button>

        <!-- bottone modale proposta  -->
        <b-button
          v-b-modal.modale-proposta
          variant="danger"
          @click="modalPropostaAperto = !modalPropostaAperto"
          class="ms-2"
          title="Apri PDF"
        >
          <font-awesome-icon icon="fa-solid fa-file-pdf" />
        </b-button>

        <!-- bottone modale inserimento articolo -->
        <b-button
          v-b-modal.modale-articolo
          title="Aggiungi articolo"
          @click="modalArticoloAperto = !modalArticoloAperto"
          class="ms-2"
          :disabled="!canUpdateAggiungiArticolo"
        >
          <font-awesome-icon icon="fa-solid fa-plus" />
        </b-button>

        <!-- bottone rinnovo -->
        <b-button
          variant="success"
          :disabled="!canUpdateRinnova || haProposta"
          class="ms-2"
          v-on:click="rinnova"
        >
          <font-awesome-icon icon="fa-solid fa-check" />
          Rinnova
        </b-button>

        <!-- bottone disdetta totale -->
        <b-button
          variant="danger"
          :disabled="!canUpdateDisdetta || haProposta"
          class="ms-2"
          v-on:click="() => caricaModaleDisdettaTotale()"
        >
          <font-awesome-icon icon="fa-regular fa-trash-can" />
          Disdetta Totale
        </b-button>
      </section>

      <!-- tabella articoli rinnovo -->
      <b-table stacked="md" class="mt-2" striped hover :items="articoliTabella">
        <template #cell(prezzo)="data">
          <b-input-group append="€">
            <b-form-input
              type="number"
              v-model="data.item.prezzo"
              min="0"
              step="0.01"
              :disabled="data.item.proposta"
              v-on:change="() => modificaItemRinnovo(data)"
            ></b-form-input>
          </b-input-group>
        </template>
        <template #cell(sconto)="data">
          <b-input-group append="€">
            <b-form-input
              type="number"
              v-model="data.item.sconto"
              min="0"
              step="0.01"
              :disabled="data.item.proposta"
              v-on:change="() => modificaItemRinnovo(data)"
            ></b-form-input>
          </b-input-group>
        </template>
        <template #cell(matricola)="data">
          <b-form-input
            type="text"
            v-model="data.item.matricola"
            :disabled="data.item.proposta"
            v-on:change="() => modificaItemRinnovo(data)"
          ></b-form-input>
        </template>
        <template #cell(scadenza)="data">
          <!-- <b-input-group>
            <b-form-input
              type="date"
              v-model="data.value"
              min="0"
              step="0.01"
              :disabled="true"
            ></b-form-input>
            <b-input-group-append>
              <b-button>
                <font-awesome-icon icon="edit" class="fa-regular pointer">
                </font-awesome-icon>
              </b-button>
            </b-input-group-append>
          </b-input-group> -->
          <b-input
            type="date"
            v-model="data.value"
            :min="minDate"
            :max="maxDate"
            v-on:input="(newDate) => updateDate(data.item.id, newDate)"
          ></b-input>
          <!-- {{ formatDate(data.value) }} -->
        </template>

        <template #cell(proposta)="data">
          <b-form-checkbox
            class="text-center"
            v-model="data.item.proposta"
            v-on:change="() => modificaItemRinnovo(data)"
          ></b-form-checkbox>
        </template>
        <template #cell(modifica)="data">
          <div class="text-center">
            <b-button
              :disabled="!canUpdateCambioPiano || data.value.t_trace == null"
              variant="primary"
              v-on:click="caricaModaleCambioPiano(data.value)"
            >
              <font-awesome-icon icon="edit" class="fa-regular pointer">
              </font-awesome-icon>
            </b-button>
          </div>
        </template>
        <template #cell(disdetta)="data">
          <div class="text-center">
            <b-button
              v-if="data.item.disdetta.t_trace != null"
              v-on:click="() => caricaModaleDisdetta(data.item.disdetta)"
              variant="danger"
              :disabled="!canUpdateDisdetta"
            >
              <font-awesome-icon icon="fa-regular fa-trash-can" />
            </b-button>
            <b-button
              v-else
              variant="warning"
              v-on:click="() => eliminazioneDiretta(data.item.disdetta)"
              :disabled="!canUpdateDisdetta"
            >
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </b-button>
          </div>
        </template>
      </b-table>
    </main>
  </div>
</template>

<script>
import * as rinnoviAnnualiRepo from "../../services/RinnoviAnnuali";
import * as clienteRepo from "../../services/Cliente";
import * as localeRepo from "../../services/Locale";

import ArticleSelect from "@/components/common/ArticleSelect.vue";

export default {
  name: "RinnovoAnnuale",
  components: { ArticleSelect },
  data() {
    return {
      //carica la pagina solo quando i dati sono pronti
      dataReady: false,
      //rinnovo scaricato
      rinnovo: {},
      modalConfigAperto: false,
      modalPropostaAperto: false,
      modalMailAperto: false,
      modalArticoloAperto: false,
      //modale disdetta
      disdetta: {
        modaleAperto: false,
        id: null,
        motivazione: null,
        note: null,
      },
      //modale disdetta totale
      disdettaTot: {
        modaleAperto: false,
        motivazione: null,
        note: null,
      },
      //modale info
      configurazioneRinnovoLocale: {
        esistente: false,
        pagamento: null,
        note: null,
        fatturazione_alternativa: null,
        tacito_rinnovo: false,
      },
      //modale inserimento articoli
      articleForm: {
        articolo: null,
        prezzo: 0,
        sconto: 0,
        matricola: null,
        data_scadenza: null,
      },
      //Modale cambio piano
      cambioPiano: {
        modaleAperto: false,
        id: null,
        articoli: [],
        articolo: null,
        prezzo: 0,
        matricola: null,
        anticipa: false,
      },
    };
  },
  //Quando viene creato il componente
  async created() {
    this.$loading(true);
    //Carico elementi necessari vuex
    const accessToken = await this.$auth.getTokenSilently();
    await this.$store.dispatch("caricaMotivazioniDisdetta", accessToken);
    await this.$store.dispatch("caricaMetodiDiPagamento", accessToken);
    await this.$store.dispatch("caricaClienti", accessToken);
    await this.$store.dispatch("caricaArticoli", accessToken);

    //Carico il rinnovo
    await this.loadRinnovo();

    //Preparo la data di scadenza per il form di inserimento articoli
    this.articleForm.data_scadenza = this.rinnovo.mese;
    //Dati pronti, v-if per l'intero componente
    this.dataReady = true;
    this.$loading(false);
  },
  computed: {
    canUpdateAggiungiArticolo() {
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:rinnovo:annuale:articolo"
          ) != -1
        );
      }
      return false;
    },
    canUpdateRinnova() {
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:rinnovo:annuale:rinnova"
          ) != -1
        );
      }
      return false;
    },
    canUpdateDisdetta() {
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:rinnovo:annuale:disdetta"
          ) != -1
        );
      }
      return false;
    },
    canUpdateCambioPiano() {
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:rinnovo:annuale:cambiopiano"
          ) != -1
        );
      }
      return false;
    },
    minDate() {
      let meseAnno = new Date(this.rinnovo.mese);
      meseAnno.setDate(1);
      return this.dateToString(meseAnno);
    },
    maxDate() {
      let max = new Date(this.minDate);
      max.setMonth(max.getMonth() + 1);
      max.setDate(1);
      max.setDate(0);
      return this.dateToString(max);
    },
    //id del rinnovo (estratto da route)
    id() {
      return parseInt(this.$route.params.id);
    },
    //Sottotitolo pagina
    sottoTitolo() {
      const nome = this.rinnovo.locale_locale
        ? this.rinnovo.locale_locale.nome
        : "";
      const ragsoc = this.rinnovo.cliente_cliente
        ? this.rinnovo.cliente_cliente.ragsoc
        : "";
      return `${nome} ( ${ragsoc} )`;
    },
    //Endpoint per apertura pdf proposta
    pdfEndpointProposta() {
      return process.env.VUE_APP_API_HOST + "/pdf/rinnovo/proposta/" + this.id;
    },
    //Endpoint per apertura pdf completo
    pdfEndpointCompleto() {
      return process.env.VUE_APP_API_HOST + "/pdf/rinnovo/" + this.id;
    },
    //true se almeno un articolo del rinnovo ha proposta
    haProposta() {
      if (!this.rinnovo.articolo_rinnovos) return false;
      let proposta = false;
      for (const item of this.rinnovo.articolo_rinnovos) {
        if (item.proponi) {
          proposta = true;
          break;
        }
      }
      return proposta;
    },
    //Metodi di pagamento per select modale info
    metodiPagamento() {
      const empty = {
        text: "Seleziona pagamento...",
        value: null,
      };
      return [empty].concat(
        this.$store.state.metodi_di_pagamento.map((pagamento) => {
          return {
            value: pagamento.cod_pag_NEW,
            text: pagamento.des_pag,
          };
        })
      );
    },
    //Clienti per select fatturazione alternativa
    clienti() {
      const empty = {
        text: "Seleziona cliente...",
        value: null,
      };
      return [empty].concat(
        this.$store.state.clienti.map((cliente) => {
          return {
            text: cliente.ragsoc,
            value: cliente.id,
          };
        })
      );
    },
    //Motivazioni disdetta per select disdetta
    motivazioniDisdetta() {
      return [{ text: "Seleziona motivazione..", value: null }].concat(
        this.$store.state.motivazioni_disdetta
          .filter((motivazione) => !motivazione.descrizione.startsWith("*"))
          .map((motivazione) => {
            const statDescription =
              motivazione.motivazione_statistica != null
                ? " (statistiche)"
                : "";
            return {
              text: motivazione.descrizione + statDescription,
              value: motivazione.id,
            };
          })
      );
    },
    //Articoli del rinnovo, mappati per inserimento in tabella
    articoliTabella() {
      if (!this.rinnovo.articolo_rinnovos) {
        return [];
      }
      return this.rinnovo.articolo_rinnovos.map((articolo) => {
        return {
          id: articolo.id,
          id_articolo: articolo.articolo_articolo.id_trace,
          articolo: articolo.articolo_articolo.title,
          listino: this.formatPrice(
            articolo.articolo_articolo.prezzos[0]
              ? articolo.articolo_articolo.prezzos[0].prz_listino
              : 0
          ),
          prezzo: articolo.prezzo,
          sconto: articolo.sconto,
          matricola: articolo.matricola,
          scadenza: articolo.data_scadenza,
          proposta: articolo.proponi,
          modifica: articolo,
          disdetta: articolo,
        };
      });
    },
    //True se il rinnovo userà l'iban del locale all'interno della fattura, false altrimenti
    usaIbanLocale() {
      if (this.usaIbanFattAlt) return false;

      return this.ibanLocaleOK;
    },
    //True se il rinnovo userà l'iban del cliente all'interno della fattura, false altrimenti
    usaIbanCliente() {
      return !this.usaIbanFattAlt && !this.usaIbanLocale;
    },
    //True se il rinnovo userà l'iban del cliente della fatturazione alternativa
    // all'interno della fattura, false altrimenti
    usaIbanFattAlt() {
      if (!this.rinnovo.locale_locale.configurazione_rinnovo_locale)
        return false;
      return this.rinnovo.locale_locale.configurazione_rinnovo_locale
        .fatturazione_alternativa_cliente
        ? true
        : false;
    },
    //True se l'iban del locale è stato compilato correttamente, false altrimenti
    ibanLocaleOK() {
      return this.ibanLocale ? true : false;
    },
    //True se l'iban del cliente è stato compilato correttamente, false altrimenti
    ibanClienteOK() {
      return this.ibanCliente ? true : false;
    },
    //True se l'iban del cliente della fatturazione alternativa è stato compilato correttamente, false altrimenti
    ibanFattAltOK() {
      return this.ibanFatturazioneAlternativa ? true : false;
    },
    //Ottieni e modifica l'iban del locale
    ibanLocale: {
      get: function () {
        if (!this.rinnovo.locale_locale) return "";
        return this.rinnovo.locale_locale.iban;
      },
      set: debounce(async function (newValue) {
        //Se è vuoto lo imposto a null
        newValue = newValue ? newValue : null;
        //modifico valore attuale in locale
        this.rinnovo.locale_locale.iban = newValue;
        //invio modifica a api
        try {
          const accessToken = await this.$auth.getTokenSilently();
          const editResponse = await localeRepo.update(
            accessToken,
            this.rinnovo.locale,
            { iban: newValue }
          );
          this.$toast.open("IBAN Locale modificato");
        } catch (e) {
          console.log(e);
          this.$toast.error(
            "Errore modifica iban locale: " +
              e.response.body.error.details[0].message
          );
        }
      }, 250),
    },
    //Ottieni e modifica l'iban del cliente
    ibanCliente: {
      get: function () {
        if (!this.rinnovo.cliente_cliente) return "";
        return this.rinnovo.cliente_cliente.iban;
      },
      set: debounce(async function (newValue) {
        //Se è vuoto lo imposto a null
        newValue = newValue ? newValue : null;
        //modifico valore attuale in locale
        this.rinnovo.cliente_cliente.iban = newValue;
        //invio modifica a api
        try {
          const accessToken = await this.$auth.getTokenSilently();
          const editResponse = await clienteRepo.update(
            accessToken,
            this.rinnovo.cliente,
            { iban: newValue }
          );
          this.$toast.open("IBAN Cliente modificato");
        } catch (e) {
          this.$toast.error(
            "Errore modifica iban cliente: " +
              e.response.body.error.details[0].message
          );
        }
      }, 250),
    },
    //Ottieni e modifica l'iban della fatturazione alternativa
    ibanFatturazioneAlternativa: {
      get: function () {
        if (!this.rinnovo.locale_locale.configurazione_rinnovo_locale)
          return "";
        if (
          !this.rinnovo.locale_locale.configurazione_rinnovo_locale
            .fatturazione_alternativa_cliente
        )
          return "";
        return this.rinnovo.locale_locale.configurazione_rinnovo_locale
          .fatturazione_alternativa_cliente.iban;
      },
      set: debounce(async function (newValue) {
        //Se è vuoto lo imposto a null
        newValue = newValue ? newValue : null;
        //modifico valore attuale in locale
        this.rinnovo.locale_locale.configurazione_rinnovo_locale.fatturazione_alternativa_cliente.iban =
          newValue;
        //invio modifica a api
        try {
          const accessToken = await this.$auth.getTokenSilently();
          const editResponse = await clienteRepo.update(
            accessToken,
            this.rinnovo.locale_locale.configurazione_rinnovo_locale
              .fatturazione_alternativa_cliente.id,
            { iban: newValue }
          );
          this.$toast.open("IBAN Fatturazione alternativa modificato");
        } catch (e) {
          this.$toast.error(
            "Errore modifica iban fatturazione alternativa: " +
              e.response.body.error.details[0].message
          );
        }
      }, 250),
    },
    //Classi del campo di input iban locale
    classiInputIbanLocale() {
      let classes = "form-control";
      if (this.usaIbanLocale) {
        if (this.ibanLocaleOK) {
          classes += " border border-2 border-success";
        } else {
          classes += " border border-2 border-danger";
        }
      }
      return classes;
    },
    //Classi del campo di input iban cliente
    classiInputIbanCliente() {
      let classes = "form-control";
      if (this.usaIbanCliente) {
        if (this.ibanClienteOK) {
          classes += " border border-2 border-success";
        } else {
          classes += " border border-2 border-danger";
        }
      }
      return classes;
    },
    //Classi del campo di input iban fatturazione alternativa
    classiInputIbanFattAlt() {
      let classes = "form-control";
      if (this.usaIbanFattAlt) {
        if (this.ibanFattAltOK) {
          classes += " border border-2 border-success";
        } else {
          classes += " border border-2 border-danger";
        }
      }
      return classes;
    },
  },
  // watch: {
  //   configurazioneRinnovoLocale: {
  //     //Se viene modificata la fatturazione alternativa nel modale info carico le informazioni del cliente
  //     //da vuex e le carico all'interno del rinnovo nella configurazione_rinnovo_locale
  //     handler(val) {
  //       const cliente =
  //         this.$store.state.clienti_search[val.fatturazione_alternativa];
  //       if (cliente) {
  //         this.rinnovo.locale_locale.configurazione_rinnovo_locale.fatturazione_alternativa_cliente =
  //           cliente;
  //       } else {
  //         this.rinnovo.locale_locale.configurazione_rinnovo_locale.fatturazione_alternativa_cliente =
  //           null;
  //       }
  //     },
  //     deep: true,
  //   },
  // },
  methods: {
    dateToString(date) {
      let fDate = new Date(date);
      const day = ("0" + fDate.getDate()).slice(-2); // 012 => 12, 04 => 04  - ;)
      const month = ("0" + (fDate.getMonth() + 1)).slice(-2);
      return `${fDate.getFullYear()}-${month}-${day}`;
    },
    async updateDate(idArticoloRinnovo, newDate) {
      //Controllo che la nuova data sia una data valida
      //e compresa nell'intervallo minDate - maxDate
      let date = new Date(newDate);
      let min = new Date(this.minDate);
      let max = new Date(this.maxDate);

      if (date.toString() == "Invalid Date" || date < min || date > max) {
        this.$toast.error("La data inserita non è valida");
        return;
      }

      const accessToken = await this.$auth.getTokenSilently();
      this.$loading(true);
      try {
        await rinnoviAnnualiRepo.updateArticoloRinnovoAnnuale(
          accessToken,
          idArticoloRinnovo,
          { data_scadenza: this.dateToString(date) }
        );
        this.$loading(false);
        this.$toast.open("Scadenza modificata con successo");
      } catch (e) {
        this.$loading(false);
        this.$toast.error(e.response.body.error);
      }
    },
    /**
     * Richiamato da click su bottone rinnovo
     *
     * Esegue il rinnovo e, se eseguito con successo, ritorna alla pagina precedente
     */
    async rinnova() {
      if (!confirm("Confermi di voler effettuare il rinnovo?")) return;
      const accessToken = await this.$auth.getTokenSilently();
      this.$loading(true);
      try {
        await rinnoviAnnualiRepo.rinnova(accessToken, this.id);
        this.$loading(false);
        this.$router.go(-1);
      } catch (e) {
        this.$loading(false);
        this.$toast.error(e.response.body.error);
      }
    },
    /**
     * Richiamato da click su bottone disdetta totale
     *
     * Esegue il rinnovo e, se eseguito con successo, ritorna alla pagina precedente
     */
    async disdettaTotale() {
      if (
        !confirm(
          "Confermi di voler effettuare la disdetta di tutti gli articoli?"
        )
      )
        return;
      const accessToken = await this.$auth.getTokenSilently();
      this.$loading(true);
      try {
        // await rinnoviAnnualiRepo.disdettaTot(accessToken, this.id);
        this.$loading(false);
        this.$router.go(-1);
      } catch (e) {
        this.$loading(false);
        this.$toast.error(e.response.body.error);
      }
    },
    /**
     * Richiamato da click su bottone disdetta (articolo con trace_id)
     *
     * Prepara il modale disdetta per la disdetta dell'articolo richiesto
     */
    caricaModaleDisdetta(articolo) {
      this.disdetta.modaleAperto = true;
      this.disdetta.id = articolo.id;
      this.disdetta.motivazione = null;
      this.disdetta.note = null;
    },
    /**
     * Richiamato da click su bottone disdetta totale
     *
     * Prepara il modale disdetta per la disdetta dell'articolo richiesto
     */
    caricaModaleDisdettaTotale() {
      this.disdettaTot.modaleAperto = true;
      this.disdettaTot.motivazione = null;
      this.disdettaTot.note = null;
    },

    /**
     * Richiamato da click su bottone cambio piano
     *
     * Prepara il modale cambio piano per il cambio piano dell'articolo richiesto
     */
    caricaModaleCambioPiano(articolo) {
      try {
        const options = this.ottieniArticoliModifica(articolo);
        //Carico configurazione
        this.cambioPiano.id = articolo.id;
        this.cambioPiano.articoli = options;
        this.cambioPiano.articolo = null;
        this.cambioPiano.prezzo = 0;
        this.cambioPiano.matricola = null;
        this.cambioPiano.anticipa = false;
        //Apro il modale
        this.cambioPiano.modaleAperto = true;
      } catch (e) {
        this.$toast.error(e.message);
      }
    },

    /**
     * Richiamato da modifica dell'articolo in select cambio piano
     *
     * Modifica il prezzo di base del cambio piano inserendo quello dell'articolo selezionato
     */
    aggiornaPrezzoArticoloCambioPiano(articolo) {
      let prezzo = 0;
      if (articolo.prezzos && articolo.prezzos.length > 0) {
        prezzo = articolo.prezzos[0].prz_listino;
      } else {
        this.$toast.error("Non è stato trovato alcun prezzo per l'articolo");
      }
      this.cambioPiano.prezzo = prezzo;
    },

    /**
     * Richiamato da click su bottone conferma modale disdetta
     *
     * Esegue la disdetta dopo aver chiesto un'ulteriore conferma, se la disdetta viene
     * effettuata con successo richiama this.loadRinnovo() per ricaricare il rinnovo
     */
    async effettuaDisdetta() {
      if (!this.disdetta.id) {
        alert("Errore disdetta");
        return;
      }
      const confirm = await this.$bvModal.msgBoxConfirm(
        `Disdetta dell'articolo ${this.disdetta.id}, operazione non reversibile, continuare?`,
        {
          title: "Disdetta",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (confirm)
        try {
          const accessToken = await this.$auth.getTokenSilently();
          const response =
            await rinnoviAnnualiRepo.disdettaArticoloRinnovoAnnuale(
              accessToken,
              this.disdetta.id,
              this.disdetta.motivazione,
              this.disdetta.note
            );
          if (response) {
            this.loadRinnovo();
          }
        } catch (e) {
          alert("Errore disdetta");
          console.log(e);
        }
    },

    /**
     * Richiamato da click su bottone conferma modale disdetta totale
     *
     * Esegue la disdetta dopo aver chiesto un'ulteriore conferma, se la disdetta viene
     * effettuata con successo torna alla pagina precedente
     */
    async effettuaDisdettaTot() {
      const confirm = await this.$bvModal.msgBoxConfirm(
        `Disdetta totale, operazione non reversibile, continuare?`,
        {
          title: "Disdetta Totale",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (confirm)
        try {
          const accessToken = await this.$auth.getTokenSilently();
          const response = await rinnoviAnnualiRepo.disdettaTotale(
            accessToken,
            this.rinnovo.id,
            this.disdettaTot.motivazione,
            this.disdettaTot.note
          );

          if (response) {
            this.loadRinnovo();
          }
        } catch (e) {
          alert("Errore disdetta totale");
          console.log(e);
        }
    },
    /**
     * Richiamato da click su bottone conferma modale cambio piano
     *
     * Esegue il cambio piano dopo aver chiesto un'ulteriore conferma, se il cambio piano viene
     * effettuata con successo richiama this.loadRinnovo() per ricaricare il rinnovo
     */
    async effettuaCambioPiano() {
      const confirm = await this.$bvModal.msgBoxConfirm(
        `Cambio piano dell'articolo ${this.cambioPiano.id}, continuare?`,
        {
          title: "Cambio Piano",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (confirm) {
        const data = {
          articolo: this.cambioPiano.articolo.id,
          matricola: this.cambioPiano.matricola,
          prezzo: this.cambioPiano.prezzo,
          anticipa: this.cambioPiano.anticipa,
        };
        this.$loading(true);
        try {
          const accessToken = await this.$auth.getTokenSilently();
          const response = await rinnoviAnnualiRepo.cambioPiano(
            accessToken,
            this.cambioPiano.id,
            data
          );
          this.loadRinnovo();
        } catch (e) {
          this.$loading(false);
          this.$toast.error(e.message);
          console.error(e);
        }
      }
    },

    /**
     * Richiamato da click su bottone disdetta (articolo senza trace_id)
     *
     * Esegue l'eliminazione diretta dell'aricolo dopo aver chiesto un'ulteriore conferma,
     * se l'eliminazione diretta viene effettuata con successo richiama this.loadRinnovo()
     * per ricaricare il rinnovo
     *
     */
    async eliminazioneDiretta(articolo) {
      if (
        !confirm(
          `Eliminazione diretta articolo ${articolo.id},operazione non reversibile, continuare?`
        )
      )
        return;
      if (!articolo.id) {
        this.$toast.error("Errore eliminazioneDiretta id non trovato");
        return;
      }
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const deleteResponse =
          await rinnoviAnnualiRepo.deleteArticoloRinnovoAnnuale(
            accessToken,
            articolo.id
          );

        if (deleteResponse) {
          this.loadRinnovo();
        }
      } catch (e) {
        this.$toast.error("Errore eliminazione diretta");
      }
    },

    /**
     * Richiamato da selezione articolo in modale inserimento articoli
     *
     * Estrae il prezzo dell'articolo da vuex e lo mostra nel modale, se
     * l'articolo non viene trovato mostra prezzo 0
     */
    setArticlePrice(data) {
      if (!this.$store.state.articoli_search[data]) {
        this.articleForm.prezzo = 0;
        return;
      }
      let prezzo = 0;
      if (this.$store.state.articoli_search[data].prezzos[0]) {
        prezzo = this.$store.state.articoli_search[data].prezzos[0].prz_listino;
      }
      this.articleForm.prezzo = prezzo;
    },
    /**
     * Richiamato in fase di caricamento del modale del cambio piano
     *
     * Ottieni tutti gli articoli sostituibili all'articolo in input durante un cambio piano
     *
     */
    ottieniArticoliModifica(articolo) {
      if (!articolo.articolo_articolo) {
        throw new Error("Non è stato possibile risalire all'articolo");
      }
      if (!articolo.articolo_articolo.gruppo) {
        throw new Error(
          "Non è stato possibile determinare il gruppo dell'articolo"
        );
      }

      let gruppo = articolo.articolo_articolo.gruppo;
      let mth_12 = articolo.articolo_articolo.mth_12;
      let mth_24 = articolo.articolo_articolo.mth_24;
      let new_year = articolo.articolo_articolo.new_year;

      return this.$store.state.articoli.filter((value) => {
        return (
          value.gruppo == gruppo &&
          value.mth_12 == mth_12 &&
          value.mth_24 == mth_24 &&
          value.new_year == new_year
        );
      });
    },

    /**
     * Richiamato da click su pulsante mail
     *
     * Effettua routing alla pagina di invio mail del rinnovo attuale
     */
    routeToMail() {
      this.$router.push({ name: "mailRinnovo", params: { id: this.id } });
    },

    /**
     * Richiamato da click su pulsante mail
     *
     * Effettua routing alla pagina di invio mail del rinnovo attuale
     */
    routeToMailCompleto() {
      this.$router.push({
        name: "mailRinnovoCompleto",
        params: { id: this.id },
      });
    },
    /**
     * Richiamato inizialmente in fase di creazione e successivamente per ricaricare il rinnovo
     *
     * Carica il rinnovo relativo all'id della route attuale, se è presente la configurazione_rinnovo_locale
     * per il rinnovo di questo locale carica i dati all'interno del modale di informazioni
     */
    async loadRinnovo() {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();

      const include = [
        {
          model: "locale",
          as: "locale_locale",
          include: {
            model: "configurazione_rinnovo_locale",
            include: {
              model: "cliente",
              as: "fatturazione_alternativa_cliente",
            },
          },
        },
        {
          model: "cliente",
          as: "cliente_cliente",
        },
        {
          model: "articolo_rinnovo",
          as: "articolo_rinnovos",
          include: {
            model: "articolo",
            as: "articolo_articolo",
            include: ["prezzos"],
          },
        },
      ];
      this.rinnovo = await rinnoviAnnualiRepo.getByIdWithInclude(
        accessToken,
        this.id,
        include
      );

      if (this.rinnovo.articolo_rinnovos.length <= 0) {
        alert(
          "Non sono presenti ulteriori articoli all'interno del rinnovo, verrai reindirizzato alla pagina precedente"
        );
        this.$router.go(-1);
      }

      if (this.rinnovo.locale_locale.configurazione_rinnovo_locale) {
        //La prima modifica deve essere fatturazione alternativa per watcher
        this.configurazioneRinnovoLocale.fatturazione_alternativa =
          this.rinnovo.locale_locale.configurazione_rinnovo_locale.fatturazione_alternativa;
        this.configurazioneRinnovoLocale.esistente = true;
        this.configurazioneRinnovoLocale.pagamento =
          this.rinnovo.locale_locale.configurazione_rinnovo_locale.pagamento;
        this.configurazioneRinnovoLocale.note =
          this.rinnovo.locale_locale.configurazione_rinnovo_locale.note;
        this.configurazioneRinnovoLocale.tacito_rinnovo =
          this.rinnovo.locale_locale.configurazione_rinnovo_locale.tacito_rinnovo;
      }

      this.$loading(false);
    },

    /**
     * Formatta una data
     */
    formatDate(date) {
      if (date == null) return "";
      let splitted = date.substring(0, 10).split("-");
      return splitted[2] + "/" + splitted[1] + "/" + splitted[0];
    },

    /**
     * Formatta un prezzo
     */
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + "€"
      );
    },

    /**
     * Richiamato su modifica del modale info (tranne campi iban)
     *
     * Se esiste già una configurazione_rinnovo_locale per il locale del rinnovo attuale
     * viene aggiornata con i dati modificati, altrimenti viene creata la configurazione_rinnovo_locale
     */
    async modificaConfigurazioneRinnovoLocale() {
      const accessToken = await this.$auth.getTokenSilently();
      const data = {
        pagamento: this.configurazioneRinnovoLocale.pagamento,
        note:
          this.configurazioneRinnovoLocale.note == ""
            ? null
            : this.configurazioneRinnovoLocale.note,
        tacito_rinnovo: this.configurazioneRinnovoLocale.tacito_rinnovo,
        fatturazione_alternativa:
          this.configurazioneRinnovoLocale.fatturazione_alternativa,
      };
      if (this.configurazioneRinnovoLocale.esistente) {
        try {
          await rinnoviAnnualiRepo.updateConfigurazioneRinnovoLocale(
            accessToken,
            this.rinnovo.locale,
            data
          );
          this.loadRinnovo();
          this.$toast.open("Informazioni modificate correttamente");
        } catch (e) {
          this.$toast.error(
            "Errore modifica:\n" + JSON.stringify(e.response.body.error)
          );
          // alert("Errore modifica:\n" + JSON.stringify(e.response.body.error));
        }
      } else {
        try {
          const response =
            await rinnoviAnnualiRepo.addConfigurazioneRinnovoLocale(
              accessToken,
              this.rinnovo.locale,
              data
            );
          if (response) {
            this.loadRinnovo();
            // this.configurazioneRinnovoLocale.esistente = true;
            this.$toast.open("Informazioni modificate correttamente");
          }
        } catch (e) {
          this.$toast.error(
            "Errore creazione:\n" + JSON.stringify(e.response.body.error)
          );
        }
      }
    },

    /**
     * Richiamato su modifica dei campi di un articolo (prezzo, sconto, matricola, proposta)
     *
     * Effettua le modifiche e, se eseguite con successo, utilizza il metodo loadRinnovo() per
     * ricaricare il rinnovo
     */
    async modificaItemRinnovo(data) {
      const row = {
        prezzo: Number(data.item.prezzo),
        sconto: Number(data.item.sconto),
        matricola: data.item.matricola
          ? data.item.matricola.toString().trim() == ""
            ? null
            : data.item.matricola
          : null,
        proponi: data.item.proposta,
      };
      try {
        const accessToken = await this.$auth.getTokenSilently();
        const response = await rinnoviAnnualiRepo.updateArticoloRinnovoAnnuale(
          accessToken,
          data.item.id,
          row
        );
        this.$toast.open("Riga modificata correttamente");
        this.loadRinnovo();
      } catch (e) {
        this.$toast.error(
          "Errore modifica:\n" +
            JSON.stringify(e.response.body.error.details[0].message)
        );
      }
    },

    /**
     * Richiamato su conferma del modale di inserimento articoli
     *
     * Aggiunge l'articolo al rinnovo e utilizza il metodo loadRinnovo() per
     * ricaricare il rinnovo
     */
    async aggiungiArticolo(event) {
      event.preventDefault();

      const data = this.articleForm;

      //Aggiungo id rinnovo al payload
      data["rinnovo"] = this.id;
      //API Token
      const accessToken = await this.$auth.getTokenSilently();
      //API Request
      const response = await rinnoviAnnualiRepo.addArticoloRinnovoAnnuale(
        accessToken,
        data
      );
      //Chiudo il modale
      this.modalArticoloAperto = false;
      //Ricarico il rinnovo
      this.loadRinnovo();
    },
  },
};

/**
 * Applica effetto debounce ad una funzione
 */
function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}
</script>

<style scoped>
.btn-info {
  font-weight: bold;
}

/* SWITCH */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 20px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #cc3264;
}

input:focus + .slider {
  box-shadow: 0 0 1px #cc3264;
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
