<template>
  <b-card bg-variant="light" header="Locale" class="text-start">
    <b-modal
      no-close-on-backdrop
      id="modal-newlocation"
      title="Creazione Locale"
      ok-only
      ok-variant="secondary"
      ok-title="Chiudi"
    >
      <LocationCreationForm
        :cliente="form.customer.id"
        @locationCreation="locationCreated"
      ></LocationCreationForm>
    </b-modal>
    <div id="ricerca" v-if="!form.location.selected">
      <!-- Using components -->
      <b-input-group class="mt-3">
        <b-form-input
          placeholder="Nome locale o indirizzo"
          v-model="ricerca.stringa"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            v-b-modal.modal-newlocation
            v-b-tooltip.hover.left="'Crea nuovo locale'"
            variant="primary"
            :disabled="!canEditLocation"
            >+
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <b-list-group style="max-height: 50vh; overflow-y: scroll">
        <b-list-group-item
          v-for="locale in searchResult"
          :key="locale.id"
          v-on:click="selezionaLocale(locale)"
          class="d-flex justify-content-between"
          :disabled="!canEditLocation"
          button
        >
          <span class="h6">{{ locale.nome }}</span>
          <span>{{ locale.via }}</span>
        </b-list-group-item>
      </b-list-group>
    </div>
    <div v-else>
      <b-button
        v-if="editable && canEditLocation"
        size="sm"
        class="mb-2"
        v-on:click="deselezionaLocale()"
      >
        <b-icon icon="arrow-left" aria-hidden="true"></b-icon> Annulla Selezione
      </b-button>

      <b-button
        size="sm"
        class="mb-2"
        variant="info"
        :to="'/locali/' + form.location.id"
        target="_blank"
      >
        <b-icon icon="geo-alt" aria-hidden="true"></b-icon> Apri scheda locale
      </b-button>
      <b-form>
        <b-form-group id="input-group-0" label="Prg Trace:" label-for="input-0">
          <b-form-input
            id="input-0"
            v-model="formLocale.prg"
            type="email"
            placeholder="Prg Trace"
            readonly
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-1" label="Insegna:" label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="formLocale.nome"
            type="email"
            placeholder="Insegna"
            readonly
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Profilo:" label-for="input-2">
          <v-select
            id="fatt_alt"
            :options="profili_locale"
            :reduce="(item) => item.value"
            v-model="formLocale.profilo_locale"
            :clearable="false"
            label="text"
            v-on:input="modificaProfiloLocale"
            :disabled="!canEditLocation"
          ></v-select>
        </b-form-group>

        <b-form-group
          id="input-group-10"
          label="Categoria:"
          label-for="input-10"
        >
          <v-select
            id="categoria"
            :options="categorie_locale"
            :reduce="(item) => item.value"
            v-model="formLocale.categoria_locale"
            :clearable="false"
            label="text"
            v-on:input="modificaCategoriaLocale"
            :disabled="!canEditLocation"
          ></v-select>
        </b-form-group>

        <b-form-group id="input-group-3" label="Indirizzo:" label-for="input-3">
          <b-form-input
            id="input-3"
            v-model="formLocale.via"
            placeholder="Indirizzo"
            readonly
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-4" label="Cap:" label-for="input-4">
          <b-form-input
            id="input-4"
            v-model="formLocale.cap"
            placeholder="Cap"
            readonly
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-5" label="Città:" label-for="input-5">
          <b-form-input
            id="input-5"
            v-model="formLocale.citta"
            placeholder="Città"
            readonly
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-6" label="Provincia" label-for="input-6">
          <b-form-input
            id="input-6"
            v-model="formLocale.prov"
            placeholder="Provincia"
            readonly
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-7" label="IBAN" label-for="input-7">
          <b-form-input
            id="input-7"
            v-model="formLocale.iban"
            placeholder="IBAN"
            readonly
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-8" label="Note" label-for="input-8">
          <b-form-textarea
            id="input-8"
            v-model="formLocale.note"
            placeholder="Note"
            readonly
          ></b-form-textarea>
        </b-form-group>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import axios from "axios";
import LocationCreationForm from "@/components/common/LocationCreationForm.vue";
import * as localeRepo from "../../services/Locale";

export default {
  props: { form: Object, editable: Boolean },
  components: {
    LocationCreationForm,
  },
  data() {
    return {
      ricerca: {
        stringa: "",
      },
      locali: [],
    };
  },
  name: "OrderFormLocation",
  form: {
    handler(val) {
      this.$emit("update", val);
    },
    deep: true,
  },
  async created() {},
  methods: {
    selezionaLocale(locale) {
      this.form.location.id = locale.id;
      this.form.location.selected = true;
    },
    ottieniLocaleById(id) {
      return this.locali_search[this.form.customer.id][id];
    },
    deselezionaLocale() {
      this.form.location.selected = false;
      this.form.location.id = "";
    },
    async locationCreated(id, location) {
      this.$bvModal.hide("modal-newlocation");
      location.id = id;
      this.$store.dispatch("aggiungiLocale", location);
      this.$emit("nuovoLocale", location);
      this.form.location.id = id;
      this.form.location.selected = true;
    },
    async modificaProfiloLocale(profilo) {
      const accessToken = await this.$auth.getTokenSilently();
      try {
        await localeRepo.update(accessToken, this.form.location.id, {
          profilo_locale: profilo,
        });
        this.$toast.open("Profilo locale modificato");
      } catch (e) {
        console.error(e);
        this.$toast.error("Errore modifica profilo locale");
      }
    },
    async modificaCategoriaLocale(categoria) {
      const accessToken = await this.$auth.getTokenSilently();
      try {
        await localeRepo.update(accessToken, this.form.location.id, {
          categoria_locale: categoria,
        });
        this.$toast.open("Categoria locale modificata");
      } catch (e) {
        console.error(e);
        this.$toast.error("Errore modifica categoria locale");
      }
    },
  },
  computed: {
    canEditLocation() {
      if (!this.$route.params.id) return true;
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:ordine:locale"
          ) != -1
        );
      }
      return false;
    },
    searchResult() {
      if (this.ricerca.stringa == "") return this.localiClienteAttivo;
      let k = 0;
      return this.localiClienteAttivo.filter((locale) => {
        if (k >= 50) return false;

        let checkLocationDesc =
          locale["nome"] != null &&
          this.ricerca.stringa != "" &&
          locale["nome"]
            .toLowerCase()
            .includes(this.ricerca.stringa.toLowerCase());

        let checkLocationAddress =
          locale["via"] != null &&
          this.ricerca.stringa != "" &&
          locale["via"]
            .toLowerCase()
            .includes(this.ricerca.stringa.toLowerCase());

        if (checkLocationDesc || checkLocationAddress) k++;
        return checkLocationDesc || checkLocationAddress;
      });
    },
    localiClienteAttivo() {
      let locali = [];
      if (this.form.customer) {
        let locali_caricati =
          this.$store.state.locali_search[this.form.customer.id];
        for (let locale in locali_caricati) {
          locali.push(locali_caricati[locale]);
        }
      }
      return locali;
    },
    profili_locale() {
      return this.$store.state.profili_locale.map((value) => {
        return {
          text: value.descrizione,
          value: value.id,
        };
      });
    },
    categorie_locale() {
      return this.$store.state.categorie_locale.map((value) => {
        return {
          text: value.descrizione,
          value: value.id,
        };
      });
    },
    locali_search() {
      return this.$store.state.locali_search;
    },
    formLocale() {
      if (!this.form.location.selected) {
        return {
          profilo_locale: "",
          categoria_locale: "",
          cliente: "",
          nome: "",
          tel: "",
          mail: "",
          contact: "",
          piva: "",
          prg: "",
          note: "",
          obsoleto: "",
          iban: "",
          via: "",
          cap: "",
          citta: "",
          prov: "",
          supplier: 0,
        };
      } else {
        return this.ottieniLocaleById(this.form.location.id);
      }
    },
  },
};
</script>
