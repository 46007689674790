<template>
  <div>
    <Mail
      v-if="pdfCaricato"
      :imessaggio="message"
      :ioggetto="oggetto"
      :idestinatario="mail"
      :iappend="now"
      :iadditional_body_params="{ saveAs: { rinnovo: rinnovo.id } }"
      :defaultSearch="rinnovo.cliente_cliente.ragsoc"
    >
      <template #title> Mail Proposta di rinnovo </template>
    </Mail>
    <h1 v-else>Caricamento PDF allegato...</h1>
  </div>
</template>

<script>
import Mail from "@/components/mail/Mail.vue";
import axios from "axios";

import * as rinnovoRepo from "../services/RinnoviAnnuali";

export default {
  name: "MailRinnovo",
  components: {
    Mail,
  },
  data() {
    return {
      rinnovo: {},
      pdfCaricato: false,
    };
  },
  async created() {
    await this.scaricaPDF();
    await this.caricaRinnovo();
  },
  methods: {
    async caricaRinnovo() {
      const accessToken = await this.$auth.getTokenSilently();

      try {
        const response = await rinnovoRepo.getByIdWithInclude(
          accessToken,
          this.id,
          ["cliente_cliente"]
        );
        if (!response) {
          alert("Errore: rinnovo non trovato");
          return;
        }
        this.rinnovo = response;
      } catch (e) {
        alert("Errore caricamento rinnovo");
        console.log(e.response);
      }
    },
    async scaricaPDF() {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();

      //Request config
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      };
      //Send request
      const response = await axios
        .get(
          `${process.env.VUE_APP_API_HOST}/pdf/rinnovo/proposta/${this.id}?store=${this.now}`,
          config
        )
        .catch((error) => {
          console.log(error);
        });

      console.log(response);
      this.pdfCaricato = true;
    },
    async allegaPDF(blob) {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();
      //Create formData
      const formData = new FormData();
      //Append file
      formData.append("file", blob);
      //Request config
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      //Send request
      await axios
        .post(
          `${process.env.VUE_APP_API_HOST}/file/temp/${this.now}`,
          formData,
          config
        )
        .then((response) => {
          //Show complete modal
          // this.showComplete = true;
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    now() {
      return Date.now();
    },
    message() {
      return `Gentile cliente,\n\nIn allegato il preventivo come da sua richiesta.\n\nRimaniamo a disposizione per qualsiasi eventuale chiarimento.`;
    },
    oggetto() {
      return `Rinnovo ${this.id}`;
    },
    mail() {
      if (!this.rinnovo.cliente_cliente) return "";
      return this.rinnovo.cliente_cliente.mail;
    },
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style></style>
