<template>
  <b-form-group
    :id="'input-group-' + identificatore"
    :label="descrizione + ':'"
    :label-for="'input-' + identificatore"
  >
    <b-form-input
      :maxlength="max"
      :id="'input-' + identificatore"
      :placeholder="descrizione"
      @input="input"
      :value="value"
      :readonly="readonly"
      required
    ></b-form-input>
  </b-form-group>
</template>

<script>
export default {
  name: "InputField",
  props: ["value", "identificatore", "descrizione", "readonly", "max"],
  data() {
    return {
      content: this.value,
    };
  },
  methods: {
    input(value) {
      this.$emit("input", value);
    },
  },
};
</script>
