import Vue from "vue";
import App from "./App.vue";
import Axios from "./plugins/axios";
import router from "./router";
import { Auth0Plugin } from "./auth";
import hljs from "highlight.js/lib/core";
import "highlight.js/styles/github.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faLink,
  faUser,
  faPowerOff,
  faUserSecret,
  faCircle,
  faEnvelopeSquare,
  faInfoCircle,
  faBookOpen,
  faPaperPlane,
  faFilePdf,
  faFile,
  faBan,
  faWrench,
  faMoneyBillAlt,
  faFilter,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faThumbsUp,
  faPencilAlt,
  faExchangeAlt,
  faEdit,
  faCheckCircle,
  faDownload,
  faArrowRotateRight,
  faXmark,
  faPlus,
  faInfo,
  faCheck,
  faList,
  faRotate,
  faSun,
  faMoon,
  faBook,
  faCalendarXmark,
  faFileInvoiceDollar,
  faFileCsv,
  faCertificate,
  faEye,
  faExpand,
  faForward,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

import { faCreativeCommonsNcEu } from "@fortawesome/free-brands-svg-icons";

import {
  faFile as farFile,
  faContactBook as farContactBook,
  faPaperPlane as farPaperPlane,
  faTrashCan as farTrashCan,
  faEye as farEye,
} from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { domain, clientId, audience } from "../auth_config.json";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import vSelect from "vue-select";
Vue.component("v-select", vSelect);
import "vue-select/dist/vue-select.css";

import VCalendar from "v-calendar";

// Use v-calendar & v-date-picker components
Vue.use(VCalendar);

import VueCryptojs from "vue-cryptojs";

Vue.use(VueCryptojs);

import VueLoading from "vuejs-loading-plugin";
Vue.use(VueLoading);

import VueTour from "vue-tour";

require("vue-tour/dist/vue-tour.css");

Vue.use(VueTour);

import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-sugar.css";

Vue.use(VueToast);

import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

import { io } from "socket.io-client";
import VueSocketIO from "vue-socket.io";

export const SocketInstance = io(
  process.env.VUE_APP_API_HOST.replace("/api", "")
  // { autoConnect: false }
);
Vue.use(
  new VueSocketIO({
    debug: false,
    connection: SocketInstance,
  })
);

//HTML preview
// import VueMarkdownEditor from "@kangc/v-md-editor";
// import "@kangc/v-md-editor/lib/style/base-editor.css";
// import vuepressTheme from "@kangc/v-md-editor/lib/theme/vuepress.js";
// import enUS from "@kangc/v-md-editor/lib/lang/en-US";

// VueMarkdownEditor.lang.use("en-US", enUS);
// VueMarkdownEditor.use(vuepressTheme);

// Vue.use(VueMarkdownEditor);
import VMdPreviewHtml from "@kangc/v-md-editor/lib/preview-html";
import "@kangc/v-md-editor/lib/style/preview-html.css";

Vue.use(VMdPreviewHtml);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

Vue.use(hljs.vuePlugin);
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(Axios);

library.add(
  faLink,
  faUser,
  faPowerOff,
  faUserSecret,
  faCircle,
  faEnvelopeSquare,
  faInfoCircle,
  faBookOpen,
  faPaperPlane,
  faFilePdf,
  faFile,
  faBan,
  faWrench,
  faMoneyBillAlt,
  faFilter,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faThumbsUp,
  faPencilAlt,
  faExchangeAlt,
  faEdit,
  faCheckCircle,
  farFile,
  faDownload,
  farContactBook,
  farPaperPlane,
  faArrowRotateRight,
  farTrashCan,
  faXmark,
  faPlus,
  faInfo,
  faCheck,
  faList,
  faRotate,
  faSun,
  faMoon,
  faBook,
  faCreativeCommonsNcEu,
  faCalendarXmark,
  faFileInvoiceDollar,
  faFileCsv,
  faCertificate,
  faEye,
  farEye,
  faExpand,
  faForward,
  faArrowRight,
  faFileCsv
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

import store from "./plugins/vuex";

new Vue({
  router,
  render: (h) => h(App),
  store: store,
}).$mount("#app");
