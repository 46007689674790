<template>
  <div>
    <b-form @submit="onSubmit">
      <b-form-group id="input-group-0" label="*ID cliente:" label-for="input-0">
        <b-form-input
          id="input-0"
          :value="cliente"
          type="text"
          placeholder="ID Cliente"
          required
          readonly
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-1" label="*Insegna:" label-for="input-1">
        <b-form-input
          :maxlength="100"
          id="input-1"
          v-model="form.nome"
          type="text"
          placeholder="Insegna"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-2" label="*Profilo:" label-for="input-2">
        <b-form-select
          class="form-control"
          v-model="form.profilo_locale"
          :options="profili"
          value-field="id"
          text-field="descrizione"
        ></b-form-select>
        <!-- <b-form-input
          id="input-2"
          v-model="form.profilo_locale"
          placeholder="Profilo"
          required
        ></b-form-input> -->
      </b-form-group>

      <b-form-group id="input-group-2" label="*Categoria:" label-for="input-15">
        <b-form-select
          class="form-control"
          v-model="form.categoria_locale"
          :options="categorie"
          value-field="id"
          text-field="descrizione"
        ></b-form-select>
      </b-form-group>

      <b-form-group id="input-group-9" label="*Indirizzo:" label-for="input-9">
        <b-form-input
          :maxlength="127"
          id="input-9"
          v-model="form.via"
          placeholder="Indirizzo"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-10" label="*Cap:" label-for="input-10">
        <b-form-input
          :maxlength="10"
          id="input-10"
          v-model="form.cap"
          placeholder="Cap"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-3" label="*Città:" label-for="input-3">
        <b-form-input
          :maxlength="50"
          id="input-3"
          v-model="form.citta"
          placeholder="Città"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-4" label="*Provincia:" label-for="input-4">
        <b-form-input
          :maxlength="20"
          id="input-4"
          v-model="form.prov"
          placeholder="Provincia"
          required
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-5" label="IBAN:" label-for="input-5">
        <b-form-input
          :maxlength="50"
          id="input-5"
          v-model="form.iban"
          placeholder="IBAN"
        ></b-form-input>
      </b-form-group>

      <!-- <b-form-group id="input-group-6" label="Mail:" label-for="input-6">
        <b-form-input
          id="input-6"
          v-model="form.mail"
          placeholder="Mail"
        ></b-form-input>
      </b-form-group>

      <b-form-group id="input-group-7" label="Telefono:" label-for="input-7">
        <b-form-input
          id="input-7"
          v-model="form.tel"
          placeholder="Telefono"
        ></b-form-input>
      </b-form-group> -->

      <b-form-group id="input-group-8" label="Note:" label-for="input-8">
        <b-form-input
          :maxlength="500"
          id="input-8"
          v-model="form.note"
          placeholder="Note"
        ></b-form-input>
      </b-form-group>

      <div class="d-flex flex-row-reverse">
        <b-button type="submit" variant="primary" class="mt-2">
          Conferma
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import axios from "axios";
import * as profiloLocaleRepo from "../../services/ProfiloLocale";
import * as categoriaLocaleRepo from "../../services/CategoriaLocale";
import * as localeRepo from "../../services/Locale";

export default {
  name: "LocationCreationForm",
  props: { cliente: Number },
  data() {
    return {
      form: {
        nome: "",
        profilo_locale: 1,
        categoria_locale: 1,
        via: "",
        cap: "",
        citta: "",
        prov: "",
        iban: "",
        mail: "",
        tel: "",
        note: "",
      },
      profili: [],
      categorie: [],
    };
  },
  async mounted() {
    const accessToken = await this.$auth.getTokenSilently();
    let profili = await profiloLocaleRepo.getAll(accessToken, {
      where: { attivo: true },
    });
    this.profili = profili;
    let categorie = await categoriaLocaleRepo.getAll(accessToken, {
      where: { attivo: true },
    });
    this.categorie = categorie;
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.creaLocale({ cliente: this.cliente, ...this.form });
    },

    async creaLocale(locale) {
      let notEmpty = {};
      for (var key in locale) {
        if (locale[key] !== "") notEmpty[key] = locale[key];
      }

      if (notEmpty["cap"]) notEmpty["cap"] = parseInt(notEmpty["cap"]);
      if (notEmpty["cliente"])
        notEmpty["cliente"] = parseInt(notEmpty["cliente"]);
      if (notEmpty["profilo_locale"])
        notEmpty["profilo_locale"] = parseInt(notEmpty["profilo_locale"]);

      if (notEmpty["categoria_locale"])
        notEmpty["categoria_locale"] = parseInt(notEmpty["categoria_locale"]);
      if (notEmpty["prg"]) notEmpty["prg"] = parseInt(notEmpty["prg"]);

      try {
        const accessToken = await this.$auth.getTokenSilently();
        let response = await localeRepo.add(accessToken, notEmpty);
        this.$emit("locationCreation", response.id, {
          ...this.form,
          ...response,
        });
      } catch (e) {
        console.log(e);
        alert("Errore nella creazione del locale");
      }
    },
  },
};
</script>
