<template>
  <v-tour name="myTour" :steps="steps"></v-tour>
</template>

<script>
export default {
  name: "OrderListTour",
  data() {
    return {
      steps: [
        {
          target: '[data-v-step="1"]',
          content: "In questa sezione è possibile filtrare i risultati",
          params: {
            placement: "bottom" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '[data-v-step="2"]',
          content:
            "Tramite gli switch è possibile selezionare o deselezionare gli stati",
          params: {
            placement: "bottom" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '[data-v-step="3"]',
          content:
            "Ulteriori filtri di ricerca sono utente, tecnico, ragione sociale e id ordine",
          params: {
            placement: "bottom" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '[data-v-step="4"]',
          content:
            "Le informazioni amministrative contengono informazioni sull'ordine dal punto di vista della contabilità: data, totale, fatturazione, ...",
          params: {
            placement: "bottom" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '[data-v-step="5"]',
          content:
            "Informazioni sul cliente e sul locale di destinazione dell'ordine ",
          params: {
            placement: "bottom" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '[data-v-step="6"]',
          content:
            "Le informazioni tecniche contengono informazioni sull'ordine dal punto di vista pratico: tecnico assegnato, data di consegna, matricole, ...",
          params: {
            placement: "bottom" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '[data-v-step="7"]',
          content:
            "In questa sezione sono disponibili una serie di azioni che è possibile effettuare sugli ordini: passaggio di stato, invio mail, ...",
          params: {
            placement: "bottom" // Any valid Popper.js placement. See https://popper.js.org/popper-documentation.html#Popper.placements
          }
        }
      ]
    };
  },
  mounted: function() {
    if (!localStorage.getItem("OrderListTour")) {
      this.$tours["myTour"].start();
      localStorage.setItem("OrderListTour", true);
    }
  }
};
</script>
