<template>
  <div class="m-3">
    <h1>Gruppi</h1>
    <div class="col-md-6 mb-2">
      <b-form-input
        type="search"
        placeholder="Inserire id, nome o id trace..."
        v-model="search"
      ></b-form-input>
    </div>

    <PaginatedTable
      :getPage="getPage"
      :getCount="getCount"
      :openRow="openRow"
      :key="refreshKey"
      pointer
    >
      <!-- <template #cell(prezzo)="data">
        {{ formatPrice(data.value) }}
      </template>
      <template #cell(import_trace)="data">
        <font-awesome-icon v-if="data.value" icon="fa-solid fa-check" />
        <font-awesome-icon v-else icon="fa-solid fa-xmark" />
      </template>
      <template #cell(immagine)="data">
        <img
          :src="getImagePath(data.value)"
          alt="image"
          width="50"
          height="50"
        />
      </template> -->
    </PaginatedTable>
  </div>
</template>

<script>
import PaginatedTable from "@/components/common/PaginatedTable.vue";
import * as gruppoRepo from "../services/Gruppo";

import router from "../router/index";

export default {
  name: "Gruppi",
  components: {
    PaginatedTable,
  },
  data() {
    return {
      search: "",
      refreshKey: 0,
    };
  },
  watch: {
    query: {
      handler(val) {
        this.ricaricaPaginatedTable();
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + "€"
      );
    },
    async getPage(accessToken, page) {
      let gruppi = await gruppoRepo.getPage(accessToken, page, this.query);
      gruppi = gruppi.map((gruppo) => {
        return {
          id: gruppo.id,
          id_trace: gruppo.id_trace,
          descrizione: gruppo.descrizione,
          tipo_gruppo: gruppo.tipo_gruppo_tipo_gruppo.descrizione,
        };
      });
      return gruppi;
    },
    async getCount(accessToken) {
      let count = await gruppoRepo.getCount(accessToken, this.query);
      return count;
    },
    ricaricaPaginatedTable() {
      this.refreshKey += 1;
    },
    openRow(row, number, event) {
      router.push({ name: "schedaGruppo", params: { id: row["id"] } });
    },
  },
  computed: {
    query() {
      let query = {
        include: ["tipo_gruppo_tipo_gruppo"],
      };
      if (this.search.trim() != "") {
        query.where = {
          and: {
            or: {
              id: {
                substring: this.search,
              },
              id_trace: {
                substring: this.search,
              },
              descrizione: {
                substring: this.search,
              },
            },
          },
        };
      }
      return query;
    },
  },
};
</script>
