<template>
  <div class="m-3 card p-4 background-secondary">
    <div class="row">
      <div class="col-md-2 image-uploader">
        <input
          class="image-input"
          id="file"
          type="file"
          @change="uploadImage"
        />
        <label class="image-label" for="file">
          <img
            class="image-output"
            :src="getImagePath(articolo.immagine)"
            alt="click per modificare"
            width="150"
            height="150"
          />
        </label>
      </div>

      <div class="col-md-10 my-auto">
        <h1>{{ articolo.title }}</h1>
      </div>
    </div>

    <ConfigurazioneRT
      class="mt-3"
      v-if="isRT"
      :articolo="articolo.id"
    ></ConfigurazioneRT>

    <b-form @submit="onSubmit" class="mt-4">
      <!-- checkboxes -->
      <b-form-group id="input-group-6">
        <b-form-checkbox :disabled="!canUpdate" v-model="articolo.attivo"
          >Attivo</b-form-checkbox
        >
        <b-form-checkbox :disabled="!canUpdate" v-model="articolo.import_trace"
          >Importa in Trace</b-form-checkbox
        >
        <b-form-checkbox :disabled="!canUpdate" v-model="articolo.order_note"
          >Necessita note tecniche</b-form-checkbox
        >
        <b-form-checkbox :disabled="!canUpdate" v-model="articolo.billing_note"
          >Necessita note fattura</b-form-checkbox
        >
        <b-form-checkbox
          :disabled="!canUpdate"
          v-model="articolo.crea_intervento"
          >Crea intervento</b-form-checkbox
        >
      </b-form-group>
      <!-- title -->
      <!-- <b-form-group id="input-group-1" label="Nome:" label-for="title">
        <b-form-input
          id="title"
          placeholder="Inserire nome articolo"
          v-model="articolo.title"
          required
        ></b-form-input>
      </b-form-group> -->
      <!-- descrizione -->
      <b-form-group
        id="input-group-2"
        label="Descrizione:"
        label-for="descrizione"
      >
        <b-form-textarea
          id="descrizione"
          placeholder="Inserire descrizione articolo"
          v-model="articolo.descrizione"
          :disabled="!canUpdate"
        ></b-form-textarea>
      </b-form-group>
      <!-- gruppo -->
      <b-form-group id="input-group-3" label="Gruppo:" label-for="gruppo">
        <b-form-select
          id="gruppo"
          class="form-control"
          v-model="articolo.gruppo"
          :options="gruppi_select"
          disabled
        ></b-form-select>
      </b-form-group>
      <!-- tipo_articolo -->
      <b-form-group id="input-group-3-1" label="Tipo:" label-for="tipo">
        <b-form-select
          id="tipo"
          class="form-control"
          v-model="articolo.tipo_articolo"
          :options="tipi_select"
          :disabled="!canUpdate"
          required
        ></b-form-select>
      </b-form-group>
      <!-- cassaincloud_id -->
      <b-form-group
        id="input-group-5"
        label="ID Cassaincloud:"
        label-for="cassaincloud_id"
      >
        <b-form-input
          id="cassaincloud_id"
          placeholder="Inserire id cassaincloud articolo"
          v-model="articolo.cassaincloud_id"
          :disabled="!canUpdate"
        ></b-form-input>
      </b-form-group>
      <!-- id_trace -->
      <b-form-group id="input-group-4" label="ID Trace:" label-for="id_trace">
        <b-form-input
          id="id_trace"
          placeholder="Inserire id_trace articolo"
          v-model="articolo.id_trace"
          readonly
        ></b-form-input>
      </b-form-group>
      <!-- costo -->
      <b-form-group
        id="input-group-5"
        append="€"
        label="Costo:"
        label-for="costo"
      >
        <b-form-input
          id="costo"
          placeholder="Inserire costo articolo"
          v-model="articolo.costo"
          readonly
        ></b-form-input>
      </b-form-group>
      <!-- prezzo -->
      <b-form-group
        id="input-group-5"
        append="€"
        label="Prezzo:"
        label-for="prezzo"
      >
        <b-form-input
          id="prezzo"
          placeholder="Inserire prezzo articolo"
          :value="
            articolo.prezzos && articolo.prezzos.length > 0
              ? articolo.prezzos[0].prz_listino
              : 'ND'
          "
          readonly
        ></b-form-input>
      </b-form-group>
      <b-form-group
        label="Periodicità:"
        id="input-group-7"
        class="mt-2"
        :disabled="!canUpdate"
      >
        <b-form-radio v-model="periodicita" name="none-1" value="NONE"
          >&nbsp;Non periodico</b-form-radio
        >
        <b-form-radio v-model="periodicita" name="mensile" value="P1"
          >&nbsp;Mensile</b-form-radio
        >
        <b-form-radio v-model="periodicita" name="annuale" value="P12"
          >&nbsp;Annuale</b-form-radio
        >
        <b-form-radio v-model="periodicita" name="biennale" value="P24"
          >&nbsp;Biennale</b-form-radio
        >
        <b-form-radio v-model="periodicita" name="new_year" value="P31"
          >&nbsp;Al 31/12</b-form-radio
        >
      </b-form-group>
      <b-form-group
        label="Seriale:"
        id="input-group-8"
        class="mt-2"
        :disabled="!canUpdate"
      >
        <b-form-radio v-model="matricola" name="none-2" value="NONE"
          >&nbsp;Non necessita seriale</b-form-radio
        >
        <b-form-radio v-model="matricola" name="need" value="NEED"
          >&nbsp;Necessita seriale</b-form-radio
        >
        <b-form-radio v-model="matricola" name="need_grenke" value="NEED_GRENKE"
          >&nbsp;Necessita seriale se grenke</b-form-radio
        >
      </b-form-group>

      <b-button v-if="canUpdate" type="submit" variant="primary" class="mt-2"
        >Conferma modifiche</b-button
      >
    </b-form>
  </div>
</template>

<script>
import * as articoloRepo from "../../services/Articolo";
import * as gruppoRepo from "../../services/Gruppo";
import ConfigurazioneRT from "@/components/gestione/ConfigurazioneRT.vue";

export default {
  name: "Articolo",
  components: {
    ConfigurazioneRT,
  },
  data() {
    return {
      articolo: {},
      gruppi: [],
      tipi_articolo: [],
    };
  },
  async created() {
    await this.loadArticolo();
    await this.loadGruppi();
    await this.loadTipiArticolo();
  },
  computed: {
    isRT() {
      return this.articolo.tipo_articolo == 5;
    },
    canUpdate() {
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:articolo"
          ) != -1
        );
      }
      return false;
    },
    id() {
      return parseInt(this.$route.params.id);
    },
    periodicita: {
      get() {
        if (this.articolo.mth_1) return "P1";
        if (this.articolo.mth_12) return "P12";
        if (this.articolo.mth_24) return "P24";
        if (this.articolo.new_year) return "P31";
        return "NONE";
      },
      set(value) {
        this.articolo.mth_1 = value == "P1";
        this.articolo.mth_12 = value == "P12";
        this.articolo.mth_24 = value == "P24";
        this.articolo.new_year = value == "P31";
      },
    },
    matricola: {
      get() {
        if (this.articolo.need_serial) return "NEED";
        if (this.articolo.need_serial_grenke) return "NEED_GRENKE";
        return "NONE";
      },
      set(value) {
        this.articolo.need_serial = value == "NEED";
        this.articolo.need_serial_grenke = value == "NEED_GRENKE";
      },
    },
    gruppi_select() {
      return this.gruppi.map((gruppo) => {
        return {
          value: gruppo.id_trace,
          text: gruppo.id_trace + " - " + gruppo.descrizione,
        };
      });
    },
    tipi_select() {
      return this.tipi_articolo.map((tipo) => {
        return {
          value: tipo.id,
          text: tipo.descrizione,
          disabled: !tipo.attivo,
        };
      });
    },
  },
  methods: {
    async loadArticolo() {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      this.articolo = await articoloRepo.getByIdWithInclude(
        accessToken,
        this.id,
        ["prezzos"]
      );
      this.$loading(false);
    },
    async loadGruppi() {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      this.gruppi = await gruppoRepo.getAll(accessToken);
      this.$loading(false);
    },
    async loadTipiArticolo() {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      this.tipi_articolo = await articoloRepo.getTipiArticolo(accessToken);
      this.$loading(false);
    },
    async uploadImage() {
      try {
        let photo = document.getElementById("file").files[0];
        let formData = new FormData();
        //Generazione del nome per il file come: id articolo + random string
        let filename =
          this.articolo.id + "-" + Math.random().toString(36).slice(2);
        formData.append("file", photo, filename);
        const accessToken = await this.$auth.getTokenSilently();

        await articoloRepo.uploadImage(accessToken, this.id, formData);

        this.loadArticolo();
      } catch (e) {
        this.$toast.error(
          "Si è verificato un errore nella modifica dell'immagine"
        );
      }
    },
    async onSubmit(event) {
      event.preventDefault();

      //Controllo che il tipo sia != 45
      if (this.articolo.tipo_articolo == 45) {
        this.$toast.error(
          "Selezionare un tipo di articolo prima di confermare"
        );
        return;
      }
      const copy = Object.assign({}, this.articolo);
      const readonlyProperties = [
        "id",
        "title",
        "data_insert",
        "data_update",
        "id_trace",
        "costo",
        "gruppo",
        "prezzos",
      ];
      for (const prop of readonlyProperties) delete copy[prop];

      const nullIfFalse = ["descrizione", "cassaincloud_id"];
      for (const prop of nullIfFalse)
        copy[prop] = copy[prop] ? copy[prop] : null;
      try {
        this.$loading(true);
        const accessToken = await this.$auth.getTokenSilently();
        await articoloRepo.update(accessToken, this.articolo.id, copy);
        this.$toast.open("Articolo modificato correttamente");
      } catch (e) {
        this.$toast.error("Errore modifica articolo: " + e.message);
      }
      this.$loading(false);
    },

    getImagePath(name) {
      let path = null;
      if (!name) return path;
      return (process.env.VUE_APP_API_HOST.replace("api", "") + name)
        .replace("../", "")
        .replace("uploads", "images");
    },
  },
};
</script>

<style>
.image-uploader {
}

.image-uploader .image-input {
  display: none;
}

.image-uploader .image-label {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

  cursor: pointer;
  width: 150px;
  height: 150px;
}
</style>
