import axios from "axios";

export const creaAggiornamentoFirmware = async (
  token,
  aggiornamentoFirmware
) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return await axios.post(
    `${process.env.VUE_APP_API_HOST}/aggiornamentoFirmware`,
    aggiornamentoFirmware,
    config
  );
};
