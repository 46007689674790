import { render, staticRenderFns } from "./ScadutoCliente.vue?vue&type=template&id=927826e8&"
import script from "./ScadutoCliente.vue?vue&type=script&lang=js&"
export * from "./ScadutoCliente.vue?vue&type=script&lang=js&"
import style0 from "./ScadutoCliente.vue?vue&type=style&index=0&id=927826e8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports