<template>
  <div>
    <select
      class="form-control"
      :name="identifier"
      :id="identifier"
      @change="change($event)"
    >
      <option :value="null">Seleziona un articolo..</option>
      <option
        v-for="article in articles"
        :key="article.id"
        :selected="article.id == value ? true : false"
        :value="article.id"
      >
        {{ article.title }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: "ArticleSelect",
  props: {
    value: Number,
    identifier: String,
    canoni: Boolean,
    non_canoni: Boolean,
  },
  data() {
    return {
      active: null,
    };
  },
  async created() {
    const accessToken = await this.$auth.getTokenSilently();

    await this.$store.dispatch("caricaArticoli", accessToken);
  },
  methods: {
    change(event) {
      let value = event.target.value ? parseInt(event.target.value) : null;
      this.$emit("input", value);
    },
  },
  computed: {
    articles() {
      return this.$store.state.articoli.filter(
        (value) =>
          value.attivo &&
          (!(this.canoni || this.non_canoni) ||
            (this.canoni && value.mth_1 == true) ||
            (this.non_canoni &&
              (value.mth_12 == true ||
                value.mth_24 == true ||
                value.new_year == true)))
      );
    },
  },
};
</script>
