import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import PannelloAmministrazione from "../views/PannelloAmministrazione.vue";
import Insoluti from "../views/Insoluti.vue";
import InsolutoCliente from "../views/InsolutoCliente.vue";

import Scaduti from "../views/Scaduti.vue";
import ScadutoCliente from "../views/ScadutoCliente.vue";

import ListaOrdini from "../views/ListaOrdini.vue";
import ListaRinnoviAnnuali from "../views/ListaRinnoviAnnuali.vue";
import ListaRinnoviMensili from "../views/ListaRinnoviMensili.vue";

import OrderForm from "../views/OrderForm.vue";

import OrderFormCustomer from "../components/ordini/OrderFormCustomer.vue";
import OrderFormLocation from "../components/ordini/OrderFormLocation.vue";
import OrderFormContact from "../components/ordini/OrderFormContact.vue";
import OrderFormCart from "../components/ordini/OrderFormCart.vue";
import OrderFormPayment from "../components/ordini/OrderFormPayment.vue";
import OrderFormDetails from "../components/ordini/OrderFormDetails.vue";
import OrderFormArticoliCliente from "../components/ordini/OrderFormArticoliCliente.vue";

import RinnovoAnnuale from "../components/rinnovi/RinnovoAnnuale.vue";

import RinnovoMensile from "../components/rinnovi/RinnovoMensile.vue";

import MailRinnovo from "../views/MailRinnovo.vue";
import MailRinnovoCompleto from "../views/MailRinnovoCompleto.vue";
import MailMensile from "../views/MailMensile.vue";
import MailOrdine from "../views/MailOrdine.vue";
import MailScaduto from "../views/MailScaduto.vue";
import MailInsoluto from "../views/MailInsoluto.vue";

import Clienti from "../views/Clienti.vue";
import Cliente from "../views/Cliente.vue";

import Articoli from "../views/Articoli.vue";
import Articolo from "../components/gestione/Articolo.vue";

import Gruppi from "../views/Gruppi.vue";
import Gruppo from "../components/gestione/Gruppo.vue";

import Locali from "../views/Locali.vue";
import Locale from "../components/gestione/Locale.vue";

import Test from "../views/Test.vue";

import NotAllowedErrorPage from "../components/NotAllowedErrorPage.vue";

import StatisticheTecniche from "../views/StatisticheTecniche.vue";
import StatisticheCentralino from "../views/StatisticheCentralino.vue";
import StatisticheAmministrazione from "../views/StatisticheAmministrazione.vue";

import Logs from "../views/Logs.vue";

import ListaContatti from "../views/ListaContatti.vue";
import Campagne from "../views/Campagne.vue";
import ListaCampagne from "../views/ListaCampagne.vue";
import CalendarioCampagne from "../views/CalendarioCampagne.vue";
import CreazioneCampagna from "../views/CreazioneCampagna.vue";
import Campagna2 from "../views/Campagna2.vue";
import RegistratoriRT from "../views/RegistratoriRT.vue";

import { authGuard } from "../auth";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/notallowed",
      name: "notallowed",
      component: NotAllowedErrorPage,
    },
    {
      path: "/test",
      name: "test",
      component: Test,
    },
    {
      path: "/admin",
      name: "pannelloAmministrazione",
      component: PannelloAmministrazione,
      beforeEnter: authGuard,
    },
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      beforeEnter: authGuard,
    },
    {
      path: "/insoluti",
      component: Insoluti,
      name: "listaInsoluti",
      beforeEnter: authGuard,
      meta: {
        scopes: ["read:insoluti"],
      },
    },
    {
      path: "/insoluti/:id",
      component: InsolutoCliente,
      name: "insolutoCliente",
      beforeEnter: authGuard,
      meta: {
        scopes: ["read:insoluti"],
      },
    },
    {
      path: "/scaduti",
      component: Scaduti,
      name: "listaScaduti",
      beforeEnter: authGuard,
      meta: {
        scopes: ["read:scaduti"],
      },
    },
    {
      path: "/scaduti/:id",
      component: ScadutoCliente,
      name: "scadutoCliente",
      beforeEnter: authGuard,
      meta: {
        scopes: ["read:scaduti"],
      },
    },
    {
      path: "/rinnovi/annuali",
      name: "rinnoviAnnuali",
      component: ListaRinnoviAnnuali,
      beforeEnter: authGuard,
      meta: {
        scopes: ["read:rinnovo:annuale"],
      },
    },
    {
      path: "/rinnovi/annuali/:id",
      name: "rinnovoAnnuale",
      component: RinnovoAnnuale,
      beforeEnter: authGuard,
      meta: {
        scopes: ["read:rinnovo:annuale"],
      },
    },
    {
      path: "/rinnovi/mensili",
      name: "rinnoviMensili",
      component: ListaRinnoviMensili,
      beforeEnter: authGuard,
      meta: {
        scopes: ["read:rinnovo:mensile"],
      },
    },
    {
      path: "/rinnovi/mensili/:id",
      name: "rinnovoMensile",
      component: RinnovoMensile,
      beforeEnter: authGuard,
      meta: {
        scopes: ["read:rinnovo:mensile"],
      },
    },
    {
      path: "/ordini",
      name: "listaOrdini",
      component: ListaOrdini,
      beforeEnter: authGuard,
    },
    //Form creazione ordini
    {
      path: "/ordini/create",
      component: OrderForm,
      beforeEnter: authGuard,
      meta: {
        scopes: ["create:ordine"],
      },
      children: [
        {
          path: "cliente",
          name: "cliente",
          component: OrderFormCustomer,
          meta: {
            scopes: ["create:ordine"],
          },
        },
        {
          path: "locale",
          name: "locale",
          component: OrderFormLocation,
          meta: {
            scopes: ["create:ordine"],
          },
        },
        {
          path: "contatti",
          name: "contatti",
          component: OrderFormContact,
          meta: {
            scopes: ["create:ordine"],
          },
        },
        {
          path: "carrello",
          name: "carrello",
          component: OrderFormCart,
          meta: {
            scopes: ["create:ordine"],
          },
        },
        {
          path: "pagamento",
          name: "pagamento",
          component: OrderFormPayment,
          meta: {
            scopes: ["create:ordine"],
          },
        },
        {
          path: "dettagli",
          name: "dettagli",
          component: OrderFormDetails,
          meta: {
            scopes: ["create:ordine"],
          },
        },
        {
          path: "articoli",
          name: "articoli",
          component: OrderFormArticoliCliente,
          meta: {
            scopes: ["create:ordine"],
          },
        },
      ],
    },
    //Form modifica ordini
    {
      path: "/ordini/:id(\\d+)?",
      component: OrderForm,
      beforeEnter: authGuard,
      meta: {
        scopes: ["update:ordine"],
      },
      children: [
        {
          path: "cliente",
          name: "cliente",
          component: OrderFormCustomer,
          meta: {
            scopes: ["update:ordine"],
          },
        },
        {
          path: "locale",
          name: "locale",
          component: OrderFormLocation,
          meta: {
            scopes: ["update:ordine"],
          },
        },
        {
          path: "contatti",
          name: "contatti",
          component: OrderFormContact,
          meta: {
            scopes: ["update:ordine"],
          },
        },
        {
          path: "carrello",
          name: "carrello",
          component: OrderFormCart,
          meta: {
            scopes: ["update:ordine"],
          },
        },
        {
          path: "pagamento",
          name: "pagamento",
          component: OrderFormPayment,
          meta: {
            scopes: ["update:ordine"],
          },
        },
        {
          path: "dettagli",
          name: "dettagli",
          component: OrderFormDetails,
          meta: {
            scopes: ["update:ordine"],
          },
        },
        {
          path: "articoli",
          name: "articoli",
          component: OrderFormArticoliCliente,
          meta: {
            scopes: ["update:ordine"],
          },
        },
      ],
    },
    {
      path: "/clienti",
      name: "clienti",
      beforeEnter: authGuard,
      component: Clienti,
      meta: {
        scopes: ["read:cliente"],
      },
    },
    {
      path: "/clienti/:id",
      name: "schedaCliente",
      beforeEnter: authGuard,
      component: Cliente,
      meta: {
        scopes: ["read:cliente"],
      },
    },
    {
      path: "/mail/rinnovo/:id",
      name: "mailRinnovo",
      beforeEnter: authGuard,
      component: MailRinnovo,
    },
    {
      path: "/mail/rinnovo/completo/:id",
      name: "mailRinnovoCompleto",
      beforeEnter: authGuard,
      component: MailRinnovoCompleto,
    },
    {
      path: "/mail/mensile/:id",
      name: "mailMensile",
      beforeEnter: authGuard,
      component: MailMensile,
    },
    {
      path: "/mail/ordine/:id",
      name: "mailOrdine",
      beforeEnter: authGuard,
      component: MailOrdine,
    },
    {
      path: "/mail/scaduto/:id",
      name: "mailScaduto",
      beforeEnter: authGuard,
      component: MailScaduto,
    },
    {
      path: "/mail/insoluto/:id",
      name: "mailInsoluto",
      beforeEnter: authGuard,
      component: MailInsoluto,
    },
    {
      path: "/articoli",
      name: "listaArticoli",
      beforeEnter: authGuard,
      component: Articoli,
      meta: {
        scopes: ["read:articolo"],
      },
    },
    {
      path: "/articoli/:id",
      name: "schedaArticolo",
      beforeEnter: authGuard,
      component: Articolo,
      meta: {
        scopes: ["read:articolo"],
      },
    },
    {
      path: "/gruppi",
      name: "listaGruppi",
      beforeEnter: authGuard,
      component: Gruppi,
      meta: {
        scopes: ["read:gruppo"],
      },
    },
    {
      path: "/gruppi/:id",
      name: "schedaGruppo",
      beforeEnter: authGuard,
      component: Gruppo,
      meta: {
        scopes: ["read:gruppo"],
      },
    },
    {
      path: "/locali",
      name: "listaLocali",
      beforeEnter: authGuard,
      component: Locali,
      meta: {
        scopes: ["read:locale"],
      },
    },
    {
      path: "/locali/:id",
      name: "schedaLocale",
      beforeEnter: authGuard,
      component: Locale,
      meta: {
        scopes: ["read:locale"],
      },
    },
    {
      path: "/statistiche/centralino",
      name: "statisticheCentralino",
      beforeEnter: authGuard,
      component: StatisticheCentralino,
    },
    {
      path: "/statistiche/tecniche",
      name: "statisticheTecniche",
      beforeEnter: authGuard,
      component: StatisticheTecniche,
    },
    {
      path: "/statistiche/admin",
      name: "statisticheAdmin",
      beforeEnter: authGuard,
      component: StatisticheAmministrazione,
      meta: {
        scopes: ["read:stat:admin"],
      },
    },
    {
      path: "/logs",
      name: "logs",
      beforeEnter: authGuard,
      component: Logs,
    },
    {
      path: "/contatti",
      name: "contatti",
      beforeEnter: authGuard,
      component: ListaContatti,
    },
    {
      path: "/contatti/campagne",
      name: "campagne",
      beforeEnter: authGuard,
      component: Campagne,
    },
    {
      path: "/contatti/campagne/calendario",
      name: "calendarioCampagne",
      beforeEnter: authGuard,
      component: CalendarioCampagne,
    },
    {
      path: "/contatti/campagne/new2",
      name: "creazioneCampagna",
      beforeEnter: authGuard,
      component: CreazioneCampagna,
    },
    {
      path: "/contatti/campagne/new",
      name: "creazioneCampagna2",
      beforeEnter: authGuard,
      component: Campagna2,
    },
    {
      path: "/rt",
      name: "registratoriRT",
      beforeEnter: authGuard,
      component: RegistratoriRT,
    },
  ],
});

//scroll to top before each route change
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});
export default router;
