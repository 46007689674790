<template>
  <div class="m-3">
    <h1>Campagne</h1>

    <b-button v-if="calendario" @click="calendario = false">
      <b-icon icon="list"></b-icon> Visualizza come Lista
    </b-button>
    <b-button v-else @click="calendario = true">
      <b-icon icon="calendar"></b-icon> Visualizza come Calendario
    </b-button>

    <Lista v-if="!calendario" />
    <Calendario v-else />
  </div>
</template>

<script>
import Lista from "@/components/campagne/Lista.vue";
import Calendario from "@/components/campagne/Calendario.vue";

export default {
  name: "Campagne",
  components: {
    Lista,
    Calendario,
  },
  watch: {},
  data() {
    return {
      calendario: true,
    };
  },
  async created() {},
  methods: {},
  computed: {},
};
</script>
