import ApiConnector from "./ApiConnector";
let connector = new ApiConnector(process.env.VUE_APP_API_HOST);

export const getByLocationIdWithInclude = (token, id, include = []) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.DisdettaApi();

    let opts = {
      selectOptions: {
        options: {
          where: {
            locale: id,
          },
          include: include,
        },
      },
    };
    apiInstance.getAllDisdettas(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};
