<template>
  <div>
    <b-button v-b-modal.configurazione style="vertical-align: middle"
      ><b-icon-telephone-fill></b-icon-telephone-fill>&nbsp;
      <span> Configurazione centralino </span></b-button
    >

    <b-modal
      id="configurazione"
      v-model="aperto"
      centered
      size="sm"
      cancel-title="Annulla"
      ok-title="Salva"
      title="Configurazione centralino"
      @ok="salvaConfigurazione"
    >
      <b-form-checkbox v-model="ignora_scaduto">
        IGNORA SCADUTO
      </b-form-checkbox>
      <b-form-checkbox v-model="blocca_assistenza">
        BLOCCA ASSISTENZA
      </b-form-checkbox>
    </b-modal>
  </div>
</template>

<script>
import * as configurazioneCentralinoClienteRepo from "../../services/ConfigurazioneCentralinoCliente";

export default {
  name: "ConfigurazioneCentralinoCliente",
  props: ["idCliente"],
  data() {
    return {
      esistente: false,
      aperto: false,
      ignora_scaduto: false,
      blocca_assistenza: false,
    };
  },
  watch: {
    aperto: function (val) {
      if (val && this.idCliente) this.scaricaConfigurazione(this.idCliente);
    },
  },
  methods: {
    async scaricaConfigurazione(cliente) {
      const token = await this.$auth.getTokenSilently();
      const configurazioneResponse =
        await configurazioneCentralinoClienteRepo.getByCustomerID(
          token,
          cliente
        );

      if (configurazioneResponse.status == 200) {
        console.log(configurazioneResponse.data);
        this.ignora_scaduto = configurazioneResponse.data.ignora_scaduto;
        this.blocca_assistenza = configurazioneResponse.data.blocca_assistenza;
        this.esistente = true;
      }
    },
    async salvaConfigurazione() {
      try {
        const token = await this.$auth.getTokenSilently();
        if (this.esistente) {
          //modifica configurazione cliente
          await configurazioneCentralinoClienteRepo.update(
            token,
            this.idCliente,
            {
              ignora_scaduto: this.ignora_scaduto,
              blocca_assistenza: this.blocca_assistenza,
            }
          );
        } else {
          //crea configurazione cliente
          await configurazioneCentralinoClienteRepo.add(token, {
            cliente: this.idCliente,
            ignora_scaduto: this.ignora_scaduto,
            blocca_assistenza: this.blocca_assistenza,
          });
        }
        this.$toast.success("Configurazione modificata con successo");
      } catch (e) {
        this.$toast.error(
          "Si è verificato un errore durante il salvataggio della configurazione"
        );
      }
    },
  },
};
</script>
