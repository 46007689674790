<template>
  <b-card bg-variant="light" header="Cliente" class="text-start">
    <b-modal
      no-close-on-backdrop
      id="modal-newcustomer"
      title="Creazione Cliente"
      ok-only
      ok-variant="secondary"
      ok-title="Chiudi"
    >
      <CustomerCreationForm
        @customerCreation="customerCreated"
      ></CustomerCreationForm>
    </b-modal>
    <div id="ricerca" v-if="!form.customer.selected">
      <b-button-toolbar
        key-nav
        aria-label="Toolbar with button groups"
        class="mb-2"
      >
        <b-button-group class="mx-1"> </b-button-group>
      </b-button-toolbar>

      <!-- Using components -->

      <b-input-group class="mt-3">
        <b-form-input
          placeholder="Ragione sociale o id cliente"
          v-model="ricerca.stringa"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            v-b-modal.modal-newcustomer
            v-b-tooltip.hover.left="'Crea nuovo cliente'"
            variant="primary"
            >+
          </b-button>
        </b-input-group-append>
      </b-input-group>

      <b-list-group style="max-height: 50vh; overflow-y: scroll">
        <b-list-group-item
          v-for="cliente in searchResult"
          :key="cliente.id"
          v-on:click="selezionaCliente(cliente)"
          class="d-flex justify-content-between"
          button
          ><span class="h6">{{ cliente.ragsoc }}</span
          ><span>{{ cliente.id_trace }}</span></b-list-group-item
        >
      </b-list-group>
    </div>
    <div v-else>
      <b-button-toolbar key-nav aria-label="Toolbar with button groups">
        <b-button-group class="mx-1">
          <b-button
            v-if="editable && canUpdateCustomer"
            size="sm"
            class="mb-2"
            v-on:click="deselezionaCliente()"
          >
            <b-icon icon="arrow-left" aria-hidden="true"></b-icon> Annulla
            Selezione
          </b-button>
          <b-button
            size="sm"
            class="mb-2"
            variant="info"
            :to="'/clienti/' + form.customer.id"
            target="_blank"
          >
            <b-icon icon="person" aria-hidden="true"></b-icon> Apri scheda
            cliente
          </b-button>
        </b-button-group>
      </b-button-toolbar>

      <b-form>
        <b-form-group id="input-group-0" label="ID Trace:" label-for="input-0">
          <b-form-input
            readonly
            id="input-0"
            v-model="formCliente.id_trace"
            placeholder="ID Trace"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-1"
          label="Nome locale:"
          label-for="input-1"
        >
          <b-form-input
            readonly
            id="input-1"
            v-model="formCliente.nome_locale"
            placeholder="Nome locale"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Ragione sociale:"
          label-for="input-2"
        >
          <b-form-input
            readonly
            id="input-2"
            v-model="formCliente.ragsoc"
            placeholder="Ragione sociale"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-3"
          label="Partita iva:"
          label-for="input-3"
        >
          <b-form-input
            readonly
            id="input-3"
            v-model="formCliente.piva"
            placeholder="Partita iva"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-4"
          label="Codice fiscale:"
          label-for="input-4"
        >
          <b-form-input
            readonly
            id="input-4"
            v-model="formCliente.codfisc"
            placeholder="Codice fiscale"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-5"
          label="Sede legale:"
          label-for="input-5"
        >
          <b-form-input
            readonly
            id="input-5"
            v-model="formCliente.via"
            placeholder="Sede legale"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-6" label="Cap:" label-for="input-6">
          <b-form-input
            readonly
            id="input-6"
            v-model="formCliente.cap"
            placeholder="Cap"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-7" label="Città:" label-for="input-7">
          <b-form-input
            readonly
            id="input-7"
            v-model="formCliente.citta"
            placeholder="Città"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-8" label="Provincia:" label-for="input-8">
          <b-form-input
            readonly
            id="input-8"
            v-model="formCliente.provincia"
            placeholder="Provincia"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-9" label="Telefono:" label-for="input-9">
          <b-form-input
            readonly
            id="input-9"
            v-model="formCliente.tel"
            placeholder="Telefono"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-10" label="Mail:" label-for="input-10">
          <b-form-input
            readonly
            id="input-10"
            v-model="formCliente.mail"
            placeholder="Mail"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-11" label="Sdi:" label-for="input-11">
          <b-form-input
            readonly
            id="input-11"
            v-model="formCliente.sdi"
            placeholder="Sdi"
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-100" label="Pec:" label-for="input-100">
          <b-form-input
            readonly
            id="input-100"
            v-model="formCliente.pec"
            placeholder="Pec"
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-12"
          label="Note Private:"
          label-for="input-12"
        >
          <b-form-textarea
            readonly
            id="input-12"
            v-model="formCliente.notepriv"
            placeholder="Note Private"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group
          id="input-group-13"
          label="Note Cliente:"
          label-for="input-13"
        >
          <b-form-textarea
            readonly
            id="input-13"
            v-model="formCliente.notecli"
            placeholder="Note Cliente"
          ></b-form-textarea>
        </b-form-group>
      </b-form>
    </div>
  </b-card>
</template>

<script>
import CustomerCreationForm from "@/components/common/CustomerCreationForm.vue";

export default {
  components: {
    CustomerCreationForm,
  },
  props: { form: Object, editable: Boolean },
  data() {
    return {
      ricerca: {
        stringa: "",
      },
    };
  },
  name: "OrderFormCustomer",
  async created() {},
  methods: {
    selezionaCliente(cliente) {
      this.form.customer.id = cliente.id;
      this.form.customer.selected = true;
    },
    ottieniClienteById(id) {
      return this.clienti_ricerca[id];
    },
    deselezionaCliente() {
      this.form.customer.selected = false;
      this.form.customer.id = "";
      this.form.location.selected = false;
      this.form.location.id = "";
    },
    async customerCreated(id, customer) {
      this.$bvModal.hide("modal-newcustomer");
      customer.id = id;
      this.$store.dispatch("aggiungiCliente", customer);
      this.form.customer.id = id;
      this.form.customer.selected = true;
    },
  },
  computed: {
    canUpdateCustomer() {
      if (!this.$route.params.id) return true;
      return (
        this.$auth.user["https://rca-api/permissions"].indexOf(
          "update:ordine:cliente"
        ) != -1
      );
    },
    searchResult() {
      if (this.ricerca.stringa == "") return [];
      let k = 0;
      return this.clienti.filter((cliente) => {
        if (k >= 50) return false;

        let checkIdTrace =
          cliente["id_trace"] != null &&
          this.ricerca.stringa != "" &&
          cliente["id_trace"].includes(this.ricerca.stringa);
        let checkCustomerRagsoc =
          cliente["ragsoc"] != null &&
          this.ricerca.stringa != "" &&
          cliente["ragsoc"]
            .toLowerCase()
            .includes(this.ricerca.stringa.toLowerCase());

        if (checkIdTrace || checkCustomerRagsoc) k++;
        return checkIdTrace || checkCustomerRagsoc;
      });
    },
    clienti() {
      return this.$store.state.clienti;
    },
    clienti_ricerca() {
      return this.$store.state.clienti_search;
    },
    formCliente() {
      if (!this.form.customer.selected) {
        return {
          id_trace: "",
          ragsoc: "",
          piva: "",
          codfisc: "",
          tel: "",
          cell: "",
          mail: "",
          contatto: "",
          notecli: "",
          notepriv: "",
          pec: "",
          sdi: "",
          title: "",
          iban: "",
          via: "",
          cap: "",
          citta: "",
          provincia: "",
        };
      } else {
        return this.ottieniClienteById(this.form.customer.id);
      }
    },
  },
};
</script>
