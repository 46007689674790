<template>
  <div class="m-3">
    <h1>Clienti</h1>
    <b-form-input
      type="search"
      placeholder="Inserire ricerca..."
      class="mb-2"
      v-model="search"
    ></b-form-input>
    <PaginatedTable
      :getPage="getPage"
      :getCount="getCount"
      :openRow="openRow"
      :key="refreshKey"
    ></PaginatedTable>
  </div>
</template>

<script>
import PaginatedTable from "@/components/common/PaginatedTable.vue";

import * as clienteRepo from "../services/Cliente";
import router from "../router/index";

export default {
  name: "Clienti",
  components: {
    PaginatedTable,
  },
  data() {
    return {
      search: "",
      refreshKey: 0,
    };
  },
  watch: {
    searchQuery: {
      handler(val) {
        this.ricaricaPaginatedTable();
      },
      deep: true,
    },
  },
  methods: {
    async getPage(accessToken, page) {
      let clienti = await clienteRepo.getPage(
        accessToken,
        page,
        this.searchQuery
      );
      for (let i = 0; i < clienti.length; i++)
        this.compattaIndirizzo(clienti[i]);

      return clienti;
    },
    async getCount(accessToken) {
      return await clienteRepo.getCount(accessToken, this.countQuery);
    },
    ricaricaPaginatedTable() {
      this.refreshKey += 1;
    },
    compattaIndirizzo(cliente) {
      cliente["indirizzo"] =
        cliente["via"] + ", " + cliente["citta"] + ", " + cliente["provincia"];
      delete cliente["via"];
      delete cliente["citta"];
      delete cliente["provincia"];
    },
    openRow(row, number, event) {
      router.push({ name: "schedaCliente", params: { id: row["id"] } });
    },
  },
  computed: {
    searchQuery() {
      let query = {
        attributes: {
          exclude: [
            "id_trace",
            "codfisc",
            "tel",
            "cell",
            "mail",
            "contatto",
            "notecli",
            "notepriv",
            "pec",
            "sdi",
            "accounting_balance",
            "title",
            "supplier",
            "iban",
            "cap",
          ],
        },
      };
      if (this.search != "")
        query["where"] = {
          or: {
            ragsoc: {
              substring: this.search,
            },
            id: {
              substring: this.search,
            },
            piva: {
              substring: this.search,
            },
          },
        };
      return query;
    },
    countQuery() {
      let query = {};
      if (this.search != "")
        query["where"] = {
          or: {
            ragsoc: {
              substring: this.search,
            },
            id: {
              substring: this.search,
            },
            piva: {
              substring: this.search,
            },
          },
        };
      return query;
    },
  },
};
</script>
