<template>
  <div>
    <div class="d-flex flex-column flex-md-row">
      <input class="form-control mb-3 me-2" v-model="search" />
      <b-pagination
        v-model="pagina"
        :total-rows="items.length"
        :per-page="righe_per_pagina"
      ></b-pagination>
    </div>
    <b-table
      :busy="isBusy"
      :items="items"
      :fields="fields"
      striped
      responsive="sm"
      :per-page="righe_per_pagina"
      :current-page="pagina"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Caricamento...</strong>
        </div>
      </template>

      <template #cell(contatti)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? "Nascondi" : "Mostra" }} Contatti
        </b-button>
      </template>

      <template #row-details="row">
        <b-card
          v-for="locale in Object.entries(row.item.locali)"
          :key="locale[0]"
        >
          <b-card-title>
            <b-button v-b-toggle="'accordion' + locale[0]" variant="primary">{{
              locali[row.item.id_cliente][locale[1][0].locale].nome +
              " - " +
              locali[row.item.id_cliente][locale[1][0].locale].via
            }}</b-button>
          </b-card-title>
          <b-collapse :id="'accordion' + locale[1][0].locale">
            <b-card
              v-for="contatto in Object.entries(locale[1])"
              :key="contatto[0]"
            >
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"><b>Referente:</b></b-col>
                <b-col>{{ contatto[1].reference }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"><b>Telefono:</b></b-col>
                <b-col>{{ contatto[1].telephone }}</b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col sm="3" class="text-sm-right"><b>Mail:</b></b-col>
                <b-col>{{ contatto[1].mail }} </b-col>
                <b-col>
                  <b-button
                    v-if="contatto[1].mail && contatto[1].mail.trim() != ''"
                    size="sm"
                    variant="success"
                    @click="() => mailSelectedEvent(contatto[1].mail)"
                    >Aggiungi ai destinatari</b-button
                  >
                </b-col>
              </b-row>
            </b-card>
          </b-collapse>
        </b-card>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isBusy: true,
      contatti: [],
      clienti: [],
      pagina: 1,
      righe_per_pagina: 10,
      fields: ["contatti", "id_cliente", "trace", "ragione_sociale"],
      search: "",
    };
  },
  props: ["defaultSearch"],
  computed: {
    items() {
      let data = [];
      for (const id_cliente of Object.keys(this.contatti)) {
        if (Object.keys(this.contatti[id_cliente]).length > 0) {
          let cliente = this.clienti[id_cliente];

          if (
            cliente &&
            this.search != "" &&
            !cliente.ragsoc.toUpperCase().includes(this.search.toUpperCase())
          )
            continue;
          if (cliente) {
            data.push({
              age: 50,
              id_cliente: cliente.id,
              trace: cliente.id_trace,
              ragione_sociale: cliente.ragsoc,
              locali: this.contatti[id_cliente],
            });
          }
        }
      }

      return data;
    },
  },
  methods: {
    mailSelectedEvent(mail) {
      this.$emit("mailSelected", mail);
    },
  },
  async created() {
    this.loading = true;
    const accessToken = await this.$auth.getTokenSilently();
    await this.$store.dispatch("caricaContatti", accessToken);
    await this.$store.dispatch("caricaClienti", accessToken);
    await this.$store.dispatch("caricaLocali", accessToken);

    this.clienti = this.$store.state.clienti_search;
    this.contatti = this.$store.state.contatti_search;
    this.locali = this.$store.state.locali_search;

    this.isBusy = false;
    this.search = this.defaultSearch;
    // if (this.cliente) this.configurazione.cliente = this.cliente;
    // this.loading = false;
  },
};
</script>
