import axios from "axios";

export const creaVersioneFirmware = async (token, versioneFirmware) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return await axios.post(
    `${process.env.VUE_APP_API_HOST}/versioneFirmware`,
    versioneFirmware,
    config
  );
};

export const modificaVersioneFirmware = async (
  token,
  idVersioneFirmware,
  versioneFirmware
) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return await axios.put(
    `${process.env.VUE_APP_API_HOST}/versioneFirmware/${idVersioneFirmware}`,
    versioneFirmware,
    config
  );
};

export const eliminaVersioneFirmware = async (token, idVersioneFirmware) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return await axios.delete(
    `${process.env.VUE_APP_API_HOST}/versioneFirmware/${idVersioneFirmware}`,
    config
  );
};
