<template>
  <div>
    <b-button v-b-modal.modal-1>Configurazione RT</b-button>

    <b-modal id="modal-1" title="Configurazione RT" hide-footer>
      <b-form-group
        id="max_azz_label"
        label="Numero massimo di azzeramenti:"
        label-for="max_azz"
      >
        <b-form-input
          id="max_azz"
          v-model="maxAzzeramenti"
          type="number"
          step="1"
          v-on:change="() => updateMaxAzzeramenti(maxAzzeramenti)"
        ></b-form-input>
      </b-form-group>
      <label class="mt-3">Nuova versione firmware:</label>
      <b-input-group>
        <b-form-input :maxlength="25" v-model="nuovaVersione"></b-form-input>
        <b-input-group-append>
          <b-button
            variant="success"
            v-on:click="() => addVersion(nuovaVersione)"
            >Aggiungi</b-button
          >
        </b-input-group-append>
      </b-input-group>
      <label class="mt-3">Versioni firmware:</label>
      <div
        v-if="
          datiArticolo &&
          datiArticolo.versione_firmwares &&
          datiArticolo.versione_firmwares.length > 0
        "
      >
        <b-input-group
          v-for="versione in datiArticolo.versione_firmwares"
          id="version"
          prepend="Versione"
          :key="versione.id"
        >
          <b-form-input
            :maxlength="25"
            v-model="versione.descrizione"
            v-on:change="() => updateVersion(versione)"
          ></b-form-input>
          <b-input-group-append>
            <b-button
              variant="danger"
              v-on:click="() => deleteVersion(versione.id)"
              >Elimina</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </div>
      <p v-else style="color: red">Nessuna versione firmware disponibile</p>
    </b-modal>
  </div>
</template>

<script>
import * as articoloRepo from "../../services/Articolo";
import * as versioneFirmwareRepo from "../../services/VersioneFirmware";

export default {
  name: "ConfigurazioneRT",
  props: {
    articolo: Number,
  },
  data() {
    return {
      versioni: [],
      configurazione: {},
      datiArticolo: {},
      nuovaVersione: "",
      maxAzzeramenti: 0,
    };
  },
  async created() {
    this.loadArticolo();
  },
  computed: {
    getMaxAzzeramenti() {
      if (
        !this.datiArticolo ||
        !this.datiArticolo.articolo_configurazione_articolo_rt ||
        !this.datiArticolo.articolo_configurazione_articolo_rt.max_azzeramenti
      )
        return 0;
      else
        return this.datiArticolo.articolo_configurazione_articolo_rt
          .max_azzeramenti;
    },
  },
  watch: {
    articolo: {
      handler(val) {
        console.log("WATCH");
      },
      deep: true,
    },
  },
  methods: {
    async addVersion(label) {
      if (!label) {
        this.$toast.error("Inserire la descrizione della versione");
        return;
      }
      try {
        const token = await this.$auth.getTokenSilently();
        const response = await versioneFirmwareRepo.creaVersioneFirmware(
          token,
          {
            articolo: this.datiArticolo.id,
            descrizione: label,
          }
        );
        if (response.status == 200) {
          this.$toast.success("Versione creata");
          this.loadArticolo();
        } else this.$toast.error("Errore nella creazione della versione");
      } catch (e) {
        this.$toast.error("Errore nella creazione della versione");
      }
    },
    async updateVersion(version) {
      try {
        const token = await this.$auth.getTokenSilently();
        const response = await versioneFirmwareRepo.modificaVersioneFirmware(
          token,
          version.id,
          {
            articolo: version.articolo,
            descrizione: version.descrizione,
          }
        );
        if (response.status == 200) this.$toast.success("Versione aggiornata");
        else this.$toast.error("Errore nell'aggiornamento della versione");
      } catch (e) {
        this.$toast.error("Errore nell'aggiornamento della versione");
      }
    },
    async deleteVersion(id) {
      try {
        const token = await this.$auth.getTokenSilently();
        const response = await versioneFirmwareRepo.eliminaVersioneFirmware(
          token,
          id
        );
        if (response.status == 200) {
          this.$toast.success("Versione eliminata");
          this.loadArticolo();
        } else this.$toast.error("Errore nell'eliminazione della versione");
      } catch (e) {
        this.$toast.error(
          "Errore nell'eliminazione della versione, non puoi eliminare una versione già assegnata"
        );
      }
    },
    async updateMaxAzzeramenti(max) {
      console.log("UPDATE MAX", max);
      try {
        const token = await this.$auth.getTokenSilently();
        const response = await articoloRepo.modificaConfigurazioneRT(
          token,
          this.articolo,
          {
            max_azzeramenti: max,
          }
        );
        this.$toast.success("Numero massimo di azzeramenti aggiornato");
      } catch (e) {
        this.$toast.error(
          "Errore nell'aggiornamento del numero massimo di azzeramenti"
        );
      }
    },
    async loadArticolo() {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      this.datiArticolo = await articoloRepo.getByIdWithInclude(
        accessToken,
        this.articolo,
        ["versione_firmwares", "articolo_configurazione_articolo_rt"]
      );
      this.maxAzzeramenti = this.getMaxAzzeramenti;
      this.$loading(false);
    },
  },
};
</script>
