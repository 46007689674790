<template>
  <div class="m-3">
    <h1>Lista Campagne</h1>
    <div style="text-align: right; font-size: 1.3rem; cursor: pointer">
      <b-button @click="ricaricaPaginatedTable">
        <b-icon icon="arrow-clockwise"></b-icon>
        Aggiorna
      </b-button>
    </div>

    <b-modal
      id="modale-template"
      v-model="modaleTemplate.aperto"
      hide-footer
      hide-header
      centered
      size="lg"
    >
      <div style="height: 90vh">
        <iframe
          :srcdoc="modaleTemplate.data"
          width="100%"
          height="100%"
        ></iframe>
      </div>
    </b-modal>
    <b-modal
      id="modale-filtri"
      v-model="modaleFiltri.aperto"
      hide-footer
      centered
      size="md"
      title="Filtri campagna"
    >
      <b-table
        stacked
        :items="modaleFiltri.data"
        :fields="[
          'filtra_per',
          'filtro',
          { key: 'profili_filtro_campagnas', label: 'Profilo locale' },
        ]"
      >
        <template #cell(profili_filtro_campagnas)="data">
          {{
            data.value
              .map((item) => item.profilo_contatto_profilo_contatto.descrizione)
              .join(", ")
          }}
        </template>
      </b-table>
    </b-modal>
    <b-modal
      id="modale-statistiche"
      v-model="modaleStatistiche.aperto"
      hide-footer
      hide-header
      centered
      size="sm"
    >
      <b-table
        :items="modaleStatistiche.data"
        :fields="['descrizione', 'valore']"
      >
      </b-table>
    </b-modal>
    <b-modal
      id="modale-statistiche-realtime"
      v-model="modaleStatisticheRealtime.aperto"
      hide-footer
      hide-header
      centered
      size="xl"
    >
      <TargetView
        :target="modaleStatisticheRealtime.target"
        :profiloContatto="modaleStatisticheRealtime.profilo_contatto"
        :hideExport="true"
        v-on:export="null"
      >
      </TargetView>
    </b-modal>

    <PaginatedTable
      :getPage="getPage"
      :getCount="getCount"
      :openRow="() => {}"
      :key="refreshKey"
      :fields="fields"
    >
      <template #cell(data)="data">
        <span
          :style="{
            color: new Date(data.value) > Date.now() ? 'orange' : 'green',
            fontWeight: 'bold',
          }"
          >{{ new Date(data.value).toLocaleString("it-IT") }}</span
        >
      </template>

      <template #cell(tipo_campagna_tipo_campagna)="data">
        {{ data.value.descrizione }}
      </template>

      <template #cell(destinazione_campagna_destinazione_campagna)="data">
        {{ data.value.descrizione }}
      </template>

      <!-- <template #cell(avviata)="data">
        {{ data.value ? "AVVIATA" : "IN ATTESA" }}
      </template> -->

      <template #cell(filtro_campagnas)="data">
        <div style="font-size: 1.5rem">
          <b-icon
            v-if="data.value.length > 0"
            icon="funnel"
            @click="() => apriModaleFiltri(data.value)"
            style="cursor: pointer"
          ></b-icon>
        </div>
      </template>

      <template #cell(template)="data">
        <div style="font-size: 1.5rem">
          <b-icon
            icon="braces"
            @click="() => apriModaleTemplate(data.value)"
            style="cursor: pointer"
          ></b-icon>
        </div>
      </template>

      <template #cell(statistiche_campagnas)="data">
        <div
          style="font-size: 1.5rem"
          v-if="
            data.item.tipo_campagna_tipo_campagna.id == 3 &&
            data.item.avviata == false
          "
        >
          <b-icon
            icon="unlock"
            @click="() => apriModaleStatisticheRealtime(data.item)"
            style="cursor: pointer"
          ></b-icon>
        </div>
        <div style="font-size: 1.5rem" v-else>
          <b-icon
            icon="lock"
            @click="() => apriModaleStatistiche(data.value)"
            style="cursor: pointer"
          ></b-icon>
        </div>
      </template>

      <template #cell(elimina)="data">
        <b-button
          :disabled="new Date(data.item.data) <= Date.now()"
          @click="() => eliminaCampagna(data.item)"
          variant="danger"
        >
          <b-icon icon="trash" style="cursor: pointer"></b-icon>
        </b-button>
      </template>
      <!-- 
      <template #cell(errore_destinazione_campagnas)="data">
        <span
          @click="() => aprimodaleTemplate(data.value)"
          style="
            color: red;
            font-weight: bold;
            text-decoration: underline;
            cursor: pointer;
          "
        >
          {{ data.value.length }}</span
        >
      </template> -->
      <!-- 
          <template #cell(import_trace)="data">
            <font-awesome-icon v-if="data.value" icon="fa-solid fa-check" />
            <font-awesome-icon v-else icon="fa-solid fa-xmark" />
          </template>
          <template #cell(immagine)="data">
            <img
              :src="getImagePath(data.value)"
              alt="image"
              width="50"
              height="50"
            />
          </template> -->
    </PaginatedTable>
  </div>
</template>

<script>
import PaginatedTable from "@/components/common/PaginatedTable.vue";
import * as localeRepo from "../services/Locale";
import * as profiloLocaleRepo from "../services/ProfiloLocale";
import * as campagneRepo from "../services/Campagne";
import TargetView from "../components/campagne/target.vue";

import router from "../router/index";

export default {
  name: "listaCampagne",
  components: {
    PaginatedTable,
    TargetView,
  },
  data() {
    return {
      search: "",
      cliente_search: null,
      profilo_search: null,
      clienti: [],
      profili: [],
      refreshKey: 0,
      modaleTemplate: {
        aperto: false,
        data: [],
      },
      modaleFiltri: {
        aperto: false,
        data: [],
      },
      modaleStatistiche: {
        aperto: false,
        data: [],
      },
      modaleStatisticheRealtime: {
        aperto: false,
        profilo_contatto: null,
        target: [],
      },
    };
  },
  watch: {
    query: {
      handler(val) {
        this.ricaricaPaginatedTable();
      },
      deep: true,
    },
  },
  // async created() {
  //   this.$loading(true);
  //   this.$loading(false);
  // },
  methods: {
    async eliminaCampagna(campagna) {
      if (new Date(campagna.data) <= Date.now()) {
        this.$toast.warning("Non puoi eliminare una campagna avviata");
        return;
      }
      if (confirm("Sei sicuro di voler eliminare la campagna?")) {
        const accessToken = await this.$auth.getTokenSilently();
        campagneRepo
          .eliminaCampagna(accessToken, campagna.id)
          .then((res) => {
            this.$toast.success("Campagna eliminata");
            this.ricaricaPaginatedTable();
          })
          .catch((e) => {
            this.$toast.error("Errore nell'eliminazione della campagna");
            console.log(e);
          });
      }
    },
    //     formatPrice(number) {
    //       return (
    //         Number(number).toLocaleString("es-ES", {
    //           minimumFractionDigits: 2,
    //         }) + "€"
    //       );
    //     },
    apriModaleTemplate(data) {
      console.log(data);
      this.modaleTemplate.data = data;
      this.modaleTemplate.aperto = true;
    },
    apriModaleStatistiche(data) {
      console.log(data);
      this.modaleStatistiche.data = data;
      this.modaleStatistiche.aperto = true;
    },
    apriModaleFiltri(data) {
      console.log(data);
      this.modaleFiltri.data = data;
      this.modaleFiltri.aperto = true;
    },
    async apriModaleStatisticheRealtime(data) {
      if (!data.filtro_campagnas || data.filtro_campagnas.length < 1) return;

      try {
        this.$loading(true);
        const filtro = data.filtro_campagnas[0];
        const query = {
          where: {
            [filtro["filtra_per"]]: filtro["filtro"],
          },
        };
        const accessToken = await this.$auth.getTokenSilently();
        const response = await campagneRepo.getTarget(accessToken, query);
        this.modaleStatisticheRealtime.target = response.data;
        this.modaleStatisticheRealtime.profilo_contatto =
          filtro.profili_filtro_campagnas.map((item) => item.profilo_contatto);
        this.modaleStatisticheRealtime.aperto = true;
      } catch (e) {
        this.$toast.error(e.message);
      } finally {
        this.$loading(false);
      }
    },
    async getPage(accessToken, page) {
      let campagne = await campagneRepo.getPage(accessToken, page, this.query);
      if (campagne.data) campagne = campagne.data;
      else campagne = [];
      console.log(campagne);
      // locali = locali.map((locale) => {
      //   return {
      //     id: locale.id,
      //     cliente: locale.cliente_cliente
      //       ? locale.cliente_cliente.ragsoc
      //       : "ND",
      //     locale: locale.nome,
      //     profilo: locale.profilo_locale_profilo_locale
      //       ? locale.profilo_locale_profilo_locale.descrizione
      //       : "ND",
      //     via: locale.via,
      //     città: locale.citta,
      //     cap: locale.cap,
      //     provincia: locale.prov,
      //     piva: locale.piva,
      //     obsoleto: locale.obsoleto,
      //   };
      // });
      return campagne;
    },
    async getCount(accessToken) {
      let count = await campagneRepo.getCount(accessToken, this.countQuery);
      if (count.data) count = count.data;
      else count = 0;
      return count;
    },
    //     async getCustomers() {
    //       const accessToken = await this.$auth.getTokenSilently();
    //       this.clienti = [{ id: null, ragsoc: "Tutti i clienti" }].concat(
    //         await clientiRepo.getAll(accessToken)
    //       );
    //     },
    //     async getProfili() {
    //       const accessToken = await this.$auth.getTokenSilently();
    //       this.profili = [{ id: null, descrizione: "Tutti i profili" }].concat(
    //         await profiloLocaleRepo.getAll(accessToken)
    //       );
    //     },
    ricaricaPaginatedTable() {
      this.refreshKey += 1;
    },
    //     openRow(row, number, event) {
    //       router.push({ name: "schedaLocale", params: { id: row["id"] } });
    //     },
  },
  computed: {
    fields() {
      return [
        "nome",
        "oggetto",
        { key: "tipo_campagna_tipo_campagna", label: "Tipo" },
        {
          key: "destinazione_campagna_destinazione_campagna",
          label: "Destinazione",
        },
        { key: "data", label: "Schedulazione" },
        // { key: "avviata", label: "Stato" },
        { key: "filtro_campagnas", label: "Filtri", tdClass: "text-center" },
        {
          key: "statistiche_campagnas",
          label: "Statistiche",
          tdClass: "text-center",
        },
        { key: "template", label: "Template", tdClass: "text-center" },
        { key: "elimina", label: "", tdClass: "text-center" },
      ];
    },
    countQuery() {
      return {};
    },
    query() {
      let query = {
        include: [
          {
            model: "filtro_campagna",
            as: "filtro_campagnas",
            include: [
              {
                model: "profili_filtro_campagna",
                as: "profili_filtro_campagnas",
                include: "profilo_contatto_profilo_contatto",
              },
            ],
          },
          "tipo_campagna_tipo_campagna",
          "destinazione_campagna_destinazione_campagna",
          "statistiche_campagnas",
        ],
      };
      return query;
      if (this.search.trim() != "") {
        query.where = {
          and: {
            or: {
              id: {
                substring: this.search,
              },
              nome: {
                substring: this.search,
              },
              via: {
                substring: this.search,
              },
              citta: {
                substring: this.search,
              },
              cap: {
                substring: this.search,
              },
              prov: {
                substring: this.search,
              },
              piva: {
                substring: this.search,
              },
            },
          },
        };
      }
      if (this.cliente_search) {
        query.where = {
          and: {
            ...query.where,
            cliente: this.cliente_search,
          },
        };
      }
      if (this.profilo_search) {
        query.where = {
          and: {
            ...query.where,
            profilo_locale: this.profilo_search,
          },
        };
      }
      return query;
    },
  },
};
</script>
