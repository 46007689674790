<template>
  <table class="table text-start">
    <thead>
      <tr>
        <th scope="col"></th>
        <th scope="col">Articolo</th>
        <th scope="col">Descrizione</th>
        <th scope="col" style="width: 5%">Quantità</th>
        <th scope="col" style="width: 13%">Listino</th>
        <th scope="col" style="width: 13%">Sconto</th>
        <th scope="col" style="width: 13%">Totale</th>
      </tr>
    </thead>
    <tbody v-if="this.software.length > 0">
      <tr>
        <td class="text-start" colspan="3">SOFTWARE</td>
        <td colspan="3" class="text-end">Totale software:</td>
        <td>{{ totaleSoftware }} €</td>
      </tr>
      <tr
        is="CartItemsDesktopRow"
        v-for="articolo in this.software"
        :key="articolo.id"
        :articolo="articolo"
        :getArticleById="getArticleById"
        :modificaScontoInBaseAlTotale="modificaScontoInBaseAlTotale"
        :rimuoviArticolo="rimuoviArticolo"
        :editable="editable"
        :scontabile="scontabile"
      ></tr>
    </tbody>
    <tbody v-if="this.hardware.length > 0">
      <tr>
        <td class="text-start" colspan="3">HARDWARE</td>
        <td colspan="3" class="text-end">Totale hardware:</td>
        <td>{{ totaleHardware }} €</td>
      </tr>
      <tr
        is="CartItemsDesktopRow"
        v-for="articolo in this.hardware"
        :key="articolo.id"
        :articolo="articolo"
        :getArticleById="getArticleById"
        :modificaScontoInBaseAlTotale="modificaScontoInBaseAlTotale"
        :rimuoviArticolo="rimuoviArticolo"
        :editable="editable"
        :scontabile="scontabile"
      ></tr>
    </tbody>
    <tbody v-if="this.servizi.length > 0">
      <tr>
        <td class="text-start" colspan="3">SERVIZI</td>
        <td colspan="3" class="text-end">Totale servizi:</td>
        <td>{{ totaleServizi }} €</td>
      </tr>
      <tr
        is="CartItemsDesktopRow"
        v-for="articolo in this.servizi"
        :key="articolo.id"
        :articolo="articolo"
        :getArticleById="getArticleById"
        :modificaScontoInBaseAlTotale="modificaScontoInBaseAlTotale"
        :rimuoviArticolo="rimuoviArticolo"
        :editable="editable"
        :scontabile="scontabile"
      ></tr>
    </tbody>
    <tbody v-if="this.riscatti.length > 0">
      <tr>
        <td class="text-start" colspan="3">RISCATTI</td>
        <td colspan="3" class="text-end">Totale riscatti:</td>
        <td>{{ totaleRiscatti }} €</td>
      </tr>
      <tr
        is="CartItemsDesktopRow"
        v-for="articolo in this.riscatti"
        :key="articolo.id"
        :articolo="articolo"
        :getArticleById="getArticleById"
        :modificaScontoInBaseAlTotale="modificaScontoInBaseAlTotale"
        :rimuoviArticolo="rimuoviArticolo"
        :editable="editable"
        :scontabile="scontabile"
      ></tr>
    </tbody>
  </table>
</template>

<script>
import CartItemsDesktopRow from "@/components/ordini/CartItemsDesktopRow.vue";
export default {
  components: { CartItemsDesktopRow },
  name: "CartItemsDesktop",
  props: [
    "software",
    "hardware",
    "servizi",
    "riscatti",
    "totaleSoftware",
    "totaleHardware",
    "totaleServizi",
    "totaleRiscatti",
    "getArticleById",
    "modificaScontoInBaseAlTotale",
    "rimuoviArticolo",
    "editable",
    "scontabile",
  ],
};
</script>
