<template>
  <b-card no-body>
    <b-tabs
      @input="
        () => {
          this.page = 1;
        }
      "
      pills
      card
      vertical
    >
      <b-tab
        active
        :title="`[${report_comunicazioniEffettive.length}] Comunicazioni effettive`"
      >
        <b-table
          small
          striped
          hover
          :items="report_comunicazioniEffettive"
          :fields="['locale', 'cliente', 'mail']"
          :perPage="perPage"
          :currentPage="page"
        >
          <template #cell(cliente)="data">
            {{ getCustomerFieldById(data.value, "ragsoc") }}
          </template>
          <template #cell(locale)="data">
            {{
              getLocationFieldById(data.item.cliente, data.item.locale, "nome")
            }}
          </template>
          <template #cell(articolo)="data">
            {{ getArticleFieldById(data.value, "title") }}
          </template>
        </b-table>
        <div class="d-flex justify-content-between">
          <b-pagination
            v-model="page"
            :total-rows="report_comunicazioniEffettive.length"
            :per-page="perPage"
          ></b-pagination>
          <b-button
            v-if="!hideExport"
            variant="primary"
            @click="launchExportEvent"
            >Conferma il target!</b-button
          >
        </div>
      </b-tab>
      <b-tab :title="`[${report_localiRaggiunti.length}] Locali raggiunti`">
        <b-table
          small
          striped
          hover
          :items="
            report_localiRaggiunti.map((item) => {
              return { locale: item };
            })
          "
          :fields="['id', 'locale']"
          :perPage="perPage"
          :currentPage="page"
        >
          <template #cell(id)="data">
            {{ data.item.locale }}
          </template>
          <template #cell(locale)="data">
            {{ getLocationFieldByLocationId(data.item.locale, "nome") }}
          </template>
        </b-table>
        <b-pagination
          v-model="page"
          :total-rows="report_localiRaggiunti.length"
          :per-page="perPage"
        ></b-pagination>
      </b-tab>
      <b-tab
        :title="`[${report_localiSenzaContatti.length}] Locali senza contatti`"
      >
        <b-table
          small
          striped
          hover
          :items="
            report_localiSenzaContatti.map((item) => {
              return { locale: item };
            })
          "
          :fields="['id', 'locale']"
          :perPage="perPage"
          :currentPage="page"
        >
          <template #cell(id)="data">
            {{ data.item.locale }}
          </template>
          <template #cell(locale)="data">
            {{ getLocationFieldByLocationId(data.item.locale, "nome") }}
          </template>
        </b-table>
        <b-pagination
          v-model="page"
          :total-rows="report_localiSenzaContatti.length"
          :per-page="perPage"
        ></b-pagination>
      </b-tab>
      <b-tab :title="`[${report_localiInTarget.length}] Locali in target`">
        <b-table
          small
          striped
          hover
          :items="
            report_localiInTarget.map((item) => {
              return { locale: item };
            })
          "
          :fields="['id', 'locale']"
          :perPage="perPage"
          :currentPage="page"
        >
          <template #cell(id)="data">
            {{ data.item.locale }}
          </template>
          <template #cell(locale)="data">
            {{ getLocationFieldByLocationId(data.item.locale, "nome") }}
          </template>
        </b-table>
        <b-pagination
          v-model="page"
          :total-rows="report_localiInTarget.length"
          :per-page="perPage"
        ></b-pagination>
      </b-tab>
      <b-tab
        :title="`[${report_comunicazioniIpotetiche.length}] Comunicazioni ipotetiche`"
      >
        <b-table
          small
          striped
          hover
          :items="report_comunicazioniIpotetiche"
          :fields="['locale', 'cliente', 'mail']"
          :perPage="perPage"
          :currentPage="page"
        >
          <template #cell(cliente)="data">
            {{ getCustomerFieldById(data.value, "ragsoc") }}
          </template>
          <template #cell(locale)="data">
            {{
              getLocationFieldById(data.item.cliente, data.item.locale, "nome")
            }}
          </template>
          <template #cell(articolo)="data">
            {{ getArticleFieldById(data.value, "title") }}
          </template>
        </b-table>
        <b-pagination
          v-model="page"
          :total-rows="report_comunicazioniIpotetiche.length"
          :per-page="perPage"
        ></b-pagination>
      </b-tab>
      <b-tab
        :title="`[${report_comunicazioniRipetuteRimosse.length}] Comunicazioni rimosse (ripetizioni)`"
      >
        <b-table
          small
          striped
          hover
          :items="report_comunicazioniRipetuteRimosse"
          :fields="['locale', 'cliente', 'mail']"
          :perPage="perPage"
          :currentPage="page"
        >
          <template #cell(cliente)="data">
            {{ getCustomerFieldById(data.value, "ragsoc") }}
          </template>
          <template #cell(locale)="data">
            {{
              getLocationFieldById(data.item.cliente, data.item.locale, "nome")
            }}
          </template>
          <template #cell(articolo)="data">
            {{ getArticleFieldById(data.value, "title") }}
          </template>
        </b-table>
        <b-pagination
          v-model="page"
          :total-rows="report_comunicazioniRipetuteRimosse.length"
          :per-page="perPage"
        ></b-pagination>
      </b-tab>
      <b-tab
        :title="`[${report_destinatariRipetuti.length}] Destinatari ripetuti`"
      >
        <b-table
          small
          striped
          hover
          :items="
            report_destinatariRipetuti.map((item) => {
              return { indirizzo: item };
            })
          "
          :fields="['indirizzo']"
          :perPage="perPage"
          :currentPage="page"
        >
        </b-table>
        <b-pagination
          v-model="page"
          :total-rows="report_destinatariRipetuti.length"
          :per-page="perPage"
        ></b-pagination>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
export default {
  name: "TargetView",
  props: ["target", "profiloContatto", "hideExport"],
  data() {
    return {
      profilo_contatto: [],
      search: "",
      perPage: 10,
      page: 1,
      clienti: [],
    };
  },
  watch: {
    profiloContatto: {
      handler(val) {
        this.profilo_contatto = val;
      },
    },
  },
  async created() {
    const accessToken = await this.$auth.getTokenSilently();
    if (this.profiloContatto) this.profilo_contatto = this.profiloContatto;

    const promises = [
      this.$store.dispatch("caricaClienti", accessToken),
      this.$store.dispatch("caricaLocali", accessToken),
    ];
    await Promise.all(promises);
    this.$loading(false);
  },
  methods: {
    launchExportEvent() {
      this.$emit("export", this.report_comunicazioniEffettive);
    },
    getCustomerFieldById(customer, field) {
      if (!customer) return null;
      if (!this.storeClienti[customer]) return null;
      return this.storeClienti[customer][field];
    },
    getLocationFieldById(customer, location, field) {
      if (!location) return null;
      if (!this.storeLocali[customer]) return null;
      if (!this.storeLocali[customer][location]) return null;
      return this.storeLocali[customer][location][field];
    },
    getLocationFieldByLocationId(location, field) {
      if (!location) return null;
      const locale = this.storeLocaliBase.find((item) => item.id == location);
      if (!locale) return null;
      return locale[field];
    },
    getArticleFieldById(id_trace, field) {
      if (!id_trace) return null;
      const index = this.articoliSelect.findIndex(
        (articolo) => articolo.id_trace == id_trace
      );
      if (index == -1) return null;
      return this.articoliSelect[index][field];
    },
  },
  computed: {
    storeClienti() {
      return this.$store.state.clienti_search;
    },
    storeLocali() {
      return this.$store.state.locali_search;
    },
    storeLocaliBase() {
      return this.$store.state.locali;
    },
    report_localiInTarget() {
      const locali = new Set();
      this.target.forEach((item) => {
        locali.add(item.locale);
      });
      return [...locali];
    },
    report_localiSenzaContatti() {
      const locali = {};
      this.target.forEach((item) => {
        if (!locali[item.locale]) locali[item.locale] = 0;
        if (item.mail && this.profilo_contatto.includes(item.profilo_contatto))
          locali[item.locale]++;
      });
      return Object.entries(locali)
        .filter(([locale, contatti]) => contatti == 0)
        .map(([locale, contatti]) => parseInt(locale));
    },
    report_localiRaggiunti() {
      return this.report_localiInTarget.filter((item) => {
        return this.report_localiSenzaContatti.indexOf(item) == -1;
      });
    },
    report_comunicazioniIpotetiche() {
      return this.target.filter((item) => {
        return (
          this.profilo_contatto.includes(item.profilo_contatto) && item.mail
        );
      });
    },
    report_comunicazioniRipetuteRimosse() {
      const indirizziPresenti = new Set();
      const comunicazioniRimosse = [];
      this.report_comunicazioniIpotetiche.forEach((item) => {
        if (!indirizziPresenti.has(item.mail)) {
          indirizziPresenti.add(item.mail);
        } else {
          comunicazioniRimosse.push(item);
        }
      });
      return comunicazioniRimosse;
    },
    report_comunicazioniEffettive() {
      const indirizziPresenti = new Set();
      const comunicazioniPresenti = [];
      this.report_comunicazioniIpotetiche.forEach((item) => {
        if (!indirizziPresenti.has(item.mail)) {
          indirizziPresenti.add(item.mail);
          comunicazioniPresenti.push(item);
        }
      });
      return comunicazioniPresenti;
    },
    report_destinatariRipetuti() {
      const indirizziPresenti = new Set();
      const indirizziRipetuti = new Set();
      this.report_comunicazioniIpotetiche.forEach((item) => {
        if (!indirizziPresenti.has(item.mail)) {
          indirizziPresenti.add(item.mail);
        } else {
          indirizziRipetuti.add(item.mail);
        }
      });
      return [...indirizziRipetuti];
    },
    reportLocali() {
      const mail_locali = {};
      this.target.forEach((item) => {
        if (!mail_locali[item.locale]) mail_locali[item.locale] = [];
        if (item.mail) {
          mail_locali[item.locale].push(item.mail);
        }
      });
      return mail_locali;
    },
  },
};
</script>
