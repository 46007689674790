<template>
  <div>
    <hero class="mt-3" />
    <div class="text-center">
      <b-button
        pill
        v-if="!isLogged"
        variant="success"
        size="lg"
        class="mt-2 mb-2"
        style="
          background-color: var(--primary-color);
          border-color: var(--primary-color);
        "
        @click="login"
        >Accedi</b-button
      >
    </div>
    <Tris :titoli="['Preventivi', 'Gestione Ordini', 'Rinnovi']">
      <template #first>
        Permette di generare preventivi completamente personalizzati di
        visualizzarli in vari formati e di inviarli al cliente sotto forma di
        mail.
      </template>
      <template #second>
        Permette di gestire tutte le fasi successive all'accettazione del
        preventivo da parte del cliente a partire dalla trasformazione in ordine
        fino alla completa evasione e consegna.
      </template>
      <template #third>
        Permette di gestire anche fasi successive al completamento degli ordini
        per tutti quei servizi che sono periodici, distinti in due categorie
        principali: annuali/biennali e mensili.
      </template>
    </Tris>
    <hr style="width: 90%; margin-left: auto; margin-right: auto" />

    <div
      style="width: 89%; margin-left: auto; margin-right: auto"
      class="card p-3 mt-4 mb-4 background-secondary"
    >
      <h4>T-Order API</h4>
      <div>
        T-Order offre una serie di API REST che permettono l'integrazione con
        qualsiasi sistema esterno.
        <br />
        Le API permettono sostanzialmente di:
        <ul>
          <li>
            leggere e modificare lo stato di qualsiasi componente (tabella) di
            T-Order
          </li>
          <li>
            eseguire logiche di business: modifica stato ordine, conferma
            rinnovo, disdetta, generazione fatture e molto altro
          </li>
          <li>
            utilizzare i servizi aggiuntivi: file manager, server mailing e
            generatore PDF
          </li>
        </ul>
        <b-button
          :href="docsEndpoint"
          target="_blank"
          style="background-color: var(--primary-color)"
        >
          <font-awesome-icon icon="fa-solid fa-book" />&nbsp; Documentazione API
        </b-button>

        <b-button
          :href="devDocsEndpoint"
          target="_blank"
          class="ms-1"
          style="background-color: var(--primary-color)"
        >
          <font-awesome-icon icon="fa-solid fa-book" />&nbsp; Manuale Utente
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Hero from "../components/Hero";
import Tris from "../components/Tris";

export default {
  name: "home",
  components: {
    Hero,
    Tris,
  },
  computed: {
    isLogged() {
      return this.$auth.isAuthenticated;
    },
    isLoading() {
      return this.$auth.loading;
    },
    docsEndpoint() {
      return process.env.VUE_APP_API_HOST;
    },
    devDocsEndpoint() {
      return "https://andrefra96.github.io/torder-docs/";
    },
  },
  watch: {
    isLoading(newValue, oldValue) {
      this.$loading(newValue);

      if (!newValue && this.isLogged) {
        // this.$loading(true);

        if (this.$auth.user["https://rca-api/user_metadata"].home_tecnico) {
          this.$router.push({ name: "statisticheTecniche" });
        }
      }
      // if (newValue != oldValue) {
      //   console.log(oldValue);
      //   console.log(newValue);
      //   //TODO: se l'utente ha home tecnico route a statistiche/tecniche
      //   if (newValue) {
      //     console.log(this.$auth.loading);
      //     const copy = this.$auth.loading;
      //     console.log(this.$auth._data);
      //     console.log(copy.user);
      //   }
      // }
    },
  },
  methods: {
    login() {
      // this.$loading(true);
      this.$auth.loginWithRedirect();
    },
  },
};
</script>
