import ApiConnector from "./ApiConnector";
import axios from "axios";

let connector = new ApiConnector(process.env.VUE_APP_API_HOST);

export const sendMail = (token, mail) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.MailApi();

    apiInstance.inviaMail(mail, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getMailInfo = (accessToken, mid, start, end) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      start: start,
      end: end,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/mail/status/${mid}`,
    config
  );
};
