<template>
  <div class="m-3">
    <!-- MODALE NOTE -->
    <b-modal
      id="modal-1"
      v-model="modaleNote.aperto"
      :title="modaleNote.ragsoc"
    >
      <b-textarea v-model="modaleNote.nota"></b-textarea>
      <template #modal-footer>
        <b-button @click="submitModaleNota">Conferma</b-button>
      </template>
    </b-modal>
    <h1>Insoluti</h1>
    <div class="row">
      <div class="col-md-6 mb-2">
        <b-form-input
          type="search"
          placeholder="Inserire id, id trace o ragione sociale..."
          v-model="search"
        ></b-form-input>
      </div>
      <div class="col-md-3">
        <b-select
          :options="utenti_search"
          v-model="userSearch"
          class="form-control"
        >
        </b-select>
      </div>
    </div>
    <b-card class="background-secondary shadow">
      <b-table
        hover
        bordered
        :items="scaduti_filtrati"
        :fields="fields"
        :perPage="perPage"
        :currentPage="currentPage"
        style="color: var(--my-text-color) !important"
        stacked="sm"
        @row-clicked="openRow"
      >
        <template #cell(tot_scaduto)="data">
          {{ formatPrice(data.item.tot_scaduto) }}
        </template>
        <template #cell(tot_scadenza)="data">
          {{ formatPrice(data.item.tot_scadenza) }}
        </template>
        <template #cell(utente_scaduto)="data">
          <b-select
            @change="(value) => modificaUtenteAssegnato(data.item.id, value)"
            class="form-control"
            :options="utenti"
            :value="data.item.utente_scaduto"
          ></b-select>
        </template>
        <template #cell(notepriv)="data">
          <div
            @click="(event) => apriModaleNote(event, data.item)"
            class="pointer"
          >
            <font-awesome-icon
              icon="fa-solid fa-circle-info"
              size="xl"
              :style="{
                color:
                  data.item.notepriv && data.item.notepriv.trim() != ''
                    ? 'red'
                    : 'black',
              }"
            />
          </div>
        </template>
      </b-table>
    </b-card>
    <div class="d-flex flex-column flex-md-row">
      <b-pagination
        class="customPagination"
        v-model="currentPage"
        :total-rows="scaduti_filtrati.length"
        :per-page="perPage"
      ></b-pagination>

      <b class="mt-auto mb-auto ms-3" style="color: var(--primary-color)">
        {{ scaduti_filtrati.length }} Risultati
      </b>
    </div>
    <!-- <div class="col-md-6 mb-2">
        <b-form-input
          type="search"
          placeholder="Inserire id, nome o id trace..."
          v-model="search"
        ></b-form-input>
      </div>
  
      <PaginatedTable
        :getPage="getPage"
        :getCount="getCount"
        :openRow="openRow"
        :key="refreshKey"
        pointer
      >
      </PaginatedTable> -->
  </div>
</template>

<script>
import PaginatedTable from "@/components/common/PaginatedTable.vue";
import * as insolutiRepo from "../services/Insoluti";
import * as clientiRepo from "../services/Cliente";

//   import router from "../router/index";

export default {
  name: "Insoluti",
  components: {
    PaginatedTable,
  },
  data() {
    return {
      modaleNote: {
        aperto: false,
        cliente: null,
        nota: null,
        ragsoc: null,
      },
      scaduti: [],
      currentPage: 1,
      perPage: 10,
      search: "",
      userSearch: 0,
    };
  },
  watch: {
    scaduti_filtrati: {
      async handler(val) {
        await this.$store.dispatch("modificaPreferenzeFormScaduti", {
          search: this.search,
          userSearch: this.userSearch,
        });
      },
      deep: true,
    },
  },
  async created() {
    //Scarico le preferenze salvate su vuex
    if (this.$store.state.preferences.forms.scaduti) {
      this.search = this.$store.state.preferences.forms.scaduti.search;
      this.userSearch = this.$store.state.preferences.forms.scaduti.userSearch;
    }
    //Scarico i dati
    this.$loading(true);
    const accessToken = await this.$auth.getTokenSilently();
    await this.caricaScaduti(accessToken);
    await this.$store.dispatch("caricaUtenti", accessToken);
    this.$loading(false);
  },
  methods: {
    apriModaleNote(event, data) {
      event.stopImmediatePropagation();
      this.modaleNote.aperto = true;
      this.modaleNote.cliente = data.id;
      this.modaleNote.nota = data.notepriv;
      this.modaleNote.ragsoc = data.ragsoc;
    },
    async submitModaleNota() {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      // Se la nota è una stringa vuota la sostituisco con null
      let nota = this.modaleNote.nota;
      if (typeof nota == "string" && nota.trim() == "") nota = null;
      //Effettuo update
      try {
        await clientiRepo.update(accessToken, this.modaleNote.cliente, {
          notepriv: nota,
        });
        this.$toast.open("Nota modificata!");
      } catch (e) {
        this.$toast.error(
          "Si è verificato un errore nella modifica delle note"
        );
      }
      //Modifico la nota nella cache degli scaduti per non ricaricare tutta la lista
      let scaduto = this.scaduti.find((scaduto) => {
        return scaduto.id == this.modaleNote.cliente;
      });
      if (scaduto) scaduto.notepriv = nota;

      this.modaleNote.aperto = false;
      this.$loading(false);
    },
    async modificaUtenteAssegnato(cliente, utente) {
      this.$loading(true);
      const accessToken = await this.$auth.getTokenSilently();
      //Effettuo update
      try {
        await clientiRepo.update(accessToken, cliente, {
          utente_scaduto: utente,
        });

        await this.caricaScaduti(accessToken);

        // //Se l'update è andato a buon fine modifico anche i dati del
        // //componente per riordinare la lista
        // for (let i = 0; i < this.scaduti.length; i++) {
        //   if (this.scaduti[i].id == cliente) {
        //     this.scaduti[i].utente_scaduto = utente;
        //     break;
        //   }
        // }
        this.$toast.open("Utente modificato!");
      } catch (e) {
        this.$toast.error(
          "Si è verificato un errore nella modifica dell'utente"
        );
      }
      this.$loading(false);
    },
    openRow(row, number, event) {
      this.$router.push({
        name: "insolutoCliente",
        params: { id: row["id_trace"] },
      });
    },
    async caricaScaduti(accessToken) {
      const response = await insolutiRepo.getList(accessToken);
      if (!response.data) {
        this.$toast.error("Errore caricamento insoluti");
        return;
      }
      this.scaduti = response.data;
    },
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + "€"
      );
    },
  },
  computed: {
    utenti() {
      return [{ value: null, text: "Nessun utente" }].concat(
        ...this.$store.state.utenti.map((utente) => {
          return { value: utente.id, text: utente.name };
        })
      );
    },
    utenti_search() {
      return [{ value: 0, text: "Tutti gli utenti" }]
        .concat(this.utenti)
        .filter((utente) => !utente.text.startsWith("*"));
    },
    fields() {
      return [
        { key: "id", sortable: false },
        { key: "id_trace", sortable: true },
        { key: "ragsoc", sortable: true },
        {
          key: "tot_scaduto",
          label: "Insoluto",
          sortable: true,
          class: "text-end",
        },
        {
          key: "tot_scadenza",
          label: "In Scadenza",
          sortable: true,
          class: "text-end",
        },
        { key: "utente_scaduto", label: "Utente", sortable: true },
        {
          key: "notepriv",
          label: "Note",
          class: "text-center",
        },
      ];
    },
    scaduti_filtrati() {
      let filtered = this.scaduti;

      if (this.search != "")
        filtered = filtered.filter((value) => {
          return (
            value.ragsoc.toLowerCase().includes(this.search.toLowerCase()) ||
            value.id.toString().includes(this.search) ||
            value.id_trace.toString().includes(this.search)
          );
        });
      if (this.userSearch != 0)
        filtered = filtered.filter((value) => {
          return value.utente_scaduto == this.userSearch;
        });

      return filtered;
    },
  },
};
</script>
