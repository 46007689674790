<template>
  <div class="m-3 card p-4 background-secondary">
    <h1>{{ locale.nome }}</h1>

    <b-form @submit="onSubmit">
      <!-- nome -->
      <b-form-group label="Nome:" label-for="nome">
        <b-form-input
          id="nome"
          :maxLength="100"
          placeholder="Inserire nome locale"
          v-model="locale.nome"
          :readonly="isReadonly || !canUpdate"
        ></b-form-input>
      </b-form-group>

      <!-- cliente -->

      Cliente:
      <b-input-group label="Cliente:" label-for="cliente">
        <b-input-group-prepend>
          <b-button
            variant="info"
            :to="'/clienti/' + locale.cliente"
            target="_blank"
            ><b-icon icon="person" aria-hidden="true"></b-icon
          ></b-button>
        </b-input-group-prepend>
        <b-form-input
          id="cliente"
          placeholder="Inserire cliente locale"
          :value="locale.cliente_cliente ? locale.cliente_cliente.ragsoc : 'ND'"
          readonly
        ></b-form-input>
      </b-input-group>

      <div class="row">
        <!-- via -->
        <div class="col-md-3">
          <b-form-group label="Via:" label-for="via">
            <b-form-input
              id="via"
              :maxLength="255"
              placeholder="Inserire via locale"
              v-model="locale.via"
              :readonly="isReadonly || !canUpdate"
            ></b-form-input>
          </b-form-group>
        </div>

        <!-- citta -->
        <div class="col-md-3">
          <b-form-group label="Città:" label-for="citta">
            <b-form-input
              id="citta"
              :maxLength="50"
              placeholder="Inserire citta locale"
              v-model="locale.citta"
              :readonly="isReadonly || !canUpdate"
            ></b-form-input>
          </b-form-group>
        </div>

        <!-- cap -->
        <div class="col-md-3">
          <b-form-group label="CAP:" label-for="cap">
            <b-form-input
              id="cap"
              type="number"
              :max="99999"
              placeholder="Inserire CAP locale"
              v-model="locale.cap"
              :readonly="isReadonly || !canUpdate"
            ></b-form-input>
          </b-form-group>
        </div>

        <!-- prov -->
        <div class="col-md-3">
          <b-form-group label="Provincia:" label-for="provincia">
            <b-form-input
              id="provincia"
              placeholder="Inserire provincia locale"
              :maxLength="10"
              v-model="locale.prov"
              :readonly="isReadonly || !canUpdate"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <!-- profilo_locale -->
      <b-form-group label="Profilo:" label-for="profilo">
        <b-form-select
          id="profilo"
          class="form-control"
          v-model="locale.profilo_locale"
          :options="profili_select"
          :disabled="!canUpdate"
          required
        ></b-form-select>
      </b-form-group>

      <!-- categoria_locale -->
      <b-form-group label="Categoria:" label-for="categoria">
        <b-form-select
          id="categoria"
          class="form-control"
          v-model="locale.categoria_locale"
          :options="categorie_select"
          :disabled="!canUpdate"
          required
        ></b-form-select>
      </b-form-group>

      <!-- prg -->
      <b-form-group label="Prg Trace:" label-for="prg">
        <b-form-input
          id="prg"
          placeholder="Inserire prg locale"
          v-model="locale.prg"
          readonly
        ></b-form-input>
      </b-form-group>
      <div class="row">
        <div class="col-md-6">
          <!-- piva -->
          <b-form-group label="Piva:" label-for="piva">
            <b-form-input
              id="piva"
              placeholder="Inserire piva locale"
              v-model="locale.piva"
              :maxLength="16"
              :readonly="isReadonly || !canUpdate"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-6">
          <!-- iban -->
          <b-form-group label="IBAN:" label-for="iban">
            <b-form-input
              id="iban"
              :maxLength="50"
              placeholder="Inserire iban locale"
              :readonly="!canUpdate"
              v-model="locale.iban"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <!-- è stato nascosto -->
      <div class="row d-none">
        <div class="col-md-4">
          <!-- contact -->
          <b-form-group label="Contatto:" label-for="contact">
            <b-form-input
              id="contact"
              :maxLength="50"
              placeholder="Inserire contatto locale"
              v-model="locale.contact"
              :readonly="isReadonly"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <!-- tel -->
          <b-form-group label="Telefono:" label-for="tel">
            <b-form-input
              id="tel"
              :maxLength="50"
              placeholder="Inserire telefono locale"
              v-model="locale.tel"
              :readonly="isReadonly"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-4">
          <!-- mail -->
          <b-form-group label="Mail:" label-for="mail">
            <b-form-input
              id="mail"
              :maxLength="127"
              placeholder="Inserire mail locale"
              v-model="locale.mail"
              :readonly="isReadonly"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <!-- note -->
      <b-form-group label="Note:" label-for="note">
        <b-form-textarea
          id="note"
          placeholder="Inserire note locale"
          v-model="locale.note"
          :readonly="isReadonly || !canUpdate"
        ></b-form-textarea>
      </b-form-group>
      <!-- obsoleto -->
      <b-form-group label="Obsoleto:" label-for="obsoleto">
        <b-form-input
          id="obsoleto"
          type="date"
          v-model="locale.obsoleto"
          :readonly="isReadonly || !canUpdate"
          >Obsoleto</b-form-input
        >
      </b-form-group>

      <b-button v-if="canUpdate" type="submit" variant="primary" class="mt-2"
        >Conferma modifiche</b-button
      >
    </b-form>
  </div>
</template>

<script>
import * as localeRepo from "../../services/Locale";
import * as profiloLocaleRepo from "../../services/ProfiloLocale";
import * as categoriaLocaleRepo from "../../services/CategoriaLocale";
import * as ibanService from "../../services/IBAN";

export default {
  name: "Locale",
  data() {
    return {
      locale: {},
      profili: [],
      categorie: [],
    };
  },
  async created() {
    this.$loading(true);
    await this.loadLocale();
    await this.loadProfili();
    await this.loadCategorie();
    this.$loading(false);
  },
  computed: {
    canUpdate() {
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:locale"
          ) != -1
        );
      }
      return false;
    },
    id() {
      return parseInt(this.$route.params.id);
    },
    isReadonly() {
      if (!this.locale) return true;
      return this.locale.prg ? true : false;
    },
    profili_select() {
      return this.profili.map((profilo) => {
        return {
          value: profilo.id,
          text: profilo.descrizione,
        };
      });
    },
    categorie_select() {
      return this.categorie.map((profilo) => {
        return {
          value: profilo.id,
          text: profilo.descrizione,
        };
      });
    },
  },
  methods: {
    async loadLocale() {
      const accessToken = await this.$auth.getTokenSilently();
      this.locale = await localeRepo.getByIdWithInclude(accessToken, this.id, [
        "cliente_cliente",
      ]);
    },
    async loadProfili() {
      const accessToken = await this.$auth.getTokenSilently();
      this.profili = await profiloLocaleRepo.getAll(accessToken, {
        where: { attivo: true },
      });
    },
    async loadCategorie() {
      const accessToken = await this.$auth.getTokenSilently();
      this.categorie = await categoriaLocaleRepo.getAll(accessToken, {
        where: { attivo: true },
      });
    },
    async onSubmit(event) {
      event.preventDefault();

      //Controllo la validità dell'iban
      if (this.locale.iban) {
        const verified = ibanService.verificaIBAN(this.locale.iban);
        if (!verified) {
          this.$toast.error("IBAN non valido!");
          return;
        }
      }

      //Creo una copia con solo i parametri necessari
      let copy = (({
        nome,
        via,
        citta,
        cap,
        prov,
        profilo_locale,
        categoria_locale,
        piva,
        iban,
        contact,
        tel,
        mail,
        note,
        obsoleto,
      }) => ({
        nome,
        via,
        citta,
        cap,
        prov,
        profilo_locale,
        categoria_locale,
        piva,
        iban,
        contact,
        tel,
        mail,
        note,
        obsoleto,
      }))(this.locale);

      //Se ha prg modifico solo profilo_locale,categoria_locale e iban
      if (this.locale.prg) {
        copy = (({ profilo_locale, categoria_locale, iban }) => ({
          profilo_locale,
          categoria_locale,
          iban,
        }))(this.locale);
      }

      //Empty strings to null
      const etn = [
        "cap",
        "citta",
        "contact",
        "iban",
        "mail",
        "nome",
        "note",
        "piva",
        "prov",
        "tel",
        "via",
        "obsoleto",
      ];
      for (const item of etn)
        if (typeof copy[item] == "string" && copy[item].trim().length == 0)
          copy[item] = null;

      try {
        this.$loading(true);
        const accessToken = await this.$auth.getTokenSilently();
        await localeRepo.update(accessToken, this.locale.id, copy);
        this.$toast.open("Locale modificato correttamente");
      } catch (e) {
        this.$toast.error("Errore modifica locale: " + e.message);
      }
      this.$loading(false);
    },
  },
};
</script>
