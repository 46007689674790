<template>
  <div class="mt-4 m-md-4 m-1">
    <div class="row align-items-center m-4">
      <div class="col-md text-md-start text-md-left">
        <h2>
          {{ $auth.user.name }}
        </h2>
        <p class="lead text-muted">{{ $auth.user.email }}</p>
      </div>
      <div class="col-md-2 text-md-end mb-3">
        <img
          :src="$auth.user.picture"
          alt="User's profile picture"
          class="rounded-circle img-fluid profile-picture"
        />
      </div>
    </div>

    <div class="card p-4 mb-2 background-secondary">
      <h3>Generale</h3>
      <!-- {{ $auth.user }} -->

      <table class="col-lg-6 text-color">
        <tr>
          <td>
            <strong>ID</strong>
          </td>
          <td>
            {{ $auth.user["https://rca-api/user_metadata"].matching_id }}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Auht0</strong>
          </td>
          <td>
            {{ $auth.user.sub }}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Nome</strong>
          </td>
          <td>
            {{ $auth.user["https://rca-api/user_metadata"].nome_utente }}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Nickname</strong>
          </td>
          <td>
            {{ $auth.user.nickname }}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Mail login</strong>
          </td>
          <td>
            {{ $auth.user.email }}
          </td>
        </tr>
        <tr>
          <td>
            <strong>Mail utente</strong>
          </td>
          <td>
            {{ $auth.user["https://rca-api/user_metadata"].mail_utente }}
          </td>
        </tr>
      </table>

      <!-- <p>
        I privilegi che ti sono stati assegnati sono:
        {{ $auth.user["https://rca-api/permissions"] }}
      </p> -->
    </div>

    <div class="card p-4 mb-2 background-secondary">
      <h3>Ruoli e Privilegi</h3>
      L'accesso alle diverse funzioni dell'applicazioni è gestito con un sistema
      di ruoli e privilegi.
      <br />
      Ogni utente ha accesso a specifiche funzioni in base alle necessità del
      suo ruolo all'interno dell'azienda.
      <br />
      <br />
      <h4>Ruoli</h4>
      <div>
        <label>I ruoli che ti sono stati assegnati sono:</label>
        <ul>
          <li v-for="ruolo of $auth.user['https://rca-api/roles'].roles">
            {{ ruolo }}
          </li>
        </ul>
      </div>
      <h4>Privilegi</h4>
      <div>
        <label>I privilegi che ti sono stati assegnati sono:</label>
        <ul>
          <li v-for="permesso of $auth.user['https://rca-api/permissions']">
            {{ permesso }}
          </li>
        </ul>
      </div>
    </div>

    <div class="card p-4 mb-2 background-secondary">
      <h3>Preferenze</h3>
      Personalizza l'esperienza all'interno di T-Order impostando le tue
      preferenze.

      <b-card no-body class="mb-1 mt-2 p-3 background">
        <div>
          <h4>Filtri lista ordini</h4>

          Seleziona quali filtri vuoi che siano attivi all'apertura della lista
          degli ordini, in questo modo li ritroverai sempre uguali
          indipendentemente dal dispositivo dal quale stai effettuando l'accesso
          <h5 class="mt-2">Stati Attivi</h5>
          Gli stati nel quale devono trovarsi gli ordini che desideri
          visualizzare all'apertura della lista degli ordini:
          <b-form-checkbox-group
            v-model="preferenze.ordini.activeStates"
            :options="statiIniziali"
            class="mt-2"
            value-field="item"
            text-field="name"
          ></b-form-checkbox-group>
          <h5 class="mt-2">Mostra cancellati</h5>
          Seleziona se visualizzare o meno gli ordini cancellati all'apertura
          della lista
          <b-form-checkbox v-model="preferenze.ordini.showDeleted">
            MOSTRA CANCELLATI
          </b-form-checkbox>
          <h5 class="mt-2">Mostra fatturati</h5>
          Seleziona se visualizzare o meno gli ordini già fatturati all'apertura
          della lista
          <b-form-checkbox class="mt-2" v-model="preferenze.ordini.showBilled">
            MOSTRA FATTURATI
          </b-form-checkbox>
          <h5 class="mt-2">Utente</h5>
          Apri la lista degli ordini visualizzando gli ordini creati da:
          <v-select
            class="col-md-3 background-secondary text-color"
            :options="utentiSelect"
            v-model="preferenze.ordini.utente"
            :reduce="(value) => value.code"
            :clearable="false"
          ></v-select>
          <h5 class="mt-2">Tecnico</h5>
          Apri la lista degli ordini visualizzando gli ordini assegnati a:
          <v-select
            class="col-md-3 background-secondary text-color"
            :options="utentiSelect"
            v-model="preferenze.ordini.tecnico"
            :reduce="(value) => value.code"
            :clearable="false"
          ></v-select>
          <div class="text-end">
            <b-button class="background-primary" @click="salvaFiltriOrdini"
              >Salva</b-button
            >
          </div>
        </div>
      </b-card>
      <b-card no-body class="mb-1 mt-2 p-3 background">
        <div>
          <h4>Temi</h4>
          <p>
            Sono disponibili due temi: uno per la dark mode e uno per la light
            mode.
            <br />
            Il tema attivo viene selezionato automaticamente in base al tema
            attivo sul dispositivo ma è comunque possibile modificarlo
            manualmente con il pulsante posto a destra della barra di
            navigazione
          </p>
          <div class="mt-4">
            <h5>Light mode</h5>
            <p>
              La light mode, modalità chiara, è la modalità più utilizzata e
              favorisce la comprensione del contenuto.
              <br />
              Seleziona tonalità di colore chiare.
            </p>
            <div class="mt-3">
              <label for="light-primary">Colore Primario:</label>
              <b-form-input
                id="light-primary"
                v-model="theme.light.primary"
                type="color"
              ></b-form-input>
            </div>
            <div class="mt-3">
              <label for="light-secondary">Colore Secondario:</label>
              <b-form-input
                id="light-secondary"
                v-model="theme.light.secondary"
                type="color"
              ></b-form-input>
            </div>
            <div class="mt-3">
              <label for="light-background">Colore di Sfondo:</label>
              <b-form-input
                id="light-background"
                v-model="theme.light.background"
                type="color"
              ></b-form-input>
            </div>
            <div class="mt-3">
              <label for="light-title">Colore dei Titoli:</label>
              <b-form-input
                id="light-title"
                v-model="theme.light.title"
                type="color"
              ></b-form-input>
            </div>
            <div class="mt-3">
              <label for="light-text">Colore del Testo:</label>
              <b-form-input
                id="light-text"
                v-model="theme.light.text"
                type="color"
              ></b-form-input>
            </div>
          </div>
          <div class="mt-4">
            <h5>Dark mode</h5>
            <p>
              La dark mode, modalità scura, viene tipicamente utilizzata in
              condizioni di scarsa illuminazione per ridurre l'affaticamento
              dell'occhio.
              <br />
              Seleziona tonalità di colore più scure.
            </p>
            <div class="mt-3">
              <label for="dark-primary">Colore Primario:</label>
              <b-form-input
                id="dark-primary"
                v-model="theme.dark.primary"
                type="color"
              ></b-form-input>
            </div>
            <div class="mt-3">
              <label for="dark-secondary">Colore Secondario:</label>
              <b-form-input
                id="dark-secondary"
                v-model="theme.dark.secondary"
                type="color"
              ></b-form-input>
            </div>
            <div class="mt-3">
              <label for="dark-background">Colore di Sfondo:</label>
              <b-form-input
                id="dark-background"
                v-model="theme.dark.background"
                type="color"
              ></b-form-input>
            </div>
            <div class="mt-3">
              <label for="dark-title">Colore dei Titoli:</label>
              <b-form-input
                id="dark-title"
                v-model="theme.dark.title"
                type="color"
              ></b-form-input>
            </div>
            <div class="mt-3">
              <label for="dark-text">Colore del Testo:</label>
              <b-form-input
                id="dark-text"
                v-model="theme.dark.text"
                type="color"
              ></b-form-input>
            </div>
          </div>

          <div class="text-end mt-2">
            <b-button class="background-primary" @click="salvaTema"
              >Salva</b-button
            >
          </div>
        </div>
      </b-card>
    </div>

    <div class="card p-4 background-secondary">
      <h3>Configurazione JSON</h3>
      <highlightjs
        autodetect
        :code="JSON.stringify($auth.user, null, 2)"
        class="rounded w-100"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Profilo",
  props: ["theme"],
  async created() {
    this.$loading(true);
    const accessToken = await this.$auth.getTokenSilently();
    await this.$store.dispatch("caricaUtenti", accessToken);
    this.initPreferenze(
      this.$auth.user["https://rca-api/user_metadata"].preferenze
    );
    this.$loading(false);
  },
  methods: {
    initPreferenze(preferenze) {
      this.preferenze = preferenze;
    },
    hasState(state) {
      return (
        this.$auth.user[
          "https://rca-api/user_metadata"
        ].preferenze.odini.activeStates.indexOf(state) != -1
      );
    },
    salvaFiltriOrdini() {
      console.log(this.$auth.user);
      alert("TODO: salva filtri in user metadata");
    },
    salvaTema() {
      alert("TODO: salva tema in user metadata");
    },
  },
  data() {
    return {
      preferenze: {
        ordini: {
          activeStates: [],
          showDeleted: false,
          showBilled: true,
          toggleStatus: true,
          ragsoc: "",
          ordine: "",
          utente: 0,
          tecnico: 0,
          all_status: false,
        },
      },
      statiIniziali: [
        { item: 2, name: "PREVENTIVO" },
        { item: 3, name: "ORDINE" },
        { item: 5, name: "IMPEGNI" },
        { item: 6, name: "LAVORAZIONE" },
        { item: 7, name: "EVASI" },
        { item: 1, name: "ARCHIVIATI" },
      ],
    };
  },
  computed: {
    utentiSelect() {
      return [{ code: 0, label: "Tutti" }].concat(
        this.$store.state.utenti.map((utente) => {
          return {
            label: utente.nickname,
            code: utente.id,
          };
        })
      );
    },
  },
};
</script>
