import Vuex from "vuex";
import Vue from "vue";

import * as clienteRepo from "../services/Cliente";
import * as localeRepo from "../services/Locale";
import * as metodoDiPagamentoRepo from "../services/TPaymentEsatto";
import * as articoloRepo from "../services/Articolo";
import * as utenteRepo from "../services/Utente";
import * as ordineRepo from "../services/Ordine";
import * as profiloContattoRepo from "../services/ProfiloContatto";
import * as profiloLocaleRepo from "../services/ProfiloLocale";
import * as categorieLocaleRepo from "../services/CategoriaLocale";
import * as motivazioneDisdettaRepo from "../services/MotivazioneDisdetta";
import * as contattiRepo from "../services/Contatto";
import * as gruppiRepo from "../services/Gruppo";
import * as scadutiRepo from "../services/Scaduti";

import { getInstance } from "../auth";

//Aggiungo Vuex a vue
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    api_token: null,
    preferences: {
      forms: {
        ordini: {
          activeStates: [],
          showDeleted: false,
          showBilled: false,
          toggleStatus: true,
          ragsoc: "",
          ordine: "",
          utente: 0,
          tecnico: 0,
          all_status: false,
        },
        ordini_modificato: false,
        rinnovi_annuali: {
          search: "",
          searchMonth: new Date().getUTCMonth() + 1,
          searchYear: new Date().getUTCFullYear(),
          searchProfile: null,
          perPage: 10,
        },
        rinnovi_mensili: {
          search: "",
          searchMonth: new Date().getUTCMonth() + 1,
          searchYear: new Date().getUTCFullYear(),
          searchProfile: null,
        },
        scaduti: {
          search: "",
          userSearch: 0,
        },
      },
    },
    clienti: [],
    locali: [],
    metodi_di_pagamento: [],
    metodi_di_pagamento_canoni: [],
    articoli: [],
    utenti: [],
    tipi_ordine: [],
    profili_contatto: [],
    profili_locale: [],
    categorie_locale: [],
    clienti_search: {},
    locali_search: {},
    metodi_di_pagamento_search: {},
    metodi_di_pagamento_canoni_search: {},
    articoli_search: {},
    motivazioni_disdetta: [],
    contatti: [],
    contatti_search: {},
    gruppi: [],
    gruppi_search: {},
    scaduti: [],
    scaduti_search: {},
    totale_scaduti: {},
  },
  mutations: {
    //Mutations relative alle preferenze utente
    impostaPreferenzeFormOrdine(state, preferenze, modificato = false) {
      state.preferences.forms.ordini = preferenze;
      state.preferences.forms.ordini_modificato = modificato;
    },
    impostaPreferenzeFormRinnovoAnnuale(state, preferenze) {
      state.preferences.forms.rinnovi_annuali = preferenze;
    },
    impostaPreferenzeFormRinnovoMensile(state, preferenze) {
      state.preferences.forms.rinnovi_mensili = preferenze;
    },
    impostaPreferenzeFormScaduti(state, preferenze) {
      state.preferences.forms.scaduti = preferenze;
    },
    //Mutations principali
    //Permettono di modificare lo stato attuale dello store
    impostaClienti(state, clienti) {
      state.clienti = clienti;
      //Chiama la mutation di indicizzazione
      this.commit("ottimizzaClienti", clienti);
    },
    impostaLocali(state, locali) {
      state.locali = locali;
      this.commit("ottimizzaLocali", locali);
    },
    impostaMetodiDiPagamento(state, metodi_di_pagamento) {
      state.metodi_di_pagamento = metodi_di_pagamento;
      this.commit("ottimizzaMetodiDiPagamento", metodi_di_pagamento);
    },
    impostaMetodiDiPagamentoCanoni(state, metodi_di_pagamento_canoni) {
      state.metodi_di_pagamento_canoni = metodi_di_pagamento_canoni;
      this.commit(
        "ottimizzaMetodiDiPagamentoCanoni",
        metodi_di_pagamento_canoni
      );
    },
    impostaArticoli(state, articoli) {
      state.articoli = articoli;
      this.commit("ottimizzaArticoli", articoli);
    },
    impostaUtenti(state, utenti) {
      state.utenti = utenti;
    },
    impostaTipiOrdine(state, tipi_ordine) {
      state.tipi_ordine = tipi_ordine;
    },
    impostaProfiliContatto(state, profili_contatto) {
      state.profili_contatto = profili_contatto;
    },
    impostaProfiliLocale(state, profili_locale) {
      state.profili_locale = profili_locale;
    },
    impostaCategorieLocale(state, categorie_locale) {
      console.log("IMPOSTA CATEGORIE");
      console.log(categorie_locale);
      state.categorie_locale = categorie_locale;
    },
    impostaMotivazioniDisdetta(state, motivazioni_disdetta) {
      state.motivazioni_disdetta = motivazioni_disdetta;
    },
    impostaContatti(state, contatti) {
      state.contatti = contatti;
      this.commit("ottimizzaContatti", contatti);
    },
    impostaGruppi(state, gruppi) {
      state.gruppi = gruppi;
      this.commit("ottimizzaGruppi", gruppi);
    },
    impostaScaduti(state, scaduti) {
      state.scaduti = scaduti;
      this.commit("ottimizzaScaduti", scaduti);
    },
    impostaTotaleScaduti(state, totale_scaduti) {
      state.totale_scaduti = totale_scaduti;
    },
    //Mutations dedicate alla modifica dello storage
    //Metodi specifici per modificare porzioni dello storage
    aggiungiCliente(state, cliente) {
      //modifico storage principale
      state.clienti.push(cliente);
      //modifico storage indicizzato
      state.clienti_search[cliente.id] = cliente;
    },
    aggiungiLocale(state, locale) {
      state.locali.push(locale);
      if (!state.locali_search[locale.cliente])
        state.locali_search[locale.cliente] = {};
      state.locali_search[locale.cliente][locale.id] = locale;
    },
    modificaIbanCliente(state, { cliente, iban }) {
      for (const cus of state.clienti) {
        if (cus.id == cliente) {
          cus.iban = iban;
          break;
        }
      }
    },
    modificaIbanLocale(state, { locale, iban }) {
      for (const loc of state.locali) {
        if (loc.id == locale) {
          loc.iban = iban;
          break;
        }
      }
    },
    //Mutations dedicate all'indicizzazione di grossi risultati
    //permettono di organizzare i dati per effettuare ricerche molto più veloci
    ottimizzaLocali(state, locali) {
      for (let locale of locali) {
        if (!state.locali_search[locale.cliente])
          state.locali_search[locale.cliente] = {};
        state.locali_search[locale.cliente][locale.id] = locale;
      }
    },
    ottimizzaClienti(state, clienti) {
      for (let cliente of clienti) {
        state.clienti_search[cliente.id] = cliente;
      }
    },
    ottimizzaMetodiDiPagamento(state, metodi_di_pagamento) {
      for (let metodo of metodi_di_pagamento) {
        state.metodi_di_pagamento_search[metodo.cod_pag_NEW] = metodo;
      }
    },
    ottimizzaMetodiDiPagamentoCanoni(state, metodi_di_pagamento_canoni) {
      for (let metodo of metodi_di_pagamento_canoni) {
        state.metodi_di_pagamento_canoni_search[metodo.cod_pag_NEW] = metodo;
      }
    },
    ottimizzaArticoli(state, articoli) {
      for (let articolo of articoli) {
        state.articoli_search[articolo.id] = articolo;
      }
    },
    ottimizzaContatti(state, contatti) {
      for (let contatto of contatti) {
        if (!state.contatti_search[contatto.cliente])
          state.contatti_search[contatto.cliente] = {};
        if (contatto.all_location) {
          if (!state.contatti_search["all"]) state.contatti_search["all"] = [];
          state.contatti_search["all"].push(contatto);
        } else {
          if (!state.contatti_search[contatto.cliente][contatto.locale])
            state.contatti_search[contatto.cliente][contatto.locale] = [];
          state.contatti_search[contatto.cliente][contatto.locale].push(
            contatto
          );
        }
      }
    },
    ottimizzaGruppi(state, gruppi) {
      for (let gruppo of gruppi) {
        state.gruppi_search[gruppo.id_trace] = gruppo;
      }
    },
    ottimizzaScaduti(state, scaduti) {
      for (let scaduto of scaduti) {
        if (!state.scaduti_search[scaduto.id_customer])
          state.scaduti_search[scaduto.id_customer] = [];
        state.scaduti_search[scaduto.id_customer].push(scaduto);
      }
    },
  },
  actions: {
    modificaPreferenzeFormOrdine({ commit, state }, preferenze) {
      commit("impostaPreferenzeFormOrdine", preferenze);
    },
    modificaPreferenzeFormRinnovoAnnuale({ commit, state }, preferenze) {
      commit("impostaPreferenzeFormRinnovoAnnuale", preferenze);
    },
    modificaPreferenzeFormRinnovoMensile({ commit, state }, preferenze) {
      commit("impostaPreferenzeFormRinnovoMensile", preferenze);
    },
    modificaPreferenzeFormScaduti({ commit, state }, preferenze) {
      commit("impostaPreferenzeFormScaduti", preferenze);
    },
    //Azioni eseguite per richiamare il caricamento dei dati
    async caricaClienti({ commit, state }, data) {
      let token = data;
      let force = false;

      if (typeof data == "object") {
        ({ token, force } = data);
      }

      //Vengono caricati nuovi dati solo se non sono già presenti
      if (!force && state.clienti.length > 0) return;
      let clienti = await clienteRepo.getAll(token, {});
      commit("impostaClienti", clienti);
    },
    async caricaLocali({ commit, state }, token) {
      if (state.locali.length > 0) return;
      let query = {
        where: {
          obsoleto: null,
        },
      };
      let locali = await localeRepo.getAll(token, query);
      commit("impostaLocali", locali);
    },
    async caricaMetodiDiPagamento({ commit, state }, token) {
      if (state.metodi_di_pagamento.length > 0) return;
      let metodi_di_pagamento = await metodoDiPagamentoRepo.getAll(token, {});
      commit("impostaMetodiDiPagamento", metodi_di_pagamento);
    },
    async caricaMetodiDiPagamentoCanoni({ commit, state }, token) {
      if (state.metodi_di_pagamento_canoni.length > 0) return;
      let metodi_di_pagamento_canoni = await metodoDiPagamentoRepo.getCanoni(
        token
      );
      commit("impostaMetodiDiPagamentoCanoni", metodi_di_pagamento_canoni);
    },
    async caricaArticoli({ commit, state }, token) {
      if (state.articoli.length > 0) return;
      //Aggiungo al risultato: gruppo, tipo_gruppo e prezzo del listino 1
      let query = {
        include: [
          {
            model: "gruppo",
            as: "gruppo_gruppo",
            include: [{ model: "tipo_gruppo", as: "tipo_gruppo_tipo_gruppo" }],
          },
          {
            model: "prezzo",
            as: "prezzos",
            where: { "$prezzos.cod_listino$": 1 },
          },
        ],
      };
      let articoli = await articoloRepo.getAll(token, query);
      commit("impostaArticoli", articoli);
    },
    async caricaUtenti({ commit, state }, token) {
      if (state.utenti.length > 0) return;
      let utenti = await utenteRepo.getAll(token, {});
      commit("impostaUtenti", utenti);
    },
    async caricaTipiOrdine({ commit, state }, token) {
      if (state.tipi_ordine.length > 0) return;
      let tipi_ordine = await ordineRepo.getTipiOrdine(token, {
        include: ["tipo_ordine_attivo"],
      });
      //Elimino i tipi inattivi
      console.log(
        tipi_ordine.filter((tipo) => tipo.tipo_ordine_attivo == null)
      );
      tipi_ordine = tipi_ordine.filter(
        (tipo_ordine) => tipo_ordine.tipo_ordine_attivo != null
      );
      commit("impostaTipiOrdine", tipi_ordine);
    },
    async caricaProfiliContatto({ commit, state }, token) {
      if (state.profili_contatto.length > 0) return;
      let profili_contatto = await profiloContattoRepo.getAll(token, {});
      commit("impostaProfiliContatto", profili_contatto);
    },
    async caricaProfiliLocale({ commit, state }, token) {
      if (state.profili_locale.length > 0) return;
      let profili_locale = await profiloLocaleRepo.getAll(token, {
        where: { attivo: 1 },
      });
      commit("impostaProfiliLocale", profili_locale);
    },
    async caricaCategorieLocale({ commit, state }, token) {
      console.log("CARICA CATEGORIE");
      if (state.categorie_locale.length > 0) return;
      let categorie_locale = await categorieLocaleRepo.getAll(token, {
        where: { attivo: 1 },
      });
      commit("impostaCategorieLocale", categorie_locale);
    },
    async caricaMotivazioniDisdetta({ commit, state }, token) {
      if (state.motivazioni_disdetta.length > 0) return;
      let motivazioni_disdetta = await motivazioneDisdettaRepo.getAll(token, {
        include: ["motivazione_statistica"],
      });
      commit("impostaMotivazioniDisdetta", motivazioni_disdetta);
    },
    async caricaContatti({ commit, state }, token) {
      if (state.contatti.length > 0) return;
      let contatti = await contattiRepo.getAll(token, {});
      commit("impostaContatti", contatti);
    },
    async caricaGruppi({ commit, state }, token) {
      if (state.gruppi.length > 0) return;
      let contatti = await gruppiRepo.getAll(token, {});
      commit("impostaGruppi", contatti);
    },
    async caricaScaduti({ commit, state }, token) {
      if (state.scaduti.length > 0) return;
      let scaduti = await scadutiRepo.getAll(token, {});
      commit("impostaScaduti", scaduti);
    },
    async caricaTotaleScaduti({ commit, state }, token) {
      if (state.totale_scaduti.length > 0) return;
      let totale_scaduti = await scadutiRepo.getTotals(token);
      for (let tot of totale_scaduti.data)
        state.totale_scaduti[tot.id_customer] = tot.totale_scaduto;
    },
    async caricaApiToken({ commit, state }) {
      //TODO: ottenere automaticamente token di accesso per ogni caricaQualcosa??
      // const auth0 = getInstance();
      // const token = await auth0.getTokenSilently();
      // const claims = await auth0.getIdTokenClaims();
      // if (claims) {
      //   const expires = new Date(Date.now() + claims.exp);
      //   console.log(claims);
      //   console.log(token);
      //   console.log(expires);
      // }
    },
    //Azioni eseguite per modificare lo storage
    aggiungiCliente({ commit, state }, cliente) {
      commit("aggiungiCliente", cliente);
    },
    aggiungiLocale({ commit, state }, locale) {
      commit("aggiungiLocale", locale);
    },
    modificaIbanCliente({ commit, state }, { cliente, iban }) {
      commit("modificaIbanCliente", { cliente, iban });

      console.log("Modifica iban cliente");
      console.log(cliente);
      console.log(iban);
    },
    modificaIbanLocale({ commit, state }, { locale, iban }) {
      commit("modificaIbanLocale", { locale, iban });
      console.log("Modifica iban locale");
      console.log(locale);
      console.log(iban);
    },
  },
});

export default store;
