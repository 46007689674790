import ApiConnector from "./ApiConnector";
let connector = new ApiConnector(process.env.VUE_APP_API_HOST);

export const getAll = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.OrdineApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getAllOrdines(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getByIdWithInclude = (token, id, include) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.OrdineApi();

    let opts = {
      selectOptions: {
        options: {
          where: {
            id: id,
          },
          include: include,
        },
      },
    };
    apiInstance.getAllOrdines(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body[0]);
      }
    });
  });
};

export const getPage = (token, page = 1, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.OrdineApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getOrdinePage(page, opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getCount = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.OrdineApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.countOrdines(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getById = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.OrdineApi();
    apiInstance.getOrdineById(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const getTipiOrdine = (token, options) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.TipoOrdineApi();
    let opts = {
      selectOptions: { options: options }, // SelectOptions | opzioni
    };
    apiInstance.getAllTipoOrdines(opts, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const add = (token, ordine) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.OrdineApi();
    apiInstance.addOrdine(ordine, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const update = (token, id, ordine) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.OrdineApi();
    apiInstance.updateOrdine(id, ordine, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const addArticoloOrdine = (token, id, articolo) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloOrdineApi();
    apiInstance.addArticoloOrdine(
      { ordine: id, ...articolo },
      (error, data, response) => {
        if (error) {
          return reject(error);
        } else {
          resolve(response.body);
        }
      }
    );
  });
};

export const getSerialActivation = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.OrdineApi();
    apiInstance.getSerialActivation(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const deleteSerialActivation = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.OrdineApi();
    apiInstance.deleteSerialActivation(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const addSerial = (token, serial) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.MatricolaArticoloOrdineApi();
    apiInstance.addMatricolaArticoloOrdine(serial, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const editSerial = (token, serial) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.MatricolaArticoloOrdineApi();
    apiInstance.updateMatricolaArticoloOrdine(
      serial.articolo_ordine,
      serial.prg,
      { matricola: serial.matricola },
      (error, data, response) => {
        if (error) {
          return reject(error);
        } else {
          resolve(response.body);
        }
      }
    );
  });
};

export const addActivation = (token, activation) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.AttivazioneArticoloOrdineApi();
    apiInstance.addAttivazioneArticoloOrdine(
      activation,
      (error, data, response) => {
        if (error) {
          return reject(error);
        } else {
          resolve(response.body);
        }
      }
    );
  });
};

export const editActivation = (token, activation) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.AttivazioneArticoloOrdineApi();
    apiInstance.updateAttivazione(
      activation.articolo_ordine,
      activation.prg,
      { attivazione: activation.attivazione },
      (error, data, response) => {
        if (error) {
          return reject(error);
        } else {
          resolve(response.body);
        }
      }
    );
  });
};

export const getAvailableStatus = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.OrdineApi();
    apiInstance.getAvailableStatus(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const editStatus = (token, id, stato) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.OrdineApi();
    apiInstance.editStatus(id, stato, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};

export const deleteArticoloOrdineByOrderId = (token, id) => {
  return new Promise((resolve, reject) => {
    let apiClient = connector.getClient(token);
    let apiInstance = new apiClient.ArticoloOrdineApi();
    apiInstance.deleteByOrderId(id, (error, data, response) => {
      if (error) {
        return reject(error);
      } else {
        resolve(response.body);
      }
    });
  });
};
