import { getInstance } from "./authWrapper";

export const authGuard = (to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (authService.isAuthenticated) {
      if (to.meta.scopes && typeof to.meta.scopes == "object") {
        const userScopes = authService.user["https://rca-api/permissions"];
        for (const scope of to.meta.scopes) {
          if (userScopes.indexOf(scope) == -1)
            return next({ name: "notallowed" });
        }
      }
      return next();
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  if (!authService.loading) {
    return fn();
  }

  authService.$watch("loading", (loading) => {
    if (loading === false) {
      return fn();
    }
  });
};
