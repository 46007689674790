<template>
  <b-input-group>
    <b-input-group-prepend>
      <b-form-select
        v-model="country"
        :options="prefissiTelefonici"
        size="lg"
        class="form-control"
        :disabled="true"
      ></b-form-select>
    </b-input-group-prepend>

    <b-form-input
      type="tel"
      v-model="formatted"
      @change="inputChange"
      :disabled="disabled"
    ></b-form-input>
  </b-input-group>
</template>

<script>
import prefissi from "../../assets/prefissi.json";
import phone from "phone";

export default {
  name: "TelephoneInput",
  props: ["value", "disabled"],
  data() {
    return {
      country: "IT",
      formatted: "",
    };
  },
  methods: {
    inputChange() {
      //Se è un numero fisso non eseguo la validazione
      if (this.formatted.startsWith("0")) {
        this.$emit("input", this.formatted);
        return;
      }
      const response = phone(this.formatted, { country: this.country });
      if (!response.isValid) {
        this.$toast.error("Numero di telefono non valido");
        this.$emit("input", this.formatted);
        return;
      }
      this.formatted = response.phoneNumber;
      this.$emit("input", response.phoneNumber);
    },
  },
  created() {
    this.formatted = this.value;
  },
  computed: {
    prefissiTelefonici() {
      return prefissi.map((paese) => {
        return {
          value: paese.code,
          text: paese.code + " " + paese.emoji,
        };
      });
    },
  },
};
</script>
