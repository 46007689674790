<template>
  <div class="text-center hero">
    <img class="app-logo" src="/torder_logo.png" alt="rca logo" />
    <!-- <div style="width: 200px; padding: 1rem">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2.1 -2.1 4.2 4.2">
        <path
          id="t-order-logo"
          d="M 0 0 L 1 0 A 1 1 0 0 1 2 1 A 1 1 0 0 1 1 2 A 1 1 0 0 1 0 1 L 0 -1 A 1 1 0 0 0 -1 -2 A 1 1 0 0 0 -2 -1 A 1 1 0 0 0 -1 0 L 0 0"
          stroke="#952647"
          stroke-width="0.1"
          fill="none"
        />
      </svg>
    </div> -->
    <h4>Software di gestione aziendale e comunicazione ai clienti</h4>
    <small>versione 3.1.8</small>
    <br />
    <small>&copy; Copyright 2022, Andrea Brioschi</small>
  </div>
</template>

<script>
export default {
  name: "Hero",
};
</script>

<style scoped>
.app-logo {
  width: 50%;
  height: auto;
}

/* #t-order-logo {
  stroke-dasharray: 13.426104545593262;
  stroke-dashoffset: 13.426104545593262;
  animation: sign 10s ease;
  animation-fill-mode: forwards;
}

@keyframes sign {
  30% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: 0;
    fill: #952647;
  }
} */
</style>
