import axios from "axios";

export const getAll = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(
    JSON.stringify({ options: options })
  );
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: encodedOptions,
    },
  };
  //Send request
  return axios.get(`${process.env.VUE_APP_API_HOST}/articoloContratto`, config);
};

export const getCount = (accessToken, options) => {
  const encodedOptions = encodeURIComponent(
    JSON.stringify({ options: options })
  );
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/articoloContratto/count`,
    config
  );
};

export const getPage = (accessToken, page = 1, options) => {
  const encodedOptions = encodeURIComponent(
    JSON.stringify({ options: options })
  );
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/articoloContratto/pagina/${page}`,
    config
  );
};

export const getPageOfXItems = (accessToken, page = 1, items = 10, options) => {
  options.offset = (page - 1) * items;
  options.limit = items;
  return getAll(accessToken, options);
};

export const getAllAsCSV = (accessToken, options) => {
  delete options.offset;
  delete options.limit;

  const encodedOptions = encodeURIComponent(
    JSON.stringify({ options: options })
  );
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      body: encodedOptions,
    },
  };
  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/articoloContratto/csv`,
    config
  );
};

export const modificaArticoloContratto = async (
  token,
  id,
  articoloContratto
) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  //Send request
  return await axios.put(
    `${process.env.VUE_APP_API_HOST}/articoloContratto/${id}`,
    articoloContratto,
    config
  );
};
