<template>
  <div class="m-3">
    <b-card title="Ricerca utente">
      <b-form @submit="ricercaUtente" >
        <b-form-group
          id="input-group-1"
          label="ID Utente:"
          label-for="id-utente"
        >
          <b-form-input
            id="id-utente"
            v-model="ricercaUtente.id"
            type="text"
            placeholder="Inserire id utente"
            required
          ></b-form-input>
        </b-form-group>
      </b-form>
      <div>
        
      </div>
    </b-card>
  </div>
</template>
<script>
//   import PaginatedTable from "@/components/common/PaginatedTable.vue";
//   import * as scadutiRepo from "../services/Scaduti";
//   import * as clientiRepo from "../services/Cliente";

//   //   import router from "../router/index";

export default {
  name: "PannelloAmministrazione",
  components: {
    // PaginatedTable,
  },
  data() {
    return {
      ricercaUtente: {
        id: "",
        response: null,
      },
    };
  },
  watch: {
    //   query: {
    //     handler(val) {
    //       this.ricaricaPaginatedTable();
    //     },
    //     deep: true,
    //   },
  },
  async created() {
    //   this.$loading(true);
    //   const accessToken = await this.$auth.getTokenSilently();
    //   await this.caricaScaduti(accessToken);
    //   await this.$store.dispatch("caricaUtenti", accessToken);
    //   this.$loading(false);
  },
  methods: {

  },
  computed: {},
};
</script>
