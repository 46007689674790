



<template>
  <div>
      TEST
  </div>
</template>

<script>

// import * as statoRepo from '../services/Stato'

export default {
  name: "home",
  async created(){
      // console.log(await statoRepo.getAll());
        // var RcaApi = require('rca_api');

        // var defaultClient = RcaApi.ApiClient.instance;
        // // Configure Bearer (JWT) access token for authorization: BearerAuth
        // var BearerAuth = defaultClient.authentications['BearerAuth'];
        // BearerAuth.accessToken = "YOUR ACCESS TOKEN"

        // var api = new RcaApi.ArticleChangeApi()
        // var articleChange = new RcaApi.ArticleChange(); // {ArticleChange} articleChange da aggiungere
        // var callback = function(error, data, response) {
        // if (error) {
        //     console.error(error);
        // } else {
        //     console.log('API called successfully. Returned data: ' + data);
        // }
        // };
        // api.addArticleChange(articleChange, callback);
  }

};
</script>

