<template>
  <select
    class="form-control"
    :name="identifier"
    :id="identifier"
    @change="change($event)"
  >
    <option value="0">TUTTI</option>
    <option
      v-for="user in activeUsers"
      :key="user.id"
      :selected="user.id == active ? true : false"
      :value="user.id"
    >
      {{ user.name }}
    </option>
  </select>
</template>

<script>
export default {
  name: "UserSelect",
  props: {
    users: Array,
    active: Number,
    identifier: String,
  },
  methods: {
    change(event) {
      this.$emit("input", event.target.value);
    },
  },
  computed: {
    activeUsers() {
      return this.users.filter((user) => !user.name.startsWith("*"));
    },
  },
};
</script>
