<template>
  <b-card bg-variant="light" header="Carrello" class="text-center">
    <!-- MODALE MODIFICA TOTALE -->
    <b-popover target="modal-tot-edit" triggers="hover" title="Calcolo Sconto">
      <div class="d-flex flex-row justify-content-center align-content-center">
        <b-input-group append="€" class="me-1">
          <b-input
            type="number"
            min="0"
            step="0.01"
            v-model="totale"
            @keydown.enter.native="calcolaSconto"
          ></b-input>
        </b-input-group>
        <b-button class="h-100 align-text-bottom" @click="calcolaSconto"
          >Calcola</b-button
        >
      </div>
      <small>Totale ordine</small>
    </b-popover>
    <div class="d-flex flex-column flex-md-row justify-content-between">
      <b-input-group prepend="Listino" append="€" class="m-1">
        <b-form-input
          :value="listino"
          style="text-align: right"
          readonly
        ></b-form-input>
      </b-input-group>
      <span></span>
      <b-input-group prepend="Sconto" append="%" class="m-1">
        <b-form-input
          v-model="form.cart.sconto"
          style="text-align: right"
          :readonly="!canUpdateCarrello || !canUpdateTotale || !editable"
        ></b-form-input>
      </b-input-group>
      <span></span>
      <b-input-group prepend="Totale" class="m-1">
        <b-form-input
          v-model="totaleScontato"
          style="text-align: right"
          :readonly="true"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            style="
              background-color: #e9ecef;
              color: black;
              border: 1px solid #ced4da;
            "
            >€</b-button
          >
          <b-button
            v-if="canUpdateCarrello && canUpdateTotale && editable"
            variant="info"
            id="modal-tot-edit"
          >
            <b-icon icon="calculator" class="fa-lg pointer"> </b-icon>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </div>
    <b-input-group class="m-1">
      <b-input-group-prepend is-text>
        <b-form-checkbox
          v-model="form.cart.canoni"
          v-on:change="modificaCanoni()"
          name="check-button"
          :disabled="!canUpdateCarrello || !editable"
        >
          <span class="ms-1">Canoni</span>
        </b-form-checkbox>
      </b-input-group-prepend>
      <b-form-input
        aria-label="Text input with checkbox"
        placeholder="Nome articolo o id trace"
        v-model="selezione.articolo"
        :readonly="!canUpdateCarrello || !editable"
      ></b-form-input>
    </b-input-group>

    <b-list-group>
      <b-list-group-item
        v-for="articolo in searchResult"
        :key="articolo.id"
        v-on:click="aggiungiArticolo(articolo)"
        class="d-flex justify-content-between"
        button
        ><span class="h6">{{ articolo.title }}</span
        ><span>{{ articolo.id_trace }}</span></b-list-group-item
      >
    </b-list-group>

    <div v-if="isMobile" class="text-start">
      <CartItemsMobile
        :software="this.softwareItems"
        :hardware="this.hardwareItems"
        :servizi="this.serviceItems"
        :riscatti="this.riscattiItems"
        :getArticleById="this.getArticleById"
        :modificaScontoInBaseAlTotale="this.modificaScontoInBaseAlTotale"
        :rimuoviArticolo="this.rimuoviArticolo"
        :editable="canUpdateCarrello && this.editable"
        :scontabile="canUpdatePrezzoArticolo"
      ></CartItemsMobile>
    </div>
    <div v-else>
      <CartItemsDesktop
        :software="this.softwareItems"
        :hardware="this.hardwareItems"
        :servizi="this.serviceItems"
        :riscatti="this.riscattiItems"
        :totale-software="this.totaleSoftware"
        :totale-hardware="this.totaleHardware"
        :totale-servizi="this.totaleServizi"
        :totale-riscatti="this.totaleRiscatti"
        :getArticleById="this.getArticleById"
        :modificaScontoInBaseAlTotale="this.modificaScontoInBaseAlTotale"
        :rimuoviArticolo="this.rimuoviArticolo"
        :editable="canUpdateCarrello && this.editable"
        :scontabile="canUpdatePrezzoArticolo"
      ></CartItemsDesktop>
    </div>
  </b-card>
</template>

<script>
import CustomerCreationForm from "@/components/common/CustomerCreationForm.vue";
import CartItemsMobile from "@/components/ordini/CartItemsMobile.vue";
import CartItemsDesktop from "@/components/ordini/CartItemsDesktop.vue";

import * as articoloRepo from "../../services/Articolo";

export default {
  components: {
    CustomerCreationForm,
    CartItemsMobile,
    CartItemsDesktop,
  },
  props: { form: Object, editable: Boolean },
  name: "OrderFormCart",
  data() {
    return {
      totale: 0,
      selezione: {
        articolo: "",
        articoli: [],
      },
      windowWidth: 0,
    };
  },
  form: {
    handler(val) {
      this.$emit("update", val);
    },
    deep: true,
  },
  async created() {
    this.windowWidth = window.innerWidth;
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },
  methods: {
    calcolaSconto() {
      if (this.listino == 0) {
        this.form.cart.sconto = 0;
        return;
      }
      this.form.cart.sconto = -(
        (100 * (this.totale - this.listino)) /
        this.listino
      );
    },
    async modificaCanoni() {
      if (this.form.cart.items.length > 0) {
        alert("Eliminare gli articoli dal carrello per modificare");
        this.form.cart.canoni = !this.form.cart.canoni;
        return;
      }
    },
    async aggiungiArticolo(articolo) {
      this.selezione.articolo = "";
      const accessToken = await this.$auth.getTokenSilently();
      let childrens = await articoloRepo.getChildrens(accessToken, articolo.id);
      for (let k = 0; k < childrens.length; k++) {
        let children = childrens[k];

        let found = false;
        for (let i = 0; i < this.form.cart.items.length; i++) {
          if (this.form.cart.items[i].id == children.id) {
            this.form.cart.items[i].quantita++;
            found = true;
          }
        }
        children["quantita"] = 1;
        children["sconto"] = 0;
        let article = {
          id: children.id,
          quantita: 1,
          sconto: 0,
          prezzo: children.prezzos[0].prz_listino,
        };

        if (!found) this.form.cart.items.push(article);
      }
    },
    getArticleById(id) {
      return this.$store.state.articoli_search[id];
    },
    rimuoviArticolo(id) {
      this.form.cart.items = this.form.cart.items.filter(
        (item) => item.id !== id
      );
    },
    modificaScontoInBaseAlTotale(totale, id_articolo) {
      for (let i = 0; i < this.form.cart.items.length; i++) {
        if (this.form.cart.items[i].id == id_articolo) {
          const imponibile =
            this.form.cart.items[i].prezzo *
            parseInt(this.form.cart.items[i].quantita);
          let sconto = -((100 * (totale - imponibile)) / imponibile);
          //Calcolo lo sconto
          //(la modifica dello sconto causa un ricalcolo del prezzo)
          this.form.cart.items[i].sconto =
            typeof sconto == "number" ? sconto : 0;
          //Reimposto prezzo preciso
          this.form.cart.items[i].totale = totale;
        }
      }
    },
  },

  computed: {
    hasTotalDiscount() {
      return this.form.cart.sconto != 0;
    },
    hasItemDiscount() {
      const itemWithDiscount = this.form.cart.items.find(
        (item) => item.sconto != 0
      );
      if (itemWithDiscount) return true;
      return false;
    },
    canUpdateCarrello() {
      if (!this.$route.params.id) return true;
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:ordine:carrello"
          ) != -1
        );
      }
      return false;
    },
    canUpdatePrezzoArticolo() {
      if (this.hasTotalDiscount) return false;
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:ordine:prezzoarticolo"
          ) != -1
        );
      }
      return false;
    },
    canUpdateTotale() {
      if (this.hasItemDiscount) return false;
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:ordine:prezzo"
          ) != -1
        );
      }
      return false;
    },
    isMobile() {
      return this.windowWidth < 1100;
    },
    listino() {
      return (
        this.totaleRiscatti +
        this.totaleSoftware +
        this.totaleHardware +
        this.totaleServizi
      );
    },
    articoliFiltrati() {
      return this.$store.state.articoli.filter((value, index, array) => {
        if (this.form.cart.canoni) {
          return value.mth_1 && value.attivo && value.tipo_articolo != 45;
        } else {
          return !value.mth_1 && value.attivo && value.tipo_articolo != 45;
        }
      });
    },
    searchResult() {
      if (this.selezione.articolo == "") return [];
      let k = 0;
      return this.articoliFiltrati.filter((articolo) => {
        if (k >= 50) return false;

        let checkIdTrace =
          articolo["id_trace"] != null &&
          this.selezione.articolo != "" &&
          articolo["id_trace"]
            .toLowerCase()
            .includes(this.selezione.articolo.toLowerCase());
        let checkArticleLocDesc =
          articolo["title"] != null &&
          this.selezione.articolo != "" &&
          articolo["title"]
            .toLowerCase()
            .includes(this.selezione.articolo.toLowerCase());

        if (checkIdTrace || checkArticleLocDesc) k++;
        return checkIdTrace || checkArticleLocDesc;
      });
    },
    totaleScontato: {
      get: function () {
        return (
          Math.round(
            (this.listino - (this.form.cart.sconto / 100) * this.listino) * 100
          ) / 100
        );
      },
      set: debounce(function (val) {
        this.form.cart.sconto =
          Math.round(((100 * (this.listino - val)) / this.listino) * 100) / 100;
        // Math.round(((100 * (this.listino - val)) / this.listino) * 1e2) / 1e2;
      }, 300),
    },
    softwareItems() {
      let filterSoftware = function filterSoftware(item, index, array) {
        let article = this.getArticleById(item.id);
        return article.gruppo_gruppo.tipo_gruppo_tipo_gruppo.id == 3;
      };

      return this.form.cart.items.filter(filterSoftware, this);
    },
    hardwareItems() {
      let filterHardware = function filterHardware(item, index, array) {
        let article = this.getArticleById(item.id);
        return article.gruppo_gruppo.tipo_gruppo_tipo_gruppo.id == 1;
      };

      return this.form.cart.items.filter(filterHardware, this);
    },
    serviceItems() {
      let filterService = function filterService(item, index, array) {
        let article = this.getArticleById(item.id);
        return article.gruppo_gruppo.tipo_gruppo_tipo_gruppo.id == 2;
      };

      return this.form.cart.items.filter(filterService, this);
    },
    riscattiItems() {
      let filterRiscatti = function filterRiscatti(item, index, array) {
        let article = this.getArticleById(item.id);
        return (
          article.gruppo_gruppo.tipo_gruppo_tipo_gruppo.id == 4 ||
          article.gruppo_gruppo.tipo_gruppo_tipo_gruppo.id == 5
        );
      };

      return this.form.cart.items.filter(filterRiscatti, this);
    },
    totaleSoftware() {
      let totale = 0;
      for (let item of this.softwareItems)
        totale +=
          item.quantita * item.prezzo -
          (item.sconto / 100) * item.prezzo * item.quantita;
      return totale;
    },
    totaleHardware() {
      let totale = 0;
      for (let item of this.hardwareItems)
        totale +=
          item.quantita * item.prezzo -
          (item.sconto / 100) * item.prezzo * item.quantita;
      return totale;
    },
    totaleServizi() {
      let totale = 0;
      for (let item of this.serviceItems)
        totale +=
          item.quantita * item.prezzo -
          (item.sconto / 100) * item.prezzo * item.quantita;
      return totale;
    },
    totaleRiscatti() {
      let totale = 0;
      for (let item of this.riscattiItems)
        totale +=
          item.quantita * item.prezzo -
          (item.sconto / 100) * item.prezzo * item.quantita;
      return totale;
    },
  },
};

/**
 * Applica effetto debounce ad una funzione
 */
function debounce(func, wait, immediate) {
  var timeout;

  return function executedFunction() {
    var context = this;
    var args = arguments;

    var later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}
</script>
