<template>
  <div>
    <!-- <b-button @click="openModal">Apri</b-button>
    <b-modal v-model="modalOpen"> -->
    <select
      v-if="priority.length > 1"
      class="form-control"
      @change="handleChange"
    >
      <option
        v-for="customer in priority"
        :key="customer.cliente"
        :selected="customer.prioritario ? true : false"
        :value="customer.cliente"
      >
        {{ getCustomerRagsoc(customer.cliente) }}
      </option>
    </select>
    <!-- </b-modal> -->
  </div>
</template>

<script>
import * as contattoRepo from "../../services/Contatto";

export default {
  name: "SelezionePrioritaTelefono",
  props: {
    telephone: String,
  },
  data() {
    return {
      priority: [],
    };
  },
  async created() {
    const accessToken = await this.$auth.getTokenSilently();
    await this.$store.dispatch("caricaClienti", accessToken);
    const priority = await contattoRepo.getTelephonePriority(
      accessToken,
      this.telephone
    );
    this.priority = priority.data;
  },
  methods: {
    handleChange(e) {
      this.setPriority(e.target.value);
    },
    async setPriority(customer) {
      const accessToken = await this.$auth.getTokenSilently();
      await contattoRepo.setTelephonePriority(
        accessToken,
        this.telephone,
        customer
      );
      this.$emit("updatePriority");
    },
    getCustomerRagsoc(id) {
      const customer = this.clienti_search[id];
      if (customer) {
        return customer.ragsoc;
      }
      return "";
    },

    //   change(event) {
    //     let value = event.target.value ? parseInt(event.target.value) : null;
    //     this.$emit("input", value);
    //   },
  },
  computed: {
    activeIndex() {
      return this.priority.findIndex((value) => value.prioritario);
    },
    clienti_search() {
      return this.$store.state.clienti_search;
    },
    articles() {
      // return this.$store.state.articoli.filter(
      //   (value) =>
      //     value.attivo &&
      //     (!(this.canoni || this.non_canoni) ||
      //       (this.canoni && value.mth_1 == true) ||
      //       (this.non_canoni &&
      //         (value.mth_12 == true ||
      //           value.mth_24 == true ||
      //           value.new_year == true)))
      // );
    },
  },
};
</script>
