<template>
  <div>
    ciao
    <div class="d-flex flex-column flex-md-row">
      <input class="form-control mb-3 me-2" v-model="configurazione.cliente" />
      <b-pagination
        v-model="pagina_attuale"
        :total-rows="numero_righe"
        per-page="10"
      ></b-pagination>
    </div>

    <b-table
      :items="tabella"
      per-page="10"
      :current-page="pagina_attuale"
      :fields="campi"
      stacked="lg"
      :busy="loading"
    >
      <template #cell(contatti)="row">
        <b-button
          size="sm"
          style="
            background-color: var(--primary-color);
            border-color: var(--primary-color);
          "
          @click="row.toggleDetails"
          class="mr-2"
        >
          {{ row.detailsShowing ? "Nascondi" : "Mostra" }}
        </b-button>
      </template>

      <template #row-details="row">
        <b-card>
          <b-table :items="formatContactTable(row.item.contatti)" stacked="lg">
            <template #cell(mail)="row">
              <b-button
                variant="success"
                v-if="row.item.mail"
                class="form-control"
                >{{ row.item.mail }}</b-button
              >
            </template>
          </b-table>
        </b-card>
      </template>

      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Caricamento...</strong>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  name: "Rubrica",

  props: {
    cliente: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      contatti: {},
      pagina_attuale: 1,
      loading: false,
      configurazione: {
        cliente: "",
      },
    };
  },
  async created() {
    this.loading = true;
    const accessToken = await this.$auth.getTokenSilently();
    await this.$store.dispatch("caricaContatti", accessToken);
    await this.$store.dispatch("caricaClienti", accessToken);
    await this.$store.dispatch("caricaLocali", accessToken);

    this.contatti = this.$store.state.contatti_search;
    if (this.cliente) this.configurazione.cliente = this.cliente;
    this.loading = false;
  },
  watch: {
    cliente: function (newVal) {
      if (newVal) this.configurazione.cliente = newVal;
    },
  },
  methods: {
    formatContactTable(contatti) {
      const data = [];
      for (const locale in contatti) {
        for (let contatto_locale of contatti[locale]) {
          contatto_locale = {
            id_locale: contatto_locale.locale,
            nome_locale:
              this.locali[contatto_locale.cliente][contatto_locale.locale].nome,
            mail: contatto_locale.mail,
            telefono: contatto_locale.telephone,
            contatto: contatto_locale.reference,
          };
          data.push(contatto_locale);
        }
      }
      return data;
    },
  },
  computed: {
    contatti_cliente() {
      return this.contatti[this.cliente];
    },
    clienti() {
      return this.$store.state.clienti_search;
    },
    locali() {
      return this.$store.state.locali_search;
    },
    campi() {
      return [
        "contatti",
        "cliente",
        "trace",
        "ragione_sociale",
        "mail_cliente",
      ];
    },
    tabella() {
      const data = [];
      for (const index in this.contatti) {
        let riga = {};
        let cliente = this.clienti[index] ? this.clienti[index] : {};
        if (
          this.configurazione.cliente != null &&
          this.configurazione.cliente != ""
        ) {
          const id_cliente = cliente.id ? String(cliente.id).toLowerCase() : "";
          const ragione_sociale = cliente.ragsoc
            ? String(cliente.ragsoc).toLowerCase()
            : "";
          if (
            !(
              id_cliente.indexOf(this.configurazione.cliente.toLowerCase()) !=
                -1 ||
              ragione_sociale.indexOf(
                this.configurazione.cliente.toLowerCase()
              ) != -1
            )
          )
            continue;
        }
        // cliente
        riga = {
          cliente: cliente.id,
          trace: cliente.id_trace,
          ragione_sociale: cliente.ragsoc,
          mail_cliente: cliente.mail,
          contatti: this.contatti[index],
        };

        data.push(riga);
        // console.log(this.clienti[index]);
      }
      return data;
    },
    numero_righe() {
      return this.tabella.length;
    },
  },
};
</script>
