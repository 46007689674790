<template>
  <div>
    <Mail
      v-if="pdfCaricato"
      :imessaggio="message"
      :ioggetto="oggetto"
      :idestinatario="mail"
      :iappend="now"
      :defaultSearch="cliente.ragsoc"
    >
      <template #title>
        Mail Insoluto
        {{ in_scadenza ? "e prossime scadenze" : "" }}
      </template>
    </Mail>
    <h1 v-else>Caricamento PDF allegato...</h1>
  </div>
</template>

<script>
import Mail from "@/components/mail/Mail.vue";
import axios from "axios";

import * as clienteRepo from "../services/Cliente";

export default {
  name: "MailInsoluto",
  components: {
    Mail,
  },
  data() {
    return {
      cliente: {},
      pdfCaricato: false,
    };
  },
  async created() {
    await this.scaricaPDF();
    await this.caricaCliente();
  },
  methods: {
    async caricaCliente() {
      const accessToken = await this.$auth.getTokenSilently();

      try {
        const response = await clienteRepo.getAll(accessToken, {
          where: {
            id_trace: this.id,
          },
        });
        if (!response) {
          alert("Errore: cliente non trovato");
          return;
        }
        if (response.length > 0) {
          this.cliente = response[0];
        } else {
          this.cliente = {};
        }
      } catch (e) {
        alert("Errore caricamento cliente");
        console.log(e.response);
      }
    },
    async scaricaPDF() {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();

      //Request config
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        responseType: "blob",
      };
      //Send request
      const response = await axios
        .get(
          `${process.env.VUE_APP_API_HOST}/pdf/insoluto/${this.id}?store=${
            this.now
          }${this.in_scadenza ? "&scadenza=true" : ""}`,
          config
        )
        .catch((error) => {
          console.log(error);
        });

      this.pdfCaricato = true;
    },
    async allegaPDF(blob) {
      //Get API token
      const accessToken = await this.$auth.getTokenSilently();
      //Create formData
      const formData = new FormData();
      //Append file
      formData.append("file", blob);
      //Request config
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      };
      //Send request
      await axios
        .post(
          `${process.env.VUE_APP_API_HOST}/file/temp/${this.now}`,
          formData,
          config
        )
        .then((response) => {
          //Show complete modal
          // this.showComplete = true;
          console.log(response);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    in_scadenza() {
      return this.$route.query.scadenza;
    },
    now() {
      return Date.now();
    },
    message() {
      return `Gentile cliente,\n\nIn allegato sono indicate le partite insolute${
        this.in_scadenza ? " e le prossime scadenze " : " "
      }per le quali vi preghiamo di provvedere al pagamento dell'importo totale di € 0000 compreso di spese bancarie.\nPer i pagamenti a mezzo bonifico le coordinate sono le seguenti:\nIBAN IT72I0503433120000000014912\n\nCordiali saluti`;
    },
    oggetto() {
      return `Insoluto ${this.in_scadenza ? "e scadenze" : ""}`;
    },
    mail() {
      if (!this.cliente) return "";
      return this.cliente.mail;
    },
    id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style></style>
