import axios from "axios";
import ApiConnector from "./ApiConnector";

export const getByCustomerID = (token, id) => {
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //Send request
  return axios.get(
    `${process.env.VUE_APP_API_HOST}/configurazioneCentralinoCliente/${id}`,
    config
  );
};

export const add = (token, configurazione) => {
  console.log("add configurazione", configurazione);
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //Send request
  return axios.post(
    `${process.env.VUE_APP_API_HOST}/configurazioneCentralinoCliente`,
    configurazione,
    config
  );
};

export const update = (token, id, configurazione) => {
  console.log("update configurazione", id, configurazione);
  //Request config
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  //Send request
  return axios.put(
    `${process.env.VUE_APP_API_HOST}/configurazioneCentralinoCliente/${id}`,
    configurazione,
    config
  );
};
