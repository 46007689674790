<template>
  <div class="m-3">
    <h1>{{ cliente.ragsoc }}</h1>
    <ConfigurazioneCentralinoCliente :idCliente="cliente.id" />
    <div class="d-flex flex-column flex-md-row">
      <b-form style="min-width: 30vw">
        <InputField
          v-model="cliente.id"
          identificatore="id"
          descrizione="ID"
          :readonly="fieldIsReadonly('id') || !canUpdate"
        ></InputField>
        <b-form-checkbox
          id="iva_esente"
          v-model="cliente.iva_esente"
          :disabled="fieldIsReadonly('iva_esente') || !canUpdate"
          :value="true"
          :unchecked-value="false"
        >
          Iva Esente
        </b-form-checkbox>
        <InputField
          v-model="cliente.id_trace"
          identificatore="id_trace"
          descrizione="ID Trace"
          :readonly="fieldIsReadonly('id_trace') || !canUpdate"
        ></InputField>
        <InputField
          :max="80"
          v-model="cliente.ragsoc"
          identificatore="ragsoc"
          descrizione="Ragione sociale"
          :readonly="fieldIsReadonly('ragsoc') || !canUpdate"
        ></InputField>
        <InputField
          :max="16"
          v-model="cliente.piva"
          identificatore="piva"
          descrizione="Partita iva"
          :readonly="fieldIsReadonly('piva') || !canUpdate"
        ></InputField>
        <InputField
          :max="16"
          v-model="cliente.codfisc"
          identificatore="codfisc"
          descrizione="Codice fiscale"
          :readonly="fieldIsReadonly('codfisc') || !canUpdate"
        ></InputField>
        <InputField
          :max="18"
          v-model="cliente.tel"
          identificatore="tel"
          descrizione="Telefono"
          :readonly="fieldIsReadonly('tel') || !canUpdate"
        ></InputField>
        <InputField
          v-model="cliente.cell"
          identificatore="cell"
          descrizione="Cellulare"
          :readonly="fieldIsReadonly('cell') || !canUpdate"
        ></InputField>
        <InputField
          :max="255"
          v-model="cliente.mail"
          identificatore="mail"
          descrizione="Mail"
          :readonly="fieldIsReadonly('mail') || !canUpdate"
        ></InputField>
        <InputField
          :max="40"
          v-model="cliente.contatto"
          identificatore="contatto"
          descrizione="Contatto"
          :readonly="fieldIsReadonly('contatto') || !canUpdate"
        ></InputField>
        <TextareaField
          v-model="cliente.notecli"
          identificatore="notecli"
          descrizione="Note cliente"
          :readonly="fieldIsReadonly('notecli') || !canUpdate"
        ></TextareaField>
        <TextareaField
          :max="150"
          v-model="cliente.notepriv"
          identificatore="notepriv"
          descrizione="Note private"
          :readonly="fieldIsReadonly('notepriv') || !canUpdate"
        ></TextareaField>
        <InputField
          :max="100"
          v-model="cliente.pec"
          identificatore="pec"
          descrizione="PEC"
          :readonly="fieldIsReadonly('pec') || !canUpdate"
        ></InputField>
        <InputField
          :max="7"
          v-model="cliente.sdi"
          identificatore="sdi"
          descrizione="SDI"
          :readonly="fieldIsReadonly('sdi') || !canUpdate"
        ></InputField>
        <InputField
          v-model="cliente.accounting_balance"
          identificatore="accounting_balance"
          descrizione="Bilancio"
          readonly
        ></InputField>
        <InputField
          v-model="cliente.title"
          identificatore="title"
          descrizione="Titolo"
          :readonly="fieldIsReadonly('title') || !canUpdate"
        ></InputField>
        <InputField
          :max="250"
          v-model="cliente.iban"
          identificatore="iban"
          descrizione="IBAN"
          :readonly="fieldIsReadonly('iban') || !canUpdate"
        ></InputField>
        <InputField
          :max="255"
          v-model="cliente.via"
          identificatore="via"
          descrizione="Via"
          :readonly="fieldIsReadonly('via') || !canUpdate"
        ></InputField>
        <InputField
          :max="10"
          v-model="cliente.cap"
          identificatore="cap"
          descrizione="Cap"
          :readonly="fieldIsReadonly('cap') || !canUpdate"
        ></InputField>
        <InputField
          :max="100"
          v-model="cliente.citta"
          identificatore="citta"
          descrizione="Città"
          :readonly="fieldIsReadonly('citta') || !canUpdate"
        ></InputField>
        <InputField
          :max="50"
          v-model="cliente.provincia"
          identificatore="provincia"
          descrizione="Provincia"
          :readonly="fieldIsReadonly('provincia') || !canUpdate"
        ></InputField>
        Pagamento predefinito:
        <b-form-select
          v-model="cliente.pagamento"
          :options="metodiDiPagamento"
          class="form-control"
          :disabled="fieldIsReadonly('pagamento') || !canUpdate"
        ></b-form-select>
        <b-button
          v-if="canUpdate"
          variant="success"
          class="mt-2"
          @click="confermaModifiche"
          >Conferma Modifiche</b-button
        >
      </b-form>
      <div class="w-100 mt-4 ms-md-3 me-md-3">
        <b-alert :show="numeroLocali <= 0"
          >Non sono presenti locali per il cliente {{ cliente.ragsoc }}</b-alert
        >
        <div class="accordion" role="tablist">
          <AccordionCard
            v-for="locale in locali"
            :key="locale.id"
            :identificatore="locale.id"
            accordion="locali"
            :titolo="locale.nome"
            :append="totaleLocali[locale.id] + ' €'"
            color="var(--primary-color)"
          >
            <Contratto
              :locale="locale.id"
              :cliente="cliente.id"
              v-on:total="updateTotal"
            ></Contratto>
          </AccordionCard>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as clienteRepo from "../services/Cliente";
import * as localeRepo from "../services/Locale";
import * as ibanService from "../services/IBAN";

import InputField from "../components/common/fields/InputField.vue";
import TextareaField from "../components/common/fields/TextareaField.vue";
import AccordionCard from "../components/common/cards/AccordionCard.vue";
import ConfigurazioneCentralinoCliente from "../components/common/ConfigurazioneCentralinoCliente.vue";

import Contratto from "../components/locali/Contratto.vue";

export default {
  name: "Cliente",
  components: {
    InputField,
    TextareaField,
    AccordionCard,
    Contratto,
    ConfigurazioneCentralinoCliente,
  },
  data() {
    return {
      cliente: {},
      locali: [],
      totaleLocali: {},
      editableFields: [
        { name: "iva_esente", alwaysEditable: true },
        { name: "ragsoc", alwaysEditable: false },
        { name: "piva", alwaysEditable: false },
        { name: "codfisc", alwaysEditable: false },
        { name: "tel", alwaysEditable: false },
        { name: "cell", alwaysEditable: false },
        { name: "mail", alwaysEditable: false },
        { name: "contatto", alwaysEditable: false },
        { name: "notecli", alwaysEditable: true },
        { name: "notepriv", alwaysEditable: true },
        { name: "pec", alwaysEditable: false },
        { name: "sdi", alwaysEditable: true },
        { name: "title", alwaysEditable: false },
        { name: "iban", alwaysEditable: true },
        { name: "via", alwaysEditable: false },
        { name: "cap", alwaysEditable: false },
        { name: "citta", alwaysEditable: false },
        { name: "provincia", alwaysEditable: false },
        { name: "pagamento", alwaysEditable: true },
      ],
    };
  },
  async created() {
    this.$loading(true);
    const accessToken = await this.$auth.getTokenSilently();
    this.cliente = await clienteRepo.getById(accessToken, this.customer_id);
    this.locali = await localeRepo.getByCustomerId(
      accessToken,
      this.customer_id
    );
    //Carico metodi di pagamento
    await this.$store.dispatch("caricaMetodiDiPagamento", accessToken);
    this.$loading(false);
  },
  computed: {
    metodiDiPagamento() {
      return [{ value: null, text: "Seleziona pagamento.." }].concat(
        this.$store.state.metodi_di_pagamento.map((metodo) => {
          return {
            value: metodo.cod_pag_NEW,
            text: metodo.des_pag,
          };
        })
      );
    },
    canUpdate() {
      if (this.$auth.user && this.$auth.user["https://rca-api/permissions"]) {
        return (
          this.$auth.user["https://rca-api/permissions"].indexOf(
            "update:cliente"
          ) != -1
        );
      }
      return false;
    },
    isReadonly() {
      if (!this.cliente.id) return true;
      if (this.cliente.id_trace) return true;
      return false;
    },
    customer_id() {
      return this.$route.params.id;
    },
    numeroLocali() {
      return this.locali.length;
    },
    actuallyEditableFields() {
      if (!this.cliente.id) return [];
      if (this.cliente.id_trace)
        return this.editableFields
          .filter((field) => field.alwaysEditable)
          .map((field) => field.name);
      return this.editableFields.map((field) => field.name);
    },
  },
  methods: {
    updateTotal(totale, locale) {
      this.totaleLocali = { ...this.totaleLocali, [locale]: totale };
    },
    async confermaModifiche() {
      const updatePayload = {};
      for (const field of this.actuallyEditableFields) {
        updatePayload[field] = this.cliente[field];
      }
      this.updateCustomer(updatePayload);
    },
    fieldIsReadonly(fieldName) {
      if (!(typeof fieldName == "string")) return true;
      return this.actuallyEditableFields.indexOf(fieldName) == -1;
    },
    async updateCustomer(customer) {
      if (customer.iban) {
        const verified = ibanService.verificaIBAN(customer.iban);
        if (!verified) {
          this.$toast.error("L'IBAN inserito non è valido");
          return;
        }
      }
      if (customer.notecli == "") customer.notecli = null;
      if (customer.notepriv == "") customer.notepriv = null;
      try {
        const accessToken = await this.$auth.getTokenSilently();
        await clienteRepo.update(accessToken, this.cliente.id, customer);
        this.$toast.open("Cliente modificato con successo");
        //Aggiorno clienti in vuex
        this.$store.dispatch("caricaClienti", {
          token: accessToken,
          force: true,
        });
      } catch (e) {
        this.$toast.error("Errore modifica cliente");
        console.log(e);
      }
    },
    async updateField(fieldName, value) {
      let obj = {};
      if (typeof value !== "boolean") {
        obj[fieldName] = value ? value : null;
      }
      try {
        const accessToken = await this.$auth.getTokenSilently();
        await clienteRepo.update(accessToken, this.cliente.id, obj);
        this.$toast.open(
          "Campo modificato con successo, nuovo valore: " + value
        );
      } catch (e) {
        this.$toast.error("Errore modifica cliente");
        console.log(e);
      }
      // console.log(field);
      // console.log(value);
    },
    onSubmit(event) {
      event.preventDefault();
      alert(JSON.stringify(this.form));
    },
  },
};
</script>

<style scoped>
.btn-info {
  font-weight: bold;
}
</style>
