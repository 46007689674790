<template>
  <div>
    <!-- SIDEBAR MOBILE -->
    <b-sidebar id="mobile-sidebar" title="Menu" shadow>
      <p>
        <!-- <b-button-group>
          <b-button variant="primary" class="mb-2" v-b-toggle.collapse-1>
            <b-icon icon="chevron-down" aria-label="Help"></b-icon>
          </b-button>
          <b-button variant="primary" class="mb-2" v-b-toggle.sidebar-1>
            <b-icon icon="tools" aria-label="Help"></b-icon>
          </b-button>
        </b-button-group> -->
        <b-list-group>
          <!-- <b-list-group-item>
              {{ statusDescription }}
            </b-list-group-item> -->

          <b-list-group-item
            :variant="customerOK ? 'success' : ''"
            :to="customerPath"
            class="d-flex align-items-center"
          >
            <b-avatar icon="person"></b-avatar>
            <span>Cliente</span>
          </b-list-group-item>

          <b-list-group-item
            :variant="locationOK ? 'success' : ''"
            :disabled="!enableLocation"
            :to="locationPath"
            class="d-flex align-items-center"
          >
            <b-avatar icon="geo-alt"></b-avatar>
            <span>Ubicazione</span>
          </b-list-group-item>

          <b-list-group-item
            :disabled="!enableContact"
            :variant="contactOK ? 'success' : ''"
            :to="contactPath"
            class="d-flex align-items-center"
          >
            <b-avatar icon="telephone"></b-avatar>
            <span>Contatti</span>
          </b-list-group-item>

          <b-list-group-item
            :variant="cartOK ? 'success' : ''"
            :to="carrelloPath"
            class="d-flex align-items-center"
          >
            <b-avatar icon="cart"></b-avatar>
            <span class="w-100">Carrello</span>

            <b-tooltip
              target="tooltip-target-1"
              triggers="hover"
              placement="right"
            >
              <template #title>Carrello modificato</template>
              Il carrello è stato modificato, le matricole saranno cancellate.
              <hr />
              Per ripristinare il contenuto del carrello clicca
              <b-link @click="ripristinaCarrello()">qui</b-link>.
            </b-tooltip>
            <b-avatar
              id="tooltip-target-1"
              :style="
                'color:orange;' +
                (cartIsEdited ? 'display:inline-flex' : 'display:none')
              "
              icon="exclamation-triangle"
            ></b-avatar>
          </b-list-group-item>

          <b-list-group-item
            :disabled="!cartOK"
            :variant="paymentOK ? 'success' : ''"
            :to="paymentPath"
            class="d-flex align-items-center"
          >
            <b-avatar icon="credit-card"></b-avatar>
            <span>Pagamento</span>
          </b-list-group-item>

          <b-list-group-item
            :variant="detailsOK ? 'success' : ''"
            :to="detailsPath"
            class="d-flex align-items-center"
          >
            <b-avatar icon="info-circle"></b-avatar>
            <span>Dettagli</span>
          </b-list-group-item>

          <b-list-group-item
            :to="articlesPath"
            class="d-flex align-items-center"
          >
            <b-avatar icon="box-seam"></b-avatar>
            <span>ArticoliCliente</span>
          </b-list-group-item>

          <b-list-group-item
            :disabled="!confirmOK"
            :variant="confirmOK ? 'info' : ''"
            @click="conferma()"
            class="d-flex align-items-center text-center mt-2"
            button
          >
            <b-avatar icon="check-circle"></b-avatar>
            <span>Conferma</span>
          </b-list-group-item>
        </b-list-group>
      </p>
    </b-sidebar>
    <div class="ms-3 me-3 mt-3">
      <h3>
        {{ statusDescription }}
      </h3>
      <b-sidebar id="sidebar-1" title="Strumenti" shadow backdrop>
        <b-nav vertical class="w-100 text-start">
          <b-nav-item @click="scaricaConfigurazione()"
            >Scarica configurazione</b-nav-item
          >
          <b-nav-item @click="caricaConfigurazione()">
            Carica configurazione
          </b-nav-item>
        </b-nav>
        <input
          id="fileInput"
          type="file"
          name="file"
          @change="handleFileSelect"
          style="display: none"
        />
        <pre id="fileContent"></pre>
      </b-sidebar>
      <a id="downloadAnchorElem" style="display: none"></a>

      <b-button
        class="d-md-none"
        style="
          position: fixed;
          bottom: 90px;
          right: 20px;
          z-index: 1;
          border-radius: 100%;
        "
        variant="success"
        size="lg"
        v-b-toggle.mobile-sidebar
      >
        <font-awesome-icon icon="fa-solid fa-list" style="margin-top: 7px" />
      </b-button>

      <div class="d-flex flex-column flex-md-row">
        <p class="d-none d-md-block">
          <!-- <b-button-group>
          <b-button variant="primary" class="mb-2" v-b-toggle.collapse-1>
            <b-icon icon="chevron-down" aria-label="Help"></b-icon>
          </b-button>
          <b-button variant="primary" class="mb-2" v-b-toggle.sidebar-1>
            <b-icon icon="tools" aria-label="Help"></b-icon>
          </b-button>
        </b-button-group> -->
          <b-collapse visible id="collapse-1" class="mt-2 shadow-lg">
            <b-list-group>
              <!-- <b-list-group-item>
              {{ statusDescription }}
            </b-list-group-item> -->
              <b-list-group-item
                v-if="scaduto > 0"
                :variant="'danger'"
                class="d-flex align-items-center"
              >
                <b-avatar icon="exclamation"></b-avatar>
                <span>Scaduto: {{ formatPrice(scaduto) }}</span>
              </b-list-group-item>

              <b-list-group-item
                :variant="customerOK ? 'success' : ''"
                :to="customerPath"
                class="d-flex align-items-center"
              >
                <b-avatar icon="person"></b-avatar>
                <span>Cliente</span>
              </b-list-group-item>

              <b-list-group-item
                :variant="locationOK ? 'success' : ''"
                :disabled="!enableLocation"
                :to="locationPath"
                class="d-flex align-items-center"
              >
                <b-avatar icon="geo-alt"></b-avatar>
                <span>Ubicazione</span>
              </b-list-group-item>

              <b-list-group-item
                :disabled="!enableContact"
                :variant="contactOK ? 'success' : ''"
                :to="contactPath"
                class="d-flex align-items-center"
              >
                <b-avatar icon="telephone"></b-avatar>
                <span>Contatti</span>
              </b-list-group-item>

              <b-list-group-item
                :variant="cartOK ? 'success' : ''"
                :to="carrelloPath"
                class="d-flex align-items-center"
              >
                <b-avatar icon="cart"></b-avatar>
                <span class="w-100">Carrello</span>

                <b-popover
                  target="popover-target-1"
                  triggers="hover"
                  placement="right"
                >
                  <template #title>Carrello modificato</template>
                  Il carrello è stato modificato, le matricole saranno
                  cancellate.
                  <hr />
                  Per ripristinare il contenuto del carrello clicca
                  <b-link @click="ripristinaCarrello()">qui</b-link>.
                </b-popover>
                <b-avatar
                  id="popover-target-1"
                  :style="
                    'color:orange;' +
                    (cartIsEdited ? 'display:inline-flex' : 'display:none')
                  "
                  icon="exclamation-triangle"
                ></b-avatar>
              </b-list-group-item>

              <b-list-group-item
                :disabled="!cartOK"
                :variant="paymentOK ? 'success' : ''"
                :to="paymentPath"
                class="d-flex align-items-center"
              >
                <b-avatar icon="credit-card"></b-avatar>
                <span>Pagamento</span>
              </b-list-group-item>

              <b-list-group-item
                :variant="detailsOK ? 'success' : ''"
                :to="detailsPath"
                class="d-flex align-items-center"
              >
                <b-avatar icon="info-circle"></b-avatar>
                <span>Dettagli</span>
              </b-list-group-item>

              <b-list-group-item
                :to="articlesPath"
                :variant="articoliClienteOK ? 'success' : ''"
                class="d-flex align-items-center"
              >
                <b-avatar icon="box-seam"></b-avatar>
                <span>ArticoliCliente</span>
              </b-list-group-item>

              <b-list-group-item
                :disabled="!confirmOK"
                :variant="confirmOK ? 'info' : ''"
                @click="conferma()"
                class="d-flex align-items-center text-center mt-2"
                button
              >
                <b-avatar icon="check-circle"></b-avatar>
                <span>Conferma</span>
              </b-list-group-item>
            </b-list-group>
          </b-collapse>
        </p>

        <p class="w-100">
          <router-view
            :key="$route.path"
            :form="formData"
            :editable="editable"
            :details-editable="detailsEditable"
            v-on:nuovoLocale="handleNuovoLocale"
            class="m-2 shadow-lg"
          ></router-view>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import * as orderService from "../services/Ordine";
import * as scadutiService from "../services/Scaduti";
import * as customerService from "../services/Cliente";
import * as articoloClienteOrdineService from "../services/ArticoloClienteOrdine";

export default {
  name: "OrderForm",
  data() {
    return {
      formData: {
        id: null,
        status: 2,
        data: new Date().toString(),
        customer: {
          selected: false,
          id: "",
        },
        location: {
          selected: false,
          id: "",
        },
        contact: { list: [] },
        cart: { items: [], sconto: 0, canoni: false },
        payment: {
          alternativeBilling: { active: false, id: null },
          billing: 1,
          grenke: false,
          acconto: { active: false, totale: 0, pagamento: null },
          type: null,
          payment_note: null,
        },
        details: {
          technician: null,
          type: null,
          escape_date: null,
          tec_note: "",
          admin_note: "",
          billing_note: "",
          wait_payment: false,
          invia_comunicazione: false,
        },
        articoliCliente: { items: [] },
      },
      scaduto: 0,
      editItems: [],
      ordine: {},
    };
  },
  async created() {
    this.$loading(true);
    await this.$store.dispatch("caricaApiToken");
    const accessToken = await this.$auth.getTokenSilently();
    await this.$store.dispatch("caricaClienti", accessToken);
    await this.$store.dispatch("caricaLocali", accessToken);
    await this.$store.dispatch("caricaMetodiDiPagamento", accessToken);
    await this.$store.dispatch("caricaArticoli", accessToken);
    await this.$store.dispatch("caricaUtenti", accessToken);
    await this.$store.dispatch("caricaTipiOrdine", accessToken);
    await this.$store.dispatch("caricaProfiliContatto", accessToken);
    await this.$store.dispatch("caricaProfiliLocale", accessToken);
    await this.$store.dispatch("caricaCategorieLocale", accessToken);

    if (this.isEdit) {
      //Scarico dati ordine
      let ordine = await orderService.getByIdWithInclude(
        accessToken,
        this.$route.params.id,
        ["articolo_ordines", "articolo_cliente_ordines"]
      );
      this.ordine = ordine;
      //Imposto stato,id e data
      this.formData.status = ordine.stato;
      this.formData.id = ordine.id;
      this.formData.data = ordine.data;
      //Imposto scheda cliente
      this.formData.customer.selected = true;
      this.formData.customer.id = ordine.cliente;
      //Imposto scheda locale
      if (ordine.locale) {
        this.formData.location.selected = true;
        this.formData.location.id = ordine.locale;
      }
      //Imposto scheda carrello
      let totaleCarrello = 0;
      if (ordine.articolo_ordines) {
        for (let o of ordine.articolo_ordines) {
          totaleCarrello += (1 - o.sconto / 100) * (o.quantita * o.prezzo);

          let item = {
            id: o.articolo,
            quantita: o.quantita,
            sconto: o.sconto,
            prezzo: o.prezzo,
          };
          this.formData.cart.items.push(item);
          this.editItems.push({ ...item });
        }
      }
      this.formData.cart.canoni = ordine.has_rid;
      //Sconto
      this.formData.cart.sconto = 0;
      if (totaleCarrello > 0)
        this.formData.cart.sconto =
          100 -
          (ordine.totale * 100) / (Math.round(totaleCarrello * 100) / 100);
      //Imposto scheda pagamento
      if (ordine.cliente_fatt_alt != null && ordine.cliente_fatt_alt != "") {
        this.formData.payment.alternativeBilling.active = true;
        this.formData.payment.alternativeBilling.id = ordine.cliente_fatt_alt;
      }
      this.formData.payment.type = ordine.pagamento;
      this.formData.payment.grenke = ordine.grenke;
      this.formData.payment.payment_note = ordine.note_pagamento;
      this.formData.payment.billing = ordine.tipo_fatturazione
        ? ordine.tipo_fatturazione
        : 1;

      //Caricamento acconto
      this.formData.payment.acconto.active = ordine.down_payment > 0;
      this.formData.payment.acconto.totale = ordine.down_payment;
      this.formData.payment.acconto.pagamento = ordine.down_payment_type;

      //Imposto scheda dettagli
      this.formData.details.technician = ordine.tecnico;
      this.formData.details.type = ordine.tipo;
      this.formData.details.escape_date = ordine.data_consegna;
      this.formData.details.wait_payment = ordine.wait_payment;
      this.formData.details.billing_note = ordine.note_fattura;
      this.formData.details.tec_note = ordine.note_tecniche;
      this.formData.details.admin_note = ordine.note_amministrative;
      this.formData.details.invia_comunicazione = ordine.invia_comunicazione;

      //Imposto scheda articoli cliente
      if (ordine.articolo_cliente_ordines) {
        this.formData.articoliCliente.items =
          ordine.articolo_cliente_ordines.map((value) => {
            return {
              articolo: value.articolo,
              attivazione: value.attivazione,
              matricola: value.matricola,
            };
          });
      }
    }
    this.$loading(false);
  },
  watch: {
    activeCustomerId: {
      async handler(newCustomerId) {
        //Se sto selezionando un cliente
        if (newCustomerId) {
          //Controllo se il cliente ha degli scaduti
          await this.caricaScaduto();

          //Se il form è aperto in modalità creazione e non ho ancora
          //selezionato un metodo di pagamento
          if (!this.isEdit && this.formData.payment.type == null) {
            //Imposto metodo di pagamento
            this.impostaPagamentoPredefinito(this.formData.customer.id);
          }
        }
        //Se sto deselezionando un cliente
        else this.scaduto = 0;
      },
    },
    itemsForWatching: {
      deep: true,
      handler(newCart) {
        let itemsIniziali = this.editItems;

        //SE FACCIO DEGLI INSERIMENTI OK
        //SE FACCIO DELLE MODIFICHE DOVRO' CANCELLARE LE MATRICOLE

        //se gli items iniziali sono vuoti devo fare solo inserimenti
        //altrimenti se gli items iniziali non sono vuoti
        //  se
        // console.log("ITEMS INIZIALI:");
        // console.log(itemsIniziali);

        // console.log("NUOVI ITEMS:");
        // console.log(newCart);

        // let itemsDaEliminare = [];
        // let itemsDaModificare = [];
        // let itemsDaInserire = [];

        // console.log("Quello che c'era è rimasto uguale?");

        //QUELLO CHE C'ERA E' RIMASTO UGUALE:
        //Il totale degli item iniziali per i quali esiste nel nuovo carrello
        //un item identico è uguale al totale degli items iniziali
        // let inizialiFraINuovi = itemsIniziali.filter((x) =>
        //   newCart.some(
        //     (y) =>
        //       y.sconto == x.sconto &&
        //       y.id == x.id &&
        //       y.prezzo == x.prezzo &&
        //       y.quantita == x.quantita
        //   )
        // );
        // if (inizialiFraINuovi.length == itemsIniziali.length) {
        //   console.log("SI");
        // } else {
        //   console.log("NO");
        // }

        // let itemsInizialiPresentiNelNuovoCarrello = itemsIniziali.filter(x =>
        //   newCart.includes(x)
        // );
        // let itemsEliminati = itemsIniziali.filter(x => !newCart.includes(x));
        // console.log(itemsInizialiPresentiNelNuovoCarrello);
        // console.log(itemsEliminati);
      },
    },
  },
  computed: {
    cartIsEdited() {
      let itemsIniziali = this.editItems;
      let newCart = this.formData.cart.items;
      let inizialiFraINuovi = itemsIniziali.filter((x) =>
        newCart.some(
          (y) =>
            y.sconto == x.sconto &&
            y.id == x.id &&
            y.prezzo == x.prezzo &&
            y.quantita == x.quantita
        )
      );
      return (
        inizialiFraINuovi.length != itemsIniziali.length ||
        itemsIniziali.length != newCart.length
      );
    },
    itemsForWatching() {
      return this.formData.cart.items;
    },
    activeCustomerId() {
      return this.formData.customer.id;
    },
    editable() {
      return !this.isEdit || this.formData.status == 2;
    },
    detailsEditable() {
      return this.formData.status < 7;
    },
    isEdit() {
      return this.$route.params.id ? true : false;
    },
    confirmOK() {
      return this.customerOK && this.cartOK && this.detailsOK && this.paymentOK;
    },
    customerOK() {
      return this.formData.customer.selected;
    },
    locationOK() {
      return this.formData.location.selected;
    },
    cartOK() {
      return this.formData.cart.items.length > 0;
    },
    contactOK() {
      return this.formData.contact.list.length > 0;
    },
    detailsOK() {
      return (
        this.formData.details.technician != null &&
        this.formData.details.type != null
      );
    },
    paymentOK() {
      return this.formData.payment.type != null;
    },
    articoliClienteOK() {
      return this.formData.articoliCliente.items.length > 0;
    },
    enableLocation() {
      return this.formData.customer.selected;
    },
    enableContact() {
      return this.formData.customer.selected && this.formData.location.selected;
    },
    basePath() {
      let path = this.$route.path.split("/");
      path.pop();
      return path.join("/");
    },
    customerPath() {
      return this.basePath + "/cliente";
    },
    locationPath() {
      return this.basePath + "/locale";
    },
    contactPath() {
      return this.basePath + "/contatti";
    },
    carrelloPath() {
      return this.basePath + "/carrello";
    },
    paymentPath() {
      return this.basePath + "/pagamento";
    },
    detailsPath() {
      return this.basePath + "/dettagli";
    },
    articlesPath() {
      return this.basePath + "/articoli";
    },
    statusDescription() {
      if (this.isEdit) {
        let tipo = "";
        if (this.formData.status == 2) tipo = "PREVENTIVO";
        if (this.formData.status == 3) tipo = "ORDINE";
        if (this.formData.status == 5) tipo = "IMPEGNO CREATO";
        if (this.formData.status == 6) tipo = "IN CONSEGNA";
        if (this.formData.status == 7) tipo = "EVASO";
        if (this.formData.status == 8) tipo = "CANCELLATO";
        const date = this.formatDate(this.formData.data);
        return `${this.formData.id} ${tipo} ${date}`;
      } else {
        return `Creazione preventivo`;
      }
    },
    totalOrder() {
      let tot = 0;
      for (var item of this.formData.cart.items) {
        tot += (1 - item.sconto / 100) * (item.quantita * item.prezzo);
      }
      return (1 - this.formData.cart.sconto / 100) * tot;
    },
  },
  methods: {
    handleNuovoLocale(locale) {
      this.formData.details.invia_comunicazione = true;
      this.$toast.open("Selezionato automaticamente 'mail di benvenuto'");
    },
    impostaPagamentoPredefinito(customer_id) {
      const cliente = this.$store.state.clienti_search[customer_id];
      if (cliente && cliente.pagamento) {
        this.formData.payment.type = cliente.pagamento;
        this.$toast.open(
          "E' stato impostato automaticamente il metodo di pagamento di default del cliente"
        );
      }
    },
    //Carica lo scaduto del cliente attivo
    async caricaScaduto() {
      const accessToken = await this.$auth.getTokenSilently();
      //Estraggo id_trace cliente
      const cliente = await customerService.getById(
        accessToken,
        this.formData.customer.id
      );
      if (!cliente) {
        this.$toast.error("Impossibile caricare scaduto cliente");
        return;
      }
      //Estraggo scaduto cliente
      const scaduto = await scadutiService.getByIdTrace(
        accessToken,
        cliente.id_trace
      );
      const totale = scaduto.data ? scaduto.data.totale_scaduto : 0;

      //Aggiorno scaduto
      this.scaduto = totale;

      if (this.scaduto > 0) {
        this.$toast.warning(
          "Il cliente selezionato ha uno scaduto di " +
            this.formatPrice(this.scaduto)
        );
      }
    },
    formatDate(date) {
      if (date == null) return "";
      let splitted = date.toString().substring(0, 10).split("-");
      return splitted[2] + "/" + splitted[1] + "/" + splitted[0];
    },
    formatPrice(number) {
      return (
        Number(number).toLocaleString("es-ES", {
          minimumFractionDigits: 2,
        }) + "€"
      );
    },
    ripristinaCarrello() {
      if (
        confirm(
          "Confermi di voler ripristinare il carrello?\nnon sarà possibile annullare l'operazione in seguito"
        )
      ) {
        this.formData.cart.items = this.editItems;
      }
    },
    encrypt(content, pwd = "itopinonavevanonipoti") {
      return this.CryptoJS.AES.encrypt(JSON.stringify(content), pwd).toString();
    },
    decrypt(content, pwd = "itopinonavevanonipoti") {
      return this.CryptoJS.AES.decrypt(content, pwd).toString(
        this.CryptoJS.enc.Utf8
      );
    },
    download(contenuto, titolo) {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(this.encrypt(contenuto));
      var dlAnchorElem = document.getElementById("downloadAnchorElem");
      dlAnchorElem.setAttribute("href", dataStr);
      dlAnchorElem.setAttribute("download", titolo + ".torder");
      dlAnchorElem.click();
    },
    scaricaConfigurazione() {
      let name = prompt("Nome file:", "orderConfig");
      if (name) this.download(this.formData, name);
    },
    handleFileSelect(event) {
      const reader = new FileReader();
      reader.onload = this.handleFileLoad;
      reader.readAsText(event.target.files[0]);
    },
    handleFileLoad(event) {
      let decrypted = this.decrypt(event.target.result);
      this.formData = JSON.parse(decrypted);
      alert("Configurazione caricata correttamente!");
    },
    caricaConfigurazione() {
      document.getElementById("fileInput").click();
    },
    async conferma() {
      this.$loading(true);
      if (this.isEdit) {
        await this.modificaOrdine(this.$route.params.id);
      } else {
        await this.creaOrdine();
      }
      this.$loading(false);
      this.$router.push({ name: "listaOrdini" });
    },
    configurationToArticoliCliente(id_ordine) {
      let articoli = this.formData.articoliCliente.items;
      if (!articoli || articoli.length == 0) return [];
      let lastPrg = {};
      let finalArticles = [];
      for (const articolo of articoli) {
        if (!lastPrg[articolo.articolo]) lastPrg[articolo.articolo] = 1;
        else lastPrg[articolo.articolo]++;
        finalArticles.push({
          ordine: id_ordine,
          articolo: articolo.articolo,
          prg: lastPrg[articolo.articolo],
          matricola: articolo.matricola ? articolo.matricola : null,
          attivazione: articolo.attivazione ? articolo.attivazione : null,
        });
      }
      return finalArticles;
    },
    configurationToOrder() {
      let form = this.formData;
      //Se trimmed value è stringa vuota o null o undefined => null
      //Altrimenti => trimmed value
      let note_fattura = form.details.billing_note
        ? form.details.billing_note.trim()
        : null;
      note_fattura = note_fattura ? note_fattura : null;

      let note_pagamento = form.payment.payment_note
        ? form.payment.payment_note.trim()
        : null;
      note_pagamento = note_pagamento ? note_pagamento : null;

      let note_tecniche = form.details.tec_note
        ? form.details.tec_note.trim()
        : null;
      note_tecniche = note_tecniche ? note_tecniche : null;

      let note_amministrative = form.details.admin_note
        ? form.details.admin_note.trim()
        : null;
      note_amministrative = note_amministrative ? note_amministrative : null;

      let ordine = {
        data: Date.now(),
        cliente: form.customer.selected ? form.customer.id : null,
        cliente_fatt_alt: form.payment.alternativeBilling.active
          ? form.payment.alternativeBilling.id
          : null,
        down_payment: form.payment.acconto.active
          ? form.payment.acconto.totale
          : 0,
        down_payment_type: form.payment.acconto.active
          ? form.payment.acconto.pagamento
          : null,
        data_consegna: form.details.escape_date,
        grenke: form.payment.grenke,
        has_rid: form.cart.canoni,
        locale: form.location.selected ? form.location.id : null,
        note_fattura: note_fattura,
        pagamento: form.payment.type,
        note_pagamento: note_pagamento,
        note_tecniche: note_tecniche,
        note_amministrative: note_amministrative,
        stato: form.status,
        tecnico: form.details.technician,
        tipo: form.details.type,
        tipo_fatturazione: form.payment.billing,
        totale: Math.round(this.totalOrder * 100) / 100,
        utente: this.$auth.user["https://rca-api/user_metadata"].matching_id,
        utente_lastupdate:
          this.$auth.user["https://rca-api/user_metadata"].matching_id,
        wait_payment: form.details.wait_payment,
        invia_comunicazione: form.details.invia_comunicazione,
      };
      return ordine;
    },
    configurationToOrderArticles() {
      let form = this.formData;
      let articles = [];
      for (let item of form.cart.items) {
        let article = {
          sconto: item.sconto,
          prezzo: item.prezzo,
          articolo: item.id,
          quantita: item.quantita,
        };
        articles.push(article);
      }
      return articles;
    },
    async creaOrdine() {
      let form = this.formData;

      let ordine = this.configurationToOrder();
      const accessToken = await this.$auth.getTokenSilently();
      let response = await orderService.add(accessToken, ordine);
      let articles = this.configurationToOrderArticles();
      for (let item of articles) {
        let itemResponse = await orderService.addArticoloOrdine(
          accessToken,
          response.id,
          item
        );
      }

      //Aggiorno articoli cliente
      await this.modificaArticoliCliente(response.id);
    },
    async modificaOrdine(id) {
      let ordine = this.configurationToOrder();
      //Se non è un preventivo elimino
      //proprietà non modificabili
      if (ordine.stato != 2) {
        delete ordine.cliente;
        delete ordine.cliente_fatt_alt;
        delete ordine.down_payment;
        delete ordine.down_payment_type;
        delete ordine.grenke;
        delete ordine.locale;
        delete ordine.pagamento;
        delete ordine.stato;
        delete ordine.tipo_fatturazione;
        delete ordine.totale;
      }
      //Non modifico l'utente
      delete ordine.utente;
      //Non modifico la data
      delete ordine.data;
      const accessToken = await this.$auth.getTokenSilently();
      await orderService.update(accessToken, id, ordine);
      //Modifica articoli ordine (solo se ho modificato il carrello)
      if (this.cartIsEdited) {
        const articoli = this.configurationToOrderArticles();
        //Elimino matricole e attivazioni
        await orderService.deleteSerialActivation(accessToken, id);
        //elimino articoli ordine
        await orderService.deleteArticoloOrdineByOrderId(accessToken, id);
        //Aggiungo i nuovi articoli
        for (let item of articoli)
          await orderService.addArticoloOrdine(accessToken, id, item);
      }
      // - se non sono entrato non faccio niente

      //Aggiorno articoli cliente
      await this.modificaArticoliCliente(id);
    },
    async modificaArticoliCliente(ordine) {
      const accessToken = await this.$auth.getTokenSilently();
      //Cancello articoli cliente
      const articoli_cliente = this.configurationToArticoliCliente(ordine);
      //Cancello gli articoli cliente attualmente presenti
      await articoloClienteOrdineService.deleteByOrderId(accessToken, ordine);
      //Inserisco nuovi articoli cliente
      for (const articolo of articoli_cliente) {
        await articoloClienteOrdineService.add(accessToken, articolo);
      }
    },
  },
};
</script>
